import React, {useEffect, useState} from 'react';
import {
  Button, Checkbox,
  FormControl, FormControlLabel,
  Grid,
  IconButton,
  MenuItem, RadioGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import {ReactComponent as SortDownIcon} from '../../../../images/icones/icn-sort-down.svg'
import {ReactComponent as SortUpIcon} from '../../../../images/icones/icn-sort-up.svg'
import RefreshIcon from '@mui/icons-material/Refresh';
import {useMissionContext} from '../../../../context/MissionContext';
import {ItemHistoriqueModel} from '../../../../model/detailsDossier/ItemHistoriqueModel';
import moment from 'moment';
import {UserContext} from '../../../../context/UserContext';
import {ActeurModel} from '../../../../model/detailsDossier/ActeurModel';
import {TextFieldSelect} from '../../../common/formsComponents/TextFieldSelect';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import useHasRole from '../../../../hook/useHasRole';
import {permittedRolesPiecesJointes} from '../../../../constantes/roles/Roles';
import groupBy from 'lodash/groupBy'
import {customTheme} from '../../../../common/GlobalTheme';
import {ContentCommonHistorique} from './ContentCommonHistorique';
import {Theme as MuiTheme} from '@mui/material/styles';
import {CSSInterpolation} from '@emotion/serialize';
import {EnteteCommentairehistorique} from '../EnteteCommentaireHistorique';

/** Design */
const styles = (theme: MuiTheme): Record<string, CSSInterpolation> => ({
  tabPanel: {
    padding: '0px',
    marginTop: '15px',
  },
  main: {
    lineHeight: '55px',
  },
  containerPrincipal: {
    border: '1px solid ' + theme.palette.secondary.dark1,
    borderRadius: '8px',
    opacity: '1',
    padding: '15px',
    margin: '30px 0px',
  },
  filtres: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  search: {
    paddingBottom: '10px',
    color: theme.palette.secondary.dark2,
  },
  textField: {
    width: 300,
    marginRight: theme.spacing(1),
  },
  noMessage: {
    textAlign: 'center' as const,
  },
  iconRoot: {
    margin: '0px',
  },
  buttonDate: {
    padding: '0px 30px 0px 15px',
  },
  content: {
    display: 'flex',
  },
  radioGroupe: {
    marginTop: '20px',
    width: '100%',
  },
  checkboxRoot: {
    paddingRight: '0px',
  },
  checkboxChecked: {
    color: theme.palette.link.main,
  },
  formLabel: {
    maxWidth: 'max-content',
  },
  gridItemLeft: {
    paddingLeft: '20px',
  },
});

interface CommonHistoriqueProps {
  onClickPj: (tabPanelValue: number, idPJ: string) => void,
  onClickComment: ((tabPanelValue: number, date: string) => void) | undefined,
  setMessagesLength?: (messagesLength: number) => void,
  typeHistorique: 'simplified' | 'detailed'
}

export const getActeurName = (acteurList: ActeurModel[], code: string, baseName: string) => {
  const acteur = acteurList.find((acteur) => acteur.codeAbonne?.trim() === code.trim());
  return acteur?.libelle ?? baseName
};

export const CommonHistorique = (
    {onClickPj, onClickComment, setMessagesLength, typeHistorique}: CommonHistoriqueProps) => {
  const classes = useEmotionStyles(styles);
  const {mission} = useMissionContext();
  const {user} = React.useContext(UserContext);
  const hasRolePiecesJointes = useHasRole(permittedRolesPiecesJointes);
  const list = mission?.historiqueDTO?.list ?? [];
  const messages = list.filter((message) =>
      ((message.typeCode !== '900501' && typeHistorique === 'simplified') || typeHistorique === 'detailed' ) ?
          message : '')
      .map((message: ItemHistoriqueModel) => {
        return message.envoyeRecu === 'envoye' ?
        {...message,
          destinataire: getActeurName(mission!.acteursDTO!.acteurs!, message.destinataireAbo!, message.destinataire)} :
        {...message,
          emetteur: getActeurName(mission!.acteursDTO!.acteurs!, message.emetteurAbo!, message.emetteur)}
      });
  const messagesWithoutPJ = messages?.filter((message) => message.typeCode !== '900501' ? message : '');
  const [messagesToDisplay, setMessagesToDisplay] =
      useState<ItemHistoriqueModel[]>(typeHistorique === 'simplified' ?messages : messagesWithoutPJ);
  const [displayPJ, setDisplayPJ] = useState(false);
  const groupeEtat = groupBy(displayPJ ? messages : messagesWithoutPJ, 'etat');
  const groupeStatut = groupBy(displayPJ ? messages : messagesWithoutPJ, 'statut');
  const [etat, setEtat] = React.useState<string[]>([]);
  const [statut, setStatut] = React.useState<string[]>([]);
  const [value, setValue] = React.useState(0);
  const [typeMessage, setTypeMessage] = React.useState('');
  const [acteur, setActeur] = React.useState('');
  const [sortDate, setSortDate] = useState(1);
  const messagesSent = messagesToDisplay?.filter((message) => message.envoyeRecu === 'envoye' ? message : '');
  const messagesReceived = messagesToDisplay?.filter((message) => message.envoyeRecu === 'recu' ? message : '');

  useEffect(() => {
    if (setMessagesLength) {
      switch (value) {
        case 0:
          setMessagesLength(messagesToDisplay.length);
          break;
        case 1:
          setMessagesLength(messagesSent.length);
          break;
        case 2:
          setMessagesLength(messagesReceived.length);
          break;
      }
    }
  }, [value, messagesToDisplay]);

  const changeFiltersSimplified = (result: ItemHistoriqueModel[]) => {
    if (typeHistorique === 'simplified') {
      if (typeMessage === '' && acteur === '') {
        setMessagesToDisplay(result)
      }
    }
  }

  const changeFiltersDetailed = (result: ItemHistoriqueModel[]) => {
    if (typeHistorique === 'detailed') {
      if (etat.length > 0) {
        result = result?.filter((message) => {
          return etat.includes(message.etat)
        });
        setMessagesToDisplay(result)
      }

      if (statut.length > 0) {
        result = result?.filter((message) => {
          return statut.includes(message.statut)
        });
        setMessagesToDisplay(result)
      }

      if (typeMessage === '' && acteur === '' && etat.length === 0 && statut.length === 0) {
        setMessagesToDisplay(result)
      }
    }
  }

  const changeFilters = () => {
    let result = (displayPJ || typeHistorique === 'simplified') ? messages : messagesWithoutPJ;

    // si displayPJ == false et que typeMessage == PJ, alors on set le filtre type message à vide
    if (!displayPJ && typeHistorique === 'detailed' && typeMessage === 'Pièce Jointe') setTypeMessage('');

    if (typeMessage!=='') {
      result = result?.filter((message) => message.libelle.trim() === typeMessage.trim() ? message : '');
      setMessagesToDisplay(result)
    }

    if (acteur!=='') {
      result = result?.filter((message) => (message.emetteurAbo?.trim() === acteur.trim() ||
        message.destinataireAbo?.trim() === acteur.trim()) ? message : '');
      setMessagesToDisplay(result)
    }

    changeFiltersSimplified(result)
    changeFiltersDetailed(result)
  };

  const handleChange = (event: React.ChangeEvent<{}>, value: number) => {
    setValue(value)
  };

  const handleChangeTypeMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTypeMessage(event.target.value)
  };

  const handleChangeActeur = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActeur(event.target.value)
  };

  const sortByDate = () => {
    setMessagesToDisplay([...messagesToDisplay].sort((a, b) => {
      const dateA = moment(a.dateReception?.replace(' - ', ''), 'DD/MM/YYYY HH:mm:ss').unix();
      const dateB = moment(b.dateReception?.replace(' - ', ''), 'DD/MM/YYYY HH:mm:ss').unix();
      return dateA > dateB ? sortDate : -sortDate
    }));
    setSortDate(-sortDate)
  };

  const typesMessage: string[] = [];
  ((displayPJ || typeHistorique === 'simplified') ? messages : messagesWithoutPJ)?.map((message) => {
    typesMessage.push(message.libelle.trim())
  });
  const listTypesMessage = Array.from(new Set([...typesMessage])).slice().sort((a, b) => {
    if (a > b) {
      return 1
    } else {
      return -1
    }
  });

  const acteurs: {codeAbo?: string, libelle: string}[] = [];
  ((displayPJ || typeMessage === 'simplified') ? messages : messagesWithoutPJ)?.map((message: ItemHistoriqueModel) => {
    if (message.emetteur !== '') {
      acteurs.push({codeAbo: message.emetteurAbo, libelle: message.emetteur.trim()});
    }
    if (message.destinataire !== '') {
      acteurs.push({codeAbo: message.destinataireAbo, libelle: message.destinataire.trim()});
    }
  });
  const acteursFiltres = Array.from(new Map(acteurs.map((acteur) => [acteur['codeAbo']?.trim(), acteur])).values());
  const listActeurs = acteursFiltres.slice().filter((acteur) => acteur.codeAbo !== user.codeAbo)
      .sort((a, b) => {
        if (a.libelle > b.libelle) {
          return 1
        } else {
          return -1
        }
      });

  const resetFilters = () => {
    setTypeMessage('');
    setActeur('');
    setSortDate(1);
    if (typeHistorique === 'detailed') setEtat([]);
    if (typeHistorique === 'detailed') setStatut([]);
    setMessagesToDisplay((displayPJ|| typeHistorique === 'simplified') ? messages : messagesWithoutPJ);
  };

  useEffect(() => {
    changeFilters()
  }, [typeMessage, acteur, etat, statut, displayPJ]);

  const updateEtatStatut = () => {
    if (!displayPJ) {
      // si displayPJ == false on compare la liste des états sélectionnés et des états disponibles pour enlever
      // ceux sélectionnés qui ne sont plus disponibles (car PJ masquées)
      let etatFiltered = etat;
      etat.forEach((e1) => {
        if (!Object.keys(groupeEtat).includes(e1)) {
          etatFiltered = etatFiltered.filter((e2) => e2 !== e1)
        }
      });
      setEtat(etatFiltered);

      // même chose avec les statuts
      let statutFiltered = statut;
      statut.forEach((s1) => {
        if (!Object.keys(groupeStatut).includes(s1)) {
          statutFiltered = statutFiltered.filter((s2) => s2 !== s1)
        }
      });
      setStatut(statutFiltered);
    }
  };

  useEffect(() => {
    updateEtatStatut()
  }, [displayPJ])

  const onChangeEtat = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value;
    setEtat(etat.includes(keyword) ? etat.filter((c) => c !== keyword) : [...etat, keyword])
  };

  const onChangeStatut = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value;
    setStatut(statut.includes(keyword) ? statut.filter((c) => c !== keyword) : [...statut, keyword])
  };

  const handleChangeDisplayPJ = () => {
    setDisplayPJ(!displayPJ);
  };

  return (
    <div>
      <EnteteCommentairehistorique value={value} handleChange={handleChange}
        messagesSentLength={messagesSent?.length} messagesReceivedLength={messagesReceived?.length}/>
      <div className={classes.main}>
        <form noValidate autoComplete="off">
          <div className={classes.containerPrincipal}>
            <Typography className={classes.search}>Rechercher vos messages</Typography>
            <div className={classes.filtres}>
              <div>
                <TextFieldSelect
                  className={classes.textField}
                  id={'typeMessage'}
                  name={'typeMessage'}
                  label={'Type de message'}
                  marginDense={true}
                  withEmptyItem={listTypesMessage.length > 1}
                  emptyItemLabel={'Tous'}
                  disabled={listTypesMessage.length === 1}
                  value={listTypesMessage.length !== 1 ? typeMessage : listTypesMessage[0]}
                  itemsList={listTypesMessage.map((type) => {
                    return (
                      <MenuItem key={type} value={type}>{type}</MenuItem>
                    )
                  })}
                  onChange={handleChangeTypeMessage}
                />
                <TextFieldSelect
                  className={classes.textField}
                  id={'acteur'}
                  name={'acteur'}
                  label={'Acteur'}
                  marginDense={true}
                  withEmptyItem={listActeurs.length > 1}
                  emptyItemLabel={'Tous'}
                  disabled={listActeurs?.length === 1}
                  value={listActeurs.length !== 1 ? acteur : listActeurs[0].codeAbo ?? ''}
                  itemsList={listActeurs.map((acteur) => {
                    return (
                      <MenuItem key={acteur.codeAbo} value={acteur.codeAbo}>{acteur.libelle}</MenuItem>
                    )
                  })}
                  onChange={handleChangeActeur}
                />
                <Button id={'filtreDateHisto'} className={classes.buttonDate} onClick={sortByDate}>
                  <div style={{display: 'flex', flexDirection: 'column', margin: '8px 0 0 2px',
                    opacity: '0.8'}}>
                    {sortDate === 1 ? <SortUpIcon /> : <SortDownIcon />}
                    <span>Date</span>
                  </div>
                </Button>
                <Tooltip disableInteractive title="Réinitialiser la recherche">
                  <IconButton id={'btnReinitRecherche'} onClick={() => resetFilters()}>
                    <RefreshIcon classes={{root: classes.iconRoot}} />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div className={classes.content}>
        {typeHistorique === 'detailed' && <div style={{width: '30%'}}>
          <Grid item className={classes.gridItemLeft}>
            <FormControl component="fieldset" className={classes.radioGroupe}>
              <Typography style={{color: customTheme.palette.secondary.dark2}}>État</Typography>
              <RadioGroup name="etat" value={etat}>
                {Object.keys(groupeEtat).length === 1 ?
                  Object.keys(groupeEtat).map((key) => {
                    return (
                      <FormControlLabel key={key} className={classes.formLabel}
                        control={
                          <Checkbox checked={true} disabled color="secondary" id={'etat-' + key.trim()}
                            classes={{root: classes.checkboxRoot, checked: classes.checkboxChecked}}
                            value={key.trim()} onChange={onChangeEtat}
                          />
                        }
                        label={key.trim()}
                      />
                    )
                  }) :
                  Object.keys(groupeEtat).sort((a, b) => a.localeCompare(b)).map((key) => {
                    return (
                      <FormControlLabel key={key} className={classes.formLabel}
                        control={
                          <Checkbox color='default' checked={etat.includes(key)} id={'etat-' + key.trim()}
                            classes={{root: classes.checkboxRoot, checked: classes.checkboxChecked}}
                            value={key.trim()} onChange={onChangeEtat}
                          />
                        }
                        label={key.trim()}
                      />
                    )
                  })
                }
              </RadioGroup>
            </FormControl>
            <FormControl component="fieldset" className={classes.radioGroupe}>
              <Typography style={{color: customTheme.palette.secondary.dark2}}>Statut</Typography>
              <RadioGroup name="etat" value={statut}>
                {Object.keys(groupeStatut).length === 1 ?
                  Object.keys(groupeStatut).map((key) => {
                    return (
                      <FormControlLabel key={key} className={classes.formLabel}
                        control={
                          <Checkbox checked={true} disabled color="secondary" id={'statut-' + key.trim()}
                            classes={{root: classes.checkboxRoot, checked: classes.checkboxChecked}}
                            value={key.trim()} onChange={onChangeStatut}
                          />
                        }
                        label={key.trim()}
                      />
                    )
                  }) :
                  Object.keys(groupeStatut).sort((a, b) => a.localeCompare(b)).map((key) => {
                    return (
                      <FormControlLabel key={key} className={classes.formLabel}
                        control={
                          <Checkbox color='default' checked={statut.includes(key)} id={'statut-' + key.trim()}
                            classes={{root: classes.checkboxRoot, checked: classes.checkboxChecked}}
                            value={key.trim()} onChange={onChangeStatut}
                          />
                        }
                        label={key.trim()}
                      />
                    )
                  })
                }
              </RadioGroup>
            </FormControl>
          </Grid>
          {hasRolePiecesJointes && messages.some((msg: ItemHistoriqueModel) => msg.typeCode === '900501') &&
            <div style={{display: 'flex', alignItems: 'center', margin: '20px 0px 0px 10px'}}>
              <Checkbox classes={{root: classes.checkboxRoot, checked: classes.checkboxChecked}} checked={displayPJ}
                color='default' value={'Afficher PJ'} onChange={handleChangeDisplayPJ}
              />
              <Typography>Afficher les messages PJ</Typography>
            </div>
          }
        </div>}
        <div style={{width: '100%'}}>
          <ContentCommonHistorique value={value} onClickPj={onClickPj} onClickComment={onClickComment}
            messagesToDisplay={messagesToDisplay} messagesSent={messagesSent}
            messagesReceived={messagesReceived} typeHistorique={typeHistorique}/>
        </div>
      </div>
    </div>
  )
};
