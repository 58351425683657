import * as yup from 'yup';
import {getTypeImmat} from '../../../../common/utils/InputValuesUtils';

export const validationSchemaNumeroFormule = yup.object({
  numeroFormule: yup.string()
      .when(['immatriculation', 'presenceCertificat'], {
        is: (immatriculation: string, presenceCertificat: boolean) =>
          (getTypeImmat(immatriculation) === 'SIV' || getTypeImmat(immatriculation) === 'CYCLO') && presenceCertificat,
        then: () => yup.string()
            .required('Veuillez saisir une valeur'),
      }),
});
