import React from 'react';
import {ButtonBlue} from '../../../../common/buttons/ButtonBlue';
import useEmotionStyles from '../../../../../common/useEmotionStyles';

/** Design */
const styles = () => ({
  btnFermer: {
    margin: '60px 0px 15px',
    textTransform: 'none' as const,
  },
});

export const FooterRequeteSIV = () => {
  const classes = useEmotionStyles(styles);

  return (
    <div style={{textAlign: 'center'}}>
      <ButtonBlue id={'bouton-fermer'} className={classes.btnFermer} libelle={'Fermer'}
        onClick={() => window.close()}/>
    </div>
  )
};
