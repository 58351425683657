import useEmotionStyles from '../../../../common/useEmotionStyles';
import {GridColDef} from '@mui/x-data-grid';
import {Theme} from '@emotion/react';

/** Design */
const styles = (theme: Theme) => ({
  headerGrid: {
    backgroundColor: theme.palette.grey.A200,
  },
  iconLink: {
    cursor: 'pointer',
    fill: theme.palette.link.main,
  },
  paddingTop14: {
    paddingTop: '14px',
  },
});

export const useGridColumns = () => {
  const classes = useEmotionStyles(styles);

  const columns: GridColDef[] = [
    {field: 'libelleTypeDocument', headerName: 'Type de document', headerClassName: classes.headerGrid,
      headerAlign: 'center', sortable: false, flex: 0.8, align: 'center',
    },

    {field: 'libelleSousTypeDocument', headerName: 'Sous type de document', headerClassName: classes.headerGrid,
      headerAlign: 'center', sortable: false, flex: 0.8, align: 'center',
    },

    {field: 'referenceSinistre', headerName: 'Référence sinistre', headerClassName: classes.headerGrid,
      headerAlign: 'center', sortable: false, flex: 0.6, align: 'center',
    },

    {field: 'codeErreur', headerName: 'Code erreur', headerClassName: classes.headerGrid, headerAlign: 'center',
      sortable: false, flex: 0.6, align: 'center',
    },

    {field: 'libelleErreur', headerName: 'Description', headerClassName: classes.headerGrid, headerAlign: 'center',
      sortable: false, flex: 1, align: 'center',
    },
  ];

  return columns;
};
