import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
  GridColumnHeaderParams,
  GridHeaderCheckbox,
} from '@mui/x-data-grid';
import {ButtonLink} from '../../../common/buttons/ButtonLink';
import React from 'react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';
import {ECHANGES_COMPONENTS} from '../../../forms/rechercheEchanges/RechercheEchanges';
import {Typography} from '@mui/material';
import {InfosEchange} from '../../../../model/rechercheEchange/InfosEchange';
import {InfosTypesDocs} from '../../../../model/rechercheEchange/InfosTypesDocs';

interface IProps {
  idEchange: string,
  numeroEchange: string,
  isEchangeRAD: boolean,
  actionEchange: string,
  callbackComponent: (
    component: string,
    infosEchange: InfosEchange,
    infosTypesDocs: InfosTypesDocs,
    lastPaginationCall?: any,
    pageSize?: string
  ) => void,
  lastPaginationCall?: any,
  pageSize?: string
}

/** Design */
const styles = (theme: Theme) => ({
  headerGrid: {
    backgroundColor: theme.palette.grey.A200,
  },
  checkBoxRADGrid: {
    display: 'grid',
    gridTemplateColumns: '25px 25px',
    alignItems: 'center',
    gridColumnGap: '12px',
  },
  checkBoxRADColumnHeader: {
    fontWeight: 401,
    fontSize: '0.875rem',
    lineHeight: 1.43,
  },
  hidden: {
    contentVisibility: 'hidden' as const,
  },
});

export const useGridColumns = (props: IProps) => {
  const classes = useEmotionStyles(styles);

  const handleClickNbDocs = (infos: any) => {
    props.callbackComponent(
        ECHANGES_COMPONENTS.DETAILS_ECHANGE_ET_DETAILS_PAR_TYPE_DOC,
        {
          idEchange: props.idEchange,
          numeroEchange: props.numeroEchange,
          isEchangeRAD: props.isEchangeRAD,
          actionEchange: props.actionEchange,
        } as InfosEchange,
        {
          typeDoc: infos.codeType,
          sousTypeDoc: infos.codeSousType,
        } as InfosTypesDocs,
        props.lastPaginationCall,
        props.pageSize,
    );
  };

  const columns: GridColDef[] = [
    {field: 'libelleType', headerName: 'Type de document', headerClassName: classes.headerGrid, headerAlign: 'center',
      sortable: false, flex: 1, align: 'center',
    },

    {field: 'libelleSousType', headerName: 'Sous type de document', headerClassName: classes.headerGrid,
      headerAlign: 'center', sortable: false, flex: 1, align: 'center',
    },

    {field: 'nbDocuments', headerName: 'Nombre de documents', headerClassName: classes.headerGrid, sortable: false,
      align: 'center', flex: 1, headerAlign: 'center',
      renderCell: (params) => (
        <ButtonLink
          id={'bouton-nombre-documents-'.concat(params.row.codeType).concat(params.row.codeSousType.trim())}
          libelle={params.row.nbDocuments.toString()}
          isBackgroundHover={false}
          isDisableRipple={true}
          onClick={() => handleClickNbDocs(params.row)}
        />
      ),
    },
    {headerClassName: classes.headerGrid, flex: 0.7, ...GRID_CHECKBOX_SELECTION_COL_DEF,
      renderHeader: (params: GridColumnHeaderParams) => (
        <div className={classes.checkBoxRADGrid}>
          <Typography className={classes.checkBoxRADColumnHeader}>RAD</Typography>
          <GridHeaderCheckbox {...params} />
        </div>
      ),
      cellClassName: () => (
          !props.isEchangeRAD ? classes.hidden : ''
      ),
    },
  ];
  return columns;
};
