import React from 'react'
import {PriseEnCharge} from './PriseEnCharge'
import Sinistre from './Sinistre';
import Recours from './Recours'
import {VehiculeDommages} from './VehiculeDommages';
import SuiviReparationBloc from './SuiviReparationsBloc';
import {useMissionContext} from '../../../../context/MissionContext';
import {EtapeReparation} from '../../../../model/detailsDossier/SuiviReparationModel';
import {InfosRendezVousModel} from '../../../../model/detailsDossier/RendezVousModel';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';

interface GestionProps {
    onClickEtiquette: (tabPanelValue: number, date: string) => void
    onClickStepper: (tabPanelValue: number) => void
}

/** Design */
const styles = (theme: Theme) => ({
  root: {
    padding: '0px',
  },
  card25: {
    marginTop: '25px',
    [theme.breakpoints.down('lg')]: {
      marginTop: '15px',
    },
  },
});

export const Gestion = (props: GestionProps) => {
  const classes = useEmotionStyles(styles);
  const {mission} = useMissionContext();
  const strListRdv = sessionStorage.getItem('listRdv');
  const listRdv : InfosRendezVousModel[] = strListRdv ? JSON.parse(strListRdv) : [];
  const {onClickEtiquette, onClickStepper} = props;
  const isSuiviReparation = mission?.suiviReparationsDTO?.suiviReparation;
  const repairSteps: EtapeReparation[] = mission?.suiviReparationsDTO?.etapesReparation ?? [];
  const etiquette = mission?.suiviReparationsDTO?.etiquette;

  return (
    <div className={classes.root} >
      {(mission?.gestionDTO?.priseEnCharge?.isOM || mission?.gestionDTO?.priseEnCharge?.isAS) &&
      (mission?.historiqueDTO?.list.some((item) => item.type === 'IFR' && item.etat === 'Actif') ||
          listRdv.length > 0) &&
            <div className={classes.card25}>
              {isSuiviReparation &&
                <SuiviReparationBloc
                  etapesReparation={repairSteps}
                  isInOngletGestion={true}
                  etiquette={etiquette}
                  onClickEtiquette={onClickEtiquette}
                  onClickStepper={onClickStepper}
                />
              }
            </div>
      }
      <div className={classes.card25}>
        <VehiculeDommages />
      </div>
      <div className={classes.card25}>
        <Sinistre />
      </div>
      <div className={classes.card25}>
        <PriseEnCharge />
      </div>
      <div className={classes.card25}>
        <Recours />
      </div>
    </div>
  )
};
