import React, {useEffect, useState} from 'react';
import {getValeursCodes} from '../../../../api/norme';
import {MenuItem} from '@mui/material';
import InputSelectField from './InputSelectField';
import {ItemGetValeursCodesModel} from '../../../../model/common/ItemGetValeursCodesModel';

interface InputSelectDtFieldProps {
    label: string
    name: string
    domain: string
    typeDT: string
    mandatory?: boolean
    callback?: (tab: ItemGetValeursCodesModel[]) => void
    className?: string
    emptyItemLabel?: string
    contextList?: string[]
    multipleValidElementsList?: string[]
    filters?: string[]
}

const InputSelectDtField = (props: InputSelectDtFieldProps) => {
  const {label, name, domain, typeDT, mandatory, callback, className, emptyItemLabel,
    contextList, multipleValidElementsList, filters} = props;
  const [itemsList, setItemsList] = useState<React.ReactElement[]>([]);

  const isDisabled = (item: ItemGetValeursCodesModel) => {
    if (contextList?.length) {
      const isElement = contextList.includes(item.valeur);
      if (multipleValidElementsList?.length) {
        return Boolean(isElement) && !multipleValidElementsList.includes(item.valeur)
      }
      return Boolean(isElement)
    }
    return false
  }

  useEffect(() => {
    getValeursCodes(domain, typeDT)
        .then((response: ItemGetValeursCodesModel[]) => {
          response.sort((firstItem, secondItem) => firstItem.libelleedition > secondItem.libelleedition ? 1 : -1 )
          const listItems = response
              .filter((item) => !filters?.includes(item.valeur))
              .map((item: ItemGetValeursCodesModel) => {
                return (
                  <MenuItem
                    key={item.valeur}
                    value={item.valeur}
                    disabled={isDisabled(item)}
                  >
                    {item.libelleedition}
                  </MenuItem>
                )
              })
          setItemsList(listItems)
          if (callback) {
            callback(response)
          }
        });
  }, [])

  return (
    <div>
      <InputSelectField
        className={className ?? ''}
        name={name}
        label={label}
        emptyItemLabel={emptyItemLabel ?? ''}
        itemsList={itemsList}
        mandatory={Boolean(mandatory)}
      />
    </div>
  );
};

export default InputSelectDtField;
