import React from 'react';
import {InputAdornment, TextField} from '@mui/material';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';

interface TextFieldSearchProps {
  id: string,
  name?: string,
  className?: string,
  fullWidth?: boolean,
  label?: string,
  placeHolderText?: string,
  maxLength?: number,
  value?: string,
  disabled?: boolean,
  endAdornment?: string,
  onError?: boolean,
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void,
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void,
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onInput?: (event: React.ChangeEvent<HTMLInputElement>) => string | void,
  onKeyDown?: (event: React.KeyboardEvent) => void,
  helperText?: React.JSX.Element | string,
}

const styles = (theme: Theme) => ({
  textfield: {
    backgroundColor: theme.palette.white.main,
  },
});

export const TextFieldSearch = ({id, name, className, fullWidth, label, placeHolderText, maxLength,
  value, disabled, endAdornment, onError, onBlur, onFocus, onChange, onInput,
  onKeyDown, helperText}: TextFieldSearchProps) => {
  const classes = useEmotionStyles(styles);

  return (
    <TextField
      id={id}
      name={name}
      type="search"
      margin="dense"
      size="small"
      className={`${className}`}
      fullWidth={fullWidth}
      label={label}
      placeholder={placeHolderText}
      InputProps={{
        inputProps: {
          maxLength: maxLength,
        },
        endAdornment: endAdornment ? <InputAdornment position="end">{endAdornment}</InputAdornment> : '',
        classes: {
          root: classes.textfield,
        },
      }}
      value={value}
      disabled={disabled}
      error={onError}
      helperText={helperText}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={onChange}
      onInput={onInput}
      onKeyDown={onKeyDown}
    />
  )
};
