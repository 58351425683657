import React, {ReactNode, useEffect, useState} from 'react';
import {Slide} from '@mui/material';
import Notification from './Notification';
import useEmotionStyles from '../../../common/useEmotionStyles';

interface IProps {
  children: ReactNode
  theme: 'Success' | 'Error' | 'Warning'
  storageItem?: string
  autoHideDuration?: number
  onClose?: () => void
}

const styles = () => ({
  notification: {
    position: 'fixed' as const,
    width: '100%',
    bottom: 0,
    right: 0,
  },
});

const SlideNotification = (props: IProps) => {
  const classes = useEmotionStyles(styles);
  const {children, theme, storageItem, autoHideDuration, onClose} = props;
  const [showNotification, setShowNotification] = useState<boolean>(true);

  useEffect(() => {
    if (showNotification) {
      if (autoHideDuration) {
        setTimeout(() => {
          setShowNotification(false);
        }, autoHideDuration);
      }
    } else if (storageItem) {
      sessionStorage.removeItem(storageItem)
    }
  }, [showNotification]);

  return (
    <Slide
      id={'notification'}
      in={showNotification}
      direction={'up'}
      timeout={1000}
      mountOnEnter
      unmountOnExit
    >
      <div className={classes.notification}>
        <Notification
          theme={theme}
          onClose={() => {
            setShowNotification(false);
            if (onClose) {
              onClose()
            }
          }}
        >
          {children}
        </Notification>
      </div>
    </Slide>
  );
};

export default SlideNotification;
