import React, {useEffect, useState} from 'react';
import {Card, CardContent, IconButton, Modal, Slide, Tooltip, Typography} from '@mui/material'
import {UserContext} from '../../../../context/UserContext'
import {ActeurModel} from '../../../../model/detailsDossier/ActeurModel'
import {
  CODE_ASSUREUR,
  CODE_EXPERT,
  CODE_REPARATEUR,
  CODE_REPAIR_MANAGER,
  CODE_INTERMEDIAIRE,
} from '../../../../constantes';
import {droitSaisieMessage, telToScreen} from '../../../../common/Utils'
import {ReactComponent as CustomChatBubbleIcon} from '../../../../images/icones/btn-message.svg'
import {ReactComponent as RelanceExpertIcon} from '../../../../images/icones/icn-relance-expert.svg'
import {ReactComponent as SollicitationRepIcon} from '../../../../images/icones/icn-sollicitation-rep.svg'
import SD99Form from '../../../forms/sd99/SD99Form'
import SD20Form from '../../../forms/sd20/SD20Form'
import {useMissionContext} from '../../../../context/MissionContext';
import {customTheme} from '../../../../common/GlobalTheme';
import {SRPForm} from '../../../forms/suiviRep/sollicitation/SRPForm';
import {IFRForm} from '../../../forms/suiviRep/infosRep/IFRForm';
import useHasRole from '../../../../hook/useHasRole';
import {permittedRolesDetailsDossierModifications} from '../../../../constantes/roles/Roles';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';

interface ActeurProps {
  title?: string,
  personne? : ActeurModel,
  icon?: React.ReactElement,
}

/** Design */
const styles = (theme: Theme) => ({
  card: {
    backgroundColor: '#F4F4F4',
    borderColor: '#DBDBDB',
    boxShadow: 'none',
  },
  title: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  label: {
    fontSize: '14px',
    marginTop: '5px',
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardContent: {
    'padding': '5px',
    '&:last-child': {
      padding: '5px',
    },
  },
  actionsActeur: {
    display: 'flex',
    alignItems: 'center',
  },
  actionsActeurMediumScreen: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '5px',
  },
});

export const Acteur = ({title, personne, icon}: ActeurProps) => {
  const classes = useEmotionStyles(styles);
  const hasRoleDossierModifications = useHasRole(permittedRolesDetailsDossierModifications);
  const {user} = React.useContext(UserContext);
  const {mission} = useMissionContext();
  const indicateurArchivage = mission?.indicateurArchivage;
  const [openSD99Form, setOpenSD99Form] = useState(false);
  const [openSD20Form, setOpenSD20Form] = useState(false);
  const [openSRPForm, setOpenSRPForm] = useState(false);
  const [openIFRForm, setOpenIFRForm] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const numeroMission = mission?.infoMissionDTO?.numeroMission;

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const handleWindowSizeChange = () => {
    setScreenWidth(window.innerWidth);
  };

  const onCloseForm = (form: string) => {
    const result = window.confirm('Si vous n\'avez pas enregistré vos modifications, elles seront perdues.\n' + '\n' +
      'Souhaitez-vous continuer ?');
    if (result) {
      switch (form) {
        case 'SD99':
          setOpenSD99Form(false);
          break;
        case 'SD20':
          setOpenSD20Form(false);
          break;
        case 'SRP':
          setOpenSRPForm(false);
          break;
        case 'IFR':
          setOpenIFRForm(false);
          break;
      }
    }
  };

  const slideContentSD99 = (
    <div>
      <SD99Form destinataire={personne} onClose={() => onCloseForm('SD99')} onCloseAndRefresh={() => {
        setOpenSD99Form(false);
        window.location.reload()
      }}/>
    </div>
  );

  const slideContentSD20 = (
    <div>
      <SD20Form destinataire={personne} onClose={() => onCloseForm('SD20')} onCloseAndRefresh={() => {
        setOpenSD20Form(false);
        window.location.reload()
      }}/>
    </div>
  );

  const slideContentSRP = (
    <div>
      <SRPForm destinataire={personne} onClose={() => onCloseForm('SRP')} onCloseAndRefresh={() => {
        setOpenSRPForm(false);
        window.location.reload()
      }}/>
    </div>
  );

  const slideContentIFR = (
    <div>
      <IFRForm destinataire={personne} onClose={() => onCloseForm('IFR')} onCloseAndRefresh={() => {
        setOpenIFRForm(false);
        window.location.reload()
      }}/>
    </div>
  );

  const actionsActeur = () => {
    return (
      <>
        {numeroMission && hasRoleDossierModifications && user.type === 'Assureur' && personne?.codeAbonne &&
        personne?.codeActeur === CODE_EXPERT && droitSaisieMessage('41030120', mission?.documentSaisissable!) &&
        <Tooltip disableInteractive title='Envoyer une relance'>
          <IconButton id={'boutonSD20' + personne?.role} component='span' style={{padding: '5px'}}
            onClick={() => setOpenSD20Form(true)}>
            <RelanceExpertIcon/>
          </IconButton>
        </Tooltip>
        }
        {numeroMission && hasRoleDossierModifications && (user.type === 'Assureur' || user.type === 'Courtier' ||
            user.type === 'Expert') && personne?.codeAbonne && (personne?.codeActeur === CODE_REPARATEUR ||
            personne?.codeActeur === CODE_REPAIR_MANAGER) &&
        droitSaisieMessage('500101', mission?.documentSaisissable!) &&
        <Tooltip disableInteractive title='Envoyer une sollicitation'>
          <IconButton id={'boutonSRP' + personne?.role} component='span' style={{padding: '5px'}}
            onClick={() => setOpenSRPForm(true)}>
            <SollicitationRepIcon/>
          </IconButton>
        </Tooltip>
        }
        {numeroMission && hasRoleDossierModifications && (user.type === 'Carrossier' || user.type === 'RM') &&
        personne?.codeAbonne && (personne?.codeActeur === CODE_ASSUREUR ||
          personne?.codeActeur === CODE_EXPERT || personne?.codeActeur === CODE_INTERMEDIAIRE) &&
        droitSaisieMessage('640101', mission?.documentSaisissable!) &&
        <Tooltip disableInteractive title='Envoyer un suivi des réparations'>
          <IconButton id={'boutonIFR' + personne?.role} component='span' style={{padding: '5px'}}
            onClick={() => setOpenIFRForm(true)}>
            <SollicitationRepIcon/>
          </IconButton>
        </Tooltip>
        }
        {numeroMission && hasRoleDossierModifications && indicateurArchivage === 'N' && personne?.codeAbonne &&
        personne?.codeAbonne != user.codeAbo && droitSaisieMessage('41030199', mission?.documentSaisissable!) &&
        <Tooltip disableInteractive title='Envoyer un message'>
          <IconButton id={'boutonSD99' + personne?.role} component='span' style={{padding: '5px'}}
            onClick={() => setOpenSD99Form(true)}>
            <CustomChatBubbleIcon fill={customTheme.palette.link.main}/>
          </IconButton>
        </Tooltip>
        }
      </>
    )
  };

  return (
    <>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div className={classes.header}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div style={{minWidth: '32px'}}>{icon}</div>
              <Typography id={'card-' + title} className={classes.title}>{title}</Typography>
            </div>
            {(screenWidth < customTheme.breakpoints.values.md || screenWidth >= customTheme.breakpoints.values.lg) &&
              <div className={classes.actionsActeur}>
                {actionsActeur()}
              </div>
            }
          </div>
          {personne?.libelle && personne?.libelle !== '' &&
            <Typography className={classes.label}>
              {personne?.libelle}
            </Typography>
          }
          {personne?.telephonePersonnel && personne?.telephonePersonnel?.trim().length > 0 &&
            <Typography className={classes.label}>{telToScreen(personne.telephonePersonnel)}</Typography>
          }
          {personne?.telephoneMobile && personne?.telephoneMobile?.trim().length > 0 &&
            <Typography className={classes.label}>{telToScreen(personne.telephoneMobile)}</Typography>
          }
          {personne?.codeActeur === CODE_ASSUREUR &&
            (personne?.gestionnaireSinistre || personne?.gestionnaireTelPro || personne?.gestionnaireEmail) &&
            <div className={classes.label}>
              <Typography style={customTheme.styledTypography.libelle}>Gestionnaire sinistre</Typography>
              <Typography style={{...customTheme.styledTypography.donneeGrise14}}>
                {personne?.gestionnaireSinistre}
              </Typography>
              {personne?.gestionnaireTelPro && personne?.gestionnaireTelPro?.trim().length > 0 &&
                <Typography style={{...customTheme.styledTypography.donneeGrise14}}>
                  {telToScreen(personne?.gestionnaireTelPro)}
                </Typography>
              }
              {personne?.gestionnaireEmail && personne?.gestionnaireEmail !== '' &&
                <Typography style={{...customTheme.styledTypography.donneeGrise14}}>
                  {personne?.gestionnaireEmail}
                </Typography>
              }
            </div>
          }
          {personne?.codeActeur === CODE_EXPERT && personne?.expertMission &&
            <div className={classes.label}>
              <Typography style={customTheme.styledTypography.libelle}>Expert missionné</Typography>
              <Typography style={{...customTheme.styledTypography.donneeGrise14}}>
                {personne?.expertMission}
              </Typography>
            </div>
          }
          {/* Icones des actions faisables sur un acteur pour les écrans de taille moyenne */}
          {screenWidth >= customTheme.breakpoints.values.md && screenWidth < customTheme.breakpoints.values.lg &&
            <div className={classes.actionsActeurMediumScreen}>
              {actionsActeur()}
            </div>
          }
        </CardContent>
      </Card>

      <Modal
        open={openSD99Form}
        aria-labelledby="modal-form-SD99"
        aria-describedby="modal-formulaire-envoie-messageSD99"
      >
        <Slide in={openSD99Form} direction={'left'} timeout={800} mountOnEnter unmountOnExit>
          {slideContentSD99}
        </Slide>
      </Modal>

      <Modal
        open={openSD20Form}
        aria-labelledby="modal-form-SD20"
        aria-describedby="modal-formulaire-envoie-messageSD20"
      >
        <Slide in={openSD20Form} direction={'left'} timeout={800} mountOnEnter unmountOnExit>
          {slideContentSD20}
        </Slide>
      </Modal>

      <Modal
        open={openSRPForm}
        aria-labelledby="modal-form-SRP"
        aria-describedby="modal-formulaire-envoie-SRP"
      >
        <Slide in={openSRPForm} direction={'left'} timeout={800} mountOnEnter unmountOnExit>
          {slideContentSRP}
        </Slide>
      </Modal>

      <Modal
        open={openIFRForm}
        aria-labelledby="modal-form-IFR"
        aria-describedby="modal-formulaire-envoie-IFR"
      >
        <Slide in={openIFRForm} direction={'left'} timeout={800} mountOnEnter unmountOnExit>
          {slideContentIFR}
        </Slide>
      </Modal>
    </>
  )
};
