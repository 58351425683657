export interface IResultLine {
  id: number,
  idDoc: string,
  doc: string,
  sousDoc: string,
  pdf: string,
  emetteur: string,
  destinataire: string,
  libelleStatut: string,
  codeStatus: string,
  dateReception: string,
  dateEmission: string,
  actif: string,
  dossier: string,
  rad: boolean,
  claim: string,
  mission: string,
  gtaCode: string,
  immatriculation: string,
  docType: string,
  docSousType: string,
  identifier: string,
  archive: string
  docVisualisable: boolean
}

export interface IDocumentsResult {
  indiceDebut: string,
  indiceFin: string,
  list: IResultLine[]
}

export const initialDocumentsResult: IDocumentsResult = {
  indiceDebut: '',
  indiceFin: '',
  list: [],
}
