import React, {useContext, useState} from 'react';
import {Loader} from '../../../common/Loader';
import {ReactComponent as DownloadIcon} from '../../../../images/icones/btn-download-small.svg';
import {customTheme} from '../../../../common/GlobalTheme';
import {PhotoModel} from '../../../../model/detailsDossier/PhotoModel';
import orderBy from 'lodash/orderBy';
import {Button, Card, CardContent, IconButton, Link, Tooltip, Typography} from '@mui/material';
import Picture from './Picture';
import {AttachmentProps, downloadAllAttachments} from '../DownloadAttachments';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {UserContext} from '../../../../context/UserContext';
import {Theme as MuiTheme} from '@mui/material/styles';
import {CSSInterpolation} from '@emotion/serialize';

interface AlbumCommonProps {
  typeAlbum: 'acteur' | 'message',
  identifiant: string,
  groupePhoto: _.Dictionary<PhotoModel[]>,
  referenceSinistre: string | undefined,
  click: (id: string, album: PhotoModel[], index: number, photo: PhotoModel, from: string) => void,
}

/** Design */
const styles = (theme: MuiTheme): Record<string, CSSInterpolation> => ({
  card: {
    backgroundColor: theme.palette.secondary.light,
    borderColor: theme.palette.secondary.main,
    boxShadow: 'none',
    marginTop: '16px',
  },
  title: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: '16px',
  },
  lientitre: {
    cursor: 'pointer',
  },
  subTitle: {
    textAlign: 'left' as const,
    fontSize: '16px',
    fontWeight: 'bold',
    letterSpacing: '0px',
    color: theme.palette.primary.main,
    opacity: '1',
  },
  date: {
    marginLeft: '8px',
    textAlign: 'left' as const,
    fontSize: '14px',
    letterSpacing: '0px',
    color: theme.palette.text.primary,
    opacity: '1',
    display: 'inline',
  },
  lineAlbum: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonPhoto: {
    padding: '4px',
    border: 'none',
  },
  loader: {
    display: 'flex',
    margin: '0px 0px 0px 15px',
  },
});

export const AlbumCommon = ({typeAlbum, groupePhoto, identifiant, referenceSinistre, click}: AlbumCommonProps) => {
  const classes = useEmotionStyles(styles);
  const {user} = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [onErrorDownload, setOnErrorDownload] = useState(false);
  const [isFilesInZip, setIsFilesInZip] = useState(true);
  const [isEndDownload, setIsEndDownload] = useState(false);
  const [nbFilesInZip, setNbFilesInZip] = useState(0);

  const identifiantName = identifiant !== 'Application FLASH' ? identifiant : 'FLASH';
  const attachments = () => {
    const tab: AttachmentProps[] = [];
    groupePhoto[identifiant].forEach((photo) => {
      tab.push({
        id: photo.id,
        name: photo.name,
        date: photo.date?.substring(0, 10).replaceAll('/', ''),
      });
    });
    return tab;
  };

  const idTypography = () => {
    if (typeAlbum === 'acteur') return 'title-photo-album-acteur-' + identifiantName.trim()
    else return 'title-photo-album-message-' + groupePhoto[identifiant][0].typeDoc
  }
  const libelleTypographySubTitle = () => {
    if (typeAlbum === 'acteur') return 'Album photo ' + identifiantName
    else return groupePhoto[identifiant][0].typeDoc + '-' + groupePhoto[identifiant][0].libelleDoc
  }
  const libelleTypographyDate = () => {
    if (typeAlbum === 'acteur') {
      return groupePhoto[identifiant]?.length + ' photo' + (groupePhoto[identifiant]?.length > 1 ? 's' : '') +
          ' - dernière photo déposée le ' +
          (orderBy(groupePhoto[identifiant], 'date', 'desc')[0].date?.replace('-', 'à'))
    } else {
      return groupePhoto[identifiant]?.length + ' photo' +
          (groupePhoto[identifiant]?.length > 1 ? 's déposées ' : ' déposée ') +
          'le ' + (orderBy(groupePhoto[identifiant], 'date', 'desc')[0].date?.replace('-', 'à')) +
          ' par ' + groupePhoto[identifiant][0].author
    }
  }

  return (
    <Card className={classes.card}>
      <CardContent>
        <div className={classes.title}>
          <Link key={'lientitre'} className={classes.lientitre} onClick={() => click(groupePhoto[identifiantName][0].id,
              groupePhoto[identifiantName], 0, groupePhoto[identifiantName][0], typeAlbum)}
          >
            <Typography id={idTypography()} className={classes.subTitle}>
              {libelleTypographySubTitle()}
            </Typography>
          </Link>
          <Typography id={idTypography()} className={classes.date}>
            {libelleTypographyDate()}
          </Typography>
        </div>
        {groupePhoto[identifiant] &&
          <div className={classes.lineAlbum}>
            <div>
              {groupePhoto[identifiant]?.slice(0, 9).map((image, index2) => {
                return (
                  <Button key= {image.id} type="button" className={classes.buttonPhoto}
                    onClick={() => click(image.id, groupePhoto[identifiant], index2, image, typeAlbum)}
                  >
                    <Picture imgId={image.id}/>
                  </Button>
                )
              })}
            </div>
            {isLoading ?
              <Loader className={classes.loader} size={20}/> :
              <Tooltip disableInteractive title='Télécharger' PopperProps={{disablePortal: true}}>
                <IconButton style={{marginLeft: '5px', width: '50px', height: '50px'}}
                  onClick={() => downloadAllAttachments({attachments: attachments(),
                    author: typeAlbum === 'acteur' ? identifiantName || '' : groupePhoto[identifiant][0].author ?? '',
                    typeDoc: typeAlbum === 'acteur' ? '' : groupePhoto[identifiant][0].typeDoc || '',
                    referenceSinistre: referenceSinistre ?? '', numClient: user.numClient, setIsLoading,
                    setIsEndDownload, setIsFilesInZip, setOnErrorDownload, setNbFilesInZip})}
                >
                  <DownloadIcon style={{fill: customTheme.palette.link.main}}/>
                </IconButton>
              </Tooltip>
            }
            {onErrorDownload &&
              <Typography style={{color: 'red', fontSize: '14px'}}>
                Erreur lors du téléchargement
              </Typography>
            }
            {!isFilesInZip &&
              <Typography style={{color: 'red', fontSize: '14px'}}>
                Aucune photo valide à télécharger
              </Typography>
            }
            {isFilesInZip && isEndDownload && nbFilesInZip !== groupePhoto[identifiant]?.length &&
              <Typography style={{color: 'red', fontSize: '14px'}}>
                {nbFilesInZip}
                {nbFilesInZip === 1 ?
                    ' image téléchargée' : ' images téléchargées'} sur {groupePhoto[identifiant]?.length}
              </Typography>
            }
          </div>
        }
      </CardContent>
    </Card>
  )
};
