import * as yup from 'yup';
import {
  checkNumMission,
  checkRaisonSoc,
} from '../../../../common/utils/InputValuesUtils';
import moment from 'moment';
import {validationSchemaImmatriculation} from '../validationSchema/validationSchemaImmatriculation';
import {validationSchemaRefSinistre} from '../validationSchema/validationSchemaRefSinistre';
import {validationSchemaIdentification} from '../validationSchema/validationSchemaIdentification';
import {validationSchemaAdresse} from '../validationSchema/validationSchemaAdresse';
import {validationSchemaDateCertificat} from '../validationSchema/validationSchemaDateCertificat';
import {validationSchemaNumeroFormule} from '../validationSchema/validationSchemaNumeroFormule';

export const validationSchemaDA = yup.object({
  numeroMission: yup.string()
      .test('test-numeroMission', 'Le champ contient des caractères interdits',
          function(value) {
            return checkNumMission(value ?? '')
          }),
  titulaireActuel: yup.object({
    siren: yup.string()
        .when('typePersonne', {
          is: (value: string) => value === 'pm',
          then: () => yup.string()
              .min(9, 'Veuillez saisir une valeur au format attendu, voir'),
        }),
    siret: yup.string()
        .when('typePersonne', {
          is: (value: string) => value === 'pm',
          then: () => yup.string()
              .min(14, 'Veuillez saisir une valeur au format attendu, voir'),
        }),
  }).concat(validationSchemaIdentification),
  titulaireNouveau: yup.object({
    raisonSociale: yup.string()
        .required('Veuillez saisir une valeur')
        .test('test-raisonSociale', 'Les caractères ! ? * ° : € # ; ne sont pas autorisés',
            function(value) {
              return checkRaisonSoc(value || '')
            }),
    siren: yup.string()
        .min(9, 'Veuillez saisir une valeur au format attendu, voir'),
    siret: yup.string()
        .min(14, 'Veuillez saisir une valeur au format attendu, voir')
        .test('test-siret', 'Veuillez saisir une valeur',
            function(value, ctx) {
              if (ctx.parent.adresse.pays === 'FRANCE') {
                return value !== undefined && value !== ''
              }
              return true
            }),
  }).concat(validationSchemaAdresse),
  numeroVin: yup.string()
      .required('Veuillez saisir une valeur'),
  presenceCertificat: yup.boolean(),
  dateAchat: yup.date()
      .required('Veuillez saisir une valeur')
      .min('1900-01-01', 'Veuillez saisir une date postérieure au 01/01/1900')
      .max(moment(), 'Veuillez saisir une date antérieure à celle du jour'),
  heureAchat: yup.string()
      .required('Veuillez saisir une valeur')
      .test('test-heureAchat', 'L\'heure doit être antérieure à l\'heure actuelle pour une date égale à celle du jour',
          function(value, ctx) {
            const date = ctx.parent.dateAchat;
            if (date && moment(date, 'YYYY-MM-DD').isSame(moment().format('YYYY-MM-DD'))) {
              return value <= moment().format('HH:mm')
            }
            return true
          }),
  dateDeclaration: yup.date()
      .required('Veuillez saisir une valeur')
      .min('1900-01-01', 'Veuillez saisir une date postérieure au 01/01/1900')
      .max(moment(), 'Veuillez saisir une date antérieure à celle du jour')
      .test('test-dateDeclaration', 'La date de la déclaration d\'achat ne peut être antérieure à la date d\'achat',
          function(value, ctx) {
            if (ctx.parent.dateAchat) {
              return ctx.parent.dateAchat <= value
            }
            return true
          }),
  heureDeclaration: yup.string()
      .required('Veuillez saisir une valeur')
      .test('test1-heureDeclaration', 'L\'heure doit être antérieure à l\'heure actuelle pour une date ' +
      'égale à celle du jour',
      function(value, ctx) {
        const date = ctx.parent.dateDeclaration;
        if (date && moment(date, 'YYYY-MM-DD').isSame(moment().format('YYYY-MM-DD'))) {
          return value <= moment().format('HH:mm')
        }
        return true
      })
      .test('test2-heureDeclaration', 'L\'heure de la déclaration d\'achat doit être postérieure à ' +
      'l\'heure d\'achat pour une même date',
      function(value, ctx) {
        if (ctx.parent.heureAchat &&
          moment(ctx.parent.dateAchat, 'YYYY-MM-DD').isSame(moment(ctx.parent.dateDeclaration, 'YYYY-MM-DD'))) {
          return value >= ctx.parent.heureAchat
        }
        return true
      }),
  ve: yup.boolean(),
  vei: yup.boolean(),
}).concat(validationSchemaImmatriculation).concat(validationSchemaRefSinistre(true))
    .concat(validationSchemaDateCertificat('DA')).concat(validationSchemaNumeroFormule);
