import {CardModel} from '../../model/rechercheDossier/CardModel';

export const VIEW_TYPE = {
  ARCHIVE: 'archive',
  SIMPLE: 'simple',
  NORMAL: 'normal',
};

export const isNumMissionInList = (list: CardModel[], numMission: string) => {
  return list.some((item) => item.numMission?.trim() === numMission);
};

export const getViewType = (indicateurArchivage?: string, isDossierSimple?: boolean) => {
  if (indicateurArchivage === 'O' || indicateurArchivage === 'A') return VIEW_TYPE.ARCHIVE;
  if (isDossierSimple) return VIEW_TYPE.SIMPLE;
  return VIEW_TYPE.NORMAL;
};

export interface IElement {
  value: string
  label: string
}
export const getMissionsList = (dossiersList: CardModel[]) => {
  const missions: IElement[] = [];
  let isOtherMissions = false;
  dossiersList.forEach((item) => {
    if (item?.numMission && item?.numMission.length > 0) {
      const element: IElement = {
        value: item.numMission.trim(),
        label: item.numMission.trim(),
      };
      missions.push(element);
    } else {
      isOtherMissions = true;
    }
  });
  if (isOtherMissions) {
    missions.push({
      value: 'Toutes',
      label: 'Toutes',
    });
  }
  return missions
};
