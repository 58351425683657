import React, {useContext, useEffect, useState} from 'react';
import {DetailsEchange} from './detailsEchange/DetailsEchange';
import {ListeDocsParTypes} from './docsParType/ListeDocsParTypes';
import {Anomalies} from './anomalies/Anomalies';
import {getEchangeAnomalies, getEchangeDocumentsParType} from '../../../api/echange';
import {UserContext} from '../../../context/UserContext';
import {DocumentsParType} from '../../../model/rechercheEchange/DocumentsParType';
import {ButtonBlue} from '../../common/buttons/ButtonBlue';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {ECHANGES_COMPONENTS} from '../../forms/rechercheEchanges/RechercheEchanges';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {Card, CardContent} from '@mui/material';
import {Loader} from '../../common/Loader';
import WarningIcon from '@mui/icons-material/Warning';
import {Theme} from '@emotion/react';
import {EchangeAnomalies} from '../../../model/rechercheEchange/EchangeAnomalies';
import {InfosEchange} from '../../../model/rechercheEchange/InfosEchange';
import {InfosTypesDocs} from '../../../model/rechercheEchange/InfosTypesDocs';
import {ID_BOUTON_RETOUR} from '../../../constantes/ids/Ids';

interface IProps {
  idEchange: string
  numeroEchange: string
  isEchangeRAD: boolean
  actionEchange: string
  callbackComponent: (component: string,
    infosEchange: InfosEchange,
    infosTypesDocs: InfosTypesDocs,
    lastPaginationCall?: any,
    pageSize?: string) => void,
  lastPaginationCall: any,
  pageSize: string
}

/** Design */
const styles = (theme: Theme) => ({
  container: {
    marginBottom: '16px',
  },
  margin: {
    margin: '48px 0',
  },
  marginTop: {
    marginTop: '16px',
  },
  erreur: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  warningIcon: {
    color: theme.palette.tertiary.main,
  },
  retour: {
    marginTop: '16px',
  },
});

const Erreur = ({composant}: {composant: string}) => {
  const classes = useEmotionStyles(styles);

  let message = '';
  switch (composant) {
    case 'documentsParType':
      message = 'des messages par type de l\'échange';
      break;
    case 'anomalies':
      message = 'des anomalies de l\'échange';
      break;
    default:
      break;
  }

  return (
    <Card className={classes.marginTop}>
      <CardContent className={`${classes.erreur} ${classes.margin}`}>
        <WarningIcon className={classes.warningIcon}/>
        Une erreur s&apos;est produite lors du chargement {message}.
        Veuillez réessayer plus tard ou contacter le support.
      </CardContent>
    </Card>
  )
};

export const DetailsEchangesDocsParTypeAnomalies = (props: IProps) => {
  const classes = useEmotionStyles(styles);
  const {idEchange, numeroEchange, isEchangeRAD, actionEchange, callbackComponent,
    lastPaginationCall, pageSize} = props;
  const {user} = useContext(UserContext);

  // State
  const [documentsParTypeList, setDocumentsParTypeList] = useState<DocumentsParType[]>([]);
  const [anomaliesList, setAnomaliesList] = useState<EchangeAnomalies>({
    anomaliesBloquantesList: [],
    anomaliesNonBloquantesList: [],
  });
  const [isLoadingDocumentsParType, setIsLoadingDocumentsParType] = useState<boolean>(true);
  const [isLoadingAnomalies, setIsLoadingAnomalies] = useState<boolean>(true);
  const [isErrorDocumentsParType, setIsErrorDocumentsParType] = useState<boolean>(false);
  const [isErrorAnomalies, setIsErrorAnomalies] = useState<boolean>(false);

  // Appels API
  useEffect(() => {
    // Récupération des documents par type et sous-type
    getEchangeDocumentsParType(user.service, idEchange)
        .then((response) => {
          setDocumentsParTypeList(response)
        })
        .catch(() => setIsErrorDocumentsParType(true))
        .finally(() => setIsLoadingDocumentsParType(false));

    // Récupération des anomalies
    getEchangeAnomalies(user.service, idEchange)
        .then((response) => {
          setAnomaliesList(response)
        })
        .catch(() => setIsErrorAnomalies(true))
        .finally(() => setIsLoadingAnomalies(false));
  }, []);

  const onClickRetour = () => {
    callbackComponent(ECHANGES_COMPONENTS.RESULTATS,
        {
          idEchange: '',
          numeroEchange: '',
          isEchangeRAD: false,
        } as InfosEchange,
        {
          typeDoc: '',
          sousTypeDoc: '',
        } as InfosTypesDocs,
        lastPaginationCall,
        pageSize)
  };

  return (
    <div className={classes.container}>
      {(isLoadingDocumentsParType || isLoadingAnomalies) &&
        <Card>
          <CardContent>
            <Loader className={classes.margin} size={80}/>
          </CardContent>
        </Card>
      }

      {!isLoadingDocumentsParType && !isLoadingAnomalies &&
        <DetailsEchange numeroEchange={numeroEchange}/>
      }

      {!isLoadingDocumentsParType && isErrorDocumentsParType &&
        <Erreur composant={'documentsParType'}/>
      }
      {!isLoadingDocumentsParType && !isErrorDocumentsParType &&
        <ListeDocsParTypes documentsParTypeList={documentsParTypeList}
          callbackComponent={callbackComponent}
          lastPaginationCall={lastPaginationCall}
          pageSize={pageSize}
          idEchange={idEchange}
          numeroEchange={numeroEchange}
          actionEchange={actionEchange}
          isEchangeRAD={isEchangeRAD}/>
      }

      {!isLoadingAnomalies && isErrorAnomalies &&
        <Erreur composant={'anomalies'}/>
      }
      {!isLoadingAnomalies && !isErrorAnomalies &&
        <Anomalies anomaliesList={anomaliesList}/>
      }

      <ButtonBlue
        id={ID_BOUTON_RETOUR}
        className={classes.retour}
        icon={<ArrowBackIcon/>}
        libelle={'Retour'}
        onClick={onClickRetour}
      />
    </div>
  )
};
