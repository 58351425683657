import React, {useEffect, useState} from 'react';
import SaveIcon from '@mui/icons-material/Save';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {ButtonBlue} from '../../common/buttons/ButtonBlue';
import ElementCalcul from './ElementCalcul';
import {ElementCalculModel} from '../../../model/detailsDossier/ElementCalculModel';
import {ButtonLink} from '../../common/buttons/ButtonLink';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {Typography} from '@mui/material';
import {
  elementsCalculTypeHV,
  elementsCalculTypeV,
} from '../../../constantes/constantesNormeAuto';
import {getValeursCodes} from '../../../api/norme';
import {ItemGetValeursCodesModel} from '../../../model/common/ItemGetValeursCodesModel';
import {Loader} from '../../common/Loader';
import {Theme} from '@emotion/react';
import {ID_BOUTON_AJOUTER, ID_BOUTON_ENREGISTRER, ID_BOUTON_RETOUR} from '../../../constantes/ids/Ids';

/** Design */
const styles = (theme: Theme) => ({
  root: {
    height: '100%',
  },
  body: {
    height: 'calc(100vh - 175px)',
    overflowY: 'auto' as const,
    paddingLeft: '8px',
  },
  aucunElement: {
    textAlign: 'center' as const,
    marginTop: '24px',
    padding: '0px 80px',
  },
  error: {
    color: theme.palette.red.main,
  },
  marginTop16: {
    marginTop: '16px',
  },
  footer: {
    height: '10vh',
    display: 'flex',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 'auto 0px', // pour centrer verticalement
    width: '100%',
  },
});

export interface ElementsCalculProps {
  listElements: ElementCalculModel[],
  onSave: (elementsCalcul: ElementCalculModel[]) => void,
  onCloseElementsCalcul: () => void,
}

const ELEMENTS_CALCUL_NB_MAX = 99;

const ElementsCalcul = (props : ElementsCalculProps) => {
  const classes = useEmotionStyles(styles);
  const {listElements, onSave, onCloseElementsCalcul} = props;
  const [listElementsCalculs, setListElementsCalculs] = useState(listElements);
  const [isDisabledButton, setIsDisabledButton] = useState(false);
  const [expanded, setExpanded] = useState<string>('');
  const [isErrorElementsV, setIsErrorElementsV] = useState<boolean>(false);
  const [isErrorElementsHV, setIsErrorElementsHV] = useState<boolean>(false);
  const [isErrorElementEnDouble, setIsErrorElementEnDouble] = useState<boolean>(false);
  const [codesValeursPriseEnCharge, setCodesValeursPriseEnCharge] = useState<ItemGetValeursCodesModel[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const elementCalcul: ElementCalculModel = {
    id: listElementsCalculs ? listElementsCalculs.length : 0,
    priseEnCharge: '',
    descriptif: '',
    montantFranchiseForaitaire: '',
    tauxFranchiseProportionnelle: '',
    montantPlancherFranchiseProportionnelle: '',
    montantPlafondFranchiseProportionnelle: '',
    tauxPriseEnCharge: '',
    plafondPriseEnCharge: '',
    tvaRecuperableParAssure: '',
    elementComprisReparation: '',
    abattementADeduire: '',
  };

  useEffect(() => {
    getValeursCodes('AR2', '90410101').then((result) => {
      result.sort((a: ItemGetValeursCodesModel, b: ItemGetValeursCodesModel) =>
        a.libelleedition.localeCompare(b.libelleedition, 'fr'),
      );
      setCodesValeursPriseEnCharge(result);
      setIsLoading(false);
    });
    if (!listElementsCalculs || listElementsCalculs.length === 0) {
      setListElementsCalculs([elementCalcul]);
      setExpanded(`panel-${elementCalcul.id}`)
    }
    if (listElementsCalculs && listElementsCalculs.length > 0) {
      const listElementsCopy = listElements.map((element, index) => {
        return {...element, id: index}
      });
      setListElementsCalculs(listElementsCopy);
    }
  }, []);

  useEffect(() => {
    setIsDisabledButton(false)
  }, [expanded]);

  const handleChangeElementCalcul = (input: string,
      event: React.ChangeEvent<{ name?: string; value: unknown } | HTMLInputElement>,
      currentElement: ElementCalculModel) => {
    const listElementsCopy: ElementCalculModel[] = [...listElementsCalculs];
    const elementFound = listElementsCopy.find((element) => element.id === currentElement.id);
    if (elementFound) {
      if (input === 'priseEnCharge') {
        setIsErrorElementsV(false);
        setIsErrorElementsHV(false);
        setIsDisabledButton(false);
        listElementsCopy[currentElement.id] = {...listElementsCopy[currentElement.id],
          [input]: event.target.value as string,
          errorFields: {...currentElement.errorFields, priseEnChargeError: false},
        }
      } else if (input === 'tvaRecuperableParAssure' || input === 'elementComprisReparation' ||
          input === 'abattementADeduire' ) {
        listElementsCopy[currentElement.id] = {
          ...listElementsCopy[currentElement.id],
          [input]: (event as React.ChangeEvent<HTMLInputElement>).target.checked ? 'O' : 'N',
        }
      } else {
        listElementsCopy[currentElement.id] = {
          ...listElementsCopy[currentElement.id],
          [input]: event.target.value,
        }
      }
    }
    setListElementsCalculs(listElementsCopy)
  };

  /* Vérifie que chaque élément de calcul a bien un élément de prise en charge (donnée obligatoire) */
  const handleErrorElements = () => {
    const listElementsCopy: ElementCalculModel[] = [...listElementsCalculs];
    listElementsCopy.forEach((element) =>{
      element.errorFields = {...element.errorFields, priseEnChargeError: !element.priseEnCharge}
    });
    setListElementsCalculs(listElementsCopy);

    return listElementsCopy.filter((element) =>
      element.errorFields?.priseEnChargeError,
    ).length > 0
  };

  /* Vérifie qu'il y a bien l'élément de calcul Véhicule si il y a au moins un élément de calcul Hors Véhicule */
  const handleErrorElementsSpecifiques = () => {
    const listElementsCopy: ElementCalculModel[] = [...listElementsCalculs];
    let isElementVehicule = false;
    let isElementV = false;
    let isElementHV = false;
    listElementsCopy.forEach((element) => {
      if (elementsCalculTypeV.includes(element.priseEnCharge)) {
        isElementV = true;
      }
      if (elementsCalculTypeHV.includes(element.priseEnCharge)) {
        isElementHV = true;
      }
      if (element.priseEnCharge === '2') {
        isElementVehicule = true;
      }
    });
    if ((isElementV || isElementHV) && !isElementVehicule) {
      return {isElementV, isElementHV}
    } else {
      return false
    }
  };

  /* Vérifie qu'il n'y a pas d'éléments de calcul en double (à part Z-Autre) */
  const handleErrorElementEnDouble = (list: ElementCalculModel[]) => {
    const listElementsCopy: ElementCalculModel[] = [...list].filter((element) => element.priseEnCharge !== 'Z');
    const listElementsCopyWithoutDuplicate = Array.from(new Map([...listElementsCopy].map((element) =>
      [element['priseEnCharge']?.trim(), element])).values(),
    );
    return listElementsCopy.length !== listElementsCopyWithoutDuplicate.length
  };

  const handleSubmit = () => {
    const isErrorElem = handleErrorElements();
    const isErrorElemSpecifique = handleErrorElementsSpecifiques();
    const isErrorElemEnDouble = handleErrorElementEnDouble(listElementsCalculs);
    if (isErrorElem) {
      setIsDisabledButton(true);
      const elementError = listElementsCalculs.find((element ) =>
        element.errorFields?.priseEnChargeError);
      setExpanded(`panel-${elementError?.id}`)
    } else if (isErrorElemSpecifique) {
      if (isErrorElemSpecifique.isElementV) setIsErrorElementsV(true);
      if (isErrorElemSpecifique.isElementHV) setIsErrorElementsHV(true);
    } else if (isErrorElemEnDouble) {
      setIsErrorElementEnDouble(true);
    } else {
      onSave(listElementsCalculs)
    }
  };

  const handleAddElement = () => {
    setIsErrorElementsV(false);
    setIsErrorElementsHV(false);
    const isError = handleErrorElements();
    if (listElementsCalculs.length >= ELEMENTS_CALCUL_NB_MAX) {
      const result = window.confirm('Vous avez atteint le nombre maximum d\'éléments de calcul');
      if (result) {
        setExpanded(``)
      }
    } else if (isError) {
      const elementError = listElementsCalculs.find((element ) =>
        element.errorFields?.priseEnChargeError);
      setExpanded(`panel-${elementError?.id}`);
      setIsDisabledButton(true)
    } else {
      setListElementsCalculs([...listElementsCalculs, elementCalcul]);
      setExpanded(`panel-${elementCalcul.id}`)
    }
  };

  const handleDeleteElement = (e: React.MouseEvent<HTMLElement>, currentElement: ElementCalculModel) => {
    e.stopPropagation();
    let isElementV = false;
    let isElementHV = false;

    const listCopy: ElementCalculModel[] = [...listElementsCalculs].filter((element) =>
      currentElement.id != element.id,
    );

    listCopy.forEach((element, index) => {
      element.id = index;
      if (elementsCalculTypeV.includes(element.priseEnCharge)) {
        isElementV = true;
      }
      if (elementsCalculTypeHV.includes(element.priseEnCharge)) {
        isElementHV = true;
      }
    });

    if (!isElementV) {
      setIsErrorElementsV(false)
    }
    if (!isElementHV) {
      setIsErrorElementsHV(false)
    }
    if (!handleErrorElementEnDouble(listCopy)) {
      setIsErrorElementEnDouble(false)
    }

    setListElementsCalculs([...listCopy]);
    setExpanded('')
  };

  const handleChangeCurrency = (currentElement: ElementCalculModel, value: string) => {
    setIsDisabledButton(false);
    const listElementsCopy: ElementCalculModel[] = [...listElementsCalculs];
    const elementFound = listElementsCopy.find((element) => element.id === currentElement.id);
    if (elementFound) {
      listElementsCopy[currentElement.id] = {...listElementsCopy[currentElement.id],
        plafondPriseEnCharge: value,
      }
    }
    setListElementsCalculs(listElementsCopy)
  };

  const handleChangePanel = (panel: string) => {
    setExpanded(expanded !== panel ? panel : '')
  };

  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <div>
          <ButtonLink
            id={ID_BOUTON_RETOUR}
            icon={<ArrowBackIcon/>}
            libelle={'Retour'}
            onClick={onCloseElementsCalcul}
          />

          {listElementsCalculs && listElementsCalculs.length === 0 &&
            <div className={classes.aucunElement}>
              <em>Aucun élément ajouté : les éléments de calcul éventuellement présents dans l&apos;ordre
                  de mission seront repris
              </em>
            </div>
          }

          {isErrorElementEnDouble &&
            <Typography className={classes.error}>
              Seul l&apos;élément de calcul de nature Autre peut être sélectionné plus d’une fois
            </Typography>
          }
          {isErrorElementsV &&
            <Typography className={classes.error}>
              Un élément de type &apos;Véhicule&apos; doit toujours être accompagné de l&apos;élément
              &apos;Véhicule&apos;
            </Typography>
          }
          {isErrorElementsHV &&
            <Typography className={classes.error}>
              Un élément &apos;Hors Véhicule&apos; doit toujours être accompagné de l&apos;élément &apos;Véhicule&apos;
            </Typography>
          }

          {isLoading &&
            <Loader size={30}/>
          }

          {!isLoading && listElementsCalculs && listElementsCalculs.length > 0 && listElementsCalculs.map((element) => {
            return (
              <div key={element.id} className={classes.marginTop16}>
                <ElementCalcul
                  codesValeursPriseEnCharge={codesValeursPriseEnCharge}
                  listElementsCalculs={listElementsCalculs}
                  expanded={expanded === `panel-${element.id}`}
                  data={element}
                  onChangeElement={(input: string, e) =>
                    handleChangeElementCalcul(input, e, element)}
                  onChangeCurrency={(currentElement: ElementCalculModel, value: string) =>
                    handleChangeCurrency(currentElement, value)}
                  onChangePanel={(panel) => handleChangePanel(panel)}
                  onDelete={(event: React.MouseEvent<HTMLElement>) => handleDeleteElement(event, element)}
                />
              </div>
            )
          })}
        </div>
      </div>

      <div className={classes.footer}>
        <div className={classes.buttons}>
          <ButtonBlue
            id={ID_BOUTON_AJOUTER}
            icon={<AddCircleIcon/>}
            libelle={'Ajouter un élément de calcul'}
            onClick={handleAddElement}
            disabled={isDisabledButton}
          />
          <ButtonBlue
            id={ID_BOUTON_ENREGISTRER}
            icon={<SaveIcon/>}
            libelle={'Enregistrer'}
            onClick={handleSubmit}
            disabled={isDisabledButton || isErrorElementsV || isErrorElementsHV || isErrorElementEnDouble}
          />
        </div>
      </div>
    </div>

  );
};

export default ElementsCalcul;
