import React from 'react';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {Typography} from '@mui/material';
import {Loader} from '../../common/Loader';
import {ButtonBlueSend} from '../../common/formsComponents/ButtonBlueSend';
import {ID_BOUTON_ENVOYER} from '../../../constantes/ids/Ids';

interface FooterFormSIVProps {
  responseReturn?: number,
  isLoading: boolean,
  disabled: boolean,
  onClick: () => Promise<any>
}

/** Design */
const styles = () => ({
  red: {
    color: 'red',
  },
  error: {
    fontSize: '12px',
    marginTop: '16px',
  },
  typography: {
    fontSize: '14px',
  },
  bottom: {
    textAlign: 'right' as const,
  },
  loader: {
    display: 'block',
    margin: '0px 60px',
  },
});

export const FooterFormSIV = (props: FooterFormSIVProps) => {
  const classes = useEmotionStyles(styles);
  const {responseReturn, isLoading, disabled, onClick} = props

  return (
    <>
      <Typography className={classes.typography}>
        Les champs marqués d&apos;un astérisque <span className={classes.red}>*</span> sont obligatoires.
      </Typography>

      {responseReturn === -1 &&
        <Typography className={`${classes.red} ${classes.error}`}>
          Échec de l&apos;envoi du message. Le contenu de votre formulaire n&apos;est pas valide.
        </Typography>
      }

      <div className={classes.bottom}>
        {isLoading &&
          <Loader className={classes.loader} size={30}/>
        }
        {!isLoading &&
          <ButtonBlueSend id={ID_BOUTON_ENVOYER} disabled={disabled} onClick={onClick}/>
        }
      </div>
    </>
  )
};
