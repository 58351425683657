import React from 'react';
import {Tooltip, Typography} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import {TextFieldSearch} from '../formsComponents/TextFieldSearch';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';

interface ImmatriculationProps {
  className?: string,
  name: string,
  formulaire: string,
  onChangeValue: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void,
  onError: boolean,
  onErrorVisible?: boolean,
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void,
  value?: string,
  helperText?: React.JSX.Element | string,
}

const styles = (theme: Theme) => ({
  title: {
    display: 'flex',
  },
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  red: {
    color: 'red',
  },
  tooltip: {
    marginLeft: '10px',
    color: theme.palette.secondary.dark2,
  },
  error: {
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
  },
});

export const Immatriculation = ({className, name, formulaire, onChangeValue, onBlur, onError,
  onErrorVisible, onFocus, value, helperText}: ImmatriculationProps) => {
  const classes = useEmotionStyles(styles);

  const titleTooltip = <span>Saisissez un numéro d&apos;immatriculation dans l&apos;un des formats suivants :
    <div>Format FNI : 9999 AAA 999 ou 9999 AA 99</div>
    <div>Format SIV : AA-999-AA</div>
    <div>Cyclo : AA 999 A</div></span>;

  return (
    <div className={className}>
      <div className={classes.title}>
        <Typography className={classes.libelle}>
          N° d&apos;immatriculation&nbsp;<span className={classes.red}>*</span>
        </Typography>
        <Tooltip
          className={classes.tooltip}
          disableInteractive
          placement="top"
          title={titleTooltip}
        >
          <HelpIcon/>
        </Tooltip>
      </div>

      <TextFieldSearch
        id={'immatriculation' + formulaire}
        name={name}
        fullWidth={true}
        maxLength={12}
        value={value}
        onError={onError}
        helperText={helperText}
        onChange={onChangeValue}
        onBlur={onBlur}
        onFocus={onFocus}
        onInput={(e: React.ChangeEvent<HTMLInputElement>) => e.target.value = ('' + e.target.value).toUpperCase()}
      />
      {onError && onErrorVisible ?
          <Typography className={`${classes.red} ${classes.error}`}>
            Veuillez saisir une valeur au format attendu, voir&nbsp;<HelpIcon style={{width: '15px'}}/>
          </Typography> : ''
      }
    </div>
  )
};
