import React, {useEffect, useState} from 'react';
import {Card, CardContent, MenuItem, FormControl, RadioGroup, Tooltip, Typography} from '@mui/material';
import {RadioBtn} from '../../common/formsComponents/RadioBtn';
import {postFDVForm} from '../../../api/message';
import {getValeursCodes} from '../../../api/norme';
import {getDocumentPdf} from '../../../api/dossier';
import {TextFieldSelect} from '../../common/formsComponents/TextFieldSelect';
import {Alerte} from './Alerte';
import {UserContext} from '../../../context/UserContext';
import {TextFieldSearch} from '../../common/formsComponents/TextFieldSearch';
import InputMask from 'react-input-mask-3.0';
import {TextFieldMultiline} from '../../common/formsComponents/TextFiledMultiline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import HelpIcon from '@mui/icons-material/Help';
import {ButtonBlue} from '../../common/buttons/ButtonBlue';
import {ButtonBlueSend} from '../../common/formsComponents/ButtonBlueSend';
import {ButtonBlueDelete} from '../../common/formsComponents/ButtonBlueDelete';
import {AlerteProps, IAttachment, useInitialStateFormFDV} from './initialStateFormFDV';
import {useIconExtensionFile, isStringEmpty} from '../../../common/Utils';
import {Loader} from '../../common/Loader';
import {useParams} from 'react-router';
import {TextFieldCurrency} from '../../common/formsComponents/TextFieldCurrency';
import {ActeurModel} from '../../../model/detailsDossier/ActeurModel';
import {RetourDossier} from '../../common/buttons/RetourDossier';
import {ItemGetValeursCodesModel} from '../../../model/common/ItemGetValeursCodesModel';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {ID_BOUTON_ENVOYER} from '../../../constantes/ids/Ids';

const base64 = require('base-64');

export interface ParamsFdvType {
  tokenFdv: string,
}

/** Design */
const styles = (theme: Theme) => ({
  card: {
    height: '100%',
  },
  red: {
    color: 'red',
  },
  container: {
    display: 'flex',
    flexDirection: 'row' as const,
  },
  alertesContainer: {
    backgroundColor: theme.palette.secondary.light,
    padding: '20px',
    borderRadius: '8px',
  },
  alertesInfos: {
    display: 'flex',
    flexWrap: 'wrap' as const,
    rowGap: '15px',
  },
  alerteInfo: {
    flexGrow: 1,
    flexBasis: '0px',
  },
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  tooltip: {
    marginLeft: '10px',
  },
  error: {
    fontSize: '12px',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  loader: {
    display: 'block',
    margin: '15px 90px',
  },
  loaderPDF: {
    display: 'block',
    marginTop: '30px',
  },
  buttonDl: {
    marginTop: '30px',
  },
  textField: {
    minWidth: '250px',
  },
});

export const ContentFormFDV = () => {
  const classes = useEmotionStyles(styles);
  const iconPDF = useIconExtensionFile('PDF', false, '10px');
  const {user} = React.useContext(UserContext);
  const params = useParams<keyof ParamsFdvType>() as ParamsFdvType;
  const token = params.tokenFdv;
  const tokenParse = JSON.parse(base64.decode(params.tokenFdv));
  const getItemMission = sessionStorage.getItem('mission');
  const mission = getItemMission ? JSON.parse(getItemMission) : '';
  const acteurs = mission?.acteursDTO?.acteurs;
  const [isDisabledButton, setIsDisabledButton] = useState(false);

  const [destinatairesList, setDestinatairesList] = useState<string[]>([]);
  const [destinataire, setDestinataire] = useState('');
  const [destinataireCodeAbo, setDestinataireCodeAbo] = useState('');

  const [niveauCriticite, setNiveauCriticite] = useState('');

  const [qualificationAlerte, setQualificationAlerte] = useState('');
  const [onErrorQualificationAlerte, setOnErrorQualificationAlerte] = useState(false);

  const [scoring, setScoring] = useState('');
  const [montantDu, setMontantDu] = useState('');
  const [montantReclamation, setMontantReclamation] = useState('');
  const [reclamationIntegrale, setReclamationIntegrale] = useState('');
  const [commentaire, setCommentaire] = useState('');

  const [alertesTab, setAlertesTab] = useState<AlerteProps[]>([{id: 1, famille: '', onErrorFamille: false,
    type: '', onErrorType: false, justificatifs: [], commentaire: ''}]);

  const [openModalJustificatifs, setOpenModalJustificatifs] = useState<{alerteId: number, open: boolean}>(
      {alerteId: 1, open: false});

  const [niveauCriticiteList, setNiveauCriticiteList] = useState<ItemGetValeursCodesModel[]>([]);
  const [qualificationAlerteList, setQualificationAlerteList] = useState<ItemGetValeursCodesModel[]>([]);
  const [isLoadingPDF, setIsLoadingPDF] = useState(false);

  useEffect(() => {
    getValeursCodes('AR2', '98670101').then((response) => setNiveauCriticiteList(response));
    getValeursCodes('AR2', '98680201').then((response) => setQualificationAlerteList(response))
  }, []);

  useEffect(() => {
    const tabTmp: string[] = [];
    const destinataires = [
      {role: 'Assureur'},
      {role: 'Expert', messagesRequired: ['OM']},
      {role: 'Carrossier', messagesRequired: ['AS']},
    ];
    destinataires.forEach((dest) => {
      const messagesRequiredArePresent = checkPresenceMessages(dest.messagesRequired ?? []);
      if (user.type !== dest.role && messagesRequiredArePresent) {
        const roleDest = dest.role === 'Carrossier' ? 'Réparateur' : dest.role;
        const acteur = getActeurByRole(acteurs, roleDest);
        if (acteur && acteur.length > 0 && acteur[0].codeAbonne !== '' && acteur[0].codeAbonne !== null) {
          tabTmp.push(roleDest)
        }
      }
    });
    setDestinatairesList(tabTmp);
    setDestinataire(tabTmp[0])
  }, []);

  useEffect(() => {
    if (!isStringEmpty(destinataire)) {
      const acteur = getActeurByRole(acteurs, destinataire);
      const codeAbo = acteur && acteur.length > 0 ? acteur[0].codeAbonne : '';
      if (codeAbo) setDestinataireCodeAbo(codeAbo);
    }
  }, [destinataire]);

  const handleClickOpenModalJustificatifs = (alerteId: number) => {
    setOpenModalJustificatifs({alerteId, open: true})
  };
  const handleClickCloseModalJustificatifs = (alerteId: number) => {
    const result = window.confirm('Si vous n\'avez pas enregistré vos modifications, elles seront perdues.\n' + '\n' +
      'Souhaitez-vous continuer ?');
    if (result) {
      setOpenModalJustificatifs({alerteId, open: false})
    }
  };

  const getAttachmentsAlerte = (idAlerte: number) => {
    const alerteTarget = alertesTab.find((alerte) => alerte.id === idAlerte);
    return alerteTarget ? alerteTarget.justificatifs : []
  };

  const checkErrors = (tab?: AlerteProps[]) => {
    const alertesTabCopy = tab ? [...tab] : [...alertesTab];
    alertesTabCopy.forEach((alerte) => {
      alerte.onErrorFamille = alerte.famille === '';
      alerte.onErrorType = alerte.type === '';
    });
    setAlertesTab(alertesTabCopy);
    const isError = alertesTabCopy.filter((alerte) => alerte.onErrorFamille ??
      alerte.onErrorType).length > 0;
    isError ? setIsDisabledButton(true) : setIsDisabledButton(false);
    return isError
  };

  const getActeurByRole = (acteursTab: ActeurModel[], role: string) => {
    return acteursTab.filter((act: ActeurModel) =>
      act.role?.toUpperCase() === role.toUpperCase());
  };

  const checkPresenceMessages = (messagesRequired: string[]) => {
    let messagesRequiredArePresent = true;
    messagesRequired.forEach((message) => {
      if (!(mission?.gestionDTO?.priseEnCharge ? mission?.gestionDTO?.priseEnCharge['is'+message] : false)) {
        messagesRequiredArePresent = false;
      }
    });
    return messagesRequiredArePresent;
  };

  const niveauCriticiteListSort = [...niveauCriticiteList].sort((a: ItemGetValeursCodesModel,
      b: ItemGetValeursCodesModel) => {
    if (a.libelleedition > b.libelleedition) {
      return 1
    } else {
      return -1
    }
  });
  const itemsListNivCriticite = niveauCriticiteListSort.map((niv: ItemGetValeursCodesModel) => {
    return (
      <MenuItem key={niv.valeur} value={niv.valeur}>{niv.lowercase}</MenuItem>
    )
  });

  const qualificationAlerteListSort = [...qualificationAlerteList].sort((a: ItemGetValeursCodesModel,
      b: ItemGetValeursCodesModel) => {
    if (a.libelleedition > b.libelleedition) {
      return 1
    } else {
      return -1
    }
  });
  const itemsListQualificationAlerte = qualificationAlerteListSort.map((qualification: ItemGetValeursCodesModel) => {
    return (
      <MenuItem key={qualification.valeur} value={qualification.valeur}>{qualification.lowercase}</MenuItem>
    )
  });

  const handleChangeDestinataire = (event: React.ChangeEvent<HTMLInputElement>) => setDestinataire(event.target.value);
  const handleChangeNiveauCriticite = (event: React.ChangeEvent<HTMLInputElement>) =>
    setNiveauCriticite(event.target.value);
  const handleChangeQualificationAlerte = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value !== '') setOnErrorQualificationAlerte(false);
    setQualificationAlerte(event.target.value)
  };
  const handleChangeScoring = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(event.target.value) > 100) event.target.value = '100';
    setScoring(event.target.value)
  };
  const handleChangeMontant = (value: string, field: string) => {
    if (field === 'montantDu') {
      setMontantDu(value)
    } else if (field === 'montantReclamation') {
      setMontantReclamation(value)
    } else if (field === 'reclamationIntegrale') {
      setReclamationIntegrale(value)
    }
  };
  const handleChangeCommentaire = (event: React.ChangeEvent<HTMLInputElement>) =>
    setCommentaire(event.target.value);

  const handleChangeFamilleAlerte = (event: React.ChangeEvent<HTMLInputElement>, alerteId: number) => {
    const alertesTabCopy = [...alertesTab];
    alertesTabCopy.forEach((alerte) => {
      if (alerte.id === alerteId) {
        alerte.famille = event.target.value;
        alerte.onErrorFamille = event.target.value === '';
        alerte.type = ''
      }
    });
    setAlertesTab(alertesTabCopy)
  };
  const handleChangeTypeAlerte = (event: React.ChangeEvent<HTMLInputElement>, alerteId: number) => {
    event.target.value !== '' ? setIsDisabledButton(false) : setIsDisabledButton(true);
    const alertesTabCopy = [...alertesTab];
    alertesTabCopy.forEach((alerte) => {
      if (alerte.id === alerteId) {
        alerte.type = event.target.value;
        alerte.onErrorType = event.target.value === '';
      }
    });
    setAlertesTab(alertesTabCopy)
  };

  const handleChangeCommentaireAlerte = (event: React.ChangeEvent<HTMLInputElement>, alerteId: number) => {
    const alertesTabCopy = [...alertesTab];
    alertesTabCopy.forEach((alerte) => {
      if (alerte.id === alerteId) alerte.commentaire = event.target.value;
    });
    setAlertesTab(alertesTabCopy)
  };

  const handleClickAddAlerte = () => {
    const errors = checkErrors();
    if (!errors) {
      const newAlerte: AlerteProps = {id: alertesTab[alertesTab.length-1].id + 1, famille: '', onErrorFamille: false,
        type: '', onErrorType: false, justificatifs: [], commentaire: ''};
      setAlertesTab([...alertesTab, newAlerte])
    }
  };
  const handleClickDeleteAlerte = (id: number) => {
    const newList = alertesTab.filter((alerte) => alerte.id !== id);
    checkErrors(newList)
  };

  const onSaveAttachments = (attachments: IAttachment[], idAlerte: number) => {
    const alertesTabCopy = [...alertesTab].map((alerte)=>{
      if (alerte.id === idAlerte) {
        return {...alerte, justificatifs: attachments};
      }
      return alerte
    });
    setAlertesTab(alertesTabCopy);
    setOpenModalJustificatifs({alerteId: idAlerte, open: false})
  };

  let formData = useInitialStateFormFDV();
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState(0);
  const [response, setResponse] = useState<any>();

  const handleClickSubmit = () => {
    const errors = checkErrors();
    if (qualificationAlerte && !errors) {
      setIsLoading(true);
      let result;
      if (qualificationAlerte === '03') {
        result = window.confirm('La qualification "Sans suite" aura pour effet de purger toutes les fiches de ' +
          'vigilance.\n\nConfirmez-vous cette action ?')
      } else {
        const qualification = qualificationAlerteList.filter((qualification: ItemGetValeursCodesModel) =>
          qualification.valeur === qualificationAlerte)[0].libelleedition;
        result = window.confirm('La qualification choisie est : ' + qualification +
          '.\n\nConfirmez-vous l\'envoi de la fiche de vigilance ?')
      }
      if (result) {
        const data = new FormData();
        alertesTab.forEach((alerte) =>{
          alerte.justificatifs.forEach((justifcatif) => {
            data.append('files', justifcatif.file!)
          })
        });
        const alertesTabCopy = alertesTab.map((alerte) => ({...alerte}));
        const alertesTabToSend = alertesTabCopy.map((alerte) => {
          delete alerte.onErrorFamille;
          delete alerte.onErrorType;
          alerte.justificatifs.forEach((attachment) => {
            delete attachment.completed;
            delete attachment.file;
            delete attachment.onError;
            return attachment
          });
          return alerte
        });
        formData = {...formData, codeAbonneDestinataire: destinataireCodeAbo,
          scenario: mission?.scenario, codeGTA: mission?.codeGTA,
          numeroAssure: mission?.societaireDTO?.numero, referenceSinistre: mission?.infoMissionDTO?.referenceSinistre,
          numMission: mission?.infoMissionDTO?.numeroMission, dateEffetPolice: mission?.infoMissionDTO?.dateEffetPolice,
          codeGestionnaireSinistre: mission?.infoMissionDTO?.codeGestionnaireSinistre,
          nomGestionnaireSinistre: mission?.infoMissionDTO?.nomGestionnaireSinistre, niveauCriticite,
          qualificationAlerte, scoring, montantDu: montantDu.replace(',', '.'),
          montantReclamation: montantReclamation.replace(',', '.'),
          reclamationIntegrale: reclamationIntegrale.replace(',', '.'), commentaire, alertesTab: alertesTabToSend,
          intermediaire: mission?.intermediaireDTO};
        data.append('metadata', new Blob([JSON.stringify(formData)], {
          type: 'application/json',
        }));
        postFDVForm(data)
            .then((response) => {
              setResponseStatus(response.status);
              setResponse(response);
            })
            .catch((error) => {
              if (error.response) {
                setResponseStatus(error.response.status);
              } else {
                setResponseStatus(-1);
              }
            })
            .finally(() => {
              setIsLoading(false)
            })
      } else {
        setIsLoading(false)
      }
    } else {
      setOnErrorQualificationAlerte(qualificationAlerte === '')
    }
  };

  const handlePdf = () => {
    if (response?.data?.retourIdDocument) {
      setIsLoadingPDF(true);
      getDocumentPdf(response.data.retourIdDocument, tokenParse, '590101', '', response.data.retourIsnDoc,
          user.isTransparent)
          .then((response) => {
          // Create a Blob from the PDF Stream
            const file = new Blob(
                [response],
                {type: 'application/pdf'});
            // Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            // Open the URL on new Window
            if (window.navigator.msSaveOrOpenBlob) {
            // IE11
              window.navigator.msSaveOrOpenBlob(file, 'document.pdf')
            } else {
              window.open(fileURL)
            }
            setIsLoadingPDF(false)
          }).catch(() => {
            setIsLoadingPDF(false)
          })
    }
  };

  return (
    <Card className={classes.card}>
      {responseStatus <= 0 &&
        <CardContent style={{padding: '16px 24px'}}>
          <Typography variant={'h3'}>
            Destinataire <span className={classes.red}>*</span>
          </Typography>
          <FormControl component="fieldset" style={{marginBottom: '20px'}}>
            <RadioGroup className={classes.container} name="destinataire" value={destinataire}
              onChange={handleChangeDestinataire}>
              {destinatairesList.map((dest, index) => {
                return (
                  <RadioBtn id={'radioBtn' + dest} key={dest} value={dest} label={dest}/>
                )
              })}
            </RadioGroup>
          </FormControl>

          <Typography variant={'h3'}>Ensemble des alertes</Typography>
          <div className={classes.alertesContainer}>
            <Typography style={{margin: '0px 0px 10px', fontWeight: 'bold'}}>
              Qualification de la vigilance
            </Typography>
            <div className={classes.alertesInfos}>
              <span className={classes.alerteInfo}>
                <Typography className={classes.libelle}>Niveau de criticité</Typography>
                <TextFieldSelect
                  className={classes.textField}
                  id={'criticite'}
                  name={'criticite'}
                  marginDense={true}
                  value={niveauCriticite}
                  itemsList={itemsListNivCriticite}
                  withEmptyItem={true}
                  emptyItemLabel={'Aucun'}
                  onChange={handleChangeNiveauCriticite}
                />
              </span>
              <span className={classes.alerteInfo}>
                <Typography className={classes.libelle}>
                  Qualification des alertes <span className={classes.red}>*</span>
                </Typography>
                <TextFieldSelect
                  className={classes.textField}
                  id={'qualification'}
                  name={'qualification'}
                  marginDense={true}
                  value={qualificationAlerte}
                  onError={onErrorQualificationAlerte}
                  itemsList={itemsListQualificationAlerte}
                  onChange={handleChangeQualificationAlerte}
                />
                {onErrorQualificationAlerte ?
                  <Typography className={`${classes.red} ${classes.error}`}>
                    Veuillez sélectionner une valeur
                  </Typography> : ''
                }
              </span>
              <span className={classes.alerteInfo}>
                <Typography className={`${classes.libelle} ${classes.flex}`}>
                  Score
                  <Tooltip disableInteractive className={classes.tooltip} placement="top" title={'Score de 0 à 100'}>
                    <HelpIcon/>
                  </Tooltip>
                </Typography>
                <InputMask mask="999" maskPlaceholder={null} value={scoring} onChange={handleChangeScoring}>
                  <TextFieldSearch id={'scoring'} className={classes.textField}/>
                </InputMask>
              </span>
            </div>
            <Typography style={{margin: '30px 0px 10px', fontWeight: 'bold'}}>
              Montants réclamés par l&apos;assuré
            </Typography>
            <div className={classes.alertesInfos}>
              <span className={classes.alerteInfo}>
                <Typography className={`${classes.libelle} ${classes.flex}`}>
                  Montant dû
                  <Tooltip disableInteractive className={classes.tooltip} placement="top"
                    title={'Montant compris entre 0 et 9999999,99'}>
                    <HelpIcon/>
                  </Tooltip>
                </Typography>
                <TextFieldCurrency id={'montantDu'} className={classes.textField} value={montantDu}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeMontant(event.target.value, 'montantDu')}
                />
              </span>
              <span className={classes.alerteInfo}>
                <Typography className={`${classes.libelle} ${classes.flex}`}>
                  Montant de la réclamation
                  <Tooltip disableInteractive className={classes.tooltip} placement="top"
                    title={'Montant compris entre 0 et 9999999,99'}>
                    <HelpIcon/>
                  </Tooltip>
                </Typography>
                <TextFieldCurrency id={'montantReclamation'} className={classes.textField}
                  value={montantReclamation}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleChangeMontant(event.target.value, 'montantReclamation')
                  }}
                />
              </span>
              <span className={classes.alerteInfo}>
                <Typography className={`${classes.libelle} ${classes.flex}`}>
                  Réclamation intégrale
                  <Tooltip disableInteractive className={classes.tooltip} placement="top"
                    title={'Montant compris entre 0 et 9999999,99'}>
                    <HelpIcon/>
                  </Tooltip>
                </Typography>
                <TextFieldCurrency id={'reclamationIntegrale'} className={classes.textField}
                  value={reclamationIntegrale}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleChangeMontant(event.target.value, 'reclamationIntegrale')
                  }}
                />
              </span>
            </div>
          </div>
          {alertesTab.map((alerte, index) => {
            return (
              <div key={alerte.id}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <Typography variant={'h3'} style={{marginRight: '20px', marginTop: '25px'}}>
                    Alerte {index + 1} {alerte.id === 1 ? <span className={classes.red}>*</span> : ''}
                  </Typography>
                  <div style={{marginTop: '15px', marginBottom: '10px'}}>
                    <ButtonBlueDelete
                      id={'boutonSupprimer' + alerte.id}
                      disabled={alertesTab.length === 1}
                      onClick={() => handleClickDeleteAlerte(alerte.id)}/>
                  </div>
                </div>
                <Alerte onChangeFamilleAlerte={(e) => handleChangeFamilleAlerte(e, alerte.id)}
                  onChangeTypeAlerte={(e) => handleChangeTypeAlerte(e, alerte.id)}
                  onChangeCommentaire={(e) => handleChangeCommentaireAlerte(e, alerte.id)}
                  alerteId={index + 1} familleAlerteValue={alerte.famille} typeAlerteValue={alerte.type}
                  commentaireAlerteValue={alerte.commentaire} openModalJustificatifsValue={openModalJustificatifs}
                  onClickOpenModalJustificatifs={() => handleClickOpenModalJustificatifs(alerte.id)}
                  onClickCloseModalJustificatifs={() => handleClickCloseModalJustificatifs(alerte.id)}
                  onErrorFamille={alerte.onErrorFamille} onErrorType={alerte.onErrorType}
                  attachments={getAttachmentsAlerte(alerte.id)}
                  onSaveAttachments={onSaveAttachments}
                />
              </div>
            )
          })}
          {alertesTab.length < 10 ?
            <div style={{textAlign: 'right', marginTop: '15px'}}>
              <ButtonBlue id={'boutonAjouterAlerte'} icon={<AddCircleIcon/>} libelle={'Ajouter une alerte'}
                disabled={isDisabledButton} onClick={handleClickAddAlerte}/>
            </div> : <div style={{marginTop: '15px'}}></div>
          }
          <Typography variant={'h3'}>Commentaire</Typography>
          <TextFieldMultiline id={'commentaire'} style={{marginBottom: '20px'}} value={commentaire}
            maxLength={800} rows={4} onChange={handleChangeCommentaire}
            helperText={commentaire.length + '/800'}/>
          <Typography style={{fontSize: '14px'}}>
            Les champs marqués d&apos;un astérisque <span className={classes.red}>*</span> sont obligatoires.
          </Typography>
          {responseStatus === -1 &&
            <Typography className={`${classes.red} ${classes.error}`} style={{marginTop: '10px'}}>
              Échec de l&apos;envoi du message. Le contenu de votre formulaire n&apos;est pas valide.
            </Typography>
          }
          <div style={{textAlign: 'right', margin: '20px 0px 10px'}}>
            {isLoading ? <Loader className={classes.loader} size={30}/> :
              <ButtonBlueSend id={ID_BOUTON_ENVOYER} disabled={onErrorQualificationAlerte || isDisabledButton}
                onClick={handleClickSubmit}/>
            }
          </div>
        </CardContent>
      }
      {responseStatus === 201 &&
        <CardContent style={{padding: '16px 24px', display: 'flex', height: '90%'}}>
          <div style={{margin: 'auto', textAlign: 'center'}}>
            <Typography style={{fontSize: '20px', padding: '0px 20%'}}>
              {qualificationAlerte === '03' ?
                'Votre demande a bien été prise en compte et l\'ensemble des fiches sera purgé prochainement' :
                'Votre fiche de vigilance a bien été créée à l\'attention du destinataire suivant :'
              }
            </Typography>
            {qualificationAlerte === '03' ?
              <div style={{marginTop: '30px'}}><RetourDossier isButton={true} token={token}/></div> :
              <React.Fragment>
                <Typography style={{fontSize: '20px', marginTop: '15px'}}>
                  <b>{destinataire}</b> - {mission?.acteursDTO?.acteurs?.filter((acteur: ActeurModel) =>
                    acteur.role?.toUpperCase() === destinataire.toUpperCase())[0].libelle}
                </Typography>
                {isLoadingPDF ? <Loader className={classes.loaderPDF} size={30}/> :
                  <ButtonBlue id={'boutonTelecharger'} className={classes.buttonDl} icon={iconPDF}
                    libelle={'Visualiser le PDF'} onClick={handlePdf}/>}
              </React.Fragment>
            }
          </div>
        </CardContent>
      }
      {responseStatus > 0 && responseStatus !== 201 &&
        <CardContent style={{padding: '16px 24px', display: 'flex', height: '90%'}}>
          <div style={{margin: 'auto', textAlign: 'center'}}>
            <Typography style={{fontSize: '20px', padding: '0px 20%'}}>
              Une erreur est survenue, merci de réessayer plus tard
            </Typography>
          </div>
        </CardContent>
      }
    </Card>
  )
};
