import {createTheme, ThemeOptions} from '@mui/material/styles';
import {frFR} from '@mui/material/locale'

const customThemeDefault = createTheme({
  palette: {
    primary: {
      main: '#241E46', // Bleu foncé Darva
    },
    secondary: {
      light: '#F4F4F4', // Gris clair
      main: '#EAE8E9', // Gris moyen
      dark1: '#DBDBDB', // Gris foncé 1
      dark2: '#7C7C7C', // Gris foncé 2
    },
    tertiary: {
      main: '#EB5C2E', // Orange Darva
    },
    text: {
      primary: '#505050', // Gris anthracite
    },
    link: {
      main: '#0095DB', // Bleu
      light1: '#4EBEEF',
    },
    white: {
      main: '#FFFFFF',
    },
    green: {
      main: '#1EA851',
      light1: '#EDF8F1',
    },
    red: {
      main: '#F0283F',
      light1: '#FEEEF0',
    },
    boxShadow: {
      light1: '#6469A61A',
      main: '#00000029',
    },
    disabled: {
      main: '#00000061',
    },
  },
  fonts: {
    main: 'Roboto',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1200,
      lg: 1600,
      xl: 1920,
    },
  },
  styledTypography: {
    libelle: {
      color: '#7C7C7C',
      fontSize: '14px',
      letterSpacing: '0px',
      minWidth: 'max-content',
    },
    donneeBleue: {
      color: '#241E46',
      fontSize: '14px',
      fontWeight: 'bold',
      letterSpacing: '0px',
    },
    donneeGrise14: {
      color: '#241E46',
      letterSpacing: '0px',
      fontSize: '14px',
    },
    donneeGrise16: {
      color: '#241E46',
      letterSpacing: '0px',
      fontSize: '16px',
    },
  },
}, frFR);

const styledTypographyDonneeStyle = {
  color: customThemeDefault.palette.text.primary,
  letterSpacing: '0px',
};
export const customTheme = createTheme(customThemeDefault as ThemeOptions, {
  styledTypography: {
    donneeGrise14: {
      ...styledTypographyDonneeStyle,
      fontSize: '14px',
    },
    donneeGrise16: {
      ...styledTypographyDonneeStyle,
      fontSize: '16px',
    },
  },
  typography: {
    body1: {
      letterSpacing: '0px',
    },
    body2: {
      letterSpacing: '0px',
    },
    h1: {
      color: customThemeDefault.palette.primary.main,
      fontFamily: customThemeDefault.fonts.main,
      fontSize: '34px',
      fontWeight: '400',
      letterSpacing: '0px',
      [customThemeDefault.breakpoints.down('md')]: {
        fontSize: '30px',
      },
    },
    h3: {
      color: customThemeDefault.palette.primary.main,
      fontFamily: customThemeDefault.fonts.main,
      fontSize: '20px',
      fontWeight: 'bold',
      letterSpacing: '0px',
      marginTop: '5px',
      marginBottom: '20px',
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: customThemeDefault.palette.white.main,
          boxShadow: '0px 10px 20px ' + customThemeDefault.palette.boxShadow.light1,
          borderRadius: '8px',
          opacity: '1',
          border: '1px solid ' + customThemeDefault.palette.secondary.main,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          '&:last-child': {
            paddingBottom: '16px',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: '12px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          'input': {
            paddingBottom: '0.5em',
            paddingTop: '0.5em',
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: customThemeDefault.palette.red.main,
          },
        },
        multiline: {
          'lineHeight': '1.1876em',
          'padding': '14px',
          '& .MuiOutlinedInput-marginDense': {
            padding: '14px',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          'width': '100%',
          'display': 'flex',
          'alignItems': 'center',
          'flexDirection': 'row',
          'justifyContent': 'center',
          'padding': '6px 12px',
          '&.Mui-selected': {
            backgroundColor: customThemeDefault.palette.primary.main,
            color: customThemeDefault.palette.white.main,
          },
          '&:hover': {
            backgroundColor: customThemeDefault.palette.secondary.light,
            color: customThemeDefault.palette.primary.main,
          },
        },
        labelIcon: {
          paddingTop: '9px',
        },
        wrapper: {
          display: 'flex',
          flexDirection: 'row',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          marginRight: '10px',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          padding: '0px',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        popper: {
          zIndex: 1000,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          'marginLeft': '0px',
          'marginRight': '0px',
          '&.Mui-error': {
            color: customThemeDefault.palette.red.main,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: '0px',
        },
      },
    },
  },
});
