import React, {useEffect, useState} from 'react';
import {CardContent, Typography, Card} from '@mui/material'
import {ReactComponent as CustomBuildIcon} from '../../../../images/icones/icones-acteurs/icn-reparateur.svg'
import {ReactComponent as CustomExpIcon} from '../../../../images/icones/icones-acteurs/icn-expert.svg'
import {ReactComponent as CustomAssureurIcon} from '../../../../images/icones/icones-acteurs/icn-assureur.svg'
import {ReactComponent as CustomLoueurIcon} from '../../../../images/icones/icones-acteurs/icn-loueur.svg'
import {ReactComponent as CustomStockeurIcon} from '../../../../images/icones/icones-acteurs/icn-stockeur.svg'
import {ReactComponent as CustomDepanneurIcon} from '../../../../images/icones/icones-acteurs/icn-depanneur.svg'
import {ReactComponent as CustomRecycleurIcon} from '../../../../images/icones/icones-acteurs/icn-recycleur.svg'
import {ReactComponent as CustomEvaluateurIcon} from '../../../../images/icones/icones-acteurs/icn-evaluateur.svg'
import {ReactComponent as CustomTiersIcon} from '../../../../images/icones/icones-acteurs/icn-tiers.svg'
import {ReactComponent as CustomIntermidiaireIcon} from '../../../../images/icones/icones-acteurs/icn-intermediaire.svg'
import {ReactComponent as CustomArgosIcon} from '../../../../images/icones/icones-acteurs/icn-argos.svg'
import {ReactComponent as CustomTeteReseauIcon} from '../../../../images/icones/icones-acteurs/icn-tete-reseau.svg'
import {ReactComponent as CustomControleurIcon} from '../../../../images/icones/icones-acteurs/icn-controleur.svg'
import {ReactComponent as CustomCourtierIcon} from '../../../../images/icones/icones-acteurs/icn-courtier.svg'
import {ReactComponent as CustomAssisteurIcon} from '../../../../images/icones/icones-acteurs/icn-assisteur.svg'
import {useMissionContext} from '../../../../context/MissionContext'
import {ActeurModel} from '../../../../model/detailsDossier/ActeurModel'
import {Acteur} from './Acteur'
import {ButtonBlue} from '../../../common/buttons/ButtonBlue';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';

/** Design */
const styles = (theme: Theme) => ({
  acteur: {
    marginTop: '10px',
  },
  icon: {
    display: 'inline',
    opacity: '1',
    marginRight: '10px',
    fill: theme.palette.primary.main + ' !important',
  },
  button: {
    display: 'flex',
    margin: '15px auto 0px',
  },
});

export const CODES_ACTEURS_ORDRE = ['04', '05', '06', '19', '02', '25', 'TeteReseau', 'Argos', '35',
  '10', '11', '36', '14', '12', '20', '21', '38', 'Controleur', 'CentreDeGestion', 'PrestataireDeTravaux', 'Assisteur'];

export const useActeursList = () => {
  const {mission} = useMissionContext();
  const indicateurArchivage = mission?.indicateurArchivage;
  const acteurs: ActeurModel[] = [];
  if (indicateurArchivage === 'N') {
    CODES_ACTEURS_ORDRE.forEach((code) => {
      const acteurFound = mission?.acteursDTO?.acteurs?.find((acteur) => code === acteur?.codeActeur);
      if (acteurFound) acteurs.push(acteurFound)
    })
  } else if (mission?.acteursDTO?.acteurs) {
    mission.acteursDTO.acteurs.forEach((acteur) => acteurs.push(acteur))
  }
  return acteurs
};

export const ContentActeurs = ({marginDense = false, acteursTab}: {marginDense?: boolean,
  acteursTab: ActeurModel[]}) => {
  const classes = useEmotionStyles(styles);
  const getIcone = (acteur: ActeurModel) => {
    switch (true) {
      case (acteur.codeActeur === '04' || acteur.role === 'ASSUREUR'): {
        return <CustomAssureurIcon className={classes.icon}/>
      }
      case (acteur.codeActeur === '05' || acteur.codeActeur === '25' || acteur.codeActeur === '35' ||
          acteur.role === 'CARROSSIER'): {
        return <CustomBuildIcon className={classes.icon}/>
      }
      case (acteur.codeActeur === '19' && acteur.role === 'INTERMÉDIAIRE'): {
        return <CustomIntermidiaireIcon className={classes.icon}/>
      }
      case (acteur.role === 'COURTIER'): {
        return <CustomCourtierIcon className={classes.icon}/>
      }
      case (acteur.codeActeur === '12' || acteur.role === 'LOUEUR'): {
        return <CustomLoueurIcon className={classes.icon}/>
      }
      case (acteur.codeActeur === '06' || acteur.role === 'EXPERT'): {
        return <CustomExpIcon className={classes.icon}/>
      }
      case ( acteur.codeActeur === '20' || acteur.codeActeur === '38' || acteur.role === 'EVALUATEUR'): {
        return <CustomEvaluateurIcon className={classes.icon}/>
      }
      case (acteur.codeActeur === '02'): {
        return <CustomTiersIcon className={classes.icon}/>
      }
      case (acteur.codeActeur === '10' || acteur.codeActeur === '11'): {
        return <CustomDepanneurIcon className={classes.icon}/>
      }
      case (acteur.codeActeur === '14'): {
        return <CustomStockeurIcon className={classes.icon}/>
      }
      case (acteur.codeActeur === '36' || acteur.role === 'DEMOLISSEUR'): {
        return <CustomRecycleurIcon className={classes.icon}/>
      }
      case (acteur.codeActeur === 'Argos' || acteur.role === 'ARGOS'): {
        return <CustomArgosIcon className={classes.icon}/>
      }
      case (acteur.codeActeur === 'TeteReseau' || acteur.role === 'TETERESEAU'): {
        return <CustomTeteReseauIcon className={classes.icon}/>
      }
      case (acteur.codeActeur === '21' || acteur.role === 'CONTROLEUR'): {
        return <CustomControleurIcon className={classes.icon}/>
      }
      case (acteur.codeActeur === 'Assisteur' || acteur.role === 'ASSISTEUR'): {
        return <CustomAssisteurIcon className={classes.icon}/>
      }
      default: {
        return <CustomBuildIcon className={classes.icon}/>
      }
    }
  };
  if (acteursTab?.length && acteursTab?.length > 0) {
    return (
      <>
        {acteursTab?.map((acteur) => {
          return (
            <div key={acteur.codeAbonne} className={classes.acteur} style={{marginRight: marginDense ? '10px' : '0px'}}>
              <Acteur title={acteur.role ?? ''} personne={acteur} icon={getIcone(acteur)}/>
            </div>
          )
        })}
      </>
    )
  } else {
    return <Typography style={{marginTop: '10px'}}>Aucun acteur pour ce dossier</Typography>
  }
};

export const Acteurs = () => {
  const classes = useEmotionStyles(styles);
  const {mission} = useMissionContext();
  const acteurs = useActeursList();
  const [expanded, setExpanded] = useState(false);
  const [acteursToDisplay, setActeursToDisplay] = useState(acteurs?.slice(0, 3));

  useEffect(() => {
    setActeursToDisplay(acteurs?.slice(0, 3))
  }, [mission]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
    setActeursToDisplay(acteurs)
  };

  const handleCollapseClick = () => {
    setExpanded(!expanded);
    setActeursToDisplay(acteurs.slice(0, 3))
  };

  return (
    <Card>
      <CardContent>
        <Typography id={'title-grid-acteurs'} variant={'h3'}>Acteurs</Typography>
        <ContentActeurs acteursTab={acteursToDisplay}/>
        {(!expanded && acteurs.length > 3) ?
          <ButtonBlue id={'boutonToutVoirActeurs'} className={classes.button} libelle={'Tout voir'}
            onClick={handleExpandClick}/> : ''
        }
        {expanded ? <ButtonBlue id={'boutonMasquer'} className={classes.button} libelle={'Masquer'}
          onClick={handleCollapseClick}/> : ''
        }
      </CardContent>
    </Card>
  )
};
