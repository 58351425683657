import {Card, CardContent, Grid, Typography} from '@mui/material';
import React from 'react';
import useEmotionStyles from '../../common/useEmotionStyles';

/** Design */
const styles = () => ({
  title: {
    margin: '0px',
    padding: '8px',
  },
  height100: {
    height: '100%',
  },
})

interface CommonRecapitulatifProps {
  blocInfosRdv: React.JSX.Element
  blocInfosAssure: React.JSX.Element
  blocLocalisation: React.JSX.Element
  blocRendezVous: React.JSX.Element
  blocInfosPartenaire: React.JSX.Element
}

export const CommonRecapitulatif = (props: CommonRecapitulatifProps) => {
  const classes = useEmotionStyles(styles);
  const {blocInfosRdv, blocInfosAssure, blocLocalisation, blocRendezVous, blocInfosPartenaire} = props

  return (<React.Fragment>
    <Grid container justifyContent='space-between' spacing={2}>
      <Grid item xs={12} md={6}>
        <Typography variant={'h3'} className={classes.title}>Récapitulatif des informations</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant={'h3'} className={classes.title}>Récapitulatif du choix du réparateur</Typography>
      </Grid>
    </Grid>

    <Grid container justifyContent='space-between' spacing={2}>
      <Grid item xs={12} md={6}>
        <Card style={{height: '100%'}}>
          <CardContent>
            <Grid container justifyContent='space-between' spacing={2}>
              <Grid item xs={12}>
                {blocInfosRdv}
              </Grid>
              <Grid item xs={12}>
                {blocInfosAssure}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card style={{height: '100%'}}>
          <CardContent className={classes.height100}>
            <Grid className={classes.height100} container justifyContent='space-between' spacing={2}>
              <Grid item sm={4}>
                {blocLocalisation}
                {blocRendezVous}
              </Grid>
              <Grid item xs={12} sm={8}>
                {blocInfosPartenaire}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  </React.Fragment>)
}
