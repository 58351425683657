import {
  GRID_CHECKBOX_SELECTION_COL_DEF, GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
  GridHeaderCheckbox,
} from '@mui/x-data-grid';
import {ButtonLink} from '../../common/buttons/ButtonLink';
import React from 'react';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';
import {IResultLine} from '../../forms/rechercheEchanges/model/model';
import {ECHANGES_COMPONENTS} from '../../forms/rechercheEchanges/RechercheEchanges';
import {Typography} from '@mui/material';
import {InfosEchange} from '../../../model/rechercheEchange/InfosEchange';
import {InfosTypesDocs} from '../../../model/rechercheEchange/InfosTypesDocs';

interface IProps {
  callbackComponent: (component: string, infosEchange: InfosEchange, infosTypesDocs: InfosTypesDocs,
                      lastPaginationCall?: any, pageSize?: string) => void,
  lastPaginationCall: any
  pageSize: string
}

/** Design */
const styles = (theme: Theme) => ({
  headerGrid: {
    backgroundColor: theme.palette.grey.A200,
  },
  checkBoxRADGrid: {
    display: 'grid',
    gridTemplateColumns: '25px 25px',
    alignItems: 'center',
    gridColumnGap: '12px',
  },
  checkBoxRADColumnHeader: {
    fontWeight: 401,
    fontSize: '0.875rem',
    lineHeight: 1.43,
  },
  hidden: {
    contentVisibility: 'hidden' as const,
  },
});

export const useGridColumns = (props: IProps) => {
  const classes = useEmotionStyles(styles);
  const {callbackComponent, lastPaginationCall, pageSize} = props;

  const handleClickEchange = (echange: IResultLine) => {
    callbackComponent(
        ECHANGES_COMPONENTS.LISTE_DOCS_ECHANGE,
        {
          idEchange: echange.idEchange,
          numeroEchange: echange.numeroLot,
          isEchangeRAD: echange.rad,
        } as InfosEchange,
        {
          typeDoc: '',
          sousTypeDoc: '',
        } as InfosTypesDocs,
        lastPaginationCall,
        pageSize,
    );
  };

  const handleClickEtat = (echange: IResultLine) => {
    callbackComponent(
        ECHANGES_COMPONENTS.DETAILS_ECHANGE_ET_DOCS_PAR_TYPE_ET_ANOMALIES,
        {
          idEchange: echange.idEchange,
          numeroEchange: echange.numeroLot,
          isEchangeRAD: echange.rad,
          actionEchange: echange.action,
        } as InfosEchange,
        {
          typeDoc: '',
          sousTypeDoc: '',
        } as InfosTypesDocs,
        lastPaginationCall,
        pageSize,
    );
  };

  const columns: GridColDef[] = [
    {field: 'numeroLot', headerName: 'N° d\'échange', headerClassName: classes.headerGrid, sortable: false,
      align: 'center', flex: 1, headerAlign: 'center',
      renderCell: (params) => (
        <ButtonLink
          id={'bouton-numero-echange-'.concat(params.row.numeroLot)}
          libelle={params.row.numeroLot}
          isBackgroundHover={false}
          isDisableRipple={true}
          onClick={() => handleClickEchange(params.row)}
        />
      ),
    },

    {field: 'dateHeureDebut', headerName: 'Date de création', headerClassName: classes.headerGrid,
      headerAlign: 'center', sortable: false, flex: 1, align: 'center',
    },

    {field: 'raisonSociale', headerName: 'Partenaire', headerClassName: classes.headerGrid, headerAlign: 'center',
      sortable: false, align: 'center', flex: 1,
    },

    {field: 'etat', headerName: 'État', headerClassName: classes.headerGrid, sortable: false, align: 'center', flex: 1,
      headerAlign: 'center',
      renderCell: (params) => (
        <ButtonLink
          id={'bouton-etat-echange-'.concat(params.row.numeroLot)}
          libelle={params.row.etat}
          isBackgroundHover={false}
          isDisableRipple={true}
          onClick={() => handleClickEtat(params.row)}
        />
      ),
    },

    {field: 'action', headerName: 'Action', headerClassName: classes.headerGrid, headerAlign: 'center', sortable: false,
      flex: 1, align: 'center',
    },
    {headerClassName: classes.headerGrid, flex: 0.7, ...GRID_CHECKBOX_SELECTION_COL_DEF,
      renderHeader: (params: GridColumnHeaderParams) => (
        <div className={classes.checkBoxRADGrid}>
          <Typography className={classes.checkBoxRADColumnHeader}>RAD</Typography>
          <GridHeaderCheckbox {...params} />
        </div>
      ),
      cellClassName: (params: GridCellParams) => (
        !params.row.rad ? classes.hidden : ''
      ),
    },
  ];

  return columns;
};
