import {
  IPecBgResponse,
  IRequestInformation,
} from './model/ModelPECBG';
import moment from 'moment/moment';
import {getService} from '../../../common/Utils';

const assureMapper = (data: any, adresseClient: any) => {
  return {
    civilite: data.client?.titreClient || '',
    nom: data.client?.nom || '',
    prenom: data.client?.prenom || '',
    adresse1: adresseClient.adresses?.[0]?.adresse || '',
    adresse2: adresseClient.adresses?.[1]?.adresse || '',
    adresse3: adresseClient.adresses?.[2]?.adresse || '',
    codePostal: adresseClient.codePostal || '',
    localite: adresseClient.localite || '',
    pays: adresseClient.pays || '',
  }
}

const centreGestionMapper = (data: any, adresseCentreDeGestion: any) => {
  return {
    adresse1: adresseCentreDeGestion.adresses?.[0]?.adresse || '',
    adresse2: adresseCentreDeGestion.adresses?.[1]?.adresse || '',
    adresse3: adresseCentreDeGestion.adresses?.[2]?.adresse || '',
    codePostal: adresseCentreDeGestion.codePostal || '',
    localite: adresseCentreDeGestion.localite || '',
    pays: adresseCentreDeGestion.pays || '',
    telephone: data.centreDeGestion?.telephone || '',
  }
}

export const pecBgResponseMapper = (data: any, demandeInformation: IRequestInformation,
    serviceCode: string): IPecBgResponse => {
  const priseEnCharge = data.priseEnCharge ?
    data.priseEnCharge.charAt(0).toUpperCase() + data.priseEnCharge.slice(1).toLowerCase() : '';
  const delaiReponseAssureur = getDateFormat(data.delaiReponseAssureur);
  const delaiReponseDarva = getDateFormat(data.delaiResponseDarva);
  const emptyAddress = {
    adresses: [{adresse: ''}, {adresse: ''}, {adresse: ''}],
    localite: '',
    codePostal: '',
    pays: '',
  };
  if (data.contrat?.numeroContrat) {
    demandeInformation.numeroContrat = data.contrat.numeroContrat;
  }
  const adresseClient = data.client?.localisation || emptyAddress;
  const adresseCentreDeGestion = data.centreDeGestion?.localisation || emptyAddress;

  const getGarantieBDG = () => {
    if (data.garantie?.garantieBDG) {
      return 'oui';
    } else if (data.garantie?.garantieBDG === false) {
      return 'non';
    }
    return '';
  }

  const getTVARecuperable = () => {
    if (data.reponse?.tvaRecuperable) {
      return 'Oui';
    } else if (data.reponse?.tvaRecuperable === false) {
      return 'Non';
    }
    return '';
  }

  return {
    demandeInformation,
    assure: assureMapper(data, adresseClient),
    centreGestion: centreGestionMapper(data, adresseCentreDeGestion),
    reponseInformation: {
      reponse: {
        codeReponse: data.reponse?.codeReponse,
        libelleReponse: data.reponse?.libelleReponse,
      },
      erreur: {
        codeErreur: '',
        libelleErreur: '',
      },
      garantie: {
        garantieBDG: getGarantieBDG(),
        montantFranchise: data.garantie?.montantFranchise ?? '',
        montantMax: data.garantie?.montantMax ?? '',
        montantMin: data.garantie?.montantMin ?? '',
        pourcentage: data.garantie?.pourcentage ?? '',
        typeFranchise: data.garantie?.typeFranchise || '',
      },
      numeroSinistre: data.numeroSinistre || '',
      priseEnCharge: priseEnCharge,
      tvaRecuperable: getTVARecuperable(),
    },
    vehicule: {
      vehMarque: data.vehicule?.vehMarque || '',
      vehModele: data.vehicule?.vehModele || '',
    },
    informationsTechniques: {
      delaiReponseAssureur,
      delaiReponseDarva,
      serviceAuto: getService(serviceCode),
    },
  } as IPecBgResponse;
};

const getDateFormat = (date: string): string => {
  if (date) {
    return moment(Date.parse(date)).format('mm:ss.SSS');
  }
  return '';
}
