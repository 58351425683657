import React, {useEffect, useState} from 'react';
import {Loader} from '../common/Loader';
import {useMissionContext} from '../../context/MissionContext';
import {UserContext} from '../../context/UserContext';
import useHasRole from '../../hook/useHasRole';
import {permittedRolesDetailsDossierConsultations} from '../../constantes/roles/Roles';
import AccessDenied from '../common/errorsComponents/AccessDenied';
import {GenericError} from '../common/errorsComponents/GenericError';
import {useNavigate} from 'react-router-dom';
import {DetailsDossier} from './DetailsDossier';
import {getMissionsList, IElement, isNumMissionInList} from './Utils';
import {SearchModel} from '../../model/common/SearchModel';
import {getDetailMission, getMissions} from '../../api/dossier';
import {useParams} from 'react-router';
import {IToken} from '../../model/detailsDossier/TokenModel';
import {TokenModel} from '../../model/common/TokenModel';
import {InfosRendezVousModel} from '../../model/detailsDossier/RendezVousModel';
import {DetailsModel} from '../../model/detailsDossier/DetailsModel';

const base64 = require('base-64');

export const ContainerDetailDossier = () => {
  const params = useParams<keyof IToken>() as IToken;
  const navigate = useNavigate();
  const {actions} = useMissionContext();
  const {user} = React.useContext(UserContext);
  const hasRoleDossierConsultations = useHasRole(permittedRolesDetailsDossierConsultations);

  const initialSearch = {
    numClient: '',
    referenceSinistre: '',
  };

  // State
  const [search, setSearch] = useState(initialSearch);
  const [tabValue, setTabValue] = useState(0);
  const [missionsList, setMissionsList] = useState<IElement[]>([]);
  const [selectedMission, setSelectedMission] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [idigoRendezVous, setIdigoRendezVous] = useState<InfosRendezVousModel[]>([]);

  useEffect(() => {
    try {
      const decodedToken = JSON.parse(base64.decode(params.token));
      if (user.userCompte && decodedToken) {
        setSearch(decodedToken);
        const searchMissions: SearchModel = {
          numClient: user.numClient,
          userCompte: user.userCompte,
          codeAbo: user.codeAbo,
          codeService: user.service,
          nbMaxElement: '12',
          referenceSinistre: decodedToken.referenceSinistre,
        };
        getMissions(searchMissions)
            .then((result) => {
            // Récupération des numéros de mission
              if (result?.list?.length) {
                const missions: IElement[] = getMissionsList(result.list);

                setMissionsList(missions);
                const numMission = decodedToken?.numeroMission?.trim();
                setSelectedMission(numMission === '' ? 'Toutes' : numMission);
                if (user.isTransparent && numMission && !isNumMissionInList(result.list, numMission)) {
                  refNotFound();
                }
              } else if (user.isTransparent) {
                refNotFound();
              }
            })
            .catch(() => {
              console.error('Impossible de charger les missions')
            });
        getDetailDossier(decodedToken);
      }
    } catch (e) {
      setIsError(true);
    }
  }, [user]);

  const refNotFound = () => {
    setIsLoading(false);
    setIsError(true);
    setErrorMessage('Le dossier recherché est introuvable');
  };

  const updateTabValue = (missionUpdate: DetailsModel) => {
    const indicateurArchivage = missionUpdate?.indicateurArchivage;
    const isDossierSimple = missionUpdate?.dossierSimple;

    const isHistoriqueActiveTab = indicateurArchivage === 'O' || indicateurArchivage === 'A' || isDossierSimple;
    if (isHistoriqueActiveTab) {
      setTabValue(1);
    } else {
      const tabStorage = sessionStorage.getItem('tab');
      tabStorage ? setTabValue(parseInt(tabStorage)) : setTabValue(0);
    }
  };

  const getDetailDossier = (payLoad: TokenModel) => {
    const urlParams: TokenModel = !user.isTransparent ? payLoad : {
      ...payLoad,
      codeGTA: user.codeGTA,
      codeAbonne: user.codeAbo,
      userCompte: user.userCompte,
    };
    getDetailMission(urlParams, user.isTransparent)
        .then((response) => {
          actions.setMission(response);
          setIdigoRendezVous(response.rendezVousDTO ?? []);
          sessionStorage.setItem('listRdv', JSON.stringify(response.rendezVousDTO));
          sessionStorage.setItem('mission', JSON.stringify(response));
          updateTabValue(response);
        })
        .catch(() => setIsError(true))
        .finally(() => {
          setIsLoading(false);
        })
  };

  if (!user.userCompte || hasRoleDossierConsultations && search.numClient !== '' && isLoading && !isError) {
    return <Loader/>
  }

  return (
    <>
      {!hasRoleDossierConsultations &&
        <AccessDenied
          message={'Ce compte ne vous permet pas de consulter le détail du dossier'}
          isReturnButton={true}
          buttonLabel={'Retour'}
          onClickButton={() => navigate('/extranet/portail-sinapps-auto')}
        />
      }

      {(!isLoading && search.numClient === '') || isError &&
        <GenericError errorMessage={errorMessage}/>
      }

      {hasRoleDossierConsultations && !isLoading && !isError &&
        <DetailsDossier
          tabValue={tabValue}
          search={search}
          missionsList={missionsList}
          selectedMission={selectedMission}
          setSelectedMission={setSelectedMission}
          setIsLoading={setIsLoading}
          getDetailDossier={getDetailDossier}
          idigoRendezVous={idigoRendezVous}
        />
      }
    </>
  )
};
