import {Theme} from '@emotion/react';

export const stylesCommon = (theme: Theme) => ({
  container: {
    padding: '32px',
  },
  titre: {
    fontSize: '24px',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    marginBottom: '30px',
  },
  sousTitre: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '10px',
    flexGrow: 1,
    flexBasis: '0px',
  },
  sousTitre2: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    fontSize: '14px',
    marginBottom: '10px',
  },
  cardContainer: {
    backgroundColor: theme.palette.white.main,
    borderRadius: '8px',
    padding: '10px',
    boxShadow: '0px 10px 20px ' + theme.palette.boxShadow.light1,
  },
  cardContent: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: '8px',
  },
  flex: {
    display: 'flex',
  },
  row: {
    columnGap: '5px',
    marginBottom: '15px',
  },
  divLibelleVal: {
    flexGrow: 1,
    flexBasis: '0px',
  },
  libelleHeader: {
    fontWeight: 'bold',
    fontSize: '14px',
  },
  libelleElement: {
    color: theme.palette.secondary.dark2,
    fontSize: '14px',
    minWidth: 'max-content',
  },
  valeurElement: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: '14px',
  },
  lienFichier: {
    color: theme.palette.link.main,
    fontWeight: 'bold',
    fontSize: '14px',
    textDecoration: 'underline',
  },
  icon: {
    marginRight: '8px',
    fill: theme.palette.link.main,
  },
  loader: {
    marginTop: '5px',
    marginRight: '10px',
  },
});
