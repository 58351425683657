import * as yup from 'yup';
import {getTypeImmat} from '../../../../common/utils/InputValuesUtils';
import moment from 'moment/moment';

export const validationSchemaDateCertificat = (typeFormulaire: string) => yup.object({
  dateCertificat: yup.date()
      .transform((current, origin) => origin === '' ? null : current)
      .nullable()
      .min('1900-01-01', 'Veuillez saisir une date postérieure au 01/01/1900')
      .max(moment(), 'Veuillez saisir une date antérieure à celle du jour')
      .test('test-dateCertificat1', 'Veuillez saisir une valeur',
          function(value, ctx) {
            if (ctx.parent.presenceCertificat && getTypeImmat(ctx.parent.immatriculation) === 'FNI') {
              return value !== undefined
            }
            return true
          })
      .test('test-dateCertificat2', 'La date du certificat d\'immatriculation ne peut être postérieure ' +
        'à la date d\'achat',
      function(value, ctx) {
        if (typeFormulaire === 'DA') {
          if (ctx.parent.dateAchat && value) {
            return value <= ctx.parent.dateAchat
          }
          return true
        } else {
          if (ctx.parent.dateCession && value) {
            return value <= ctx.parent.dateCession
          }
          return true
        }
      }),
});
