import React, {ReactElement} from 'react';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {Typography} from '@mui/material';
import {Theme} from '@emotion/react';

interface CardContentProps {
  titre: string,
  content: ReactElement
}

/** Design */
const styles = (theme: Theme) => ({
  cardContent: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: '0px 0px 8px 8px',
    padding: '24px',
    marginBottom: '16px',
  },
  otherCardContent: {
    borderRadius: '8px',
  },
});

export const CardContent = (props: CardContentProps) => {
  const classes = useEmotionStyles(styles);
  const {titre, content} = props

  return (
    <div className={`${classes.cardContent} ${classes.otherCardContent}`}>
      <Typography variant={'h3'}>{titre}</Typography>
      {content}
    </div>)
};
