import React, {useState} from 'react';
import {Typography} from '@mui/material';
import {TextFieldSearch} from '../../common/formsComponents/TextFieldSearch';
import {BlocAdresse} from '../../common/adresse/BlocAdresse';
import {customTheme} from '../../../common/GlobalTheme';
import moment from 'moment';
import {refusRdv} from '../../../api/idigo';
import {RendezVousModel} from '../../../model/rechercherRendezVous/SearchRdvModel';
import {UserContext} from '../../../context/UserContext';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {Telephone} from '../../common/personne/Telephone';
import {checkTelMobile} from './PriseRDV';
import {CommonForm} from '../../forms/common/CommonForm';

interface RefusProps {
  rdv: RendezVousModel,
  referenceSinistre: string,
  onClose: () => void,
  onCloseAndRefresh: () => void,
}

export interface ErrorField {
  fieldName?: string
}

export interface ErrorFields extends Array<ErrorField> {
}

const styles = (theme: Theme) => ({
  body: {
    padding: '20px 20px 10px',
  },
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  red: {
    color: 'red',
  },
  error: {
    fontSize: '12px',
  },
  checkboxRoot: {
    paddingRight: '0px',
  },
  checkboxChecked: {
    color: theme.palette.link.main,
  },
  formLabel: {
    maxWidth: 'max-content',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '20px',
  },
  addMarginTop10: {
    marginTop: '10px',
  },
  addMarginTop20: {
    marginTop: '20px',
  },
  addMarginTop30: {
    marginTop: '30px',
  },
});

export const RefusForm = (props: RefusProps) => {
  const {id, idActivite, partenaire, dateDebut, dateFin, mailAssure, telAssure, mailReparateur} = props.rdv;
  const {referenceSinistre} = props;
  const {user} = React.useContext(UserContext);
  const [isDisabledButton, setIsDisabledButton] = useState(false);
  const [errorFields, setErrorFields] = useState<ErrorFields>([]);
  const [responseReturn, setResponseReturn] = useState(0);
  const [mailAss, setMailAss] = useState(mailAssure ?? '');
  const [telAss, setTelAss] = useState(telAssure ? telAssure.replace('+33', '0') : '');
  const [isLoading, setIsLoading] = useState(false);
  const classes = useEmotionStyles(styles);
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  const isErrorField = (fieldName: string) => {
    return errorFields && errorFields.filter((error) => error.fieldName === fieldName).length > 0 || false
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    setErrorFields([...errorFields.filter((error)=> error.fieldName != event.target.name)])
  };

  const handleChangeMailAss = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsDisabledButton(false);
    setMailAss(event.target.value);
    if ((event.target.value.trim().length === 0 && telAss.trim().length === 0)) {
      setIsDisabledButton(true);
    }
  };

  const handleChangeTelAss = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsDisabledButton(false);
    setTelAss(event.target.value);
    if ((event.target.value.trim().length === 0 && mailAss.trim().length === 0)) {
      setIsDisabledButton(true);
    }
  };

  const onBlurMailAss = (event: React.FocusEvent<HTMLInputElement>) => {
    if (!emailPattern.test(event.target.value) && event.target.value.length !== 0) {
      setErrorFields([...errorFields,
        {fieldName: 'emailAssure'},
      ]);
      setIsDisabledButton(true);
    }
  };

  const onBlurTelephone = (event: React.FocusEvent<HTMLInputElement>) => {
    const telFormated = event.target.value.replaceAll(' ', '');
    const checkTel = !event.target.value.startsWith('06') && !event.target.value.startsWith('07') ||
      telFormated.length != 10;

    if (checkTel && telAss.trim().length > 0) {
      setErrorFields([...errorFields,
        {fieldName: 'telephone'},
      ])
    }
  };

  const handleSubmit = () => {
    if ((telAss.trim().length > 0 || mailAss.trim().length > 0) && errorFields.length === 0) {
      setIsLoading(true);
      const mailAuth = mailAss.trim().length > 0;
      const telAuth = telAss.trim().length > 0;
      const {codeGTA, numClient, userCompte} = user;
      refusRdv({idRdv: id, idPartenaire: partenaire.id, message: '', mailAss, telAss, mailRep: mailReparateur,
        mailAuth, telAuth, codeGTA, numClient, nomClient: user.raisonSociale,
        userCompte, serviceCode: user.service, referenceSinistre, idActivite})
          .then((response) => {
            setResponseReturn(response.status);
            setIsLoading(false)
          }).catch((error) => {
            setResponseReturn(error.response.status)
          })
    }
  };

  const formName = 'refus'

  return (
    <CommonForm
      idForm={formName}
      idTitre={formName}
      titre={'Annulation du rendez-vous'}
      libelleEnvoye={'Votre rendez-vous est bien annulé'}
      onClose={props.onClose}
      onCloseAndRefresh={props.onCloseAndRefresh}
      responseReturn={responseReturn}
      content={(responseReturn <= 0) ?
        <div className={classes.body}>
          <Typography variant={'h3'}>Informations du rendez-vous</Typography>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div>
              <Typography style={customTheme.styledTypography.libelle}>
                Rendez-vous avec le réparateur
              </Typography>
              <Typography style={{fontWeight: 'bold'}}>
                {partenaire.raisonSociale}
              </Typography>
              <div style={{marginTop: '-12px'}}>
                <BlocAdresse adresse={partenaire.adresse}/>
              </div>
            </div>
            <div>
              <Typography style={customTheme.styledTypography.libelle}>
                Date du rendez-vous
              </Typography>
              <Typography style={{fontWeight: 'bold'}}>
                {moment(dateDebut).format('DD/MM/YYYY')}
              </Typography>
              <Typography>
                {`De ${moment(dateDebut).format('HH:mm')} à ${moment(dateFin).format('HH:mm')}`}
              </Typography>
            </div>
          </div>

          <div>
            <Typography variant={'h3'} style={{margin: '24px 0px 5px', marginBottom: 0}}>
              Coordonnées des destinataires <span style={{color: 'red'}}>*</span>
            </Typography>
            <Typography className={`${classes.red} ${classes.error}`}>
              {telAss.trim() === '' && mailAss.trim() === '' &&
                'Veuillez remplir au moins un des deux champs'
              }
            </Typography>
            <Typography style={customTheme.styledTypography.donneeGrise14} className={classes.addMarginTop20}>
              Envoyer une notification d&apos;annulation du rendez-vous
            </Typography>
          </div>
          <div className={classes.grid + ' ' + classes.addMarginTop30 } >
            <Telephone
              fullWidth={true}
              title={'Téléphone de l\'assuré'}
              isMandatory={false}
              formulaire={'RDV'}
              defaultTelephone={telAss}
              onChangeValue={handleChangeTelAss}
              onBlur={onBlurTelephone}
              onFocus={handleFocus}
              onError={isErrorField('telephone')}
              typeError={checkTelMobile(telAss, false).typeError}
            />
            <div>
              <Typography className={classes.libelle}>
                E-mail de l&apos;assuré
              </Typography>
              <TextFieldSearch
                id={'emailAssure'}
                name={'emailAssure'}
                fullWidth={true}
                value={mailAss}
                onChange={handleChangeMailAss}
                onBlur={onBlurMailAss}
                onFocus={handleFocus}
              />
              {isErrorField('emailAssure') &&
                <Typography className={`${classes.red} ${classes.error}`}>
                  Veuillez saisir une adresse email au bon format
                </Typography>
              }
            </div>
          </div>
          <Typography className={classes.addMarginTop30} style={customTheme.styledTypography.donneeGrise14}>
            E-mail du réparateur
          </Typography>
          <Typography className={classes.addMarginTop10} style={customTheme.styledTypography.libelle}>
            {mailReparateur}
          </Typography>
        </div> : <></>
      }
      withoutPj={true}
      isLoading={isLoading}
      errorMessageField={isDisabledButton}
      handleClick={handleSubmit}
    />
  )
};
