import produce from 'immer'
import {Dispatch} from 'react'
import {CardsModel} from '../model/rechercheDossier/CardsModel'
import {ResultActions} from '../action/result.action'

type ResultActionType =
  | 'SET_RESULT'

export interface ResultAction {
  type: ResultActionType
  result?: CardsModel
}

export interface ResultDataState {
  result?: CardsModel
  dispatch: Dispatch<ResultAction>
  actions: ResultActions
}

export const resultReducer = (state: ResultDataState, action: ResultAction) => {
  if (action.type === 'SET_RESULT') {
    return produce(state, (draftState) => {
      draftState.result = action.result;
      return draftState
    })
  } else {
    return {...state}
  }
};
