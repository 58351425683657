import React from 'react';
import useEmotionStyles from '../../../../../common/useEmotionStyles';
import {Typography} from '@mui/material';
import {IVehicle} from '../../model/ModelPECBG';
import {stylesCommon} from './StylesCommon';

export interface VehicleProps {
  vehicule: IVehicle,
}

const Vehicle = (props: VehicleProps) => {
  const {vehicule} = props;
  const classes = useEmotionStyles(stylesCommon);
  return (
    <div>
      <Typography variant={'h6'} className={classes.title}>
        Informations sur le véhicule
      </Typography>
      <div className={classes.card}>
        <div className={classes.cardContent}>
          <div className={classes.grid}>
            <div>
              <span className={classes.libelle}>Modèle du véhicule : </span>
              <span>{vehicule.vehModele}</span>
            </div>
            <div>
              <span className={classes.libelle}>Marque du véhicule : </span>
              <span>{vehicule.vehMarque}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vehicle;
