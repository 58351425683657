import React from 'react';
import {MenuItem, TextField} from '@mui/material';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';

interface TextFieldSelectProps {
  className?: string,
  classNameInput?: string,
  style?: object,
  id: string,
  name: string,
  marginDense?: boolean,
  border?: boolean,
  fullWidth?: boolean,
  label?: string,
  disabled?: boolean,
  value: string,
  autoComplete?: string,
  itemsList: React.ReactElement[],
  withEmptyItem?: boolean,
  emptyItemLabel?: string,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void,
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void,
  onError?: boolean,
  helperText?: React.JSX.Element | string,
}

const styles = (theme: Theme) => ({
  textfield: {
    backgroundColor: theme.palette.white.main,
  },
  notchedOutline: {
    border: 'none',
  },
});

export const TextFieldSelect = ({className, classNameInput, style, id, name, marginDense, border, fullWidth, label,
  disabled, value, onChange, onFocus, onBlur, onError, helperText, autoComplete, itemsList, withEmptyItem,
  emptyItemLabel}: TextFieldSelectProps) => {
  const classes = useEmotionStyles(styles);

  if (withEmptyItem) {
    itemsList.unshift(<MenuItem key={'empty'} value=""><em>- {emptyItemLabel} -</em></MenuItem>);
  }

  const notchedOutline = border !== undefined && !border ? {notchedOutline: classes.notchedOutline} : '';
  const input = classNameInput !== undefined ? {input: classNameInput} : '';
  const inputPropsClasses = {
    root: classes.textfield,
    ...{...notchedOutline},
    ...{...input},
  };

  return (
    <TextField
      select
      id={id}
      name={name}
      margin={marginDense ? 'dense' : 'none'}
      size={marginDense ? 'small' : undefined}
      variant="outlined"
      className={`${className}`}
      fullWidth={fullWidth}
      InputProps={{
        classes: inputPropsClasses,
      }}
      style={style}
      label={label}
      disabled={disabled}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      error={onError}
      helperText={helperText}
    >
      {itemsList}
    </TextField>
  )
};
