import React from 'react';
import {ButtonLink} from '../../../../common/buttons/ButtonLink';
import {ButtonBlue} from '../../../../common/buttons/ButtonBlue';
import SaveIcon from '@mui/icons-material/Save';
import useEmotionStyles from '../../../../../common/useEmotionStyles';
import {FormikValues, useFormikContext} from 'formik';
import {IDommage} from './Models';
import {ID_BOUTON_ENREGISTRER} from '../../../../../constantes/ids/Ids';

interface IProps {
  addDommage: () => void
  onSaveAll: () => void
}

/** Design */
const styles = () => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 'auto 0px',
    width: '100%',
    padding: '0px 24px',
  },
});

export const DommagesFooter = (props: IProps) => {
  const classes = useEmotionStyles(styles);
  const {addDommage, onSaveAll} = props;
  const {values, errors}: FormikValues = useFormikContext();

  const isAllDommagesCompleted = values.dommages.filter((dommage: IDommage) => !dommage.completed).length === 0;

  const isDisabledAdd = (values.dommages.length && errors?.dommages?.length > 0) || !isAllDommagesCompleted;

  const isDisabledSave = errors?.dommages?.length > 0 || !isAllDommagesCompleted;

  return (
    <div className={classes.buttons}>
      <ButtonLink
        id={'bouton-ajouter-dommage'}
        libelle={'Ajouter un dommage'}
        isLibelleUpperCase={true}
        isLibelleBold={true}
        onClick={addDommage}
        disabled={isDisabledAdd}
      />
      <ButtonBlue
        id={ID_BOUTON_ENREGISTRER}
        icon={<SaveIcon/>}
        libelle={'Enregistrer'}
        onClick={onSaveAll}
        disabled={isDisabledSave}
      />
    </div>
  )
};
