import {Typography} from '@mui/material';
import {customTheme} from '../../../common/GlobalTheme';
import {ReactComponent as CustomUploadIcon} from '../../../images/icones/icn-upload.svg';
import React, {DragEvent, useRef, useState} from 'react';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {Theme as MuiTheme} from '@mui/material/styles';
import {CSSInterpolation} from '@emotion/serialize';
import {STRING_TYPES_FILES_OK} from '../utils/Utils';

/** Design */
const styles = (theme: MuiTheme): Record<string, CSSInterpolation> => ({
  text: {
    marginTop: '12px',
    fontWeight: 'bold',
  },
  container: {
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '24px',
  },
  dropArea: {
    'height': '185px',
    'display': 'flex',
    'justifyContent': 'center',
    'alignItems': 'center',
    'flexFlow': 'column noWrap',
    'padding': '8px',
    'cursor': 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
      boxShadow: 'none',
      borderRadius: '2%',
    },
  },
});

export interface CommonUploadFilesProps {
  selectedFiles: any,
  buildAttachments: (files: File[]) => any,
  handleAttachmentsTemporary: (attachmentsTemporary: any) => Promise<void>
}

export const CommonUploadFiles = (props: CommonUploadFilesProps) => {
  const classes = useEmotionStyles(styles);
  const {selectedFiles, buildAttachments, handleAttachmentsTemporary} = props
  const [dragging, setDragging] = useState(false);
  const fileInput = useRef<HTMLInputElement>(null);

  const style = dragging ?
    {'backgroundColor': customTheme.palette.secondary.light, 'boxShadow': 'none', 'borderRadius': '2%'} : {};

  const handleDragEnterAndLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(!dragging)
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation()
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
    const fileList = event.dataTransfer.files;

    if (fileList.length) {
      const files = Array.from(fileList);
      const attachmentsTemporary = buildAttachments(files);
      handleAttachmentsTemporary(attachmentsTemporary)
    }
  };

  const handleChangeInputFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files?.length) {
      const files = Array.from(event?.target?.files);
      const attachmentsTemporary = buildAttachments(files);
      handleAttachmentsTemporary(attachmentsTemporary);
    }
    event.target.value = ''
  };

  return (
    <>
      <Typography className={classes.text} style={customTheme.styledTypography.donneeGrise16}>
        Faites glisser ou cliquez pour importer vos fichiers
      </Typography>
      <Typography style={customTheme.styledTypography.donneeGrise16}>
        Vous pouvez déposer jusqu&apos;à 50 fichiers avec une taille maximale par fichier de 10 Mo et
        une taille maximale pour l&apos;ensemble des fichiers de 30 Mo.
      </Typography>
      <div className={classes.container}>
        <div
          style={style}
          className={classes.dropArea}
          onDragEnter={handleDragEnterAndLeave}
          onDragLeave={handleDragEnterAndLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          aria-hidden={true}
          onClick={() => fileInput.current!.click()}
        >
          <CustomUploadIcon/>

          <input
            type="file"
            hidden
            multiple
            ref={fileInput}
            onChange={handleChangeInputFile}
            accept={STRING_TYPES_FILES_OK}
          />
        </div>
        {selectedFiles?.error &&
          <div>
            <Typography style={{color: 'red', fontSize: '12px'}}>{selectedFiles?.error?.message}</Typography>
          </div>
        }
      </div>
    </>
  )
}
