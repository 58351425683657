import React, {useContext, useState} from 'react';
import {Typography} from '@mui/material';
import {useMissionContext} from '../../../context/MissionContext';
import {UserContext} from '../../../context/UserContext';
import {TextFieldMultiline} from '../../common/formsComponents/TextFiledMultiline';
import {Attachments} from '../components/Attachment/FilesDragAndDrop';
import {postSD99Form} from '../../../api/message';
import {FormModel} from '../../../model/forms/FormModel';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {CommonForm} from '../common/CommonForm';
import {Theme as MuiTheme} from '@mui/material/styles';
import {CSSInterpolation} from '@emotion/serialize';

type StylesProps = {
  showPjForm: boolean,
  isLoading: boolean,
}

/** Design */
const styles = (theme: MuiTheme, props: StylesProps): Record<string, CSSInterpolation> => ({
  body: {
    padding: '20px',
  },
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  red: {
    color: 'red',
  },
  error: {
    fontSize: '12px',
  },
});

const SD99Form = (props: FormModel) => {
  const {mission} = useMissionContext();
  const {user} = useContext(UserContext);
  const [errorMessageField, setErrorMessageField] = useState(false);
  const [responseReturn, setResponseReturn] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showPjForm, setShowPjForm] = useState(false);
  const [commentaire, setCommentaire] = useState('');
  const classes = useEmotionStyles(styles, {showPjForm, isLoading});
  const nomCompteAssistance = sessionStorage.getItem('nomCompteAssistance');

  const initialState = {
    referenceSinistre: mission?.infoMissionDTO?.referenceSinistre,
    numMission: mission?.infoMissionDTO?.numeroMission,
    dateEffetPolice: mission?.infoMissionDTO?.dateEffetPolice,
    codeGestionnaireSinistre: mission?.infoMissionDTO?.codeGestionnaireSinistre,
    nomGestionnaireSinistre: mission?.infoMissionDTO?.nomGestionnaireSinistre,
    coordonneesCommunicationGestionnaire: mission?.infoMissionDTO?.coordonneesCommunicationGestionnaire,
    codeGTA: mission?.codeGTA,
    codeService: user.service,
    numeroAssure: mission?.societaireDTO?.numero,
    codeAbonneEmeteur: user.codeAbo,
    codeAbonneDestinataire: props.destinataire?.codeAbonne,
    commentaire: '',
    scenario: mission?.scenario,
    intermediaire: mission?.intermediaireDTO,
    numClient: user.numClient,
    userCompte: user.userCompte,
    nomCompteAssistance: nomCompteAssistance ?? '',
  };
  const initialStatePj = {
    attachments: [],
    isValid: true,
  };
  const [formData, setFormData] = useState(initialState);
  const [piecesJointesList, setPiecesJointesList] = useState<Attachments>(initialStatePj);

  const libelleLink = (!piecesJointesList || piecesJointesList.attachments.length === 0) ?
        'Ajouter des pièces jointes' :
        'Gérer les pièces jointes (' + piecesJointesList.attachments.length + ')';


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessageField(false);
    setCommentaire(event.target.value);
    setFormData({...formData, commentaire: event.target.value})
  };

  const onUpload = (attachments: Attachments) => {
    setShowPjForm(!showPjForm);
    setPiecesJointesList(attachments)
  };

  const onCloseAttachmentForms = () => {
    const result = window.confirm('Si vous n\'avez pas enregistré vos modifications, elles seront perdues.\n' + '\n' +
      'Souhaitez-vous continuer ?');
    if (result) {
      setShowPjForm(!showPjForm)
    }
  };

  const handleClick = () => {
    setErrorMessageField(false);
    if (formData.commentaire === '' && piecesJointesList.attachments.length <= 0) {
      setErrorMessageField(true);
    } else {
      setIsLoading(true);
      const payload = buildPayLoad();
      postSD99Form(payload)
          .then((response) => {
            setResponseReturn(response.status);
          })
          .catch((error) => {
            if (error.response) {
              setResponseReturn(error.response.status);
            } else {
              setResponseReturn(-1);
            }
          })
          .finally(() => {
            setIsLoading(false)
          })
    }
  };

  const buildPayLoad = () => {
    const payLoad = new FormData();
    const piecesJointes = piecesJointesList.attachments.map((attachment, index) => {
      payLoad.append(`file-${index}`, attachment.file);
      return {
        id: `file-${index}`,
        reference: attachment.libelle,
        type: attachment.type,
        nature: attachment.nature,
        version: attachment.version,
      };
    });

    payLoad.append('metadata',
        new Blob([JSON.stringify({...formData, piecesJointes: piecesJointes})],
            {type: 'application/json'}));
    return payLoad;
  };

  const formName = 'sd99';

  return (
    <CommonForm
      idForm={formName}
      idTitre={formName}
      titre={'Envoyer un message à ' + props.destinataire?.libelle}
      libelleEnvoye={'Votre message a bien été envoyé'}
      onClose={props.onClose}
      onCloseAndRefresh={props.onCloseAndRefresh}
      responseReturn={responseReturn}
      content={<React.Fragment>
        {responseReturn <= 0 && !showPjForm &&
          <div className={classes.body}>
            <Typography className={classes.libelle}>
              Votre message{!piecesJointesList.attachments.length && <span className={classes.red}> *</span>}
            </Typography>
            <TextFieldMultiline id={'message'} style={{marginTop: '15px'}} marginDense={true} rows={14}
              onChange={handleChange} onError={errorMessageField} value={commentaire}/>
            {errorMessageField ?
              <Typography className={`${classes.red} ${classes.error}`}>
                Veuillez saisir un message
              </Typography> : ''
            }
            {responseReturn === -1 &&
              <Typography className={`${classes.red} ${classes.error}`}>
                Échec de l&apos;envoi du message. Le contenu de votre formulaire n&apos;est pas valide.
              </Typography>
            }
          </div>
        }
      </React.Fragment>}
      showPjForm={showPjForm}
      commonAttachmentsFormProps={{
        piecesJointesList: piecesJointesList,
        onUpload: onUpload,
        onCloseAttachmentForms: onCloseAttachmentForms,
      }}
      withoutPj={false}
      showFooter={showPjForm}
      isLoading={isLoading}
      libelleLink={libelleLink}
      errorMessageField={errorMessageField}
      setShowPjForm={() => setShowPjForm(!showPjForm)}
      handleClick={handleClick}
    />
  );
};

export default SD99Form;
