import React, {useContext, useState, useEffect} from 'react'
import {MenuItem, Typography} from '@mui/material'
import {useMissionContext} from '../../../context/MissionContext'
import {postSD20Form} from '../../../api/message'
import {getValeursCodes} from '../../../api/norme'
import {UserContext} from '../../../context/UserContext'
import {TextFieldSelect} from '../../common/formsComponents/TextFieldSelect';
import {TextFieldMultiline} from '../../common/formsComponents/TextFiledMultiline';
import {ItemGetValeursCodesModel} from '../../../model/common/ItemGetValeursCodesModel';
import {FormModel} from '../../../model/forms/FormModel';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {CommonForm} from '../common/CommonForm';
import {Theme as MuiTheme} from '@mui/material/styles';
import {CSSInterpolation} from '@emotion/serialize';

/** Design */
const styles = (theme: MuiTheme): Record<string, CSSInterpolation> => ({
  body: {
    padding: '20px',
  },
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  red: {
    color: 'red',
  },
  error: {
    fontSize: '12px',
  },
});

const SD20Form = (props: FormModel) => {
  const classes = useEmotionStyles(styles);
  const {mission} = useMissionContext();
  const {user} = useContext(UserContext);
  const nomCompteAssistance = sessionStorage.getItem('nomCompteAssistance');

  const initialState = {
    referenceSinistre: mission?.infoMissionDTO?.referenceSinistre,
    numMission: mission?.infoMissionDTO?.numeroMission,
    dateEffetPolice: mission?.infoMissionDTO?.dateEffetPolice,
    codeGestionnaireSinistre: mission?.infoMissionDTO?.codeGestionnaireSinistre,
    nomGestionnaireSinistre: mission?.infoMissionDTO?.nomGestionnaireSinistre,
    coordonneesCommunicationGestionnaire: mission?.infoMissionDTO?.coordonneesCommunicationGestionnaire,
    codeGTA: mission?.codeGTA,
    codeService: user.service,
    numeroAssure: mission?.societaireDTO?.numero,
    codeAbonneEmeteur: user.codeAbo,
    codeAbonneDestinataire: props.destinataire?.codeAbonne,
    codeMessage: '',
    commentaire: '',
    scenario: mission?.scenario,
    intermediaire: mission?.intermediaireDTO,
    numClient: user.numClient,
    userCompte: user.userCompte,
    nomCompteAssistance: nomCompteAssistance ?? '',
  };

  const [formData, setFormData] = useState(initialState);
  const [codesValeursMotifRelance, setCodesValeursMotifRelance] = useState([]);
  const [errorFields, setErrorFields] = useState({hasErrorCodeMessage: false});
  const [responseReturn, setResponseReturn] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getValeursCodes('AR2', '91030101').then((res) => setCodesValeursMotifRelance(res))
  }, []);

  const codesValeursMotifRelanceSort = [...codesValeursMotifRelance].sort((a: ItemGetValeursCodesModel,
      b: ItemGetValeursCodesModel) => {
    if (a.libelleedition > b.libelleedition) {
      return 1
    } else {
      return -1
    }
  });
  const itemsListMotifsRelance = codesValeursMotifRelanceSort.map((item: ItemGetValeursCodesModel) => {
    return (
      <MenuItem key={item.valeur} value={item.valeur}>{item.libelleedition}</MenuItem>
    )
  });

  const handleClick = () => {
    setErrorFields({hasErrorCodeMessage: !formData.codeMessage});
    if (formData.codeMessage) {
      setIsLoading(true);
      postSD20Form(formData)
          .then((response) => {
            setResponseReturn(response.status);
          })
          .catch((error) => {
            if (error.response) {
              setResponseReturn(error.response.status);
            } else {
              setResponseReturn(-1);
            }
          })
          .finally(() => {
            setIsLoading(false)
          })
    }
  };

  const handleChangeComment = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({...formData, commentaire: event.target.value})
  };

  const handleChangeMotif = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorFields({hasErrorCodeMessage: false});
    setFormData({...formData, codeMessage: event.target.value})
  };

  const formName = 'sd20';

  return (
    <CommonForm
      idForm={formName}
      idTitre={formName}
      titre={`Relance de l'expert`}
      libelleEnvoye={'Votre relance a bien été envoyée'}
      onClose={props.onClose}
      onCloseAndRefresh={props.onCloseAndRefresh}
      responseReturn={responseReturn}
      content={responseReturn <= 0 ?
        <div className={classes.body}>
          <Typography className={classes.libelle}>
            Motif de la relance <span className={classes.red}>*</span>
          </Typography>
          <TextFieldSelect
            id={'motifRelance'}
            name={'motifRelance'}
            marginDense={true}
            fullWidth={true}
            value={formData.codeMessage}
            itemsList={itemsListMotifsRelance}
            onChange={(event) => handleChangeMotif(event)}
            onError={errorFields.hasErrorCodeMessage}
          />
          {errorFields.hasErrorCodeMessage ?
            <Typography className={`${classes.red} ${classes.error}`}>
              Veuillez sélectionner le motif de la relance
            </Typography> : ''
          }
          <div style={{marginTop: '20px'}}>
            <Typography className={classes.libelle}>Votre message</Typography>
            <TextFieldMultiline id={'message'} marginDense={true} rows={14} onChange={handleChangeComment}/>
          </div>
          {responseReturn === -1 &&
            <Typography className={`${classes.red} ${classes.error}`}>
              Échec de l&apos;envoi du message. Le contenu de votre formulaire n&apos;est pas valide.
            </Typography>
          }
        </div> : <></>
      }
      withoutPj={true}
      isLoading={isLoading}
      errorMessageField={errorFields.hasErrorCodeMessage}
      handleClick={handleClick}
    />
  )
};

export default SD20Form;
