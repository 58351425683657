import {Card, Typography} from '@mui/material'
import React from 'react'
import {CardModel} from '../../../model/rechercheDossier/CardModel'
import {StyledTypography} from '../../common/StyledTypography'
import {useNavigate, useLocation} from 'react-router-dom';
import {ReactComponent as VehiculeIcon} from '../../../images/icones/icn-vehicule.svg'
import {TokenModel} from '../../../model/common/TokenModel'
import {UserContext} from '../../../context/UserContext';
import ArchiveIcon from '@mui/icons-material/Archive';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';

const base64 = require('base-64')

export interface BlocCardProps {
  card: CardModel,
}

/** Design */
type StylesProps = {indicateurArchivage: string}
const styles = (theme: Theme, props: StylesProps) => ({
  card: {
    width: '313px',
    height: '100%',
    cursor: 'pointer',
    ['@media (max-width:2100px)']: {width: '13.5vw'},
    ['@media (max-width:2000px)']: {width: '13.4vw'},
    ['@media (max-width:1800px)']: {width: '19.5vw'},
    ['@media (max-width:1600px)']: {width: '19.3vw'},
    ['@media (max-width:1500px)']: {width: '19vw'},
    ['@media (max-width:1300px)']: {width: '20.2vw'},
    ['@media (max-width:1100px)']: {width: '27.4vw'},
    ['@media (max-width:900px)']: {width: '40.6vw'},
    ['@media (max-width:800px)']: {width: '40.2vw'},
    ['@media (max-width:700px)']: {width: '39.3vw'},
    ['@media (max-width:600px)']: {width: '50vw'},
    ['@media (max-width:500px)']: {width: '86.4vw'},
  },
  header: {
    padding: '10px',
    textAlign: 'right' as const,
    boxShadow: '0px 3px 6px ' + theme.palette.boxShadow.main,
    backgroundColor: props.indicateurArchivage === 'O' ? theme.palette.secondary.dark2 : theme.palette.primary.main,
  },
  dossier: {
    fontSize: '14px',
    color: theme.palette.white.main,
  },
  divIcon: {
    position: 'relative' as const,
    top: '-20px',
    left: '15px',
  },
  icon: {
    width: '25px',
    height: '25px',
    padding: '5px',
    borderRadius: '50px',
    border: '2px solid ' + theme.palette.white.main,
  },
  archiveIcon: {
    backgroundColor: theme.palette.secondary.dark2,
    color: theme.palette.white.main,
  },
  vehiculeIcon: {
    backgroundColor: theme.palette.primary.main,
  },
  infos: {
    padding: '0px 24px 24px',
    display: 'flex',
    flexDirection: 'column' as const,
    height: '75%',
  },
  sinistre: {
    fontSize: '16px',
    fontWeight: 'bold',
    letterSpacing: '0px',
  },
  date: {
    fontSize: '14px',
    letterSpacing: '0px',
    color: theme.palette.secondary.dark2,
  },
  bottom: {
    marginTop: 'auto',
  },
  barre: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  assureur: {
    fontSize: '16px',
    letterSpacing: '0px',
    color: theme.palette.secondary.dark2,
  },
})

export const BlocCard = ({card}: BlocCardProps) => {
  const classes = useEmotionStyles(styles, {indicateurArchivage: card.indicateurArchivage})
  const navigate = useNavigate()
  const location = useLocation()
  const {user} = React.useContext(UserContext)

  const handleLink = () => {
    sessionStorage.setItem('fromPath', location.pathname)
    const tokenMission: TokenModel = {
      referenceSinistre: card.refSinistre,
      numeroMission: card.numMission,
      codeGTA: card.codeGTA,
      numClient: user.numClient,
      userCompte: user.userCompte,
      codeAbonne: user.codeAbo,
      codeService: user.service,
      immatriculation: card.immatriculation,
      isDossierComplet: false,
    }
    const token = base64.encode(JSON.stringify(tokenMission))
    navigate(`/extranet/dossiers/mission/`+ token)
  }

  return (
    <Card id={'carte-'.concat(card.id.toString())} className={classes.card} tabIndex={0} onClick={handleLink}>
      <div className={classes.header}>
        <Typography id={'titre-carte'} className={classes.dossier}>
          DOSSIER {card.indicateurArchivage === 'O' ? ' ARCHIVÉ' : ' SINISTRE'}
        </Typography>
      </div>

      <div className={classes.divIcon}>
        {card.indicateurArchivage === 'O' ?
          <ArchiveIcon className={`${classes.icon} ${classes.archiveIcon}`}/> :
          <VehiculeIcon className={`${classes.icon} ${classes.vehiculeIcon}`}/>
        }
      </div>

      <div className={classes.infos}>
        <div>
          <Typography id={'reference-sinistre-' + card.id} className={classes.sinistre}>
            {card.refSinistre}
          </Typography>
          <Typography id={'date-creation-' + card.id} className={classes.date}>
            Créé le {card.dateCreationMission}
          </Typography>
          <StyledTypography id={'numero-mission-' + card.id} title={'Numéro de mission'} text={card.numMission}/>
          <StyledTypography id={'immatriculation-' + card.id} title={'Immatriculation'} text={card.immatriculation}/>
          <StyledTypography id={'bien-concerne-' + card.id} title={'Bien concerné'} text={card.bienConcerne}/>
        </div>

        <div className={classes.bottom}>
          <hr className={classes.barre}/>
          <Typography id={'assureur-' + card.id} className={classes.assureur}>{card.assureurConcerne}</Typography>
        </div>
      </div>
    </Card>
  )
}
