import React from 'react';
import {Card, CardContent, Typography} from '@mui/material';
import {StyledTypography} from '../../../common/StyledTypography';
import {BlocAdresse} from '../../../common/adresse/BlocAdresse';
import {useMissionContext} from '../../../../context/MissionContext';
import moment from 'moment';

export interface ContentInfosDossierProps {
  showMission?: boolean,
}

export const ContentInfosDossier = ({showMission}: ContentInfosDossierProps) => {
  const {mission} = useMissionContext()
  const informationsMission = mission?.infoMissionDTO
  return (
    <>
      <StyledTypography id={'numSinistre'} title={'N° de sinistre'} text={informationsMission?.referenceSinistre} />
      {showMission &&
        <StyledTypography id={'numMission'} title={'N° de la mission'} text={informationsMission?.numeroMission} />}
      <StyledTypography id={'immat'} title={'Immatriculation'} text={informationsMission?.immatriculation} />
      <StyledTypography title={'Type de la mission'} text={informationsMission?.typeMission} />
      <StyledTypography title={'Nature du sinistre'} text={informationsMission?.natureSinistre} />
      {moment(informationsMission?.dateMissionnement).isValid() ?
        <StyledTypography title={'Date du missionnement'}
          text={moment(informationsMission?.dateMissionnement).format('DD/MM/YYYY')}/> :
        <StyledTypography title={'Date du missionnement'} text={''}/>
      }
      <BlocAdresse title={'Lieu d\'expertise'} adresse={informationsMission?.lieuExpertiseAdresse}
        commentaire={informationsMission?.lieuExpertiseCommentaire} />
    </>
  )
}

export const InfosDossier = ({showMission}: ContentInfosDossierProps) => {
  return (
    <Card>
      <CardContent>
        <Typography variant={'h3'}>Informations du dossier</Typography>
        <ContentInfosDossier showMission={showMission}/>
      </CardContent>
    </Card>
  )
}
