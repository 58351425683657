import * as yup from 'yup';
import {
  checkIdentificationPM,
  checkNumMission, checkRaisonSoc,
} from '../../../../common/utils/InputValuesUtils';
import moment from 'moment/moment';
import {validationSchemaImmatriculation} from '../validationSchema/validationSchemaImmatriculation';
import {validationSchemaRefSinistre} from '../validationSchema/validationSchemaRefSinistre';
import {validationSchemaNomUsagePrenom} from '../validationSchema/validationSchemaNomUsagePrenom';
import {validationSchemaAdresse} from '../validationSchema/validationSchemaAdresse';
import {validationSchemaDateCertificat} from '../validationSchema/validationSchemaDateCertificat';
import {validationSchemaNumeroFormule} from '../validationSchema/validationSchemaNumeroFormule';

export const validationSchemaDC = yup.object({
  numeroMission: yup.string()
      .test('test-numeroMission', 'Le champ contient des caractères interdits',
          function(value) {
            return checkNumMission(value ?? '')
          }),
  titulaireActuel: yup.object({
    identificationPM: yup.string()
        .required('Veuillez saisir une valeur')
        .test('test-identificationPM', 'Les caractères ! ? * ° : € # ; ne sont pas autorisés',
            function(value) {
              return checkIdentificationPM(value || '')
            }),
    siren: yup.string()
        .min(9, 'Veuillez saisir une valeur au format attendu, voir'),
    siret: yup.string()
        .required('Veuillez saisir une valeur')
        .min(14, 'Veuillez saisir une valeur au format attendu, voir'),
  }),
  titulaireNouveau: yup.object({
    sexe: yup.string()
        .when('typePersonne', {
          is: (value: string) => value === 'pp',
          then: () => yup.string()
              .required('Veuillez saisir une valeur'),
        }),
    societeCommerciale: yup.boolean(),
    raisonSociale: yup.string()
        .when('typePersonne', {
          is: (value: string) => value === 'pm',
          then: () => yup.string()
              .required('Veuillez saisir une valeur')
              .test('test-raisonSociale', 'Les caractères ! ? * ° : € # ; ne sont pas autorisés',
                  function(value) {
                    return checkRaisonSoc(value || '')
                  }),
        }),
    siren: yup.string()
        .when('typePersonne', {
          is: (value: string) => value === 'pm',
          then: () => yup.string()
              .min(9, 'Veuillez saisir une valeur au format attendu, voir'),
        }),
    siret: yup.string()
        .when('typePersonne', {
          is: (value: string) => value === 'pm',
          then: () => yup.string()
              .min(14, 'Veuillez saisir une valeur au format attendu, voir')
              .test('test-siret', 'Veuillez saisir une valeur',
                  function(value, ctx) {
                    if (ctx.parent.societeCommerciale && ctx.parent.adresse.pays === 'FRANCE') {
                      return value !== undefined && value !== ''
                    }
                    return true
                  }),
        }),
  }).concat(validationSchemaNomUsagePrenom).concat(validationSchemaAdresse),
  numeroVin: yup.string()
      .required('Veuillez saisir une valeur'),
  presenceCertificat: yup.boolean(),
  dateCession: yup.date()
      .required('Veuillez saisir une valeur')
      .min('1900-01-01', 'Veuillez saisir une date postérieure au 01/01/1900')
      .max(moment(), 'Veuillez saisir une date antérieure à celle du jour'),
  heureCession: yup.string()
      .required('Veuillez saisir une valeur')
      .test('test-heureCession', 'L\'heure doit être antérieure à l\'heure actuelle pour une date ' +
        'égale à celle du jour',
      function(value, ctx) {
        const date = ctx.parent.dateCession;
        if (date && moment(date, 'YYYY-MM-DD').isSame(moment().format('YYYY-MM-DD'))) {
          return value <= moment().format('HH:mm')
        }
        return true
      }),
  dateDeclaration: yup.date()
      .required('Veuillez saisir une valeur')
      .min('1900-01-01', 'Veuillez saisir une date postérieure au 01/01/1900')
      .max(moment(), 'Veuillez saisir une date antérieure à celle du jour')
      .test('test-dateDeclaration', 'La date de la déclaration de cession ne peut être antérieure à la date de cession',
          function(value, ctx) {
            if (ctx.parent.dateCession) {
              return ctx.parent.dateCession <= value
            }
            return true
          }),
  heureDeclaration: yup.string()
      .required('Veuillez saisir une valeur')
      .test('test1-heureDeclaration', 'L\'heure doit être antérieure à l\'heure actuelle pour une date ' +
      'égale à celle du jour',
      function(value, ctx) {
        const date = ctx.parent.dateDeclaration;
        if (date && moment(date, 'YYYY-MM-DD').isSame(moment().format('YYYY-MM-DD'))) {
          return value <= moment().format('HH:mm')
        }
        return true
      })
      .test('test2-heureDeclaration', 'L\'heure de la déclaration de cession doit être postérieure à ' +
        'l\'heure de cession pour une même date',
      function(value, ctx) {
        if (ctx.parent.heureCession &&
          moment(ctx.parent.dateCession, 'YYYY-MM-DD').isSame(moment(ctx.parent.dateDeclaration, 'YYYY-MM-DD'))) {
          return value >= ctx.parent.heureCession
        }
        return true
      }),
}).concat(validationSchemaImmatriculation).concat(validationSchemaRefSinistre(true))
    .concat(validationSchemaDateCertificat('DC')).concat(validationSchemaNumeroFormule);
