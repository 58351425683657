import React from 'react';
import {Card, CardContent, Typography} from '@mui/material';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {useGridColumns} from './gridColumns';
import {DataGrid, GridRowsProp} from '@mui/x-data-grid';
import {EchangeAnomalies} from '../../../../model/rechercheEchange/EchangeAnomalies';
import {Theme} from '@emotion/react';

interface IProps {
  anomaliesList: EchangeAnomalies
}

/** Design */
const styles = (theme: Theme) => ({
  marginTop: {
    marginTop: '16px',
  },
  iconSeparator: {
    fill: theme.palette.secondary.dark2,
  },
});

export const Anomalies = (props: IProps) => {
  const classes = useEmotionStyles(styles);
  const {anomaliesList} = props;

  // Récupération des colonnes de la grille
  const gridColumns = useGridColumns();

  return (
    <Card className={classes.marginTop}>
      <CardContent>
        <Typography variant={'h3'}>Anomalies</Typography>

        {!anomaliesList.anomaliesBloquantesList.length && !anomaliesList.anomaliesNonBloquantesList.length &&
          <Typography>Aucune anomalie pour cet échange</Typography>
        }

        {anomaliesList.anomaliesBloquantesList.length > 0 &&
          <>
            <Typography variant={'h3'}>Détails des anomalies bloquantes</Typography>
            <DataGrid
              classes={{
                iconSeparator: classes.iconSeparator,
              }}
              rows={anomaliesList.anomaliesBloquantesList as GridRowsProp<any>}
              columns={gridColumns}
              columnVisibilityModel={{id: false}}
              rowHeight={80}
              columnHeaderHeight={80}
              disableColumnMenu
              disableRowSelectionOnClick
              hideFooter={true}
              density={'compact'}
              hideFooterPagination
            />
          </>
        }

        {anomaliesList.anomaliesNonBloquantesList.length > 0 &&
          <div className={classes.marginTop}>
            <Typography variant={'h3'}>Détails des anomalies non bloquantes</Typography>
            <DataGrid
              classes={{
                iconSeparator: classes.iconSeparator,
              }}
              rows={anomaliesList.anomaliesNonBloquantesList as GridRowsProp<any>}
              columns={gridColumns}
              columnVisibilityModel={{id: false}}
              rowHeight={80}
              columnHeaderHeight={80}
              disableColumnMenu
              disableRowSelectionOnClick
              hideFooter={true}
              density={'compact'}
              hideFooterPagination
            />
          </div>
        }
      </CardContent>
    </Card>
  )
};
