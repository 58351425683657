import React from 'react';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../../common/useEmotionStyles';
import {Grid, Typography} from '@mui/material';
import InputTextField from '../../../multiStepsForm/fields/InputTextField';
import SwitchField from '../../../multiStepsForm/fields/SwitchField';
import {FormikValues, useFormikContext} from 'formik';
import LocaliteAutocompleteField from '../../../multiStepsForm/fields/LocaliteAutocompleteField';
import InputPhoneField from '../../../multiStepsForm/fields/InputPhoneField';

const styles = (theme: Theme) => ({
  cardContent: {
    backgroundColor: theme.palette.secondary.light,
    padding: '20px',
    borderRadius: '8px',
  },
  switch: {
    display: 'flex',
    alignContent: 'center',
  },
});

const IntermediaireBloc = () => {
  const classes = useEmotionStyles(styles);
  const {values}: FormikValues = useFormikContext();
  const isIntermediaire = values.isIntermediaire;

  return (
    <div className={classes.cardContent}>
      <div className={classes.switch}>
        <SwitchField
          name='isIntermediaire'
          checked={isIntermediaire}
        />
        <Typography variant={'h3'} style={{marginTop: '8px'}}>
          Intermédiaire
        </Typography>
      </div>
      {isIntermediaire &&
        <Grid container rowSpacing={1}>
          <Grid item>
            <InputTextField
              name='intermediaire.nomIntermediaire'
              label='Nom'
              mandatory={true}
              maxLength={32}
            />
          </Grid>
          <Grid item>
            <InputPhoneField
              name='intermediaire.telephonePerso'
              label='Téléphone personnel'
            />
          </Grid>
          <Grid item>
            <InputPhoneField
              name='intermediaire.telephonePro'
              label='Téléphone professionnel'
            />
          </Grid>
          <Grid item>
            <InputPhoneField
              name='intermediaire.telephonePortable'
              label='Téléphone portable'
              isPortable={true}
            />
          </Grid>
          <Grid item>
            <InputTextField
              name='intermediaire.adresse.adresse1'
              label='Adresse'
              maxLength={32}
            />
          </Grid>
          <Grid item>
            <InputTextField
              name='intermediaire.adresse.adresse2'
              label='Adresse complémentaire 1'
              maxLength={32}
            />
          </Grid>
          <Grid item>
            <InputTextField
              name='intermediaire.adresse.adresse3'
              label='Adresse complémentaire 2'
              maxLength={32}
            />
          </Grid>
          <Grid item>
            <LocaliteAutocompleteField
              id='intermediaire-adresse-localite'
              label='Localité'
              name='intermediaire.adresse.localite'
              isMandatory={true}
            />
          </Grid>
        </Grid>
      }
    </div>
  );
};

export default IntermediaireBloc;
