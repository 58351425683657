import useEmotionStyles from '../../../../../../common/useEmotionStyles';
import {ButtonBlue} from '../../../../../common/buttons/ButtonBlue';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SaveIcon from '@mui/icons-material/Save';
import React from 'react';
import {FormikValues, useFormikContext} from 'formik';
import {IElementCalcul} from '../../../model/ModelOM';
import {ID_BOUTON_AJOUTER, ID_BOUTON_ENREGISTRER} from '../../../../../../constantes/ids/Ids';

interface ModalFooterProps {
  addElementCalcul: () => void
  onSave: () => void
}

const styles = () => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 'auto 0px', // pour centrer verticalement
    width: '100%',
    padding: '0px 24px',
  },
});

export const ModalFooter = (props: ModalFooterProps) => {
  const classes = useEmotionStyles(styles);
  const {addElementCalcul, onSave} = props;
  const {values, errors}: FormikValues = useFormikContext();

  const isDisabled = errors?.elementsCalcul?.length > 0 ||
    values.elementsCalcul.filter((element: IElementCalcul) => !element.isValid).length > 0;

  return (
    <div className={classes.buttons}>
      <ButtonBlue
        id={ID_BOUTON_AJOUTER}
        icon={<AddCircleIcon/>}
        libelle={'Ajouter un élément de calcul'}
        onClick={addElementCalcul}
        disabled={isDisabled}
      />
      <ButtonBlue
        id={ID_BOUTON_ENREGISTRER}
        icon={<SaveIcon/>}
        libelle={'Enregistrer'}
        onClick={onSave}
        disabled={isDisabled}
      />
    </div>
  )
};
