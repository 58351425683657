import React from 'react'
import {Typography} from '@mui/material'
import {Adresse} from '../../../model/common/Adresse'
import {customTheme} from '../../../common/GlobalTheme';
import useEmotionStyles from '../../../common/useEmotionStyles';

/** Design */
const styles = () => ({
  ligneAdresse: {
    ['@media print']: {
      fontSize: '12px !important',
    },
  },
  bloc: {
    marginTop: '12px',
  },
  emptyBloc: {
    marginBottom: '24px',
  },
});

export interface BlocAdresseProps {
  title?: string,
  adresse?: Adresse,
  commentaire?: string,
  isSmallTypo?: boolean,
}
export const BlocAdresse = ({title, adresse, commentaire, isSmallTypo}: BlocAdresseProps) => {
  const classes = useEmotionStyles(styles);
  const numeroVoie = adresse?.numeroVoie;
  const indiceRepetition = adresse?.indiceRepetition;
  const typeVoie = adresse?.typeVoie;
  const adresse1 = adresse?.adresse1 ?? adresse?.ligne1;
  const adresse2 = adresse?.adresse2 ?? adresse?.ligne2;
  const adresse3 = adresse?.adresse3 ?? adresse?.ligne3;
  const localite = adresse?.localite ?? adresse?.commune;
  const codePostal = adresse?.codePostal;
  const pays = adresse?.pays;
  const isAdressePresent = numeroVoie ?? indiceRepetition ?? typeVoie ?? adresse1 ?? adresse2 ?? adresse3 ?? localite ??
    codePostal ?? pays;
  const nom = adresse?.nomAdresse;

  const styleTypo = isSmallTypo ?
    customTheme.styledTypography.donneeGrise14 :
    customTheme.styledTypography.donneeGrise16;

  const isEmptyBloc = !nom && !commentaire && !isAdressePresent

  return (
    <div className={`${classes.bloc} ${isEmptyBloc && classes.emptyBloc}`}>
      <Typography style={customTheme.styledTypography.libelle}>{title}</Typography>
      {commentaire &&
        <Typography style={styleTypo} className={classes.ligneAdresse}>
          {commentaire}
        </Typography>
      }
      {nom &&
        <Typography style={styleTypo} className={classes.ligneAdresse}>
          {nom}
        </Typography>
      }
      {isAdressePresent &&
        <>
          {(numeroVoie || indiceRepetition || typeVoie || adresse1) &&
            <Typography style={styleTypo} className={classes.ligneAdresse}>
              {numeroVoie} {indiceRepetition} {typeVoie} {adresse1}
            </Typography>
          }
          {adresse2 &&
            <Typography style={styleTypo} className={classes.ligneAdresse}>
              {adresse2}
            </Typography>
          }
          {adresse3 &&
            <Typography style={styleTypo} className={classes.ligneAdresse}>
              {adresse3}
            </Typography>
          }
          {(localite || codePostal) &&
            <Typography style={styleTypo} className={classes.ligneAdresse}>
              {codePostal} {localite}
            </Typography>
          }
          {pays && pays.trim().toUpperCase() != 'FRANCE' &&
            <Typography style={styleTypo} className={classes.ligneAdresse}>
              {pays}
            </Typography>
          }
        </>
      }
    </div>
  )
};
