import React, {useEffect, useState} from 'react';
import {Tooltip} from '@mui/material';
import {getPieceJointe} from '../../../../api/dossier';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {UserContext} from '../../../../context/UserContext';

/** Design */
const styles = () => ({
  img: {
    'width': '60px',
    'height': '60px',
    'objectFit': 'cover' as const,
    '&:hover': {
      opacity: '50%',
    },
    ['@media (max-width:1350px)']: {
      width: '50px',
      height: '50px',
    },
  },
});

export interface PictureProps {
  imgId: string
  saveImgSrc?: (imgId: string, url: string) => void
}

const Picture = (props: PictureProps) => {
  const classes = useEmotionStyles(styles);
  const {user} = React.useContext(UserContext);
  const {imgId, saveImgSrc} = props;
  const [isOnError, setIsOnError] = useState<boolean>(false);
  const [response, setResponse] = useState<Blob>();
  const [imgSrc, setImgSrc] = useState('');

  useEffect(() => {
    getPieceJointe(imgId, user.numClient).then((response) => {
      setResponse(response.data);
    }).catch(() => {
      setIsOnError(true);
    })
  }, []);

  useEffect(() => {
    if (response) {
      const base64 = URL.createObjectURL(response);
      setImgSrc(base64);
      if (saveImgSrc) saveImgSrc(imgId, base64);
    }
  }, [response]);

  const onError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    if (event.type === 'error') {
      setIsOnError(true)
    } else {
      setIsOnError(false)
    }
  };

  return (
    <>
      {imgSrc && !isOnError &&
        <img
          className={classes.img}
          src={imgSrc}
          alt=""
          onError={onError}
          aria-hidden={true}
        />
      }
      {isOnError &&
        <Tooltip disableInteractive title='Photo indisponible'>
          <img
            className={classes.img}
            src={imgSrc}
            alt=""
            onError={onError}
            aria-hidden={true}
          />
        </Tooltip>
      }
    </>
  );
};

export default Picture;
