import React, {ReactElement} from 'react';
import {MenuItem} from '@mui/material';
import {Link, To} from 'react-router-dom';
import useEmotionStyles from '../../common/useEmotionStyles';
import {Theme} from '@emotion/react';

interface IProps {
  id: string
  to: To
  onClick: (event: React.MouseEvent<HTMLElement>) => void
  children: ReactElement
}

/** Design */
const styles = (theme: Theme) => ({
  menuItemRoot: {
    padding: 0,
  },
  menuLinks: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    width: '100%',
  },
  linkContent: {
    padding: '10px 16px',
    display: 'flex',
    alignItems: 'center',
  },
});

export const ItemMenu = (props: IProps) => {
  const {id, to, onClick, children} = props;
  const classes = useEmotionStyles(styles);

  return (
    <MenuItem classes={{root: classes.menuItemRoot}}>
      <Link id={id} className={classes.menuLinks} to={to} onClick={onClick}>
        <div className={classes.linkContent}>
          {children}
        </div>
      </Link>
    </MenuItem>
  )
};
