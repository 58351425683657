import React, {useEffect, useState} from 'react';
import InputSelectField from '../../multiStepsForm/fields/InputSelectField';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';
import {FormikValues, useFormikContext} from 'formik';
import InfoIcon from '@mui/icons-material/Info';
import {Tooltip} from '@mui/material';
import InputTextField from '../../multiStepsForm/fields/InputTextField';
import {IElementRecherchable} from '../../../../model/common/ElementRecherchable';
import {useGetItemsList} from '../../../../common/utils/useGetItemsList';
import {getActionsEchangeRecherchables, getEtatsEchangeRecherchables} from '../../../../api/norme';

const styles = (theme: Theme) => ({
  flex: {
    display: 'flex',
  },
  marginLeft48: {
    marginLeft: '48px',
  },
  marginTop24: {
    marginTop: '24px',
  },
  field: {
    'width': '480px',
    'marginRight': '24px',
    '& .Mui-disabled': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  loader: {
    marginTop: '0px',
  },
  tooltip: {
    color: theme.palette.secondary.dark2,
  },
});

export const SearchFields = () => {
  const classes = useEmotionStyles(styles);
  const {values}: FormikValues = useFormikContext();

  const [etatsEchangeRecherchables, setEtatsEchangeRecherchables] = useState<IElementRecherchable[]>([]);
  const [actionsEchangeRecherchables, setActionsEchangeRecherchables] = useState<IElementRecherchable[]>([]);

  // Chargement de la liste des états d'échanges
  useEffect(() => {
    getEtatsEchangeRecherchables()
        .then((response) => {
          setEtatsEchangeRecherchables(response)
        })
        .catch(() => {
          return false
        })

    getActionsEchangeRecherchables()
        .then((response) => {
          setActionsEchangeRecherchables(response)
        })
        .catch(() => {
          return false
        })
  }, []);

  const etatsList = useGetItemsList(etatsEchangeRecherchables);
  const actionsList = useGetItemsList(actionsEchangeRecherchables);

  const onInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    // permet de remplacer tout ce qui n'est pas une lettre, un chiffre ou le caractère - par vide ('')
    e.target.value = e.target.value.replace(/[^A-Za-z0-9\-\s]+/, '');
  }

  return (
    <div className={`${classes.flex} ${classes.marginTop24}`}>
      <InputSelectField
        className={classes.field}
        name='etat'
        label='État'
        itemsList={etatsList}
        tooltip={
          <Tooltip
            className={classes.tooltip}
            disableInteractive
            placement="right"
            title={'Ce champ n\'est combinable qu\'avec un champ de type période'}
          >
            <InfoIcon />
          </Tooltip>
        }
        disabled={values.numeroEchange}
      />

      <InputTextField
        className={classes.field}
        name='numeroEchange'
        label={'N° d\'échange'}
        maxLength={12}
        onInput={onInput}
        disabled={values.dateDebut || values.dateFin}
      />

      <InputSelectField
        className={classes.field}
        name='action'
        label='Action'
        itemsList={actionsList}
        disabled={values.numeroEchange}
      />
    </div>
  )
};
