import React from 'react';
import {ItemHistoriqueModel} from '../../../../model/detailsDossier/ItemHistoriqueModel';
import {PanelProps} from '../Details';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {Box, Typography} from '@mui/material';
import {ContentSimplifiedHistorique} from './ContentSimplifiedHistorique';
import {ContentDetailedHistorique} from './ContentDetailedHistorique';

const styles = () => ({
  tabPanel: {
    padding: '0px',
    marginTop: '15px',
  },
});

export interface ContentCommonHistoriqueProps {
  value: number,
  onClickPj: (tabPanelValue: number, idPJ: string) => void,
  onClickComment?: ((tabPanelValue: number, date: string) => void),
  messagesToDisplay: ItemHistoriqueModel[],
  messagesSent: ItemHistoriqueModel[] | undefined,
  messagesReceived: ItemHistoriqueModel[] | undefined,
  typeHistorique: string
}

const NoMessage = (props: {messagesList: ItemHistoriqueModel[] | undefined}) => {
  const classes = useEmotionStyles(styles);
  const {messagesList} = props;
  return messagesList?.length === 0 ?
    <div className={classes.noMessage}><Typography>Aucun résultat</Typography></div> : <></>
};

const TabPanel= (props: PanelProps) => {
  const classes = useEmotionStyles(styles)
  const {value, index, children} = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
    >
      {value === index && (
        <Box p={3} className={classes.tabPanel}>
          {children}
        </Box>
      )}
    </div>
  )
};

export const ContentCommonHistorique = (props: ContentCommonHistoriqueProps) => {
  const {value, onClickPj, onClickComment, messagesToDisplay, messagesSent, messagesReceived, typeHistorique} = props

  return (<React.Fragment>
    <TabPanel value={value} index={0}>
      <React.Fragment>
        <NoMessage messagesList={messagesToDisplay}/>
        {typeHistorique === 'simplified' &&
          <ContentSimplifiedHistorique messagesList={messagesToDisplay} onClickComment={onClickComment}
            onClickPj={onClickPj}/>}
        {typeHistorique === 'detailed' &&
          <ContentDetailedHistorique messagesList={messagesToDisplay} onClickPj={onClickPj}/>}
      </React.Fragment>
    </TabPanel>
    <TabPanel value={value} index={1}>
      <React.Fragment>
        <NoMessage messagesList={messagesSent} />
        {typeHistorique === 'simplified' &&
          <ContentSimplifiedHistorique messagesList={messagesSent} onClickComment={onClickComment}
            onClickPj={onClickPj}/>}
        {typeHistorique === 'detailed' &&
          <ContentDetailedHistorique messagesList={messagesSent} onClickPj={onClickPj}/>}
      </React.Fragment>
    </TabPanel>
    <TabPanel value={value} index={2}>
      <React.Fragment>
        <NoMessage messagesList={messagesReceived}/>
        {typeHistorique === 'simplified' &&
          <ContentSimplifiedHistorique messagesList={messagesReceived} onClickComment={onClickComment}
            onClickPj={onClickPj}/>}
        {typeHistorique === 'detailed' &&
          <ContentDetailedHistorique messagesList={messagesReceived} onClickPj={onClickPj}/>}
      </React.Fragment>
    </TabPanel>
  </React.Fragment>)
}
