
import React from 'react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {PersonnePhysiqueMorale} from '../../../common/personne/PersonnePhysiqueMorale';
import {Nom} from '../../../common/personne/Nom';
import {Prenom} from '../../../common/personne/Prenom';
import {Sexe} from '../../../common/personne/Sexe';
import {SocieteCommerciale} from '../../../common/personne/SocieteCommerciale';
import {RaisonSociale} from '../../../common/personne/RaisonSociale';
import {Siren} from '../../../common/personne/Siren';
import {helperText, helperTextWithIcon, onError} from '../../../../common/Utils';
import {Siret} from '../../../common/personne/Siret';
import {IconButton} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {Theme} from '@emotion/react';

interface IdentiteNouveauTitulaireProps {
  formikDC: any,
  onFocus: (field: string, entity?: string) => () => void,
  setShowSearchCorporation: (value: boolean) => void
}

/** Design */
const styles = (theme: Theme) => ({
  flex: {
    display: 'flex',
    flexWrap: 'wrap' as const,
    rowGap: '8px',
  },
  marginTop: {
    marginTop: '24px',
  },
  textfield: {
    width: '360px',
    marginRight: '24px',
  },
  textfieldSmall: {
    width: '240px',
    marginRight: '24px',
  },
  containerSearchIcon: {
    marginTop: '24px',
  },
  searchIcon: {
    color: theme.palette.link.main,
    marginRight: '0px',
  },
  helpIcon: {
    width: '15px',
    height: 'auto',
  },
});

export const IdentiteNouveauTitulaire = (props: IdentiteNouveauTitulaireProps) => {
  const classes = useEmotionStyles(styles);
  const {formikDC, onFocus, setShowSearchCorporation} = props

  return (<React.Fragment>
    <PersonnePhysiqueMorale
      name={'titulaireNouveau.typePersonne'}
      formulaire={'DC'}
      onChangeValue={formikDC.handleChange}
      value={formikDC.values.titulaireNouveau.typePersonne}
    />

    {formikDC.values.titulaireNouveau.typePersonne === 'pp' &&
      <>
        <div className={`${classes.flex} ${classes.marginTop}`}>
          <Nom
            className={classes.textfield}
            name={'titulaireNouveau.nom'}
            value={formikDC.values.titulaireNouveau.nom}
            fullWidth={true}
            formulaire={'DC'}
            nomUsage={false}
            onChangeValue={formikDC.handleChange}
            onBlur={formikDC.handleBlur}
            onFocus={onFocus('nom', 'titulaireNouveau')}
            onError={onError(formikDC.touched.titulaireNouveau?.nom, formikDC.errors.titulaireNouveau?.nom)}
            helperText={helperText(formikDC.touched.titulaireNouveau?.nom, formikDC.errors.titulaireNouveau?.nom)
            }
            maxLength={80}
          />
          <Nom
            className={classes.textfield}
            name={'titulaireNouveau.nomUsage'}
            value={formikDC.values.titulaireNouveau.nomUsage}
            fullWidth={true}
            formulaire={'DC'}
            nomUsage={true}
            onChangeValue={formikDC.handleChange}
            onBlur={formikDC.handleBlur}
            onFocus={onFocus('nomUsage', 'titulaireNouveau')}
            onError={onError(formikDC.touched.titulaireNouveau?.nomUsage,
                formikDC.errors.titulaireNouveau?.nomUsage)
            }
            helperText={helperText(formikDC.touched.titulaireNouveau?.nomUsage,
                formikDC.errors.titulaireNouveau?.nomUsage)
            }
            maxLength={80}
          />
          <Prenom
            className={classes.textfield}
            name={'titulaireNouveau.prenom'}
            value={formikDC.values.titulaireNouveau.prenom}
            fullWidth={true}
            formulaire={'DC'}
            onChangeValue={formikDC.handleChange}
            onBlur={formikDC.handleBlur}
            onFocus={onFocus('prenom', 'titulaireNouveau')}
            onError={onError(formikDC.touched.titulaireNouveau?.prenom,
                formikDC.errors.titulaireNouveau?.prenom)}
            helperText={helperText(formikDC.touched.titulaireNouveau?.prenom, formikDC.errors.titulaireNouveau?.prenom)
            }
            maxLength={80}
          />
        </div>
        <div className={classes.marginTop}>
          <Sexe
            className={classes.textfieldSmall}
            name={'titulaireNouveau.sexe'}
            value={formikDC.values.titulaireNouveau.sexe}
            formulaire={'DC'}
            onChangeValue={formikDC.handleChange}
            onFocus={onFocus('sexe', 'titulaireNouveau')}
            onError={onError(formikDC.touched.titulaireNouveau?.sexe, formikDC.errors.titulaireNouveau?.sexe)}
            helperText={helperText(formikDC.touched.titulaireNouveau?.sexe, formikDC.errors.titulaireNouveau?.sexe)
            }
          />
        </div>
      </>
    }

    {formikDC.values.titulaireNouveau.typePersonne === 'pm' &&
      <>
        <SocieteCommerciale
          className={classes.marginTop}
          name={'titulaireNouveau.societeCommerciale'}
          value={formikDC.values.titulaireNouveau.societeCommerciale}
          onChangeValue={formikDC.handleChange}
        />
        <div className={`${classes.flex} ${classes.marginTop}`}>
          <RaisonSociale
            className={classes.textfield}
            name={'titulaireNouveau.raisonSociale'}
            value={formikDC.values.titulaireNouveau.raisonSociale}
            formulaire={'DC'}
            onChangeValue={formikDC.handleChange}
            onBlur={formikDC.handleBlur}
            onFocus={onFocus('raisonSociale', 'titulaireNouveau')}
            onError={onError(formikDC.touched.titulaireNouveau?.raisonSociale,
                formikDC.errors.titulaireNouveau?.raisonSociale)
            }
            helperText={helperText(formikDC.touched.titulaireNouveau?.raisonSociale,
                formikDC.errors.titulaireNouveau?.raisonSociale)
            }
          />
          <Siren
            className={classes.textfield}
            id={'siren'}
            name={'titulaireNouveau.siren'}
            value={formikDC.values.titulaireNouveau.siren}
            formulaire={'DC'}
            isMandatory={false}
            onChangeValue={formikDC.handleChange}
            onBlur={formikDC.handleBlur}
            onFocus={onFocus('siren', 'titulaireNouveau')}
            onError={onError(formikDC.touched.titulaireNouveau?.siren,
                formikDC.errors.titulaireNouveau?.siren)}
            helperText={helperTextWithIcon(formikDC, 'siren', 'titulaireNouveau', classes.helpIcon)}
          />
          <Siret
            className={classes.textfield}
            id={'siret'}
            name={'titulaireNouveau.siret'}
            value={formikDC.values.titulaireNouveau.siret}
            formulaire={'DC'}
            isMandatory={formikDC.values.titulaireNouveau.societeCommerciale &&
                formikDC.values.titulaireNouveau.adresse.pays === 'FRANCE'
            }
            onChangeValue={formikDC.handleChange}
            onBlur={formikDC.handleBlur}
            onFocus={onFocus('siret', 'titulaireNouveau')}
            onError={onError(formikDC.touched.titulaireNouveau?.siret,
                formikDC.errors.titulaireNouveau?.siret)}
            helperText={helperTextWithIcon(formikDC, 'siret', 'titulaireNouveau', classes.helpIcon)}
          />
          <div className={classes.containerSearchIcon}>
            <IconButton id={'boutonRecherche'} onClick={() => setShowSearchCorporation(true)}>
              <SearchIcon className={classes.searchIcon}/>
            </IconButton>
          </div>
        </div>
      </>
    }
  </React.Fragment>)
};
