import React, {useEffect, useState} from 'react';
import {Button, Card, CardContent, Divider, MenuItem, Typography} from '@mui/material';
import Sollicitation from './Sollicitation';
import {
  InformationMoodel as InformationType, InfoSollicitation,
  SollicitationModel as SollicitationType,
} from '../../../../model/detailsDossier/SuiviReparationModel';
import Information from './Information';
import {TextFieldSelect} from '../../../common/formsComponents/TextFieldSelect';
import {ReactComponent as WorkTodoIcon} from '../../../../images/icones/icn-work-todo.svg'
import {ReactComponent as InProgressIcon} from '../../../../images/icones/icn-in-progress.svg'
import {ReactComponent as FinishIcon} from '../../../../images/icones/icn-check-circle.svg'
import {UserContext} from '../../../../context/UserContext';
import {customTheme} from '../../../../common/GlobalTheme';
import {useMissionContext} from '../../../../context/MissionContext';
import moment from 'moment';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';

interface GestionSollicitationsProps {
    listeMsgSuivi: (SollicitationType | InformationType)[],
    itemSelected: string,
    onClickPj: (tabPanelValue: number, idPJ: string) => void,
    onClickComment: (tabPanelValue: number, date: string) => void
}

const disabledColor = 'silver';

/** Design */
const styles = (theme: Theme) => ({
  filtres: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '32px 0px 4px 0px',
  },
  textField: {
    width: '37vw',
    marginRight: theme.spacing(1),
  },
  libelle: {
    color: theme.palette.secondary.dark2,
    fontWeight: 'bold',
  },
  buttonFiltre: {
    padding: '8px 8px 8px 4px',
    height: '36px',
    border: '1px solid gray',
    borderRadius: '30px',
  },
  icon: {
    padding: '0px 8px 2px 4px',
  },
  blue: {
    fill: theme.palette.link.main,
  },
  disabled: {
    fill: disabledColor,
  },
});

const GestionSollicitations = (props: GestionSollicitationsProps) => {
  const classes = useEmotionStyles(styles);
  const {user} = React.useContext(UserContext);
  const {mission} = useMissionContext();
  const {listeMsgSuivi, itemSelected, onClickPj, onClickComment} = props;
  const [msgFiltres, setMsgFiltres] = useState<(SollicitationType | InformationType)[]>(listeMsgSuivi);
  const [motifs, setMotifs] = useState<string[]>([]);
  const [motifSelected, setMotifSelected] = useState('');
  const [disabledMotifs, setDisabledMotifs] = useState(false);
  const [etats, setEtats] = useState<number[]>([]);
  const [etatSelected, setEtatSelected] = useState(-1);
  const [idsSRP, setIdsSRP] = useState<string[]>([]);
  const initColor = customTheme.palette.link.main;
  const selectColor = customTheme.palette.secondary.dark2;
  const [color0, setColor0] = useState<string|undefined>(initColor);
  const [color1, setColor1] = useState<string|undefined>(initColor);
  const [color2, setColor2] = useState<string|undefined>(initColor);
  const [icon0Blue, setIcon0Blue] = useState(true);
  const [icon1Blue, setIcon1Blue] = useState(true);
  const [icon2Blue, setIcon2Blue] = useState(true);
  const [disabledButton0, setDisabledButton0] = useState(false);
  const [disabledButton1, setDisabledButton1] = useState(false);
  const [disabledButton2, setDisabledButton2] = useState(false);

  useEffect(() => {
    const selectedElement = document.getElementById(itemSelected);
    if (selectedElement) {
      selectedElement.scrollIntoView({behavior: 'smooth', block: 'center'})
    }
  }, [itemSelected]);

  useEffect(() => {
    initFilter()
  }, []);

  const disabledMot = () => {
    let result = true;
    listeMsgSuivi.forEach((msgIFR) => {
      if (msgIFR instanceof Object && 'listeSuiviRep' in msgIFR &&
          !idsSRP.includes(msgIFR.idSollicitation)) {
        result = false;
      }
    });
    return result
  }

  const initFilter = () => {
    const listMotif: string[] = [];
    const listEtat: number[] = [];
    const listIdSRP: string[] = [];
    listeMsgSuivi.forEach((msgSRP) => {
      if (msgSRP instanceof Object && 'infoSollicitation' in msgSRP) {
        listIdSRP.push(msgSRP.idDoc);
        if (!listEtat.includes(msgSRP.etat)) {
          listEtat.push(msgSRP.etat)
        }
        const infoList:InfoSollicitation[] = msgSRP.infoSollicitation;
        for (const value of infoList) {
          if (!listMotif.includes(value.motif)) {
            listMotif.push(value.motif)
          }
        }
      }
    });
    setMotifs(listMotif);
    setEtats(listEtat);
    setIdsSRP(listIdSRP);
    setDisabledButton0(!listEtat.includes(0));
    setDisabledButton1(!listEtat.includes(1));
    setDisabledButton2(!listEtat.includes(2));
    setDisabledMotifs(disabledMot());
  };

  const getSollicitation = (idSollicitation: string) => {
    const sollicitationType = listeMsgSuivi.find((msg) => {
      if ('infoSollicitation' in msg) {
        return msg.idDoc === idSollicitation
      }
    });
    return sollicitationType as SollicitationType
  };

  const getComment = (date: string, typeDoc: string) => {
    const commentairesItem = mission?.commentairesDTO?.commentaires.find((comment) => {
      return comment.date === date && comment.typeDoc === typeDoc
    });
    if (commentairesItem?.contenu && commentairesItem?.contenu.length > 0) {
      return commentairesItem?.contenu.join(' ');
    }
  };

  const sortListeMsg = (listeMsg: any[]) => {
    if (listeMsg.length > 0) {
      listeMsg.sort((a, b) => {
        const dateA = moment(a.date?.replace(' - ', ''), 'DD/MM/YYYY HH:mm:ss').unix();
        const dateB = moment(b.date?.replace(' - ', ''), 'DD/MM/YYYY HH:mm:ss').unix();
        return dateA > dateB ? -1 : 1
      })
    }
  }

  const subFilterMessages = (msg1: SollicitationType | InformationType, listeMsg: any[]) => {
    listeMsg.push(msg1);
    listeMsgSuivi.forEach((msg2) => {
      if (msg2 instanceof Object && 'listeSuiviRep' in msg2 &&
          msg2.idSollicitation === msg1.idDoc) {
        listeMsg.push(msg2);
      }
    })
  }

  const conditionFilterMessages = (motif: string | undefined, etat: number | undefined) =>
    motif !== '' || etat !== -1
  const conditionInfoSollicitation = (info : InfoSollicitation, motif: string | undefined, addedSRP: boolean,
      msg1: SollicitationType, etat: number | undefined) =>
    motif !== '' && info.motif === motif && !addedSRP && (msg1.etat === etat || etat === -1)
  const conditionSollicitationType = (motif: string | undefined, addedSRP: boolean,
      msg1: SollicitationType, etat: number | undefined) =>
    etat !== -1 && msg1.etat === etat && !addedSRP && (msg1.etat !== etat || motif === '')

  const filterMessages = (motif?: string, etat?: number) => {
    let listeMsg: any[] = [];
    if (conditionFilterMessages(motif, etat)) {
      listeMsgSuivi.forEach((msg1) => {
        let addedSRP = false;
        if (msg1 instanceof Object && 'infoSollicitation' in msg1) {
          msg1.infoSollicitation?.forEach((info : InfoSollicitation) => {
            if (conditionInfoSollicitation(info, motif, addedSRP, msg1, etat)) {
              addedSRP = true
              subFilterMessages(msg1, listeMsg)
            }
          });
          if (conditionSollicitationType(motif, addedSRP, msg1, etat)) {
            addedSRP = true
            subFilterMessages(msg1, listeMsg)
          }
        }
      });
      sortListeMsg(listeMsg)
    } else {
      listeMsg = [...listeMsgSuivi];
    }
    setMsgFiltres(listeMsg)
  };

  const handleChangeMotif = (event: React.ChangeEvent<HTMLInputElement>) => {
    filterMessages(event.target.value, etatSelected);
    setMotifSelected(event.target.value)
  };

  const onClickFilterWorkTodo = () => {
    if (color0 === initColor) {
      filterMessages(motifSelected, 0);
      setEtatSelected(0);
      setColor0(selectColor);
      setIcon0Blue(false);
      setColor2(initColor);
      setIcon2Blue(true)
    } else {
      filterMessages(motifSelected, -1);
      setEtatSelected(-1);
      setColor0(initColor);
      setIcon0Blue(true)
    }
  };

  const onClickFilterInProgress = () => {
    if (color1 === initColor) {
      filterMessages(motifSelected, 1);
      setEtatSelected(1);
      setColor1(selectColor);
      setIcon1Blue(false);
      setColor2(initColor);
      setIcon2Blue(true)
    } else {
      filterMessages(motifSelected, -1);
      setEtatSelected(-1);
      setColor1(initColor);
      setIcon1Blue(true)
    }
  };

  const onClickFilterFinish = () => {
    if (color2 === initColor) {
      filterMessages(motifSelected, 2);
      setEtatSelected(2);
      setColor2(selectColor);
      setIcon2Blue(false);
      setColor0(initColor);
      setIcon0Blue(true);
      setColor1(initColor);
      setIcon1Blue(true)
    } else {
      filterMessages(motifSelected, -1);
      setEtatSelected(-1);
      setColor2(initColor);
      setIcon2Blue(true)
    }
  };

  const classNameIconATraiter = () => {
    if (disabledButton0) {
      return `${classes.icon} ${classes.disabled}`;
    }
    return (icon0Blue ? `${classes.icon} ${classes.blue}` : classes.icon)
  };
  const classNameIconEnCours = () => {
    if (disabledButton1) {
      return `${classes.icon} ${classes.disabled}`;
    }
    return (icon1Blue ? `${classes.icon} ${classes.blue}` : classes.icon)
  };
  const classNameIconTermine = () => {
    if (disabledButton2) {
      return `${classes.icon} ${classes.disabled}`;
    }
    return (icon2Blue ? `${classes.icon} ${classes.blue}` : classes.icon)
  };

  return (
    <Card>
      <CardContent>
        <Typography variant={'h3'} id={'gestionSollicitationTitle'}>Gestion des sollicitations</Typography>
        <div className={classes.filtres}>

          <div>
            <Typography id={'filtre-motif-titre'} className={classes.libelle}>Filtrer par motif</Typography>
            <TextFieldSelect
              className={classes.textField}
              id={'filtreMotif'}
              name={'filtreMotif'}
              marginDense={true}
              withEmptyItem={motifs.length >= 1}
              emptyItemLabel={'Aucun'}
              disabled={motifs.length === 0 || (motifs.length === 1 && disabledMotifs)}
              value={(motifs.length === 1 && disabledMotifs) ? motifs[0] : motifSelected}
              itemsList={motifs.map((motif, index) => {
                const keyMenuItem = `${index}`
                return (
                  <MenuItem key={keyMenuItem} value={motif}>{motif}</MenuItem>
                )
              })}
              onChange={handleChangeMotif}
            />
          </div>

          <div>
            <div>
              <Typography id={'filtre-etat-titre'} className={classes.libelle}>Filtrer par état</Typography>
              <div style={{margin: '12px 0px 4px 0px'}}>
                {(user.type === 'Carrossier' || user.type === 'RM') &&
                  <Button id={'filtre-srp-a-traiter'} className={classes.buttonFiltre} disabled={disabledButton0}
                    style={{
                      marginRight: '10px', borderColor: disabledButton0 ? disabledColor : color0,
                      color: disabledButton0 ? disabledColor : color0,
                    }}
                    onClick={onClickFilterWorkTodo}>
                    <WorkTodoIcon
                      id={'icon-a-traiter'} className={classNameIconATraiter()}/>A traiter
                  </Button>
                }
                {(user.type === 'Assureur' || user.type === 'Expert') &&
                  <Button id={'filtre-srp-en-cours'} className={classes.buttonFiltre} disabled={!etats.includes(1)}
                    style={{marginRight: '10px', borderColor: disabledButton1?disabledColor:color1,
                      color: disabledButton1?disabledColor:color1}}
                    onClick={onClickFilterInProgress}>
                    <InProgressIcon
                      className={classNameIconEnCours()}/>En cours
                  </Button>
                }
                <Button id={'filtre-srp-termine'} className={classes.buttonFiltre} disabled={!etats.includes(2)}
                  style={{borderColor: disabledButton2?disabledColor:color2,
                    color: disabledButton2?disabledColor:color2}}
                  onClick={onClickFilterFinish}>
                  <FinishIcon
                    className={classNameIconTermine()}
                  />Terminé
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Divider variant={'fullWidth'} style={{marginBottom: '40px'}}/>
        {msgFiltres.length > 0 &&
        msgFiltres.map((msgSuivi, index) => {
          const keyDiv = `${index}`
          return <div key={keyDiv} id={msgSuivi.date}>
            {
              'infoSollicitation' in msgSuivi &&
              <Sollicitation
                indexBubble={index}
                sollicitaion={msgSuivi}
                itemSelected={itemSelected === msgSuivi.date}
                commentaires={getComment(msgSuivi.date, msgSuivi.typeDoc) ?? ''}
                onClickPj={onClickPj}
                onClickComment={onClickComment}
              />
            }
            {
              'listeSuiviRep' in msgSuivi &&
              <Information
                indexBubble={index}
                information={msgSuivi}
                itemSelected={itemSelected === msgSuivi.date}
                sollicitation={getSollicitation(msgSuivi.idSollicitation)}
                commentaires={getComment(msgSuivi.date, msgSuivi.typeDoc) ?? ''}
                onClickComment={onClickComment}
                onClickPj={onClickPj}
              />
            }
          </div>
        })}
      </CardContent>
    </Card>
  );
};

export default GestionSollicitations;
