import useEmotionStyles from '../../common/useEmotionStyles';
import React from 'react';
import {Filiale} from '../../context/UserContext';
import {Theme} from '@emotion/react';
import {Button, Typography} from '@mui/material';
import {Loader} from '../common/Loader';

interface IEntityElementProps {
  filiale: Filiale,
  selectedFiliale: string
  changeFiliale: Function
  loadingElement: string
  errorElement: string
}

const styles = (theme: Theme) => ({
  entityElement: {
    borderBottom: 'solid 1px ' + theme.palette.secondary.main,
    padding: '16px 12px 16px 16px',
    width: '100%',
    display: 'block',
  },
  entityElementGrid: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 24px',
    alignItems: 'center',
    minHeight: '28px',
    justifyItems: 'left',
  },
  isSelected: {
    color: theme.palette.secondary.dark,
  },
  errorLabel: {
    display: 'flex',
    justifyItems: 'left',
    color: theme.palette.error.light,
    fontSize: '0.9em',
  },
})

export const EntityElement = (props: IEntityElementProps) => {
  const {filiale, selectedFiliale, changeFiliale, loadingElement, errorElement} = props;
  const classes = useEmotionStyles(styles);
  const isSelected = selectedFiliale === filiale.codeAbonne;

  const handleSelectEntity = (filiale: Filiale) => {
    if (!isSelected) {
      changeFiliale(filiale)
    }
  }

  return (
    <Button
      id={'filiale-'.concat(filiale.numeroClient)}
      className={classes.entityElement}
      disabled={isSelected}
      onClick={() => handleSelectEntity(filiale)}
    >
      <div className={classes.entityElementGrid}>
        <Typography className={isSelected ? classes.isSelected : undefined}>{filiale.raisonSociale}</Typography>
        {loadingElement === filiale?.codeAbonne &&
          <Loader size={24}/>
        }
      </div>
      {errorElement === filiale?.codeAbonne &&
        <Typography className={classes.errorLabel}>
          Le service sélectionné n&apos;est pas disponible pour cette entité
        </Typography>
      }
    </Button>
  )
};
