import React, {useContext, useState} from 'react';
import {Grid, IconButton, MenuItem, Tooltip, Typography} from '@mui/material';
import {ButtonHome} from '../common/buttons/ButtonHome';
import {InfosDossier} from './components/infosDossierComponents/InfosDossier';
import {InfosDossierSimple} from './components/infosDossierComponents/InfosDossierSimple';
import {InfosDossierArchive} from './components/infosDossierComponents/InfosDossierArchive';
import {Assure} from './components/Assure';
import PrintIcon from '@mui/icons-material/Print';
import {TextFieldSelect} from '../common/formsComponents/TextFieldSelect';
import {InfosDossierPrint} from './components/infosDossierComponents/InfosDossierPrint';
import {Details} from './components/Details';
import {Acteurs} from './components/acteurs/Acteurs';
import {AlbumPhotos} from './components/photosComponents/AlbumPhotos';
import {RendezVous} from './components/rendezVousComponents/RendezVous';
import useEmotionStyles from '../../common/useEmotionStyles';
import {Theme} from '@emotion/react';
import {TokenModel} from '../../model/common/TokenModel';
import {useLocation, useNavigate} from 'react-router-dom';
import useHasRole from '../../hook/useHasRole';
import {permittedRolesMenuRDV} from '../../constantes/roles/Roles';
import {isAssureurOrExpertOrCarrossier} from '../../common/utils/UserTypeUtils';
import {useMissionContext} from '../../context/MissionContext';
import {UserContext} from '../../context/UserContext';
import {getViewType, IElement, VIEW_TYPE} from './Utils';
import {ButtonMenuDossier} from './components/responsive/ButtonMenuDossier';
import {InfosRendezVousModel} from '../../model/detailsDossier/RendezVousModel';

const base64 = require('base-64');

interface IProps {
  tabValue: number
  search: any
  missionsList: IElement[]
  selectedMission: string
  setSelectedMission: (selectedMission: string) => void
  setIsLoading: (isLoading: boolean) => void
  getDetailDossier: (payload: TokenModel) => void
  idigoRendezVous: InfosRendezVousModel[]
}

// Design
type StylesProps = {indicateurArchivage: string}
const styles = (theme: Theme, props: StylesProps) => ({
  root: {
    flexGrow: 1,
    margin: 'auto',
    border: 'none',
    marginTop: '35px',
    opacity: '1',
  },
  colonneGauche: {
    width: '17%',
    marginBottom: '20px',
    paddingRight: '20px',
    [theme.breakpoints.down('lg')]: {
      width: '17%',
      paddingRight: '10px',
    },
    [theme.breakpoints.down('md')]: {
      width: '12%',
    },
    ['@media print']: {
      display: 'none',
    },
  },
  back: {
    textAlign: 'left' as const,
    height: '40px',
  },
  card15: {
    marginTop: '15px',
  },
  card25: {
    marginTop: '25px',
    [theme.breakpoints.down('lg')]: {
      marginTop: '15px',
    },
  },
  smallScreen: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  colonneCentre: {
    width: '60%',
    marginBottom: '20px',
    [theme.breakpoints.down('md')]: {
      width: '80%',
    },
    ['@media print']: {
      width: '100% !important',
      marginTop: '-70px ! important',
    },
  },
  center: {
    textAlign: 'left' as const,
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  header: {
    display: 'flex',
    alignItems: props.indicateurArchivage === 'O' || props.indicateurArchivage === 'A' ? 'baseline' : 'end',
  },
  noPrint: {
    ['@media print']: {
      display: 'none !important',
    },
  },
  titlePrint: {
    display: 'none',
    ['@media print']: {
      display: 'inline !important',
      fontSize: '18px !important',
      marginBottom: '10px !important',
    },
  },
  iconButtonPrint: {
    padding: '5px',
    marginLeft: '10px',
  },
  printIcon: {
    marginRight: '0px',
    color: theme.palette.link.main,
  },
  commentaire: {
    fontSize: '24px',
    color: theme.palette.primary.main,
    marginLeft: '30px',
  },
  missionCell: {
    justifySelf: 'end',
    width: '57%',
    marginTop: '-8px',
    display: 'grid',
    gridTemplateColumns: '70px 1fr',
    gridGap: '10px',
    marginBottom: '-5px',
  },
  libelle: {
    color: theme.palette.secondary.dark2,
    justifySelf: 'end',
    alignSelf: 'center',
  },
  select: {
    width: '100%',
  },
  maincard: {
    marginTop: '15px',
  },
  infoPrint: {
    display: 'none',
    ['@media print']: {
      display: 'block !important',
    },
  },
  colonneDroite: {
    width: '17%',
    marginBottom: '20px',
    paddingLeft: '20px',
    [theme.breakpoints.down('lg')]: {
      width: '17%',
      paddingLeft: '10px',
    },
    ['@media print']: {
      display: 'none',
    },
  },
  emptyDiv: {
    height: '40px',
  },
  notification: {
    position: 'fixed' as const,
    bottom: '0px',
    right: '0px',
  },
});

export const DetailsDossier = (props: IProps) => {
  const {tabValue, search, missionsList, selectedMission, setSelectedMission, setIsLoading, getDetailDossier,
    idigoRendezVous} = props;
  const location = useLocation();
  const {mission} = useMissionContext();
  const {user} = useContext(UserContext);
  const navigate = useNavigate();
  const refSinistre = mission?.infoMissionDTO?.referenceSinistre ?? '';
  const indicateurArchivage = mission?.indicateurArchivage ?? '';
  const isDossierSimple = mission?.dossierSimple;
  const hasRoleRdvConsultation = useHasRole(permittedRolesMenuRDV) && isAssureurOrExpertOrCarrossier(user.type);
  const viewType = getViewType(indicateurArchivage, isDossierSimple);
  const classes = useEmotionStyles(styles, {indicateurArchivage});

  // State
  const [isPrint, setIsPrint] = useState(false);

  const isShowMission = missionsList.length === 1 && selectedMission !== 'Toutes';

  const itemsMissionsList = missionsList.map((item) => {
    return (
      <MenuItem key={item.label} value={item.value}>{item.label}</MenuItem>
    )
  });

  const onChangeSelectedMission = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (selectedMission !== value) {
      setIsLoading(true);
      setSelectedMission(value);
      sessionStorage.setItem('fromPath', location.pathname);
      const tokenMission: TokenModel = {
        referenceSinistre: search.referenceSinistre,
        numeroMission: value !== 'Toutes' ? value : '',
        codeGTA: mission?.codeGTA ?? user.codeGTA,
        numClient: user.numClient,
        userCompte: user.userCompte,
        codeAbonne: user.codeAbo,
        codeService: user.service,
        immatriculation: mission?.infoMissionDTO?.immatriculation,
        isDossierComplet: false,
      };
      if (!user.isTransparent) {
        const token = base64.encode(JSON.stringify(tokenMission));
        navigate(`/extranet/dossiers/mission/`+ token);
      }
      getDetailDossier(tokenMission);
    }
  };

  const onClickPrint = () => {
    setIsPrint(true)
  };

  return (
    <div className={classes.root}>
      <ButtonMenuDossier className={classes.noPrint}/>

      <Grid container justifyContent="center">
        <Grid item className={classes.colonneGauche}>
          <div>
            <div className={classes.back}>
              {!user.isTransparent &&
                <ButtonHome/>
              }
            </div>

            <div className={`${classes.card15} ${classes.smallScreen}`}>
              {viewType === VIEW_TYPE.NORMAL && <InfosDossier showMission={isShowMission}/>}
              {viewType === VIEW_TYPE.SIMPLE && <InfosDossierSimple/>}
              {viewType === VIEW_TYPE.ARCHIVE && <InfosDossierArchive showMission={isShowMission}/>}
            </div>

            {viewType === VIEW_TYPE.NORMAL &&
              <div className={`${classes.card25} ${classes.smallScreen}`}>
                <Assure/>
              </div>
            }
          </div>
        </Grid>

        <Grid item className={classes.colonneCentre}>
          <div className={classes.center}>
            <div className={classes.grid}>
              <div className={classes.header}>
                <Typography variant={'h1'} className={classes.noPrint}>Détails du dossier</Typography>
                <Typography variant={'h1'} className={classes.titlePrint}>Détails du dossier {refSinistre}</Typography>

                {viewType === VIEW_TYPE.NORMAL &&
                  <Tooltip
                    disableInteractive
                    title='Imprimer une synthèse du dossier'
                    placement="right"
                  >
                    <IconButton
                      className={`${classes.iconButtonPrint} ${classes.noPrint}`}
                      component='button'
                      onClick={onClickPrint}
                    >
                      <PrintIcon className={classes.printIcon}/>
                    </IconButton>
                  </Tooltip>
                }

                <Typography className={classes.commentaire}>
                  {indicateurArchivage === 'O' && 'Dossier archivé'}
                  {indicateurArchivage === 'A' && 'En cours de désarchivage'}
                </Typography>
              </div>

              {missionsList?.length > 1 &&
                <div className={`${classes.missionCell} ${classes.noPrint}`}>
                  <Typography className={classes.libelle}>Missions</Typography>
                  <TextFieldSelect
                    id={'numero-mission'}
                    className={classes.select}
                    name={'numero-mission'}
                    value={selectedMission}
                    itemsList={itemsMissionsList}
                    disabled={false}
                    onChange={onChangeSelectedMission}
                    marginDense={true}
                  />
                </div>
              }
            </div>

            <div className={classes.maincard}>
              <div className={classes.infoPrint}>
                <InfosDossierPrint/>
              </div>
              <Details valueInit={tabValue} isPrint={isPrint} onChangeIsPrint={() => setIsPrint(false)}/>
            </div>
          </div>
        </Grid>

        <Grid item className={`${classes.colonneDroite} ${classes.smallScreen}`}>
          <div>
            <div className={classes.emptyDiv}>
              &nbsp;
            </div>
            <div className={classes.card15}>
              <Acteurs/>
            </div>
            {viewType === VIEW_TYPE.NORMAL &&
              <div className={classes.card25}>
                <AlbumPhotos/>
              </div>
            }
            {hasRoleRdvConsultation && idigoRendezVous?.length > 0 &&
              <div className={classes.card25}>
                <RendezVous idigoRendezVous={idigoRendezVous} refSinistre={refSinistre}/>
              </div>
            }
          </div>
        </Grid>
      </Grid>
    </div>
  )
};
