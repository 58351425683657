import * as React from 'react'
import {Typography} from '@mui/material'
import {customTheme} from '../../common/GlobalTheme';
import useEmotionStyles from '../../common/useEmotionStyles';

interface StyledTypographyProps {
  id?: string
  title?: string
  text?: string
  isTextBold?: boolean
}

type StylesProps = {isTextBold?: boolean}
const styles = (theme: any, props: StylesProps) => ({
  bloc: {
    marginTop: '12px',
  },
  bold: {
    fontWeight: props.isTextBold ? 'bold' : '',
  },
});

export const StyledTypography = (props: StyledTypographyProps) => {
  const {id, title, text, isTextBold} = props;
  const classes = useEmotionStyles(styles, {isTextBold});

  return (
    <div className={classes.bloc}>
      <Typography id={'titre-' + id} style={customTheme.styledTypography.libelle}>{title}</Typography>

      {text ?
        <Typography
          id={id}
          style={customTheme.styledTypography.donneeGrise16}
          className={classes.bold}
        >
          {text}
        </Typography> :
        <br/>
      }
    </div>
  )
};
