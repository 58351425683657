import React from 'react';
import {EdiProps} from './VueEdi';
import {ReponseCommonSIV} from '../../vueEdiSIV/reponseSIV/ReponseCommonSIV';
import {RequeteCommonSIV} from '../../vueEdiSIV/requeteSIV/RequeteCommonSIV';

interface VueEdiSIVProps {
  content: EdiProps,
  search: any,
}

const formulaire = (search: any) => {
  switch (search.type) {
    case 'CSIT00':
    case 'ISIT00': return 'IT';
    case 'CSCT00':
    case 'ISCT00': return 'CT';
    case 'CSSA00':
    case 'ISSA00': return 'SA';
    case 'ODDC00':
    case 'AQDC00': return 'DC';
    case 'ODIR00':
    case 'AQIR00': return 'IR';
    case 'ODDA00':
    case 'AQDA00': return 'DA';
    default: return undefined;
  }
}

export const VueEdiSIV = (props: VueEdiSIVProps) => {
  const {content, search} = props;
  const form = formulaire(search)
  if (form != undefined) {
    switch (search.type) {
      case 'CSIT00':
      case 'CSCT00':
      case 'CSSA00':
      case 'ODDC00':
      case 'ODIR00':
      case 'ODDA00':
        return <RequeteCommonSIV sIVReqRepModel={{content: content, from: 'historique'}}
          formulaire={form}
        />;
      case 'ISIT00':
      case 'ISCT00':
      case 'ISSA00':
      case 'AQDC00':
      case 'AQIR00':
      case 'AQDA00':
        return <ReponseCommonSIV sIVReqRepModel={{content: content, from: 'historique'}}
          formulaire={form}
        />;
      default:
        return <></>
    }
  } else return <></>
};
