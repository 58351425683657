import React from 'react';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {InputDate} from '../../common/InputDate';
import {Hour} from '../../common/Hour';
import {helperText, onError} from '../../../common/Utils';

interface InformationDeclarationProps {
    formulaire: string,
    formik: any
    onFocus: (field: string, entity?: string) => () => void
}

/** Design */
const styles = () => ({
  flex: {
    display: 'flex',
    flexWrap: 'wrap' as const,
    rowGap: '8px',
  },
  dateHeure: {
    marginRight: '48px',
  },
  textfieldSmall: {
    width: '240px',
    marginRight: '24px',
  },
});

const titleDateDeclaration = (formulaire: string) => {
  if (formulaire === 'DA') {
    return 'Déclaration d\'achat'
  } else {
    return formulaire === 'DC' ? 'Déclaration de cession' : 'Déclaration de refus'
  }
}

export const InformationDeclaration = (props: InformationDeclarationProps) => {
  const classes = useEmotionStyles(styles);
  const {formulaire, formik, onFocus} = props
  const helperTextDate = () => {
    if (formulaire === 'DA') {
      return (formik.touched.dateAchat && formik.errors.dateAchat && formik.errors.dateAchat ?
        formik.errors.dateAchat : '')
    } else {
      return (formik.touched.dateCession && formik.errors.dateCession && formik.errors.dateCession ?
        formik.errors.dateCession : '')
    }
  }
  const helperTextHour = () => {
    if (formulaire === 'DA') {
      return (formik.touched.heureAchat && formik.errors.heureAchat ? formik.errors.heureAchat : '')
    } else {
      return (formik.touched.heureCession && formik.errors.heureCession ? formik.errors.heureCession : '')
    }
  }

  return (<div className={classes.flex}>
    {formulaire !== 'IR' && <span className={`${classes.flex} ${classes.dateHeure}`}>
      <InputDate
        className={classes.textfieldSmall}
        id={formulaire === 'DA' ? 'dateAchat' : 'dateCession'}
        name={formulaire === 'DA' ? 'dateAchat' : 'dateCession'}
        formulaire={formulaire}
        isMandatory={true}
        title={formulaire === 'DA' ? 'Achat du véhicule' : 'Cession du véhicule'}
        value={formulaire === 'DA' ? formik.values.dateAchat : formik.values.dateCession}
        onChangeValue={formik.handleChange}
        onBlur={formik.handleBlur}
        onFocus={onFocus(formulaire === 'DA' ? 'dateAchat': 'dateCession')}
        onError={formulaire === 'DA' ?
            (onError(formik.touched.dateAchat, formik.errors.dateAchat)) :
            (onError(formik.touched.dateCession, formik.errors.dateCession))}
        helperText={helperTextDate()}
      />
      <Hour
        className={classes.textfieldSmall}
        id={formulaire === 'DA' ? 'heureAchat' : 'heureCession'}
        name={formulaire === 'DA' ? 'heureAchat' : 'heureCession'}
        formulaire={formulaire}
        isMandatory={false}
        title={'\xa0'}
        value={formulaire === 'DA' ? formik.values.heureAchat : formik.values.heureCession}
        onChangeValue={formik.handleChange}
        onBlur={formik.handleBlur}
        onFocus={onFocus(formulaire === 'DA' ? 'heureAchat' : 'heureCession')}
        onError={formulaire === 'DA' ?
            (onError(formik.touched.heureAchat, formik.errors.heureAchat)) :
            (onError(formik.touched.heureCession, formik.errors.heureCession))}
        helperText={helperTextHour()}
      />
    </span>}
    <span className={classes.flex}>
      <InputDate
        className={classes.textfieldSmall}
        id={'dateDeclaration'}
        name={'dateDeclaration'}
        formulaire={formulaire}
        isMandatory={true}
        title={titleDateDeclaration(formulaire)}
        value={formik.values.dateDeclaration}
        onChangeValue={formik.handleChange}
        onBlur={formik.handleBlur}
        onFocus={onFocus('dateDeclaration')}
        onError={onError(formik.touched.dateDeclaration, formik.errors.dateDeclaration)}
        helperText={helperText(formik.touched.dateDeclaration, formik.errors.dateDeclaration)
        }
      />
      <Hour
        className={classes.textfieldSmall}
        id={'heureDeclaration'}
        name={'heureDeclaration'}
        formulaire={formulaire}
        isMandatory={false}
        title={'\xa0'}
        value={formik.values.heureDeclaration}
        onChangeValue={formik.handleChange}
        onBlur={formik.handleBlur}
        onFocus={onFocus('heureDeclaration')}
        onError={onError(formik.touched.heureDeclaration, formik.errors.heureDeclaration)}
        helperText={helperText(formik.touched.heureDeclaration, formik.errors.heureDeclaration)
        }
      />
    </span>
  </div>)
};
