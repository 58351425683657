import {CardContainer, CardContentProps} from '../CardContainer';
import React from 'react';
import {getService} from '../../../../../common/Utils';
import {UserContext} from '../../../../../context/UserContext';
import {getLibelleValeur, getValueDateElement, getValueElement} from '../Utils';
import {dateToDateText, dateToHeure} from '../../../../../common/utils/DateUtils';

interface EnteteProps {
  data: any,
  prefix: string,
  formulaire: string,
  withoutTitreContainer?: boolean
  specificiteCT?: CardContentProps
  refSinistre: string
  numMission?: string
}

export const Entete = (props: EnteteProps) => {
  const {data, prefix, formulaire, withoutTitreContainer, specificiteCT, refSinistre, numMission} = props
  const {user} = React.useContext(UserContext);

  const dateHeureSinistre = () => {
    return (
      (data.getElementsByTagName('ns1:horodate_sinistre').length !== 0 ?
        dateToDateText(data.getElementsByTagName('ns1:horodate_sinistre')[0].value
            .replace('Z', '')) : '') + ' ' +
      (data.getElementsByTagName('ns1:horodate_sinistre').length !== 0 ?
        dateToHeure(data.getElementsByTagName('ns1:horodate_sinistre')[0].value
            .replace('Z', '')) : '')
    )
  }

  const libellesValeursSpecifiques = () => {
    switch (formulaire) {
      case 'CT':
        return [
          [
            getLibelleValeur('Service :', getService(user.service) ?? ''),
            getLibelleValeur('Société :', user.raisonSociale),
          ],
          [
            getLibelleValeur('N° immatriculation :', getValueElement(data, prefix, 'numero_immatriculation')),
            getLibelleValeur(`N° de police d'assurance :`, getValueElement(data, prefix, 'numero_police_assurance')),
          ],
          refSinistre ? [
            getLibelleValeur('Référence sinistre :', refSinistre),
          ] : [],
        ]
      case 'IR':
        return [
          [
            getLibelleValeur('Service :', getService(user.service) ?? ''),
            getLibelleValeur('Société :', user.raisonSociale),
          ],
          [
            getLibelleValeur('Référence sinistre :', refSinistre),
            getLibelleValeur(`Date de l'événement :`, getValueDateElement(data, prefix, 'date_sinistre')),
          ],
        ]
      case 'SA':
      case 'IT':
        return [
          [
            getLibelleValeur('Service :', getService(user.service) ?? ''),
            getLibelleValeur('Société :', user.raisonSociale),
          ],
          [
            getLibelleValeur('N° immatriculation :', getValueElement(data, prefix, 'numero_immatriculation')),
            getLibelleValeur(`Date et heure du sinistre :`, dateHeureSinistre()),
          ],
          [
            getLibelleValeur('Référence sinistre :', refSinistre),
          ],
        ]
      case 'DA':
      case 'DC':
        return [
          [
            getLibelleValeur('Service :', getService(user.service) ?? ''),
            getLibelleValeur('Société :', user.raisonSociale),
          ],
          numMission ? [
            getLibelleValeur('Référence sinistre :', refSinistre),
            getLibelleValeur( `N° de mission :`, numMission),
          ] : [],
        ]
      default: return []
    }
  }

  const libellesValeurs = () => {
    if (specificiteCT) {
      return (
        [
          {
            libellesValeurs: libellesValeursSpecifiques(),
          },
          specificiteCT,
        ]
      )
    } else {
      return (
        [
          {
            libellesValeurs: libellesValeursSpecifiques(),
          },
        ]
      )
    }
  };

  return (
    <CardContainer titreContainer={
        !withoutTitreContainer ? 'Informations sur la demande' : undefined
    }
    content={libellesValeurs()}
    isEntete={true}
    />
  )
};

