import React, {ChangeEvent, useState} from 'react';
import {FieldConfig, FieldHookConfig, useField} from 'formik';
import {UserContext} from '../../../../context/UserContext';
import {getLocalites} from '../../../../api/infogeo';
import {ILocalite} from '../../ordre-mission/model/ModelOM';
import {AutocompleteField} from './AutocompleteField';

export interface LocalityAutocompleteProps extends FieldConfig{
    id: string,
    label: string,
    isMandatory: boolean,
}

const LocaliteAutocompleteField = ({label, isMandatory, id, ...props}: LocalityAutocompleteProps) => {
  const {user} = React.useContext(UserContext);
  const [field, meta, helper] = useField<ILocalite>(props as FieldHookConfig<ILocalite>);

  const [localities, setLocalities] = useState<ILocalite[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSearchLocality = (event: ChangeEvent<{}>, value: string) => {
    if (value.trim().length > 2) {
      setIsLoading(true);
      getLocalites(value, user.numClient).then((response) => {
        setLocalities(response);
        setIsLoading(false)
      }).catch((error) => {
        console.error(error)
      })
    } else {
      setLocalities([])
    }
  };

  const onSelectLocality = (event: ChangeEvent<{}>, value: any) => {
    value ? helper.setValue(value) :
        helper.setValue({
          codePostal: '',
          commune: '',
        })
  };

  return (
    <AutocompleteField
      id={id}
      label={label}
      isMandatory={isMandatory}
      isLoading={isLoading}
      options={localities}
      getOptionLabel={(loc: ILocalite) => [loc.codePostal, loc.commune].filter(Boolean).join(' - ')}
      onChange={onSelectLocality}
      onInputChange={handleSearchLocality}
      field={field}
      meta={meta}
    />
  );
};

export default LocaliteAutocompleteField;
