import React from 'react';
import {FieldConfig, FieldHookConfig, useField} from 'formik';
import {TextField} from '@mui/material';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';
import {getIdFromName} from '../../utils/Utils';
import {CommonTooltip} from './CommonTooltip';

interface InputFieldProps extends FieldConfig{
    label: string
    mandatory?: boolean
    maxLength?: number
    disabled?: boolean
    className?: string
}
const styles = (theme: Theme) => ({
  container: {
    width: '360px',
    marginRight: '24px',
  },
  textfield: {
    backgroundColor: theme.palette.white.main,
  },
})
const InputImmatriculationField = ({label, ...props} : InputFieldProps) => {
  const classes = useEmotionStyles(styles);
  const [field, meta] = useField<string>(props as FieldHookConfig<string>);
  const {type, maxLength, mandatory, disabled, className} = props

  const titleTooltip = <span>Saisissez un numéro d&apos;immatriculation dans l&apos;un des formats suivants :
    <div>Format FNI : 9999 AAA 999 ou 9999 AA 99</div>
    <div>Format SIV : AA-999-AA</div>
    <div>Cyclo : AA 999 A</div></span>;

  return (
    <div className={className ?? classes.container}>
      <CommonTooltip label={label} mandatory={mandatory} titleTooltip={titleTooltip}/>
      <TextField
        id={getIdFromName(field.name)}
        fullWidth
        margin='dense'
        size='small'
        type={type ?? 'search'}
        disabled={Boolean(disabled)}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
        {...field}
        InputProps={{
          inputProps: {
            maxLength: maxLength,
          },
          classes: {
            root: classes.textfield,
          },
        }}
      />
    </div>
  );
};

export default InputImmatriculationField;
