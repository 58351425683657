import React, {useEffect, useState} from 'react';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../../common/useEmotionStyles';
import {MenuItem, Typography} from '@mui/material';
import InputImmatriculationField from '../../../multiStepsForm/fields/InputImmatriculationField';
import InputSelectField from '../../../multiStepsForm/fields/InputSelectField';
import {IPartenaire} from '../../model/ModelPECBG';
import {FormikValues, useFormikContext} from 'formik';
import {
  kilometrageCases,
  lustrageCases,
  refReparateurCases,
  refReparateurMandatoryCases,
} from '../../model/ConstantsPECBG';
import {UserContext} from '../../../../../context/UserContext';
import InputTextField from '../../../multiStepsForm/fields/InputTextField';
import {IDommage} from '../../components/inputDommagesField/Models';

const styles = (theme: Theme) => ({
  cardContent: {
    backgroundColor: theme.palette.secondary.light,
    padding: '24px',
    borderRadius: '8px',
    marginBottom: '24px',
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap' as const,
    rowGap: '8px',
  },
  marginTop24: {
    marginTop: '24px',
  },
  dateTime: {
    width: '170px',
    marginRight: '24px',
  },
});

const InfosVehiculeBloc = () => {
  const classes = useEmotionStyles(styles);
  const {user} = React.useContext(UserContext);
  const {values}: FormikValues = useFormikContext();
  const [itemsAssureursPecBgList, setItemsAssureursPecBgList] = useState<React.ReactElement[]>([]);
  const [showReferenceReparateur, setShowReferenceReparateur] = useState(false);
  const assureurGTA = values.infosVehicule.assureurGTA;
  const agrementsBG = user.agrementsBG ?? [];

  useEffect(() => {
    const listItems = agrementsBG
        .filter((agrement) => agrement.codeGTA !== '')
        .map((item: IPartenaire) => {
          return (
            <MenuItem
              key={item.codeGTA}
              value={item.codeGTA.toUpperCase()}
            >
              {item.agrement.raisonSocialeMandant.toUpperCase()}
            </MenuItem>
          )
        });
    setItemsAssureursPecBgList(listItems);
  }, []);

  useEffect(() => {
    values.infosVehicule.codeAssureur = assureurGTA;
    values.infosVehicule.assureur = getAssureur(assureurGTA)?.agrement.raisonSocialeMandant;

    if (refReparateurCases.includes(assureurGTA)) {
      setShowReferenceReparateur(true);
    } else {
      setShowReferenceReparateur(false);
    }

    if (!lustrageCases.includes(assureurGTA)) {
      values.dommages.forEach((dommage: IDommage) => {
        if (dommage.typeIntervention === 'L') {
          dommage.typeIntervention = '';
          dommage.completed = false;
        }
      })
    }
  }, [assureurGTA]);

  const getAssureur = (codeGta: string) => {
    return agrementsBG.find((item) => item.codeGTA === codeGta);
  };

  const getOnlyNums = () => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      event.target.value = event.target.value.replace(/\D/g, '');
    };
  }

  return (
    <div className={classes.cardContent}>
      <Typography variant={'h3'}>Informations sur le v&eacute;hicule</Typography>
      <div className={classes.flex}>
        <InputSelectField
          name='infosVehicule.assureurGTA'
          label='Assureur'
          itemsList={itemsAssureursPecBgList}
          mandatory={true}
        />
        <InputImmatriculationField
          name='infosVehicule.immatriculation'
          label={'Numéro d\'immatriculation'}
          mandatory={true}
        />
        <InputTextField
          name='infosVehicule.numContrat'
          label='Numéro de contrat'
          mandatory={false}
          maxLength={16}
        />
      </div>

      <div className={`${classes.flex} ${classes.marginTop24}`}>
        <div>
          <InputTextField
            name='infosVehicule.kilometrage'
            label='Kilométrage'
            mandatory={kilometrageCases.includes(assureurGTA)}
            maxLength={7}
            onInput={getOnlyNums()}
          />
        </div>
        <div>
          <InputTextField
            className={classes.dateTime}
            name='infosVehicule.dateSurvenance'
            type='date'
            label='Date de survenance'
            mandatory={true}
          />
        </div>
        <div>
          <InputTextField
            className={classes.dateTime}
            name='infosVehicule.heureSurvenance'
            type='time'
            label='Heure de survenance'
            mandatory={true}
          />
        </div>
      </div>

      {showReferenceReparateur &&
        <div className={classes.marginTop24}>
          <InputTextField
            name='infosVehicule.refReparateur'
            label='Référence réparateur'
            maxLength={20}
            mandatory={refReparateurMandatoryCases.includes(assureurGTA)}
          />
        </div>
      }
    </div>
  );
};

export default InfosVehiculeBloc;
