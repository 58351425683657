import {AxiosResponse} from 'axios';
import {IDocument} from '@cyntler/react-doc-viewer';
import * as mime from 'react-native-mime-types';
import {TABLE_TYPES_FILES_OK} from '../../forms/utils/Utils';

export const getFileName = (axiosResponse: AxiosResponse) => {
  const contentDisposition: string = axiosResponse.headers['content-disposition']
  return contentDisposition.split(';')
      .find((n) => n.includes('filename='))!
      .replace('filename=', '')
      .trim()
}

export const getDocumentFromResponse = (axiosResponse: AxiosResponse): IDocument | undefined => {
  const fileName = getFileName(axiosResponse)
  const fileExtension = fileName.substring(fileName.lastIndexOf('.'))
  if (TABLE_TYPES_FILES_OK.includes(fileExtension)) {
    const fileTypeMime = mime.lookup(fileName)
    if (fileTypeMime) {
      const file = new Blob([axiosResponse.data], {type: fileTypeMime})
      return {
        uri: URL.createObjectURL(file),
        fileName: fileName,
      }
    }
  }
}
