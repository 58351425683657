import AxiosService from '../services/AxiosService';
import {dateToApiFormat} from '../common/utils/DateUtils';
import {EchangeSearchModel} from '../model/rechercheEchange/EchangeSearchModel';
import Cookies from 'js-cookie';
import {RadEchangeParams} from '../model/rechercheEchange/RadEchangeParams';

const baseUrl = window.extranet.globalConfig.apiUrl + '/api';
const axiosInstance = AxiosService.getAxiosClient();

/**
 * Recherche d'échanges
 *
 * @param {EchangeSearchModel} search - Objet contenant les infos sur le client (numClient, codeAbonne, codeService),
 * sur les paramètres choisis (état, action, dateDebut, dateFin)
 * et des infos supplémentaires (nbMaxElement, navigationOrder, codePagination, isnReprise)
 *
 * @return {Promise} response data
 *
 */
export async function getEchanges(search: EchangeSearchModel) {
  const response = await axiosInstance(`${baseUrl}/echanges`,
      {
        params: {
          codeAbonne: search?.codeAbo,
          codeService: search?.codeService,
          numClient: search?.numClient,
          etat: search?.etat,
          numEchange: search?.numEchange,
          dateDebut: dateToApiFormat(search?.dateDebut ?? ''),
          dateFin: dateToApiFormat(search?.dateFin ?? ''),
          action: search?.action,
          nbMaxElement: search?.nbMaxElement,
          navigationOrder: search?.navigationOrder,
          codePagination: search?.codePagination,
          isnReprise: search?.isnReprise,
        },
      });
  return response.data
}

/**
 * Récupération de la liste des documents par type et sous-type pour un échange
 *
 * @param {String} codeService - Code service
 * @param {String} idEchange - id de l'échange recherché
 *
 * @return {Promise} response data
 *
 */
export async function getEchangeDocumentsParType(codeService:string, idEchange: string) {
  const response = await axiosInstance(`${baseUrl}/echanges/documentsParType`,
      {
        params: {
          codeService,
          idEchange,
        },
      });
  return response.data
}

/**
 * Récupération de la liste des anomalies pour un échange
 *
 * @param {String} codeService - Code service
 * @param {String} idEchange - id de l'échange recherché
 *
 * @return {Promise} response data
 *
 */
export async function getEchangeAnomalies(codeService:string, idEchange: string) {
  const response = await axiosInstance(`${baseUrl}/echanges/anomalies`,
      {
        params: {
          codeService,
          idEchange,
        },
      });
  return response.data
}

/**
 * Récupération du détail d'un type de document pour un échange
 *
 * @param {String} codeService - Code service
 * @param {String} idEchange - id de l'échange recherché
 * @param {String} typeDoc - type du document
 * @param {String} ssTypeDoc - sous type du document
 *
 * @return {Promise} response data
 *
 */
export async function getEchangeDocumentsParTypeParPartenaire(codeService:string,
    idEchange: string,
    typeDoc: string,
    ssTypeDoc: string) {
  const response = await axiosInstance(`${baseUrl}/echanges/documentsParTypeParPartenaire`,
      {
        params: {
          codeService,
          idEchange,
          typeDoc,
          ssTypeDoc,
        },
      });
  return response.data
}

/**
 * Remise à disposition de plusieurs échanges
 *
 * @param {Object} data[] - Objet contenant les données nécessaires à la RAD des échanges
 * @return {Promise}
 */
export function postEchangesRAD(data: RadEchangeParams[]) {
  return axiosInstance.post(`${baseUrl}/remisesAdispositionEchanges`, data,
      {
        headers: {
          'Authorization': `Bearer ${Cookies.get('token')}`,
        },
      },
  )
}
