import React, {useState} from 'react';
import {MessageBubble} from '../../../common/MessageBubble';
import {
  InfoReparation,
  InformationMoodel,
  SollicitationModel,
} from '../../../../model/detailsDossier/SuiviReparationModel';
import {Typography} from '@mui/material';
import {customTheme} from '../../../../common/GlobalTheme';
import {ReactComponent as ReplyIcon} from '../../../../images/icones/icn-reply-arrow.svg'
import moment from 'moment';
import useEmotionStyles from '../../../../common/useEmotionStyles';

interface InformationProps {
    indexBubble?: number,
    information: InformationMoodel
    itemSelected: boolean
    sollicitation?: SollicitationModel
    commentaires: string
    onClickComment: (tabPanelValue: number, date: string) => void
    onClickPj: (tabPanelValue: number, idPJ: string) => void,
}

interface ContentProps {
    information: InformationMoodel
    sollicitation?: SollicitationModel
    commentaires: string
    onClickComment: (tabPanelValue: number, date: string) => void
    onClickPj: (tabPanelValue: number, idPJ: string) => void,
}

/** Design */
const styles = () => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '12px',
  },
  card: {
    width: '100%',
    display: 'flex',
    backgroundColor: 'white',
    padding: '12px',
    borderRadius: '8px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    color: '#0095db',
    padding: '0px 12px',
  },
  divClick: {
    display: 'flex',
    cursor: 'pointer',
    width: 'max-content',
    marginLeft: '12px',
    marginTop: '12px',
  },
});

const BlocSollicitation = (props: { sollicitation: SollicitationModel }) => {
  const classes = useEmotionStyles(styles);
  const {sollicitation} = props;
  return (
    <div className={classes.container}>
      <div className={classes.card}>
        <div style={{margin: '8px 20px 0px 8px'}}>
          <ReplyIcon/>
        </div>
        <div>
          <Typography style={customTheme.styledTypography.libelle}>
            {sollicitation.infoSollicitation.length > 1 ?
            `Motifs de la sollicitation (${sollicitation.infoSollicitation.length})` :
            'Motif de la sollicitation'}
          </Typography>
          <Typography style={customTheme.styledTypography.donneeGrise16}>
            {sollicitation.infoSollicitation[0].motif}
          </Typography>
        </div>
      </div>
    </div>
  )
};

const Typo = (props: { libelle: string, valeur: string }) => {
  return (
    <Typography>
      <span style={customTheme.styledTypography.libelle}> {props.libelle} : </span>
      <span style={{...customTheme.styledTypography.donneeGrise14, fontWeight: 'bold'}}>
        {props.valeur}
      </span>
    </Typography>
  )
};

const BlocDatePrevisionnelles = (props: { infoReparation: InfoReparation }) => {
  const {infoReparation} = props;
  return (
    <div>
      {(!!infoReparation.finTravaux || !!infoReparation.debutTravaux || !!infoReparation.duree ||
         !!infoReparation.miseADispoVehicule || !!infoReparation.dateRemise) &&
        <Typography style={customTheme.styledTypography.libelle}>
            Dates prévisionnelles des travaux :
        </Typography>
      }
      {!!infoReparation.finTravaux &&
        <Typo
          libelle={'Fin'}
          valeur={moment(infoReparation.finTravaux).format('DD/MM/YYYY')}
        />
      }
      {!!infoReparation.debutTravaux &&
        <Typo
          libelle={'Début'}
          valeur={moment(infoReparation.debutTravaux).format('DD/MM/YYYY')}
        />
      }
      {!!infoReparation.duree &&
        <Typo
          libelle={'Durée'}
          valeur={`${infoReparation.duree} ${infoReparation.duree > 1 ? 'jours' : 'jour'}`}
        />
      }
      {!!infoReparation.miseADispoVehicule &&
        <Typo
          libelle={'Mise à disposition du véhicule'}
          valeur={moment(infoReparation.miseADispoVehicule).format('DD/MM/YYYY')}
        />
      }
      {!!infoReparation.dateRemise &&
        <Typo
          libelle={'Remise du véhicule'}
          valeur={moment(infoReparation.dateRemise).format('DD/MM/YYYY')}
        />
      }
    </div>
  )
};

const BlocSuivi = (props: { infoReparation: InfoReparation }) => {
  const {infoReparation} = props;
  return (
    <div>
      <Typography style={customTheme.styledTypography.libelle}>
        Suivi des réparations
      </Typography>
      <Typography style={customTheme.styledTypography.donneeGrise16}>
        {infoReparation?.motif}
      </Typography>
      {infoReparation?.informationComplementaire &&
        <div>
          <Typography style={customTheme.styledTypography.libelle}>
            Informations complémentaires
          </Typography>
          <Typography style={customTheme.styledTypography.donneeGrise16}>
            {infoReparation.informationComplementaire}
          </Typography>
        </div>
      }
    </div>
  )
};

const BlocCommentaires = (props: { commentaires: string }) => {
  const {commentaires} = props;
  return (
    <div>
      <Typography style={customTheme.styledTypography.libelle}>Commentaires</Typography>
      <Typography style={customTheme.styledTypography.donneeGrise16}>
        {commentaires}
      </Typography>
    </div>
  )
};

const Content = (props: ContentProps) => {
  const classes = useEmotionStyles(styles);
  const {information, sollicitation, commentaires, onClickComment, onClickPj} = props;
  const {idDoc, envoyeRecu, date, listeSuiviRep, nbPieceJointe} = information;

  const [expanded, setExpanded] = useState<boolean>(false);
  const expandable = listeSuiviRep.some((suivi) => {
    return !!suivi.debutTravaux || !!suivi.finTravaux || !!suivi.duree || !!suivi.miseADispoVehicule ||
      !!suivi.dateRemise || !!commentaires
  });

  const showButtonComment = commentaires && commentaires.length > 202;
  const showBlocComment = commentaires && commentaires.length <= 202;

  const handleClickComment = () => {
    const {emetteurCodeAbonne, destinataireCodeAbonne, typeDoc} = information;
    const codeAbonneActeur = `${envoyeRecu === 'recu' ? emetteurCodeAbonne.trim() : destinataireCodeAbonne.trim()}`;
    const identifiant = `${date}${typeDoc}${codeAbonneActeur}`;
    onClickComment(2, identifiant)
  };
  return (
    <>
      {sollicitation?.infoSollicitation && sollicitation.infoSollicitation.length > 0 &&
        <BlocSollicitation sollicitation={sollicitation}/>
      }
      <div style={{marginLeft: '16px'}}>
        <BlocSuivi infoReparation={listeSuiviRep[0]}/>
        {expandable && expanded &&
          <div style={{marginTop: '16px'}}>
            <BlocDatePrevisionnelles infoReparation={listeSuiviRep[0]}/>
          </div>
        }
      </div>
      {expandable && expanded &&
        <div style={{marginLeft: '16px'}}>
          {listeSuiviRep.slice(1).map((info, index) => {
            const keyDiv = `${index}`
            return (
              <div key={keyDiv} style={{marginLeft: '16px', padding: '12px 12px 0px'}}>
                <div style={{marginTop: '16px'}}>
                  <BlocSuivi infoReparation={info}/>
                </div>
                <div style={{marginTop: '16px'}}>
                  <BlocDatePrevisionnelles infoReparation={info}/>
                </div>
              </div>
            )
          })}
          {showBlocComment &&
            <div style={{marginTop: '16px'}}>
              <BlocCommentaires commentaires={commentaires}/>
            </div>
          }
        </div>
      }
      {(expandable || nbPieceJointe > 0 || showButtonComment || showBlocComment) &&
        <div className={classes.footer}>
          {showButtonComment &&
            <div className={classes.divClick}>
              <Typography variant="button" display="inline" style={{fontSize: '14px'}} onClick={handleClickComment}>
                COMMENTAIRES
              </Typography>
            </div>
          }
          {nbPieceJointe > 0 &&
            <div className={classes.divClick}>
              <Typography variant="button" display="inline" style={{fontSize: '14px'}}
                onClick={() => onClickPj(3, idDoc)}
              >
                PIECES JOINTES
              </Typography>
            </div>
          }
          {(expandable || showBlocComment) && !expanded &&
            <div className={classes.divClick} onClick={() => setExpanded(true)} aria-hidden={true}>
              <Typography variant="button" display="inline" style={{fontSize: '14px'}}>
                VOIR PLUS
              </Typography>
            </div>
          }
          {(expandable || showBlocComment) && expanded &&
            <div className={classes.divClick} onClick={() => setExpanded(false)} aria-hidden={true}>
              <Typography variant="button" display="inline" style={{fontSize: '14px'}}>
                VOIR MOINS
              </Typography>
            </div>
          }
        </div>
      }
    </>
  )
};

const Information = (props: InformationProps) => {
  const {indexBubble, information, itemSelected} = props;
  const {libelleDoc, emetteur, destinataire, envoyeRecu, date} = information;
  const acteur = envoyeRecu === 'recu' ? 'De : '.concat(emetteur) : 'À : '.concat(destinataire);

  return (
    <div>
      <MessageBubble
        indexBubble={indexBubble}
        itemSelected={itemSelected}
        titleBold={acteur}
        subtitle={libelleDoc}
        contentBubble={<Content
          information={props.information}
          sollicitation={props.sollicitation}
          commentaires={props.commentaires}
          onClickComment={props.onClickComment}
          onClickPj={props.onClickPj}
        />}
        typeBubble={envoyeRecu}
        dateHeure={date.replace('-', 'à')}
      />
    </div>
  );
};

export default Information;
