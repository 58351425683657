import React from 'react';
import {Card, CardContent, Typography} from '@mui/material';
import {customTheme} from '../../../../common/GlobalTheme';
import useEmotionStyles from '../../../../common/useEmotionStyles';

const styles = () => ({
  card: {
    display: 'flex',
    height: '110px',
    borderColor: customTheme.palette.secondary.dark1,
    boxShadow: 'none',
    ['@media (max-width:600px)']: {
      height: '100px',
    },
  },
  cardContent: {
    width: '100%',
  },
  header: {
    display: 'flex',
    height: '30%',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  footer: {
    display: 'flex',
    height: '70%',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  chip: {
    display: 'inline-block',
    minWidth: 'fit-content',
    padding: '5px',
    marginRight: '12px',
    fontWeight: 'bold',
    lineHeight: '1',
    borderRadius: '5px',
    backgroundColor: customTheme.palette.secondary.main,
  },
  libelle: {
    ['@media (max-width:1100px)']: {
      fontSize: '0.75rem',
    },
    ['@media (max-width:600px)']: {
      fontSize: '0.6rem',
    },
  },
  nbOccur: {
    ['@media (max-width:1100px)']: {
      fontSize: '25px',
    },
    ['@media (max-width:600px)']: {
      fontSize: '20px',
    },
  },
});

export interface IndicateurModel {
    docType : string,
    docTypeLibelle : string,
    nombreOccurences : number,
}

export const IndicateurCard = (indicateurProps: IndicateurModel) => {
  const classes = useEmotionStyles(styles);
  const {docType, docTypeLibelle, nombreOccurences} = indicateurProps;
  return (
    <Card id={`document-${docType}`} className={classes.card}>
      <CardContent className={classes.cardContent}>
        <div className={classes.header}>
          <Typography variant='caption' className={classes.chip}>{docType}</Typography>
          <Typography className={classes.libelle}>{docTypeLibelle}</Typography>
        </div>
        <div className={classes.footer}>
          <Typography id={'nombre-documents'} variant='h1' className={classes.nbOccur}>{nombreOccurences}</Typography>
        </div>
      </CardContent>
    </Card>
  );
};
