import React from 'react';
import markerMapIcon from '../../../../images/icones/icones-map/icn-marker-map.svg';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';

type StylesProps = { isNumberSup9?: boolean, isSelected?: boolean }
const styles = (theme: Theme, props: StylesProps) => ({
  containerMarker: {
    position: 'relative' as const,
    width: 'fit-content',
  },
  numeroReparateur: {
    position: 'absolute' as const,
    color: theme?.palette.white.main,
    fontFamily: theme?.fonts.main,
    fontWeight: 'bold',
  },
  numeroReparateurOnCard: {
    top: '3px',
    left: props.isNumberSup9 ? '4px' : '9px',
  },
  image: {
    height: 'auto',
    width: '25px',
    filter: props.isSelected ?
      'invert(11%) sepia(38%) saturate(1697%) hue-rotate(219deg) brightness(95%) contrast(96%)' :
      'invert(42%) sepia(22%) saturate(4004%) hue-rotate(345deg) brightness(98%) contrast(88%)',
  },
});

export const CustomMarker = ({numero, isSelected}: {numero: string, isSelected: boolean}) => {
  const isNumberSup9 = Number(numero) > 9;
  const classes = useEmotionStyles(styles, {isNumberSup9, isSelected});

  return (
    <div className={classes.containerMarker} id='marker-${numero}'>
      <img className={classes.image} src={markerMapIcon} alt={'icon'}/>
      <div className={`${classes.numeroReparateur} ${classes.numeroReparateurOnCard}`}>{numero}</div>
    </div>
  )
};
