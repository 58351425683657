import {Theme} from '@emotion/react';

export const stylesCommon = (theme: Theme) => ({
  title: {
    marginBottom: '8px',
  },
  card: {
    backgroundColor: theme.palette.secondary.light,
    padding: '16px',
    borderRadius: '8px',
    marginBottom: '16px',
  },
  cardContent: {
    backgroundColor: theme.palette.secondary.main,
    padding: '16px',
    borderRadius: '8px',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    rowGap: '16px',
  },
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  gridElement: {
    display: 'grid',
    gridTemplateColumns: '75px 1fr',
    marginBottom: '16px',
  },
  addressElement: {
    marginBottom: '8px',
  },
  marginBottom16: {
    marginBottom: '16px',
  },
});
