import AxiosService from '../services/AxiosService';
import Cookies from 'js-cookie';

const baseUrl = window.extranet.globalConfig.apiUrl + '/api';
const axiosInstance = AxiosService.getAxiosClient();

/**
 * Retourne la liste des valeurs de codes pour une DT (Donnée Technique)
 *
 * @param {String} domain - Nom du domaine dans lequel on recherche la donnée
 * @param {String} type - Type de la donnée
 * @return {Promise} response data
 *
 * @example
 *     getValeursCodes('AR2', '91030101')
 */
export async function getValeursCodes(domain: string, type: string) {
  const response = await axiosInstance(`${baseUrl}/valeursCode`,
      {
        headers: {
          'Authorization': `Bearer ${Cookies.get('token')}`,
        },
        params: {
          domain: domain,
          type: type,
        },
      });
  return response.data
}

/**
 * Retourne la liste des documents disponibles pour la recherche de documents
 *
 * @param {String} typeActeur - Type du user connecté
 * @return {Promise} response data
 *
 * @example
 *     getDocumentsRecherchables('assureur')
 */
export async function getDocumentsRecherchables(typeActeur: string) {
  const response = await axiosInstance(`${baseUrl}/documentsRecherchables`,
      {
        params: {
          typeActeur: typeActeur,
        },
      });
  return response.data
}

/**
 * Retourne la liste des sous documents disponibles associé à un document pour la recherche de documents
 *
 * @param {String} typeActeur - Type du user connecté
 * @param {String} codeDocument - Code du document
 * @return {Promise} response data
 *
 * @example
 *     getSousDocumentsRecherchables('assureur')
 */
export async function getSousDocumentsRecherchables(typeActeur: string, codeDocument: string) {
  const response = await axiosInstance(`${baseUrl}/sousDocumentsRecherchables`,
      {
        params: {
          typeActeur: typeActeur,
          codeDocument: codeDocument,
        },
      });
  return response.data
}

/**
 * Retourne la liste des états disponibles pour la recherche d'échanges
 *
 * @return {Promise} response data
 *
 */
export async function getEtatsEchangeRecherchables() {
  const response = await axiosInstance(`${baseUrl}/etatsEchangeRecherchables`);
  return response.data
}

/**
 * Retourne la liste des actions disponibles pour la recherche d'échanges
 *
 * @return {Promise} response data
 *
 */
export async function getActionsEchangeRecherchables() {
  const response = await axiosInstance(`${baseUrl}/actionsEchangeRecherchables`);
  return response.data
}
