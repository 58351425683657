import React from 'react';
import {DocParTypeParPartenaire} from '../../../../model/rechercheEchange/DocParTypeParPartenaire';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';

interface IProps {
  docs: DocParTypeParPartenaire,
  txt: string
}

const styles = (theme: Theme) => ({
  number: {
    display: 'inline-block',
    width: '5%',
  },
  text: {
    display: 'inline-block',
    width: '20%',
  },
  partner: {
    display: 'inline-block',
    width: '70%',
    textAlign: 'end' as const,
  },
  line: {
    paddingBottom: '16px',
  },
});

export const ListeEchangesPartenairesDocs = (props: IProps) => {
  const classes = useEmotionStyles(styles);

  return (<div className={classes.line}>
    <div className={classes.number}>{props.docs.nbDocs}</div>
    <div className={classes.text}>{props.txt}</div>
    <div className={classes.partner}>{props.docs.raisonSociale} ({props.docs.codeAbonne})</div>
  </div>)
}
