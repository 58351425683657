import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {getDetailRendezVous, getIdigoParamValues} from '../../../../api/idigo';
import {getValeursCodes} from '../../../../api/norme';
import {Loader} from '../../../common/Loader';
import {ButtonLink} from '../../../common/buttons/ButtonLink';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useNavigate} from 'react-router-dom';
import {emptyMarque, InformationAssure, InformationsRdv, RdvModel} from '../../../../model/priseRDV/RDVModel';
import {DetailRdvDTO} from '../../../../model/rechercherRendezVous/SearchRdvModel';
import {ErrorFields} from '../../priseRDV/PriseRDV';
import {ItemGetValeursCodesModel} from '../../../../model/common/ItemGetValeursCodesModel';
import {PartenaireProps} from '../../../../model/priseRDV/PartenaireModel';
import {permittedRolesMenuRDV, permittedRolesRDV} from '../../../../constantes/roles/Roles';
import useHasRole from '../../../../hook/useHasRole';
import {UserContext} from '../../../../context/UserContext';
import AccessDenied from '../../../common/errorsComponents/AccessDenied';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {BlocInfosRdv} from '../../priseRDV/recapitulatif/components/BlocInfosRdv';
import {IdigoParameterValueModel} from '../../../../model/priseRDV/IdigoParameterValueModel';
import {BlocInfosAssure} from '../../priseRDV/recapitulatif/components/BlocInfosAssure';
import {BlocInfosPartenaire} from '../../priseRDV/recapitulatif/components/BlocInfosPartenaire';
import {emptyHoraire} from '../../../../model/priseRDV/HoraireModel';
import {BlocRendezVous} from '../../priseRDV/recapitulatif/components/BlocRendezVous';
import {BlocLocalisation} from '../../priseRDV/recapitulatif/components/BlocLocalisation';
import {LatLngExpression} from 'leaflet';
import {isAssureurOrExpertOrCarrossier} from '../../../../common/utils/UserTypeUtils';
import {CommonRecapitulatif} from '../../CommonRecapitulatif';

const base64 = require('base-64');

export interface ParamsDetailRdv {
  params: string
}

const styles = () => ({
  container: {
    width: '95%',
    margin: '2% auto',
  },
  flex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px',
  },
});

const informationsRdv = (referenceSinistre: string, detailRdvDTO: DetailRdvDTO | undefined,
    sousActivitesList: IdigoParameterValueModel[]): InformationsRdv => {
  return {
    referenceSinistre: referenceSinistre,
    typeRdv: {
      code: '',
      libelle: detailRdvDTO?.rendezVous?.motif ?? '',
    },
    sousActivite: {
      code: detailRdvDTO?.activite?.activiteSousType ?? '',
      libelle: sousActivitesList.find((ssAct) =>
        ssAct.code === detailRdvDTO?.activite?.activiteSousType)?.libelle ?? '',
    },
  }
};

const infosPartenaireRecap = (detailRdvDTO: DetailRdvDTO, isToutesMarques: boolean, marquesReparees: string[]) => {
  return {
    raisonSociale: detailRdvDTO?.partenaire.raisonSociale.nom ?? '',
    adresse: detailRdvDTO?.partenaire.adresse ?? {},
    coordonnees: detailRdvDTO?.partenaire.coordonnees.telephone ??
        detailRdvDTO?.partenaire.coordonnees.portable ??
        detailRdvDTO?.partenaire.coordonnees.email ?? '',
    isToutesMarques: isToutesMarques,
    marquesReparees: marquesReparees ?? [],
    conges: detailRdvDTO?.partenaire.conges?.periodes ?? [],
    horaires: detailRdvDTO?.partenaire.horaires ?? {...emptyHoraire},
  }
}

const DetailRDV = () => {
  const classes = useEmotionStyles(styles);
  const navigate = useNavigate();
  const {user} = React.useContext(UserContext);
  const hasRoleConsultationRDV = useHasRole(permittedRolesMenuRDV) && isAssureurOrExpertOrCarrossier(user.type);
  const hasRoleModificationRDV = useHasRole(permittedRolesRDV);
  const paramsDetailRdv = useParams<keyof ParamsDetailRdv>() as ParamsDetailRdv;
  const params = JSON.parse(base64.decode(paramsDetailRdv.params));
  const {referenceSinistre, idRdv, idPartenaire, rdvActif} = params;
  const [detailRdvDTO, setDetailRdvDTO] = useState<DetailRdvDTO>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [codesValeursMarque, setCodesValeursMarque] = useState<ItemGetValeursCodesModel[]>([]);
  const [sousActivitesList, setSousActivitesList] = useState<IdigoParameterValueModel[]>([]);
  const [errorFields, setErrorFields] = useState<ErrorFields>([]);

  useEffect(() => {
    getValeursCodes('AR2', '90280203').then((result) => setCodesValeursMarque(result));
    getIdigoParamValues('SousActivites').then((res: IdigoParameterValueModel[]) => {
      const sousActivitesAtelierReparation = res.filter((ssAct) => ssAct.subCode === 'AtelierReparation');
      setSousActivitesList(sousActivitesAtelierReparation);
    }).catch((e) => {
      console.error(e.response.status);
    });
    const {numClient, userCompte} = user;
    getDetailRendezVous(idRdv, idPartenaire, numClient, userCompte).then((response) => {
      setDetailRdvDTO(response);
    }).catch((e) => {
      console.error(e);
    }).finally(() => {
      setIsLoading(false)
    })
  }, []);

  const activite = detailRdvDTO?.partenaire.activites?.length && detailRdvDTO.partenaire.activites[0];
  const marquesReparees = activite ? activite.marquesReparees : [];
  const isToutesMarques = !!(marquesReparees?.includes('Toutes marques (TTESMARQUES)'));

  const marque = () => {
    const marqueRdv = {...emptyMarque};
    if (detailRdvDTO?.rendezVous.dommages.marqueVehicule && codesValeursMarque.length > 0) {
      const marqueDT = codesValeursMarque.find((marque) =>
        marque.valeur.toUpperCase().trim() === detailRdvDTO.rendezVous.dommages.marqueVehicule.toUpperCase().trim());
      if (marqueDT) {
        marqueRdv.valeur = marqueDT.valeur.toUpperCase().trim();
        marqueRdv.libelleedition = marqueDT.libelleedition.trim();
      }
    }
    return marqueRdv;
  };

  const onChangeCreneau = () => {
    const motif = detailRdvDTO?.rendezVous?.motif ?? '';
    const nom = detailRdvDTO?.rendezVous?.personne?.nom ?? '';
    const prenom = detailRdvDTO?.rendezVous?.personne?.prenom ?? '';
    const email = detailRdvDTO?.rendezVous?.personne?.coordonnees?.email;
    const portable = detailRdvDTO?.rendezVous?.personne?.coordonnees?.portable;
    const modeleVehicule = detailRdvDTO?.rendezVous?.dommages?.modeleVehicule ?? '';
    const immatriculation = detailRdvDTO?.rendezVous?.dommages?.immatriculation ?? '';

    const infosRdv: InformationsRdv = {
      referenceSinistre: referenceSinistre,
      typeRdv: {
        code: '',
        libelle: motif,
      },
      sousActivite: informationsRdv(referenceSinistre, detailRdvDTO, sousActivitesList).sousActivite,
    };

    const infoAssure: InformationAssure = {
      prenom: prenom,
      nom: nom,
      telephone: portable?.replace('+33', '0') ?? '',
      email: email,
      immatriculation: immatriculation,
      marque: marque(),
      modele: modeleVehicule,
      coordonneesGPS: {
        nom: '',
        latitude: 0,
        longitude: 0,
      },
      adresse: {
        nom: '',
        numero: '',
        voie: '',
        codePostal: '',
        commune: '',
      },
    };

    const raisonSociale = detailRdvDTO?.partenaire?.raisonSociale!;
    const numeroTva = detailRdvDTO?.partenaire?.numeroTva ?? '';
    const numeroSiret = detailRdvDTO?.partenaire?.numeroSiret ?? '';
    const numeroClient = detailRdvDTO?.partenaire?.numeroClient ?? '';
    const statut = detailRdvDTO?.partenaire?.statut ?? '';
    const coordonnees = detailRdvDTO?.partenaire?.coordonnees!;
    const coordonneesAdresse = detailRdvDTO?.partenaire?.coordonneesAdresse!;
    const dateModification = detailRdvDTO?.partenaire?.dateModification ?? '';
    const horaires = detailRdvDTO?.partenaire?.horaires!;
    const conges = detailRdvDTO?.partenaire?.conges!;
    const activites = detailRdvDTO?.partenaire?.activites!;
    const adresse = detailRdvDTO?.partenaire?.adresse!;

    const partenaire: PartenaireProps =
     {
       id: idPartenaire,
       raisonSociale: raisonSociale,
       numeroTva: numeroTva,
       numeroSiret: numeroSiret,
       numeroClient: numeroClient,
       statut: statut,
       coordonnees: coordonnees,
       coordonneesAdresse: coordonneesAdresse,
       dateModification: dateModification,
       distance: 0,
       horaires: horaires,
       conges: conges,
       activites: activites,
       adresse: adresse,
     };
    const rdvModel: RdvModel = {
      informationsRdv: infosRdv,
      informationsAssure: infoAssure,
      partenaire: {
        infosPartenaire: partenaire,
        nextDispo: {
          dateDebut: '',
          dateFin: '',
          idExpertList: [],
        },
      },
      creneau: '',
      duree: {
        libelle: '',
        valeur: 0,
      },
      idRdv: detailRdvDTO?.rendezVous.id,
    };
    sessionStorage.setItem('detailRdv', JSON.stringify(rdvModel));
    navigate('/extranet/dossiers/prise-rdv/modification-rdv');
  };

  const infosAssureRecap: InformationAssure = {
    prenom: detailRdvDTO?.rendezVous.personne.prenom ?? '',
    nom: detailRdvDTO?.rendezVous.personne.nom ?? '',
    telephone: detailRdvDTO?.rendezVous.personne.coordonnees?.portable?.replace('+33', '0') ??
      detailRdvDTO?.rendezVous.personne.coordonnees?.telephone ?? '',
    email: detailRdvDTO?.rendezVous.personne.coordonnees?.email ?? '',
    immatriculation: detailRdvDTO?.rendezVous.dommages.immatriculation ?? '',
    marque: marque(),
    modele: detailRdvDTO?.rendezVous.dommages.modeleVehicule ?? '',
    coordonneesGPS: {
      nom: '',
      latitude: 0,
      longitude: 0,
    },
    adresse: {
      nom: '',
      numero: '',
      voie: '',
      codePostal: '',
      commune: '',
    },
  };

  const positionPartenaire: LatLngExpression = [
    detailRdvDTO?.partenaire.coordonneesAdresse.lat ?? 0, detailRdvDTO?.partenaire.coordonneesAdresse.lng ?? 0,
  ];

  return (
    <>
      {hasRoleConsultationRDV &&
        <div className={classes.container}>
          {isLoading && <div className={classes.flex}><Loader/></div>}
          {!isLoading && detailRdvDTO &&
            <div>
              <div style={{marginBottom: '24px'}}>
                <ButtonLink
                  id={'boutonRetourRdv'}
                  icon={<ArrowBackIcon/>}
                  libelle={'Retour'}
                  onClick={() => navigate(`/extranet/dossiers/prise-rdv/rechercher-rdv`)}
                />
              </div>

              <CommonRecapitulatif
                blocInfosRdv={<BlocInfosRdv informationsRdv={
                  informationsRdv(referenceSinistre, detailRdvDTO, sousActivitesList)
                }/>}
                blocInfosAssure={detailRdvDTO?.rendezVous?.personne ?
                  <BlocInfosAssure
                    from={'detailRdv'}
                    infosAssure={infosAssureRecap}
                    idRdv={detailRdvDTO.rendezVous.id}
                    idPartenaire={detailRdvDTO.partenaire.id}
                    idActivite={detailRdvDTO.activite.idActivite}
                    rdvActif={rdvActif}
                    codesValeursMarque={codesValeursMarque}
                    hasRoleModificationRDV={hasRoleModificationRDV}
                    errorFields={errorFields}
                    setErrorFields={setErrorFields}
                  /> : <></>
                }
                blocLocalisation={detailRdvDTO?.partenaire?.coordonneesAdresse ?
                  <BlocLocalisation
                    isUpdateRdv={true}
                    zoom={12}
                    center={positionPartenaire}
                    positionMarkerPartenaire={positionPartenaire}
                  /> : <></>
                }
                blocRendezVous={detailRdvDTO?.rendezVous?.dateDebut ?
                  <BlocRendezVous
                    dateDebut={detailRdvDTO.rendezVous.dateDebut}
                    dateFin={detailRdvDTO.rendezVous.dateFin}
                    hasRoleModificationRDV={hasRoleModificationRDV}
                    rdvActif={rdvActif}
                    onChangeCreneau={onChangeCreneau}
                  /> : <></>
                }
                blocInfosPartenaire={<BlocInfosPartenaire
                  isUpdateRdv={true}
                  data={infosPartenaireRecap(detailRdvDTO, isToutesMarques, marquesReparees)}
                />}
              />
            </div>
          }
        </div>
      }
      {!hasRoleConsultationRDV &&
        <AccessDenied
          message={'Ce compte ne vous permet pas d\'accéder au détail rendez-vous'}
          isReturnButton={true}
          buttonLabel={'Retour'}
          onClickButton={() => navigate('/extranet/portail-sinapps-auto')}
        />
      }
    </>
  );
};

export default DetailRDV;
