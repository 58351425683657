import {MenuItem, Typography} from '@mui/material';
import {TextFieldSelect} from '../formsComponents/TextFieldSelect';
import React, {useEffect, useState} from 'react';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';
import {ItemGetValeursCodesModel} from '../../../model/common/ItemGetValeursCodesModel';
import {getValeursCodes} from '../../../api/norme';
import {CommonProps} from './CommonProps';

const styles = (theme: Theme) => ({
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  red: {
    color: 'red',
  },
});

export const Pays = (props: CommonProps) => {
  const {className, name, formulaire, value, onChangeValue, onBlur, onFocus, onError, helperText} = props;
  const classes = useEmotionStyles(styles);

  const [paysList, setPaysList] = useState([]);

  useEffect(() => {
    getValeursCodes('SIV', '91090101').then((response) => setPaysList(response));
  }, []);

  const paysListSort = [...paysList].sort((a: ItemGetValeursCodesModel, b: ItemGetValeursCodesModel) => {
    if (a.libelleedition > b.libelleedition) {
      return 1
    } else {
      return -1
    }
  });
  const itemsListPays = paysListSort.map((pays: ItemGetValeursCodesModel) => {
    return (
      <MenuItem key={pays.valeur} value={pays.uppercase}>{pays.uppercase}</MenuItem>
    )
  });

  return (
    <div className={className}>
      <Typography className={classes.libelle}>
        Pays <span className={classes.red}>*</span>
      </Typography>

      <TextFieldSelect
        id={'pays' + formulaire}
        name={name}
        fullWidth={true}
        marginDense={true}
        value={value}
        onChange={onChangeValue}
        onBlur={onBlur}
        onFocus={onFocus}
        onError={onError}
        helperText={helperText}
        itemsList={itemsListPays}
        autoComplete="on"
      />
    </div>
  )
};
