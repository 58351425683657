import React, {useEffect, useState} from 'react';
import {Stepper, Step, StepButton, Typography} from '@mui/material';
import {useParams} from 'react-router-dom'
import {RechercherReparateur} from './rechercherReparateur/RechercherReparateur';
import {getIdigoParamValues, postRendezVous, updateRendezvous} from '../../../api/idigo';
import {getCoordonneesGPS} from '../../../api/infogeo';
import {getValeursCodes} from '../../../api/norme';
import {UserContext} from '../../../context/UserContext';
import Informations from './informations/Informations';
import moment from 'moment';
import {Recapitulatif} from './recapitulatif/Recapitulatif';
import {RdvConfirme} from './confirmation/RdvConfirme';
import RdvNonConfirme from './confirmation/RdvNonConfirme';
import ChoisirCreneau from './choisirCreneau/ChoisirCreneau';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {ButtonLink} from '../../common/buttons/ButtonLink';
import {useNavigate} from 'react-router';
import ErrorAPI from '../../common/errorsComponents/ErrorAPI';
import {emptyItem, ItemGetValeursCodesModel} from '../../../model/common/ItemGetValeursCodesModel';
import {itemsTypeRDV} from './informations/InfoRdv';
import {AdresseAutoCompleteProps} from '../../../model/common/AdresseAutoCompleteModel';
import {partenaireInitialState, PartenaireProps} from '../../../model/priseRDV/PartenaireModel';
import {Duree, emptyMarque, IMarque, RdvModel} from '../../../model/priseRDV/RDVModel';
import {ActiviteProps} from '../../../model/priseRDV/ActiviteModel';
import {LocaliteProps} from '../../../model/priseRDV/LocaliteModel';
import {CoordonneesGPS} from '../../../model/priseRDV/CoordonneeGPSModel';
import {CreneauxDisponibiliteProps} from '../../../model/priseRDV/CreneauDisponibiliteModel';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';
import useHasRole from '../../../hook/useHasRole';
import {permittedRolesRDV} from '../../../constantes/roles/Roles';
import AccessDenied from '../../common/errorsComponents/AccessDenied';
import {IdigoParameterValueModel} from '../../../model/priseRDV/IdigoParameterValueModel';
import {IToken} from '../../../model/detailsDossier/TokenModel';
import {ID_BOUTON_RETOUR} from '../../../constantes/ids/Ids';

export interface ErrorField {
  filedName?: string
  errorMessage?: string
}

export interface ErrorFields extends Array<ErrorField> {
}

/** Design */
const styles = (theme: Theme) => ({
  linkBack: {
    marginLeft: '35%',
    [theme.breakpoints.down('md')]: {
      marginLeft: '20%',
    },
  },
  title: {
    margin: 'auto 2rem',
  },
  myStepper: {
    '& .MuiStepLabel-label': {
      color: theme.palette.primary.main,
    },
    '& .MuiPaper-root': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
});


const getSteps = () => {
  return [
    'Informations générales',
    'Rechercher un réparateur',
    'Choisir un créneau',
    'Récapitulatif & confirmation',
  ]
};

/* Refacto avec formulaire Yup et fonction "check" à supprimer et à reprendre de InputValuesUtils */
// eslint-disable-next-line max-len
const regexSpecialCharAccepted = /^[\w\sàáâãäåçèéêëìíîïðòóôõöùúûüýÿÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÐÒÓÔÕÖÙÚÛÜÝ+=/.,;*'$£€µ%§#@?øØ²~`«»¨´&(){}°^|!:[\]-]*$/;
const regex1RefSin = /^(.)\\1{9,17}/;
const regex2RefSin = /(.)\\1{9,17}$/;
const regex3RefSin = /^(.)\\1{8,17}$/;
export const checkRefSin = (refSin: string, isMandatory: boolean) => {
  let onError: boolean = isMandatory ? refSin.length === 0 : false;
  let typeError: string = '';
  if (refSin.length > 0 && refSin.length < 6) {
    onError = true;
    typeError = 'errorLength'
  } else if (regex1RefSin.test(refSin) || regex2RefSin.test(refSin) || regex3RefSin.test(refSin)) {
    onError = true;
    typeError = 'errorFormat'
  } else if (!regexSpecialCharAccepted.test(refSin)) {
    onError = true;
    typeError = 'errorChar'
  }
  return {onError: onError, typeError: typeError}
};
export const checkNom = (nom: string, isNomUsage: boolean) => {
  let onError: boolean = !isNomUsage ? nom.trim().length === 0 : false;
  let typeError: string = '';
  if (/[!?*°:€#;&]+/.test(nom)) {
    onError = true;
    typeError = 'errorChar'
  }
  return {onError: onError, typeError: typeError}
};
export const checkPrenom = (prenom: string) => {
  let onError: boolean = prenom.trim().length === 0;
  let typeError: string = '';
  if (/[!?*°:€#;&]+/.test(prenom)) {
    onError = true;
    typeError = 'errorChar'
  }
  return {onError: onError, typeError: typeError}
};
export const checkTelMobile = (inputTelephone: string, isMandatory: boolean) => {
  let onError: boolean = isMandatory ? inputTelephone.length === 0 : false;
  let typeError = isMandatory ? '' : null;
  if (inputTelephone.length && !(/(0[67])(?:\s?(\d{2})){4}/.test(inputTelephone))) {
    onError = true;
    typeError = 'errorChar'
  }
  return {onError: onError, typeError: typeError}
};
/**/

const initFromSessionStorage = (key: string) => {
  return sessionStorage.getItem(key) && JSON.parse(sessionStorage.getItem(key) ?? '')
}
export const PriseRdv = () => {
  const classes = useEmotionStyles(styles);
  const navigate = useNavigate();
  const urlParams = useParams<keyof IToken>() as IToken;
  const {user} = React.useContext(UserContext);
  const hasRolePriseRDV = useHasRole(permittedRolesRDV);
  const mission = initFromSessionStorage('mission');
  const detailRdv = initFromSessionStorage('detailRdv');
  const searchRdv = initFromSessionStorage('searchRdv');
  const isSearchRdvEqualsMission = searchRdv && mission ?
    searchRdv.referenceSinistre === mission.infoMissionDTO?.referenceSinistre : true;
  const societaire = mission?.societaireDTO;
  const vehicule = mission?.gestionDTO.vehicule;
  const telephoneSocietaire =
      societaire && isSearchRdvEqualsMission && societaire.telephoneMobile &&
      (societaire.telephoneMobile.startsWith('06') || societaire.telephoneMobile.startsWith('07')) ?
        societaire.telephoneMobile : '';
  const {adresse1, adresse2, adresse3, codePostal, localite} = societaire && isSearchRdvEqualsMission &&
  societaire.adresse || {};
  const adresseSocietaire = [adresse1, adresse2, adresse3]
      .filter(Boolean)
      .join(' ');
  const adresseSocietaireFullName = [adresse1, adresse2, adresse3, codePostal, localite]
      .filter(Boolean)
      .join(' ');

  const isFromDossier = sessionStorage.getItem('fromPath')?.includes('/extranet/dossiers/mission/');

  const marqueDossier = () => {
    if (vehicule?.marque && isSearchRdvEqualsMission) {
      return {
        valeur: '',
        libelleedition: vehicule.marque.trim(),
      }
    } else {
      return {...emptyMarque};
    }
  };

  const initialState = (): RdvModel => ({
    informationsRdv: {
      referenceSinistre: searchRdv?.referenceSinistre || mission?.infoMissionDTO?.referenceSinistre?.trim() || '',
      typeRdv: {
        code: '',
        libelle: '',
      },
      sousActivite: {
        code: '',
        libelle: '',
      },
    },
    informationsAssure: {
      prenom: '',
      nom: societaire && isSearchRdvEqualsMission && societaire.nom || '',
      telephone: telephoneSocietaire,
      email: societaire && isSearchRdvEqualsMission && societaire.email || '',
      immatriculation: mission && isSearchRdvEqualsMission && mission.infoMissionDTO?.immatriculation || '',
      marque: marqueDossier(),
      modele: vehicule && isSearchRdvEqualsMission && vehicule.modele || '',
      coordonneesGPS: {nom: '', latitude: 0, longitude: 0},
      adresse: {
        nom: adresseSocietaireFullName || '',
        commune: societaire && isSearchRdvEqualsMission && societaire.adresse?.localite || '',
        codePostal: societaire && isSearchRdvEqualsMission && societaire.adresse?.codePostal || '',
        voie: adresseSocietaire,
        numero: '',
      },
    },
    partenaire: {
      infosPartenaire: partenaireInitialState,
      nextDispo: {
        dateDebut: '',
        dateFin: '',
        idExpertList: [],
      },
    },
    creneau: '',
    duree: {
      valeur: 0,
      libelle: '',
    },
  });

  const [state, setState] = useState<RdvModel>(detailRdv ?? initialState);
  const [errorFields, setErrorFields] = useState<ErrorFields>([]);
  const [activeStep, setActiveStep] = useState(detailRdv ? 2 : 0);
  const [isRdvConfirmed, setIsRdvConfirmed] = useState(false);
  const [isRdvNotConfirmed, setIsRdvNotConfirmed] = useState(false);
  const [isLoadingConfirmation, setIsLoadingConfirmation] = useState(false);
  const [codesValeursMarque, setCodesValeursMarque] = useState<ItemGetValeursCodesModel[]>([]);
  const [selectedMarque, setSelectedMarque] = useState<ItemGetValeursCodesModel>(emptyItem);
  const [sousActivitesList, setSousActivitesList] = useState<IdigoParameterValueModel[]>([]);
  const [remarques, setRemarques] = useState('');
  const [errorApi, setErrorApi] = useState<boolean>(false);
  const [responseReturn, setResponseReturn] = useState(0);
  const disableSteps = detailRdv ? [0, 1] : [];
  const isUpdateRdv = !!detailRdv;

  useEffect(()=> {
    getValeursCodes('AR2', '90280203').then((result) => setCodesValeursMarque(result));
    getIdigoParamValues('SousActivites').then((res: IdigoParameterValueModel[]) => {
      const sousActivitesAtelierReparation = res.filter((ssAct) => ssAct.subCode === 'AtelierReparation');
      setSousActivitesList(sousActivitesAtelierReparation);
    }).catch((e) => {
      setErrorApi(e.response.status);
    })
  }, []);

  useEffect(() => {
    const defaultMarque = codesValeursMarque.find((item) =>
      item.libelleedition.toUpperCase().trim() === state.informationsAssure.marque?.libelleedition.toUpperCase());
    setSelectedMarque(defaultMarque ?? emptyItem);
    defaultMarque && setState({...state, informationsAssure: {...state.informationsAssure, marque: {
      valeur: defaultMarque.valeur.toUpperCase().trim(), libelleedition: defaultMarque.libelleedition.trim()}}})
  }, [codesValeursMarque]);

  const commonValidateFields = (errorRequiredFields: ErrorFields,
      entry: any, name: string, check: {onError: boolean, typeError: string | null}) => {
    if (entry[0] === name && check.onError) {
      errorRequiredFields.push({
        filedName: entry[0],
        errorMessage: check.typeError ?? '',
      })
    }
  }
  const commonSubValidateFields = (errorRequiredFields: ErrorFields,
      entry: any, name: string, errorMessage: string) => {
    if (entry[0] === name) {
      errorRequiredFields.push({filedName: name === 'codePostal' ? entry[0]: 'localite', errorMessage: errorMessage})
    }
  }
  const validateFields = () => {
    const {informationsRdv: {referenceSinistre, typeRdv, sousActivite}} = state;
    const {informationsAssure: {prenom, nom, telephone, email, adresse, immatriculation}} = state;

    let requiredFields: any = {
      referenceSinistre: referenceSinistre,
      typeRdv: typeRdv.code,
      prenom: prenom,
      nom: nom,
      codePostal: adresse.codePostal,
      sousActivite: sousActivite.code,
      immatriculation: immatriculation,
    };

    if (telephone?.length || email?.length) {
      if (telephone?.length) {
        requiredFields = {...requiredFields, telephone: telephone}
      }
      if (email?.length) {
        requiredFields = {...requiredFields, email: email}
      }
    } else {
      requiredFields = {...requiredFields, canauxCommunication: ''}
    }

    const errorRequiredFields: ErrorFields = [];
    Object.entries(requiredFields).forEach((entry: any) => {
      commonValidateFields(errorRequiredFields, entry, 'referenceSinistre', checkRefSin(entry[1], true))
      commonValidateFields(errorRequiredFields, entry, 'nom', checkNom(entry[1], false))
      commonValidateFields(errorRequiredFields, entry, 'prenom', checkPrenom(entry[1]))
      commonValidateFields(errorRequiredFields, entry, 'telephone', checkTelMobile(entry[1], false))
      if (errorRequiredFields.length == 0 && !entry[1].length) {
        commonSubValidateFields(errorRequiredFields, entry, 'canauxCommunication', 'Veuillez saisir une valeur')
        commonSubValidateFields(
            errorRequiredFields, entry, 'sousActivite', 'Veuillez sélectionner une prestation du réparateur')
        commonSubValidateFields(errorRequiredFields, entry, 'codePostal', 'Veuillez saisir une valeur')
        commonSubValidateFields(errorRequiredFields, entry, 'typeRdv', 'Veuillez sélectionner une valeur')
        if (errorRequiredFields.length == 0) {
          errorRequiredFields.push({filedName: entry[0], errorMessage: 'Veuillez saisir une valeur'})
        }
      }
    });
    setErrorFields(errorRequiredFields);
    return !errorRequiredFields.length
  };

  const handleNext = () => {
    if (validateFields()) {
      const {nom: nomAdresse, codePostal, commune} = state.informationsAssure.adresse;
      const termes = !nomAdresse ? commune.concat(' ', codePostal) : nomAdresse;
      getCoordonneesGPS(termes, user.numClient).then((response) => {
        const {data, status} = response;
        if (status === 200) {
          if (data?.latitude && data?.longitude) {
            setState({...state,
              informationsAssure: {...state.informationsAssure,
                coordonneesGPS: {nom: data.nom, latitude: data.latitude, longitude: data.longitude},
              },
            });
            setActiveStep((prevActiveStep) => prevActiveStep + 1)
          } else {
            setErrorFields([...errorFields,
              {filedName: 'adresse', errorMessage: 'Adresse invalide'},
            ])
          }
        } else {
          setErrorApi(true)
        }
      }).catch((error) => {
        console.error(error)
      })
    }
  };

  const steps: string[] = getSteps();

  const handleChange = (input: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (input === 'referenceSinistre') {
      setState({...state, informationsRdv: {...state.informationsRdv, [input]: event.target.value}})
    } else if (input === 'typeRdv') {
      setState({...state, informationsRdv: {...state.informationsRdv, typeRdv: {code: event.target.value,
        libelle: itemsTypeRDV.filter((type) => type.valeur === event.target.value)[0].libelle}}});
      setErrorFields([...errorFields.filter((error) => error.filedName != 'typeRdv')]);
    } else if (input === 'sousActivite') {
      setState({...state, informationsRdv: {...state.informationsRdv, sousActivite: {code: event.target.value,
        libelle: sousActivitesList.find((ssAct) => ssAct.code === event.target.value)?.libelle ?? ''}}});
      setErrorFields([...errorFields.filter((error) => error.filedName != 'sousActivite')]);
    } else {
      setState({...state, informationsAssure: {...state.informationsAssure, [input]: event.target.value}})
    }
  };

  const onChangePartenaire = (input: string, data: {infosPartenaire: PartenaireProps,
    nextDispo: CreneauxDisponibiliteProps}) => {
    setState({...state, [input]: data})
  };

  const payloadUpdateRDV = () => {
    return {
      motif: state.informationsRdv.typeRdv.libelle,
      personne: {
        nom: state.informationsAssure.nom,
        prenom: state.informationsAssure.prenom,
        coordonnees: {
          email: state.informationsAssure.email ?? '',
          portable: state.informationsAssure.telephone?.replaceAll(' ', '').replace('0', '+33') ?? '',
          telephoneAutorisation: !!state.informationsAssure.telephone?.length,
          emailAutorisation: !!state.informationsAssure.email?.length,
        },
        numeroSinistre: state.informationsRdv.referenceSinistre,
      },
      dommages: {
        marqueVehicule: state.informationsAssure.marque?.valeur ?? '',
        modeleVehicule: state.informationsAssure.modele ?? '',
      },
      creneau: {
        dateDebut: state.creneau,
        dateFin: moment(state.creneau).add(state.duree.valeur, 'minutes').toISOString(),
        duree: state.duree.valeur,
      },
    };
  }
  const payloadRDV = () => {
    const coordonnees = (() => {
      if (state.informationsAssure.email && state.informationsAssure.telephone) {
        return {
          email: state.informationsAssure.email,
          portable: state.informationsAssure.telephone?.replaceAll(' ', '').replace('0', '+33'),
        }
      } else if (state.informationsAssure.email) {
        return {
          email: state.informationsAssure.email,
        }
      } else {
        return {
          portable: state.informationsAssure.telephone?.replaceAll(' ', '').replace('0', '+33'),
        }
      }
    })();

    const motif = itemsTypeRDV.filter((type) => type.valeur === state.informationsRdv.typeRdv.code);

    const marqueVehicule = state.informationsAssure.marque?.valeur ?
        {marqueVehicule: state.informationsAssure.marque.valeur} : '';
    return {
      dateDebut: state.creneau,
      dateFin: moment(state.creneau).add(state.duree.valeur, 'minutes').toISOString(),
      motif: motif.length ? motif[0].lowercase : '',
      personne: {
        nom: state.informationsAssure.nom,
        prenom: state.informationsAssure.prenom,
        coordonnees: {
          ...coordonnees,
          telephoneAutorisation: !!state.informationsAssure.telephone?.length,
          emailAutorisation: !!state.informationsAssure.email?.length,
        },
        numeroSinistre: state.informationsRdv.referenceSinistre,
      },
      activite: {
        idActivite: state.partenaire.infosPartenaire.activites[0].idActivite,
        activiteType: 'AtelierReparation',
        activiteSousType: state.partenaire.infosPartenaire.activites[0].categorieReparateur,
      },
      dommages: {
        pointsDeChoc: [],
        immatriculation: state.informationsAssure.immatriculation,
        ...marqueVehicule,
        modeleVehicule: state.informationsAssure.modele,
        remarques: remarques,
      },
      autoAcceptation: true,
    };
  }
  const paramsRDV = (activite: ActiviteProps) => {
    const numeroAgrement = activite.agrements && activite.agrements.length > 0 ?
        activite.agrements[0].numeroAgrement : '';
    const numAgre = numeroAgrement !== '' ? {numeroAgrement: numeroAgrement} : '';
    const otherParams = {...numAgre};
    return {
      referenceSinistre: state.informationsRdv.referenceSinistre,
      idPartenaire: state.partenaire.infosPartenaire.id,
      idActivite: activite.idActivite,
      numeroSiret: state.partenaire.infosPartenaire.numeroSiret,
      codeAbonne: activite.codeAbonne,
      numClient: user.numClient,
      nomClient: user.raisonSociale,
      userCompte: user.userCompte,
      activiteType: 'AtelierReparation',
      activiteSousType: state.informationsRdv.sousActivite.code,
      serviceCode: user.service,
      codeGTA: user.codeGTA,
      ...otherParams,
    }
  }
  const catchOnClickConfirmRdv = (error: any) => {
    if (error.response) {
      const {status} = error.response;
      if (status === 409) {
        setIsRdvNotConfirmed(true);
      } else {
        setErrorApi(true);
      }
    } else {
      setResponseReturn(-1);
    }
  }
  const onClickConfirmRdv = (activite: ActiviteProps) => {
    setIsLoadingConfirmation(true);
    if (isUpdateRdv) {
      const {numClient, userCompte, raisonSociale} = user;
      updateRendezvous({idPartenaire: state.partenaire.infosPartenaire.id, idRdv: state.idRdv!,
        idActivite: activite.idActivite, numClient, userCompte, codeGTA: mission?.codeGTA,
        serviceCode: user.service, data: payloadUpdateRDV(), sendNotif: true,
        infosPartenaire: state.partenaire.infosPartenaire, nomClient: raisonSociale})
          .then(() => {
            setIsLoadingConfirmation(false);
            setIsRdvConfirmed(true)
          })
          .catch((error) => {
            catchOnClickConfirmRdv(error)
          })
          .finally(() => {
            setIsLoadingConfirmation(false)
          })
    } else {
      postRendezVous(paramsRDV(activite), payloadRDV())
          .then((response) => {
            setIsRdvConfirmed(true)
          })
          .catch((error) => {
            catchOnClickConfirmRdv(error)
          })
          .finally(() => {
            setIsLoadingConfirmation(false)
          })
    }
  };

  const onSelectCreneau = (creneau: string, duree: Duree) => {
    setState({...state,
      creneau: creneau,
      duree: duree,
    })
  };

  const setLocalite = (localite: LocaliteProps) => {
    const {numero, voie} = state.informationsAssure.adresse;
    const {codePostal, commune} = localite || {};
    const newAdresse = {
      ...state.informationsAssure.adresse,
      nom: [numero, voie, codePostal, commune].join(' ').trim(),
      codePostal: codePostal || '',
      commune: commune || '',
    };
    setState({
      ...state,
      informationsAssure: {
        ...state.informationsAssure,
        adresse: newAdresse,
      },
    })
  };

  const onClickSaveInfosAssure = (prenom: string, nom: string, tel: string, email: string, marque?: IMarque,
      modele?: string) => {
    const marqueSelectionnee = codesValeursMarque.find((item) =>
      item.valeur.toUpperCase().trim() === marque?.valeur?.toUpperCase().trim());
    setSelectedMarque(marqueSelectionnee ?? emptyItem);
    setState({
      ...state,
      informationsAssure: {
        ...state.informationsAssure, prenom: prenom, nom: nom, telephone: tel, email: email, marque: marque,
        modele: modele,
      }})
  };

  const onClickSaveRemarques = (remarques: string) => {
    setRemarques(remarques);
  };

  const onClickBackButton = () => {
    const messageConfirmation = `Vous allez être redirigé vers ${isFromDossier && !isUpdateRdv ?
        'le détail du dossier' :''}${isUpdateRdv ? 'le détail du rendez-vous' : ''}${!isFromDossier && !isUpdateRdv ?
        'l\'écran précédent' : ''} ${isUpdateRdv ? 'et le rendez-vous ne sera pas mis à jour.' :
        'et le rendez-vous ne sera pas créé.'} \nSouhaitez-vous continuer ?`;

    const result = window.confirm(messageConfirmation);
    if (result) {
      if (isFromDossier && !isUpdateRdv) {
        navigate('/extranet/dossiers/mission/' + urlParams.token);
      }
      if (isUpdateRdv || !isFromDossier && !isUpdateRdv) {
        sessionStorage.removeItem('detailRdv');
        navigate('/extranet/dossiers/prise-rdv/rechercher-rdv')
      }
    }
  };

  const setAdresse = (adresse: AdresseAutoCompleteProps) => {
    setState({
      ...state,
      informationsAssure:
          {
            ...state.informationsAssure,
            adresse: adresse || {nom: '', numero: '', voie: '', codePostal: '', commune: ''},
          },
    })
  };

  const setAdresseCoordonneesGPS = (adresse: AdresseAutoCompleteProps, coordonneesGPS?: CoordonneesGPS) => {
    setState({
      ...state,
      informationsAssure:
          {
            ...state.informationsAssure,
            adresse: adresse || {nom: '', numero: '', voie: '', codePostal: '', commune: ''},
            coordonneesGPS: coordonneesGPS ?? {nom: '', longitude: 0, latitude: 0},
          },
    })
  };

  const setMarque = (value: ItemGetValeursCodesModel) => {
    setSelectedMarque(value || emptyItem);
    setState({
      ...state, informationsAssure: {
        ...state.informationsAssure,
        marque: {valeur: value?.valeur.toUpperCase().trim(), libelleedition: value.libelleedition.trim()},
      },
    })
  };

  const choisirNouveauCreneau = () => {
    setIsRdvNotConfirmed(false);
    setActiveStep(2);
  };

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return <Informations
          informationsRdv={state.informationsRdv}
          informationsAssure={state.informationsAssure}
          codesValeursMarque={codesValeursMarque}
          selectedMarque={selectedMarque}
          setMarque={setMarque}
          sousActivitesList={sousActivitesList}
          errorFields={errorFields}
          onChangeValue={handleChange}
          setLocalite={setLocalite}
          setAdresse={setAdresse}
          setErrorFields={(errFields) => setErrorFields(errFields)}
          handleNextStep={handleNext}
        />;
      case 1:
        return <RechercherReparateur
          coordonneesGPSAssure={state.informationsAssure.coordonneesGPS}
          adresse={state.informationsAssure.adresse}
          sousActiviteChoisie={state.informationsRdv.sousActivite.code}
          setAdresseCoordonneesGPS={setAdresseCoordonneesGPS}
          onChangeActiveStep={setActiveStep}
          onChangePartenaire={onChangePartenaire}
          setErrorApi={(isErrorApi) => setErrorApi(isErrorApi)}
          codesValeursMarque={codesValeursMarque}
        />;
      case 2:
        return <ChoisirCreneau
          isUpdateRdv={isUpdateRdv}
          step={activeStep}
          partenaire={state.partenaire.infosPartenaire}
          nextDispo={state.partenaire.nextDispo}
          sousActiviteChoisie={state.informationsRdv.sousActivite.code}
          typeRdv={state.informationsRdv.typeRdv.code}
          selectedCreneau={state.creneau}
          onSelectCreneau={onSelectCreneau}
          handleNextStep={(step) => setActiveStep(step)}
          setErrorApi={(isErrorApi) => setErrorApi(isErrorApi)}
        />;
      case 3:
        return <Recapitulatif
          data={state}
          codesValeursMarque={codesValeursMarque}
          selectedMarque={selectedMarque}
          errorFields={errorFields}
          setErrorFields={(errFields) => setErrorFields(errFields)}
          onClickSaveInfosAssure={onClickSaveInfosAssure}
          onClickSaveRemarques={onClickSaveRemarques}
          onClickConfirm={onClickConfirmRdv}
          isLoadingConfirmation={isLoadingConfirmation}
          responseReturn={responseReturn}
        />
    }
  };

  const contentWidth = (() => {
    switch (activeStep) {
      case 1:
        return '100%';
      case 2:
        return '75%';
      case 3:
        return '95%';
      default:
        return '70%';
    }
  })();

  return (
    <>
      {hasRolePriseRDV &&
        <div>
          {!isRdvConfirmed && !isRdvNotConfirmed && !errorApi &&
            <>
              <div style={{margin: '35px 0px 5px', display: 'flex'}}>
                <div style={{width: '12%'}}>
                  <ButtonLink
                    className={classes.linkBack}
                    id={ID_BOUTON_RETOUR}
                    icon={<ArrowBackIcon/>}
                    libelle={'Retour'}
                    onClick={onClickBackButton}/>
                </div>
                <div style={{width: '75%'}}>
                  <div>
                    <Typography variant={'h1'} className={classes.title}>
                      Prise de rendez-vous réparateur
                    </Typography>
                  </div>
                  <div className={classes.myStepper}>
                    <Stepper style={{padding: 24}} nonLinear activeStep={activeStep}>
                      {steps.map((label, index) => {
                        const keyStep = `${index}`
                        return (
                          <Step key={keyStep}>
                            <StepButton
                              onClick={() => setActiveStep(index)}
                              disabled={index >= activeStep || disableSteps.includes(index)}
                            >
                              {label}
                            </StepButton>
                          </Step>
                        )
                      })}
                    </Stepper>
                  </div>
                </div>
              </div>
              <div style={{margin: '0px auto', width: contentWidth,
                paddingBottom: activeStep === 0 ? '10px' : ''}}>
                {getStepContent(activeStep)}
              </div>
            </>
          }
          {errorApi &&
            <ErrorAPI retourToRdv={() => {
              setActiveStep(0);
              setErrorApi(false)
            }}
            />
          }
          {isRdvConfirmed &&
            <RdvConfirme
              assure={state.informationsAssure}
              reparateur={state.partenaire.infosPartenaire}
              isUpdateRdv={isUpdateRdv}
            />
          }
          {isRdvNotConfirmed &&
            <RdvNonConfirme
              choisirNouveauCreneau={choisirNouveauCreneau}
            />
          }
        </div>
      }
      {!hasRolePriseRDV &&
        <AccessDenied
          message={'Ce compte ne vous permet pas d\'accéder à la prise de rendez-vous'}
          isReturnButton={true}
          buttonLabel={'Retour'}
          onClickButton={() => navigate('/extranet/portail-sinapps-auto')}
        />
      }
    </>
  );
};
