import React, {useState} from 'react';
import {Entete} from './Entete';
import {Loader} from '../../../../common/Loader';
import {Typography} from '@mui/material';
import useEmotionStyles from '../../../../../common/useEmotionStyles';
import {stylesCommon} from '../StylesCommon';
import moment from 'moment/moment';
import {downloadOneAttachment} from '../../DownloadAttachments';
import {UserContext} from '../../../../../context/UserContext';
import {getValueElement} from '../Utils';
import {ReactComponent as PDFIcon} from '../../../../../images/icones/icn-pdf.svg';

interface ReponseDADCProps {
  formulaire: string
  data: any,
  prefix: string
}

export const ReponseDADC = (props: ReponseDADCProps) => {
  const classes = useEmotionStyles(stylesCommon);
  const {user} = React.useContext(UserContext);
  const {data, prefix, formulaire} = props;
  const [isLoading, setIsLoading] = useState(false);
  const [onErrorDownload, setOnErrorDownload] = useState(false);
  const name = formulaire == 'DA' ? 'Récépissé de déclaration d\'achat' : 'Récépissé de déclaration de cession';

  const downloadFile = (identifier: string) => {
    const attachment = {
      id: identifier,
      name: name,
      date: moment().format('DDMMYYYY'),
    };
    downloadOneAttachment({attachment, author: '', typeDoc: '',
      referenceSinistre: getValueElement(data, prefix, 'reference_sinistre'),
      immatriculation: getValueElement(data, prefix, 'numero_immatriculation'),
      numClient: user.numClient, setOnErrorDownload, setIsLoading});
  };

  const nomFichierRecipisse = formulaire =='DA' ? 'decAchat_1.pdf' : 'decCession_1.pdf';

  const specificiteDADC = () => {
    return (
      <div>
        {(data.getElementsByTagName(prefix + 'nom_fichier_recepisse').length !== 0 &&
            data.getElementsByTagName(prefix + 'nom_fichier_recepisse')[0].value !== nomFichierRecipisse) ?
            <div className={classes.flex} style={{alignItems: 'center', marginTop: '30px'}}>
              {isLoading && <Loader className={classes.loader} size={20}/>}
              {!isLoading && <PDFIcon className={classes.icon}/>}
              <Typography
                id={formulaire =='DA' ? 'pdf-da' : 'pdf-dc'}
                className={classes.lienFichier}
                style={{cursor: 'pointer'}}
                onClick={() => downloadFile(data.getElementsByTagName(prefix + 'nom_fichier_recepisse')[0].value)}
              >
                {name}
              </Typography>
            </div> : <></>
        }
        {onErrorDownload ?
          <Typography style={{color: 'red', fontSize: '14px', marginTop: '10px'}}>
            Erreur lors du téléchargement
          </Typography> : <></>
        }
      </div>
    )
  };

  return (
    <div style={{columnGap: '30px', width: '60%', margin: 'auto'}}>
      <Entete
        data={data}
        prefix={prefix}
        formulaire={formulaire}
        withoutTitreContainer={true}
        specificiteDADC={specificiteDADC()}
      />
    </div>
  )
};
