import React from 'react';
import {Button} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface ButtonSeeProps {
  typeButton: 'less' | 'more'
  className?: string,
  id: string,
  iconPosition: 'top' | 'left',
  libelleButton: string
  onClick: (event: React.MouseEvent<HTMLElement>) => void,
}

type StylesProps = { iconPosition: string }
const styles = (theme: Theme, {iconPosition}: StylesProps) => ({
  iconButton: {
    color: theme.palette.link.main,
    padding: '6px 0px',
  },
  label: {
    display: 'flex',
    flexDirection: iconPosition === 'top' ? 'column' as const : 'row' as const,
    alignItems: 'center',
  },
  expandIcon: {
    marginRight: '0px',
  },
});

export const ButtonSee = (props: ButtonSeeProps) => {
  const {typeButton, className, id, iconPosition, libelleButton, onClick} = props;
  const classes = useEmotionStyles(styles, {iconPosition});
  return (
    <Button id={id} className={`${className} ${classes.iconButton}`} onClick={onClick}>
      <span className={classes.label}>
        {typeButton === 'less' ? <ExpandLessIcon className={classes.expandIcon}/> :
          <ExpandMoreIcon className={classes.expandIcon}/>
        }{libelleButton}
      </span>
    </Button>
  )
};
