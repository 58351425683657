export interface IResultLine {
  id: number,
  idEchange: string,
  numeroLot: string,
  dateHeureDebut: string,
  dateHeureFin: string,
  raisonSociale: string,
  etat: string,
  action: string,
  codeAbonne: string,
  rad: boolean
}

export interface IEchangesResult {
  indiceDebut: string,
  indiceFin: string,
  list: IResultLine[]
}

export const initialEchangesResult: IEchangesResult = {
  indiceDebut: '',
  indiceFin: '',
  list: [],
}
