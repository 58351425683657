import React from 'react'
import {Card, CardContent, Typography} from '@mui/material'
import {StyledTypography} from '../../../common/StyledTypography'
import {useMissionContext} from '../../../../context/MissionContext'
import useEmotionStyles from '../../../../common/useEmotionStyles';

const styles = () => ({
  card: {
    ['@media print']: {
      boxShadow: 'none',
      pageBreakInside: 'avoid' as const,
    },
  },
  container: {
    display: 'flex',
    columnGap: '30px',
  },
  left: {
    width: '50%',
  },
  right: {
    width: '50%',
  },
});

const Recours = () => {
  const classes = useEmotionStyles(styles);
  const {mission} = useMissionContext();
  const sinistre = mission?.gestionDTO?.recoursEtConventionDTO;
  const recours = sinistre?.recours;
  const convention =sinistre?.convention;
  const bareme = sinistre?.bareme;

  const isVisible = recours ?? bareme ?? convention;

  return (
    isVisible ?
      <Card className={classes.card}>
        <CardContent>
          <Typography variant={'h3'}>Recours et convention</Typography>
          <div className={classes.container}>
            <div className={classes.left}>
              <StyledTypography id={'recoursPresume'} title={'Recours présumé'} text={recours} />
              <StyledTypography id={'convention'} title={'Convention appliquée'} text={convention} />
            </div>
            <div className={classes.right}>
              <StyledTypography id={'baremeResponsabilite'} title={'Barème de responsabilité'} text={bareme} />
            </div>
          </div>
        </CardContent>
      </Card> : <div></div>
  );
};

export default Recours
