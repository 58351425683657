import * as React from 'react'
import {ReactPanZoom} from './Pan-zoom-rotate';
import Image from './Image';
import {useEffect, useState} from 'react';
import {ReactComponent as ZoomInIcon} from '../../images/icones/icn-zoom-in-img.svg'
import {ReactComponent as ZoomOutIcon} from '../../images/icones/icn-zoom-out-img.svg'
import {ReactComponent as RotateIcon} from '../../images/icones/icn-rotate-img.svg'
import {ReactComponent as MiroirIcon} from '../../images/icones/icn-miroir-img.svg'
import {ReactComponent as ResetIcon} from '../../images/icones/icn-reset-img.svg'
import {customTheme} from '../../common/GlobalTheme';
import {Typography} from '@mui/material';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../common/useEmotionStyles';

/** Design */
const styles = (theme: Theme) => ({
  groupeBouton: {
    right: '10px',
    zIndex: 2,
    top: 10,
    userSelect: 'none' as const,
    borderRadius: 2,
    background: theme.palette.white.main,
    boxShadow: '0px 2px 6px rgba(53, 67, 93, 0.32)',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  boutonImg: {
    'textAlign': 'center' as const,
    'cursor': 'pointer',
    'height': 35,
    'width': 35,
    'display': 'inline-block',
  },
  imgContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
  text: {
    marginTop: '170px',
  },
});

type PanViewerProps = {
  imgUrl: string
  setIsPhotoOnError: (value: boolean) => void
  isPhotoOnError: boolean
  miseAzero: number
}

const PhotoViewer = ({imgUrl, setIsPhotoOnError, isPhotoOnError, miseAzero}: PanViewerProps) => {
  const classes = useEmotionStyles(styles);
  const [dx, setDx] = React.useState(0);
  const [zoom, setZoom] = React.useState(1);
  const [rotation, setRotation] = React.useState(0);
  const [flip, setFlip] = React.useState(false);
  const [reset, setReset] = React.useState(miseAzero);
  const [isChange, setIsChange] = useState(false);

  const resetAll = () => {
    setIsChange(false);
    setReset(reset+1);
    setDx(0);
    setZoom(1);
    setRotation(0);
    setFlip(false)
  };

  const zoomIn = () => {
    setIsChange(true);
    setZoom(zoom + 0.2)
  };

  const zoomOut = () => {
    setIsChange(true);
    if (zoom >= 1) {
      setZoom(zoom - 0.2)
    }
  };

  const rotateRight = () => {
    setIsChange(true);
    if (rotation === 3) {
      setRotation(0)
    } else {
      setRotation(rotation + 1)
    }
  };

  const flipImage = () => {
    setIsChange(true);
    setFlip(!flip)
  };

  useEffect(() => {
    resetAll()
  }, [miseAzero]);

  return (
    <div>
      <div className={classes.groupeBouton}>
        {imgUrl && !isPhotoOnError &&
          <>
            <div className={classes.boutonImg+' boutonImg'} style={{borderRight: '1px solid #ccc'}}
              onClick={zoomIn} aria-hidden={true}>
              <ZoomInIcon/>
              <div className='tooltiptext'>Zoom avant</div>
            </div>
            <div className={classes.boutonImg+' boutonImg'} style={{borderRight: '1px solid #ccc'}}
              onClick={zoomOut} aria-hidden={true}>
              <ZoomOutIcon/>
              <div className='tooltiptext'>Zoom arrière</div>
            </div>
            <div className={classes.boutonImg+' boutonImg'} style={{borderRight: '1px solid #ccc'}}
              onClick={rotateRight} aria-hidden={true}>
              <RotateIcon/>
              <div className='tooltiptext'>Rotation</div>
            </div>
            <div className={classes.boutonImg+' boutonImg'} style={{borderRight: ' 1px solid #ccc'}}
              onClick={flipImage} aria-hidden={true}>
              <MiroirIcon/>
              <div className='tooltiptext'>Miroir</div>
            </div>
            <div className={classes.boutonImg+' boutonImg'} onClick={resetAll} aria-hidden={true}>
              <ResetIcon style={{fill: isChange ? customTheme.palette.link.main :
                  customTheme.palette.secondary.dark2}}/>
              <div className='tooltiptext'>Réinitialiser</div>
            </div>
          </>
        }
      </div>
      {(!imgUrl || isPhotoOnError) &&
        <Typography className={classes.text}>Photo indisponible</Typography>
      }
      {imgUrl && !isPhotoOnError &&
        <ReactPanZoom
          className={classes.imgContainer}
          setIsChange={setIsChange}
          zoom={zoom}
          setZoom={setZoom}
          key={dx}
          enablePan={true}
          reset={reset}
        >
          <Image flip={flip} imgUrl={imgUrl} rotation={rotation} zoom={zoom} setIsPhotoOnError={setIsPhotoOnError}/>
        </ReactPanZoom>
      }
    </div>
  )
};

export default PhotoViewer
