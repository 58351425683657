import React from 'react';
import {FieldConfig, FieldHookConfig, useField} from 'formik';
import {Theme} from '@emotion/react';
import {Switch} from '@mui/material';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {getIdFromName} from '../../utils/Utils';
interface CheckBoxFieldProps extends FieldConfig{
    checked?: boolean
    mandatory?: boolean
    disabled?: boolean
}

const styles = (theme: Theme) => ({
  switch: {
    '& .MuiSwitch-switchBase': {
      'color': theme.palette.secondary.dark2,
      '&.Mui-checked': {
        'color': theme.palette.link.main,
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.link.light1,
        },
      },
    },
    '& .MuiSwitch-track': {
      backgroundColor: theme.palette.secondary.dark1,
      opacity: 1,
    },
  },
});

const SwitchField = ({mandatory, checked, disabled, ...props} : CheckBoxFieldProps) => {
  const classes = useEmotionStyles(styles);
  const [field] = useField<string>(props as FieldHookConfig<string>);

  return (
    <Switch
      id={getIdFromName(field.name)}
      className={classes.switch}
      {...field}
      type='checkbox'
      checked={Boolean(checked)}
      disabled={Boolean(disabled)}
    />
  );
};

export default SwitchField;
