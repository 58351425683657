import React, {useState} from 'react';
import {Card, CardContent, IconButton, TextField, Tooltip, Typography} from '@mui/material';
import useEmotionStyles from '../../common/useEmotionStyles';
import CancelIcon from '@mui/icons-material/Cancel';
import {Theme} from '@emotion/react';
import {Filiale, UserContext} from '../../context/UserContext';
import {EntityElement} from './EntityElement';
import {getUserByCodeAbonne} from '../../api/user';
import {listServices} from '../../common/Utils';

interface IPopUpEntitiesProps {
  onClosePopIn: () => void
}

/** Design */
const styles = (theme: Theme) => ({
  container: {
    position: 'fixed' as const,
    inset: '0px',
    opacity: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1,
    height: '100vh',
  },
  content: {
    right: '16px',
    top: '70px',
    justifyContent: 'center',
    alignItems: 'center',
    width: '27%',
    zIndex: 1,
    position: 'absolute' as const,
  },
  exitButton: {
    padding: '0px',
    margin: '12px 12px',
    color: theme.palette.primary.main,
    position: 'absolute' as const,
    right: 0,
  },
  card: {
    height: 'calc(100vh - 90px)',
  },
  cardContent: {
    height: 'calc(100% - 40px)',
    padding: '24px',
  },
  listContent: {
    marginTop: '24px',
    height: 'calc(100% - 90px)',
    overflow: 'auto',
  },
  textField: {
    width: '100%',
  },
  entityElement: {
    borderBottom: 'solid 1px ' + theme.palette.secondary.main,
    padding: '16px 0 16px 16px',
    cursor: 'pointer',
    color: theme.palette.secondary.dark,
  },
  entityElementLabel: {
    color: theme.palette.primary.main,
  },
  bottom12: {
    marginBottom: '12px',
  },
  right0: {
    marginRight: '0',
  },
})

export const PopUpEntities = (props: IPopUpEntitiesProps) => {
  const {user, setDataUser} = React.useContext(UserContext);
  const {onClosePopIn} = props
  const [enteredEntity, setEnteredEntity] = useState('');
  const [selectedFiliale, setSelectedFiliale] = useState(user.codeAbo);
  const classes = useEmotionStyles(styles)
  const [filialesList, setFilialesList] = useState<Filiale[]>(user.filiales);
  const [loadingElement, setLoadingElement] = useState('');
  const [errorElement, setErrorElement] = useState('');

  const inputEntity = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnteredEntity(event.currentTarget.value)
    setFilialesList(
        user.filiales.filter((item) =>
          item.raisonSociale.toLowerCase().includes(event.currentTarget.value.toLowerCase())),
    )
  }

  const changeFiliale = (filiale: Filiale) => {
    setErrorElement('');
    setLoadingElement(filiale?.codeAbonne);
    getUserByCodeAbonne(filiale?.codeAbonne, user.service)
        .then((response) => {
          const adresse = {
            complementAdresse: '',
            numeroVoie: response.infogeoEtablissement?.adresse?.numeroVoie ??
              response.compte.partenaire.adresse.adresse1 ?? '',
            indiceRepetition: '',
            typeVoie: response.infogeoEtablissement?.adresse?.typeVoie ??
              response.compte.partenaire.adresse.adresse2?? '',
            libelleVoie: response.infogeoEtablissement?.adresse?.libelleVoie ??
              response.compte.partenaire.adresse.adresse3 ?? '',
            codePostal: response.infogeoEtablissement?.adresse?.codePostal ??
              response.compte.partenaire.adresse.codePostal ?? '',
            codeCedex: '',
            distributionSpeciale: '',
            libelleCommune: response.infogeoEtablissement?.adresse?.libelleCommune ??
              response.compte.partenaire.adresse.commune ?? '',
            libelleCedex: '',
          }
          const newUser = {
            userCompte: user.userCompte,
            nom: response.compte.partenaire.nom,
            givenName: '',
            familyName: '',
            type: response.compte.partenaire.libelleType,
            roles: user.roles,
            codeAbo: filiale?.codeAbonne,
            service: user.service,
            listeServices: listServices(response),
            codeGTA: response.compte.partenaire.codeGta,
            numClient: response.compte.partenaire.numeroClient,
            isAssistance: user.isAssistance,
            isTransparent: user.isTransparent,
            isSSO: user.isSSO,
            raisonSociale: response.compte.partenaire.raisonSociale,
            raisonSocialeInfogeo: response.infogeoEtablissement?.siege?.raisonSociale ??
              response.compte.partenaire.raisonSociale ?? '',
            siret: response.compte.partenaire.siret ?? '',
            siren: response.infogeoEtablissement?.siren ?? '',
            telephone: response.compte.partenaire.telephone.trim() ?? '',
            token: user.token,
            adresse: adresse,
            filiales: user.filiales,
            agrements: response.agrements,
            agrementsBG: response.agrementsBG,
          };
          setDataUser(newUser);
          sessionStorage.setItem('user', JSON.stringify(newUser));
          setSelectedFiliale(filiale.codeAbonne)
          onClosePopIn()
        })
        .catch(() => {
          setErrorElement(filiale?.codeAbonne);
        })
        .finally(() => {
          setLoadingElement('');
        })
  }

  const selectedFirst = (filiale: Filiale, next: Filiale) => {
    if (filiale.codeAbonne === selectedFiliale && next.codeAbonne !== selectedFiliale) {
      return -1;
    }
    if (filiale.codeAbonne !== selectedFiliale && next.codeAbonne === selectedFiliale) {
      return 1;
    }
    return 0;
  }

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <Card className={classes.card}>
          <div className={classes.bottom12}>
            <Tooltip disableInteractive title='Fermer' PopperProps={{disablePortal: true}}>
              <IconButton
                component='span'
                className={classes.exitButton}
                onClick={onClosePopIn}>
                <CancelIcon className={classes.right0}/>
              </IconButton>
            </Tooltip>
          </div>
          <CardContent className={classes.cardContent}>
            <Typography variant={'h6'}>Recherche une entité</Typography>
            <TextField
              className={classes.textField}
              value={enteredEntity}
              onChange={inputEntity}
            />
            <div className={classes.listContent}>
              {
                [...filialesList]
                    .sort(selectedFirst)
                    .map((filiale: Filiale) => {
                      return <EntityElement
                        key={filiale.codeAbonne}
                        filiale={filiale}
                        selectedFiliale={selectedFiliale}
                        changeFiliale={changeFiliale}
                        loadingElement={loadingElement}
                        errorElement={errorElement}
                      />
                    })
              }
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}
