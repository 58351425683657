interface IRechercheEchange {
  dateDebut: string
  dateFin: string
  isEchangeDepuisHier: boolean
  etat: string
  numeroEchange: string
  action: string
}

export const initialValues: IRechercheEchange = {
  dateDebut: '',
  dateFin: '',
  isEchangeDepuisHier: false,
  etat: ' ',
  numeroEchange: '',
  action: ' ',
};
