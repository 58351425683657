import {Attachments, FilesDragAndDrop} from '../components/Attachment/FilesDragAndDrop';
import React from 'react';
import {CSSInterpolation} from '@emotion/serialize';
import useEmotionStyles from '../../../common/useEmotionStyles';

/** Design */
const styles = (): Record<string, CSSInterpolation> => ({
  attachments: {
    padding: '10px 0px 0px 20px',
    height: 'calc(100vh - 80px)', // 100vh - (hauteur du header + paddingTop)
  },
})

export interface CommonAttachmentsFormProps {
  piecesJointesList: Attachments
  onUpload: (attachments: Attachments) => void
  onCloseAttachmentForms: () => void
}

export const CommonAttachmentsForm = (props: CommonAttachmentsFormProps) => {
  const classes = useEmotionStyles(styles);
  const {piecesJointesList, onUpload, onCloseAttachmentForms} = props

  return (
    <div className={classes.attachments}>
      <FilesDragAndDrop
        attachments={piecesJointesList}
        onSave={onUpload}
        onCloseAttachmentForms={onCloseAttachmentForms}
      />
    </div>
  )
}
