import React, {useContext, useEffect, useState} from 'react';
import {Card, CardContent, MenuItem, Typography} from '@mui/material';
import PartenaireCard from './PartenaireCard';
import Agenda from './Agenda';
import {getDisponibilitesPartenaire} from '../../../../api/idigo';
import moment from 'moment';
import groupBy from 'lodash/groupBy';
import {ButtonBlue} from '../../../common/buttons/ButtonBlue';
import {TextFieldSelect} from '../../../common/formsComponents/TextFieldSelect';
import {ReactComponent as CustomExpIcon} from '../../../../images/icones/icones-acteurs/icn-expert.svg';
import {Duree} from '../../../../model/priseRDV/RDVModel';
import {Creneau, CreneauProps, DayOfWeek, RowCalendar} from '../../../../model/priseRDV/CreneauModel';
import {CreneauxDisponibiliteProps} from '../../../../model/priseRDV/CreneauDisponibiliteModel';
import {ExpertProps} from '../../../../model/priseRDV/ExpertModel';
import {UserContext} from '../../../../context/UserContext';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {roundTime} from '../../../../common/utils/DateUtils';

const styles = (theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column' as const,
    margin: 'auto 24px',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
  },
  colonneDroite: {
    width: '70%',
    display: 'flex',
    flexDirection: 'column' as const,
  },
  card: {
    marginBottom: '25px',
  },
  expert: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
  },
  typography: {
    fontSize: '14px',
    color: theme.palette.text.primary,
    paddingTop: '5px',
  },
  textFieldSelect: {
    width: '100%',
  },
  typographyTournees: {
    fontSize: '14px',
    color: theme.palette.text.primary,
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

const dateFinSubtract = (dateDebut: string) => {
  const currentWeek = moment(dateDebut).week();
  const currentYear = moment(dateDebut).year();
  return moment(currentYear.toString())
      .add(currentWeek, 'weeks')
      .endOf('isoWeek')
      .toISOString()
};

const generateHours = () => {
  return Array.from({length: 24}, (__, i) => i)
      .reduce((r: string[], hour) => {
        r.push(moment({hour, minute: 0}).format('HH:mm'));
        r.push(moment({hour, minute: 30}).format('HH:mm'));
        return r;
      }, []);
};

const getLabelDayOfWeek = (day: number) => {
  switch (day) {
    case 0:
      return 'Dim';
    case 1:
      return 'Lun';
    case 2:
      return 'Mar';
    case 3:
      return 'Mer';
    case 4:
      return 'Jeu';
    case 5:
      return 'Ven';
    case 6:
      return 'Sam';
    default:
      return 'Unknown';
  }
};

const getWeekLabel = (date: string) => {
  const currentWeekNumber = moment(date).week();
  const currentYear = moment(date).year();
  const dateDebutSemaine = moment(currentYear.toString())
      .add(currentWeekNumber, 'weeks')
      .startOf('isoWeek')
      .format('YYYY-MM-DD');
  const dateFinSemaine = dateFinSubtract(dateDebutSemaine);
  const isTheSameYear = moment(dateDebutSemaine).year() === moment(dateFinSemaine).year();
  const isTheSameMounth = moment(dateDebutSemaine).month() === moment(dateFinSemaine).month();
  let label;
  if (isTheSameYear) {
    label = isTheSameMounth ? moment(dateDebutSemaine)
        .format('DD')
        .concat(' - ')
        .concat(moment(dateFinSemaine).format('DD MMMM YYYY')) :
            moment(dateDebutSemaine)
                .format('DD MMMM')
                .concat(' - ')
                .concat(moment(dateFinSemaine).format('DD MMMM YYYY'));
  } else {
    label = moment(dateDebutSemaine)
        .format('DD MMMM YYYY')
        .concat(' - ')
        .concat(moment(dateFinSemaine).format('DD MMMM YYYY'));
  }
  return label;
};

const getStartOfWeek = (date: string) => {
  const currentWeekNumber = moment(date).week();
  const currentYear = moment(date).year();
  return moment(currentYear.toString())
      .add(currentWeekNumber, 'weeks')
      .startOf('isoWeek')
      .format('YYYY-MM-DD');
};

const getDurationLabel = (dureeEnMinutes: string) => {
  switch (dureeEnMinutes) {
    case '30':
      return '30 min';
    case '60':
      return '1h';
    case '90':
      return '1h30';
    case '120':
      return '2h';
    case '150':
      return '2h30';
    case '180':
      return '3h';
    case '210':
      return '3h30';
    default:
      return '30 min';
  }
};

const buildCreneaux = (dispos: CreneauxDisponibiliteProps[]) => {
  return dispos?.map((dispo) => {
    return {
      dateDebut: moment(dispo.dateDebut).toISOString(),
      dateFin: moment(dispo.dateFin).toISOString(),
      idExpertList: dispo.idExpertList || [],
      label: `${moment(dispo.dateDebut).format('H:mm')} à ${moment(dispo.dateFin).format('H:mm')}`,
      disponible: true,
    }
  })
};

const buildRows = (weekCalendar: DayOfWeek[]) => {
  const rows: RowCalendar[] = [];
  const hoursList = generateHours();

  hoursList.forEach((hour) => {
    const creneaux: Creneau[] = [];
    weekCalendar.forEach((day) => {
      let crn = day.creneaux.find((creneau) => {
        const timeRounded = roundTime(creneau.dateDebut).format('HH:mm');
        return timeRounded === hour;
      });
      crn = crn ? {...crn, disponible: true} : {
        dateDebut: '',
        dateFin: '',
        idExpertList: [],
        label: '',
        disponible: false,
      };
      creneaux.push(crn)
    });
    const row: RowCalendar = {
      id: hour,
      creneaux: creneaux,
    };
    rows.push(row)
  });
  return rows;
};

const ChoisirCreneau = (props: CreneauProps) => {
  const classes = useEmotionStyles(styles);
  const {user} = useContext(UserContext);
  const {
    isUpdateRdv, step, partenaire, nextDispo, sousActiviteChoisie, typeRdv, selectedCreneau, onSelectCreneau,
    handleNextStep, setErrorApi,
  } = props;

  const intialState = {
    selectedCreneau: selectedCreneau,
    creneau: {
      dateDebut: '',
      dateFin: '',
      label: '',
      disponible: false,
    } as Creneau,
    duree: {
      valeur: 0,
      libelle: '',
    },
    maxCreneauxNumber: 0,
  };

  const [expertsList, setExpertsList] = useState<ExpertProps[]>();
  const {id: idPartenaire, numeroSiret, numeroClient} = partenaire;
  const [state, setState] = useState(intialState);

  const [dateDebut, setDateDebut] = useState(roundTime(nextDispo?.dateDebut ? nextDispo.dateDebut :
        moment().toISOString()).toISOString());
  const [dateFin, setDateFin] = useState(dateFinSubtract(dateDebut));
  const [currentWeek, setCurrentWeek] = useState<DayOfWeek[]>();
  const [weekNumber, setWeekNumber] = useState(0);
  const [weekLabel, setWeekLabel] = useState('');
  const [rows, setRows] = useState<RowCalendar[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [expert, setExpert] = useState('');

  useEffect(() => {
    const dateFinDeduite = dateFinSubtract(dateDebut);
    setDateFin(dateFinDeduite);
    setIsLoading(true);
    const {numClient, userCompte} = user;
    const idActivite = partenaire.activites[0].idActivite;
    getDisponibilitesPartenaire({idPartenaire, idActivite, numeroSiret, numeroClientPartenaire: numeroClient,
      numClient, userCompte, dateDebutDispo: dateDebut, dateFinDispo: dateFinDeduite, userSiret: user.siret})
        .then((response) => {
          const {data, status} = response;
          if (status === 200) {
            const disponibilites: CreneauxDisponibiliteProps[] = data.disponibilites;
            const expertsList: ExpertProps[] = data.expertsList;
            const sortedDisponibilites = disponibilites &&
                        [...disponibilites].sort((firstDate, secondDate) =>
                          moment(firstDate.dateDebut).valueOf() - moment(secondDate.dateDebut).valueOf());
            setExpertsList(expertsList);
            if (sortedDisponibilites?.length) {
              buildCalendar(sortedDisponibilites)
            } else {
              setWeekNumber(moment(dateDebut).week());
              setWeekLabel(getWeekLabel(dateDebut));
              setCurrentWeek([]);
              setIsLoading(false)
            }
          } else {
            setErrorApi(true)
          }
        }).catch((e) =>
          console.error(e.response),
        )
  }, [dateDebut]);

  useEffect(() => {
    if (expertsList?.length === 1) {
      setExpert(expertsList[0].id);
    }
  }, [expertsList]);

  const itemsListExperts = expertsList?.map((expert) => {
    return (
      <MenuItem key={expert.id} value={expert.id}>{expert.raisonSociale}</MenuItem>
    )
  });

  const handleChangeExpert = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExpert(event.target.value);
  };

  const buildCalendar = (disponibilitesList: CreneauxDisponibiliteProps[]) => {
    const week: DayOfWeek[] = [];
    const diff = moment(disponibilitesList[0].dateFin)
        .diff(disponibilitesList[0].dateDebut, 'minute');
    const duree: Duree = {valeur: diff, libelle: getDurationLabel(diff.toString())};

    const groupeByDate = groupBy(disponibilitesList, (dispo) => {
      return moment(dispo.dateDebut).format('YYYY-MM-DD')
    });
    let creneauxNumber = 0;
    Object.keys(groupeByDate).forEach((key) => {
      if (groupeByDate[key].length > creneauxNumber) {
        creneauxNumber = groupeByDate[key].length
      }
      const dayofWeek: DayOfWeek = {
        id: key,
        label: getLabelDayOfWeek(moment(key).day()),
        dayOfMonth: moment(key).format('DD').toString(),
        dayOfweek: moment(key).day(),
        creneaux: buildCreneaux(groupeByDate[key]),
      };
      week.push(dayofWeek)
    });
    setState({...state, duree: duree});
    completeCalendar(week)
  };

  const completeCalendar = (week: DayOfWeek[]) => {
    const calendarWeek: DayOfWeek[] = [];
    const dateDebutSemaine = getStartOfWeek(week[0].id);

    const dayNumbers: number[] = [0, 1, 2, 3, 4, 5, 6];
    dayNumbers.forEach((index) => {
      const dayWeekDate = moment(dateDebutSemaine)
          .add(index, 'days')
          .format('YYYY-MM-DD');

      const dayofWeek: DayOfWeek = {
        id: dayWeekDate,
        label: getLabelDayOfWeek(moment(dayWeekDate).day()),
        dayOfweek: moment(dayWeekDate).day(),
        dayOfMonth: moment(dayWeekDate).format('DD').toString(),
        creneaux: [],
      };
      calendarWeek.push(dayofWeek)
    });

    week.forEach((day) => {
      let found = calendarWeek.find((jour) => day.id === jour.id);
      if (found) {
        const index = calendarWeek.indexOf(found);
        found = {...found, creneaux: day.creneaux};
        calendarWeek.splice(index, 1, found)
      }
    });
    setWeekNumber(moment(week[0].id).week());
    setWeekLabel(getWeekLabel(week[0].id));
    setCurrentWeek(calendarWeek);
    setRows(buildRows(calendarWeek));
    setIsLoading(false)
  };

  const activite = partenaire?.activites?.find((act) =>
    act.categorieReparateur === sousActiviteChoisie);
  const services = activite?.servicesProposes;

  const handleClickCreneau = (creneau: Creneau) => {
    setState({...state, selectedCreneau: creneau.dateDebut, creneau: creneau});
    onSelectCreneau(creneau.dateDebut, state.duree)
  };

  const handleNext = () => {
    const firstDateNextWeek = moment(dateFin)
        .add(1, 'second')
        .toISOString();
    setDateDebut(firstDateNextWeek)
  };

  const handlePrevious = () => {
    let firstDatePreviousWeek = moment(dateDebut)
        .subtract(1, 'week')
        .toISOString();
    if (moment(firstDatePreviousWeek).week() >= moment().week()) {
      if (moment().isAfter(firstDatePreviousWeek)) {
        firstDatePreviousWeek = roundTime(moment().toISOString()).toISOString();
      }
      setDateDebut(firstDatePreviousWeek)
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <PartenaireCard
          isUpdateRdv={isUpdateRdv}
          raisonSociale={partenaire.raisonSociale}
          distance={partenaire.distance}
          adresse={partenaire.adresse}
          coordonnees={partenaire.coordonnees}
          horaires={partenaire.horaires}
          conges={partenaire.conges}
          activites={partenaire.activites}
          services={services ?? []}
        />
        <div className={classes.colonneDroite}>
          {itemsListExperts && typeRdv === '02' &&
            <Card className={classes.card}>
              <CardContent>
                <div className={classes.expert}>
                  <CustomExpIcon style={{marginRight: '10px'}}/>
                  <Typography>Tournées de l&apos;expert</Typography>
                </div>
                <TextFieldSelect
                  className={classes.textFieldSelect}
                  id={'expert'}
                  name={'expert'}
                  marginDense={true}
                  label={'Sélectionner un expert'}
                  itemsList={itemsListExperts}
                  value={expert}
                  disabled={itemsListExperts && itemsListExperts.length <= 1}
                  onChange={handleChangeExpert}
                  withEmptyItem={true}
                  emptyItemLabel={'Sélectionner un expert'}
                />
                {itemsListExperts?.length === 0 &&
                  <Typography className={classes.typographyTournees}><i>Aucune tournée renseignée</i></Typography>
                }
              </CardContent>
            </Card>
          }
          <Agenda
            weekLabel={weekLabel}
            weekNumber={weekNumber}
            weekCalendar={currentWeek}
            rows={rows}
            isLoading={isLoading}
            firstAvailability={nextDispo.dateDebut}
            selectedCreneau={state.selectedCreneau}
            expertId={expert}
            handleClickCreneau={handleClickCreneau}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
          />
          {state.duree?.libelle &&
            <Typography className={classes.typography}>
              Les créneaux sont d&apos;une durée de {state.duree.libelle}.
            </Typography>
          }
          <div className={classes.footer}>
            <ButtonBlue
              id={'suivant'}
              libelle={'Suivant'}
              disabled={state.selectedCreneau.length === 0}
              onClick={() => handleNextStep(step + 1)}/>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChoisirCreneau;
