import React, {useEffect, useState} from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import InputMask from 'react-input-mask-3.0';
import {ButtonBlueDelete} from '../../common/formsComponents/ButtonBlueDelete';
import BlueSwitch from '../../common/BlueSwitch'
import {ElementCalculModel} from '../../../model/detailsDossier/ElementCalculModel';
import {TextFieldSelect} from '../../common/formsComponents/TextFieldSelect';
import {TextFieldSearch} from '../../common/formsComponents/TextFieldSearch';
import {TextFieldCurrency} from '../../common/formsComponents/TextFieldCurrency';
import HelpIcon from '@mui/icons-material/Help';
import {findElementByCodeInArray} from '../../../common/Utils';
import {ItemGetValeursCodesModel} from '../../../model/common/ItemGetValeursCodesModel';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';

/** Design */
const styles = (theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'baseline',
  },
  accordion: {
    borderRadius: '8px 8px 0px 0px',
    width: '90%',
  },
  summary: {
    'backgroundColor': theme.palette.secondary.light,
  },
  summaryRoot: {
    '&.Mui-expanded': {
      minHeight: 'inherit',
    },
  },
  summaryContent: {
    'display': 'flex',
    'alignItems': 'center',
    '&.Mui-expanded': {
      margin: '0px',
    },
  },
  accordionSummaryExpandIcon: {
    padding: '12px 0px',
  },
  libelleAccordion: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  accordionDetails: {
    display: 'block',
    padding: '16px',
  },
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  red: {
    color: theme.palette.red.main,
  },
  textFieldFullWidth: {
    width: '100%',
  },
  error: {
    fontSize: '12px',
    color: 'red',
  },
  flex: {
    display: 'flex',
  },
  flexWrap: {
    flexWrap: 'wrap' as const,
  },
  columnGap60: {
    columnGap: '60px',
  },
  marginTop8: {
    marginTop: '8px',
  },
  marginTop16: {
    marginTop: '16px',
  },
  width50: {
    width: '50%',
  },
  titre: {
    fontWeight: 'bold',
    marginTop: '24px',
  },
  textField: {
    width: '150px',
  },
  marginLeft8: {
    marginLeft: '8px',
  },
  switchContainer: {
    width: '50%',
    marginTop: '24px',
  },
  switch: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '8px 0px 0px',
  },
  boutonContainer: {
    display: 'flex',
    width: '10%',
  },
  marginAuto: {
    margin: 'auto',
  },
});

export interface ElementCalculProps {
  codesValeursPriseEnCharge: ItemGetValeursCodesModel[],
  listElementsCalculs: ElementCalculModel[],
  expanded: boolean,
  data: ElementCalculModel,
  onChangeElement:
    (input: string,
     event: React.ChangeEvent<{ name?: string; value: unknown } | HTMLInputElement>,
     currentElement: ElementCalculModel) => void,
  onChangeCurrency: (currentElement: ElementCalculModel, value: string) => void,
  onChangePanel: (panel: string) => void,
  onDelete: (event: React.MouseEvent<HTMLElement>, currentElement: ElementCalculModel) => void,
}

const ElementCalcul = (props: ElementCalculProps) => {
  const classes = useEmotionStyles(styles);
  const {codesValeursPriseEnCharge, listElementsCalculs, expanded, data, onChangeElement, onDelete, onChangePanel,
    onChangeCurrency} = props;
  const [formData, setFormData] = useState(data);
  const [errorFields, setErrorFields] = useState(data.errorFields);
  const [valueCurrency, setValueCurrency] = useState(data.plafondPriseEnCharge);
  const [checkTvaAssure, setCheckTvaAssure] = useState(data.tvaRecuperableParAssure === 'O');
  const [checkElementReparation, setCheckElementReparation] = useState(data.elementComprisReparation === 'O');
  const [checkAbattementADeduire, setCheckAbattementADeduire] = useState(data.abattementADeduire === 'O');

  const itemsListPriseEnCharge = codesValeursPriseEnCharge.map((item, index) => {
    const isElement = !!listElementsCalculs.find((element) => element.priseEnCharge === item.valeur);
    return (
      <MenuItem
        disabled={isElement && item.valeur !== 'Z'}
        key={item.valeur}
        id={`priseEnCharge-${index}`}
        value={item.valeur}
      >
        {item.libelleedition}
      </MenuItem>
    )
  });

  useEffect(() => {
    setErrorFields(data.errorFields)
  }, [data.errorFields]);

  useEffect(() => {
    setFormData(data)
  }, [data]);

  useEffect(() => {
    setFormData({...formData, plafondPriseEnCharge: valueCurrency});
    onChangeCurrency(data, valueCurrency)
  }, [valueCurrency]);

  const handleChangeTauxPriseEnCharge = (input: string,
      event: React.ChangeEvent<{ name?: string; value: unknown }> | React.ChangeEvent<HTMLInputElement>) => {
    if (input === 'tauxPriseEnCharge' && event.target.value as number > 100) {
      event.target.value = '100'
    }
  }
  const handleChangeTvaRecuperableParAssure = (input: string,
      event: React.ChangeEvent<{ name?: string; value: unknown }> | React.ChangeEvent<HTMLInputElement>) => {
    if (input === 'tvaRecuperableParAssure') {
      setFormData({...formData, [input]: (event as React.ChangeEvent<HTMLInputElement>).target.checked ? 'O' : 'N'});
      setCheckTvaAssure((event as React.ChangeEvent<HTMLInputElement>).target.checked)
    }
  }
  const handleChangeElementComprisReparation = (input: string,
      event: React.ChangeEvent<{ name?: string; value: unknown }> | React.ChangeEvent<HTMLInputElement>) => {
    if (input === 'elementComprisReparation') {
      setFormData({...formData, [input]: (event as React.ChangeEvent<HTMLInputElement>).target.checked ? 'O' : 'N'});
      setCheckElementReparation((event as React.ChangeEvent<HTMLInputElement>).target.checked)
    }
  }
  const handleChangeAbattementADeduire = (input: string,
      event: React.ChangeEvent<{ name?: string; value: unknown }> | React.ChangeEvent<HTMLInputElement>) => {
    if (input === 'abattementADeduire') {
      setFormData({...formData, [input]: (event as React.ChangeEvent<HTMLInputElement>).target.checked ? 'O' : 'N'});
      setCheckAbattementADeduire((event as React.ChangeEvent<HTMLInputElement>).target.checked)
    }
  }

  const handleChange = (input: string) =>
    (event: React.ChangeEvent<{ name?: string; value: unknown }> | React.ChangeEvent<HTMLInputElement>) => {
      if (input === 'priseEnCharge') {
        setErrorFields({...errorFields, priseEnChargeError: false});
      }
      handleChangeTauxPriseEnCharge(input, event)
      setFormData({...formData, [input]: event.target.value});
      handleChangeTvaRecuperableParAssure(input, event)
      handleChangeElementComprisReparation(input, event)
      handleChangeAbattementADeduire(input, event)
      onChangeElement(input, event, formData)
    };

  const libellePriseEnCharge = findElementByCodeInArray(codesValeursPriseEnCharge,
      formData.priseEnCharge)?.libelleedition;

  return (
    <div className={classes.root}>
      <Accordion
        className={classes.accordion}
        expanded={expanded}
        onChange={() => onChangePanel(`panel-${data.id}`)}
      >
        <AccordionSummary
          expandIcon={<ExpandMore/>}
          aria-controls='panel1a-content'
          id={'header-' + data.id}
          className={classes.summary}
          classes={{
            root: classes.summaryRoot,
            content: classes.summaryContent,
            expandIconWrapper: classes.accordionSummaryExpandIcon,
          }}
        >
          <Typography className={classes.libelleAccordion}>
            {props.data.id + 1} - {libellePriseEnCharge || <i>Ajoutez un élément de calcul</i>}
          </Typography>
        </AccordionSummary>

        <AccordionDetails className={classes.accordionDetails}>
          <Typography className={classes.libelle}>
            Élément de prise en charge <span className={classes.red}>*</span>
          </Typography>
          <TextFieldSelect
            className={classes.textFieldFullWidth}
            id={'element-' + props.data.id}
            name={'element'}
            marginDense={true}
            withEmptyItem={true}
            emptyItemLabel={'Aucun'}
            value={formData.priseEnCharge}
            itemsList={itemsListPriseEnCharge}
            onError={errorFields?.priseEnChargeError}
            onChange={handleChange('priseEnCharge')}
          />
          {errorFields?.priseEnChargeError &&
            <Typography className={classes.error}>Veuillez sélectionner une valeur</Typography>
          }

          {formData.priseEnCharge &&
            <>
              <div className={`${classes.flex} ${classes.columnGap60} ${classes.marginTop16}`}>
                <div className={classes.width50}>
                  <Typography className={classes.libelle}>Descriptif de l&apos;élément</Typography>
                  <TextFieldSearch
                    id={'descriptif-' + props.data.id}
                    className={classes.textFieldFullWidth}
                    value={formData.descriptif}
                    maxLength={20}
                    onChange={handleChange('descriptif')}
                  />
                </div>
                <div className={classes.width50}>
                  <Typography className={classes.libelle}>Montant franchise forfaitaire</Typography>
                  <InputMask
                    mask="99999"
                    maskPlaceholder={null}
                    value={formData.montantFranchiseForaitaire}
                    onChange={handleChange('montantFranchiseForaitaire')}
                  >
                    <TextFieldSearch
                      id={'montant-franchise-' + props.data.id}
                      className={classes.textFieldFullWidth}
                      endAdornment={'€'}
                    />
                  </InputMask>
                </div>
              </div>

              <Typography className={classes.titre}>Franchise proportionnelle</Typography>
              <div className={`${classes.flex} ${classes.flexWrap} ${classes.columnGap60} ${classes.marginTop8}`}>
                <div>
                  <Typography className={classes.libelle}>Taux</Typography>
                  <InputMask
                    mask='99'
                    maskPlaceholder={null}
                    value={formData.tauxFranchiseProportionnelle}
                    onChange={handleChange('tauxFranchiseProportionnelle')}
                  >
                    <TextFieldSearch
                      id={'taux-franchise-' + props.data.id}
                      className={classes.textField}
                      endAdornment={'%'}
                    />
                  </InputMask>
                </div>
                <div>
                  <Typography className={classes.libelle}>Montant plancher</Typography>
                  <InputMask
                    mask='99999'
                    maskPlaceholder={null}
                    value={formData.montantPlancherFranchiseProportionnelle}
                    onChange={handleChange('montantPlancherFranchiseProportionnelle')}
                  >
                    <TextFieldSearch
                      id={'montant-plancher-franchise-' + props.data.id}
                      className={classes.textField}
                      endAdornment={'€'}
                    />
                  </InputMask>
                </div>
                <div>
                  <Typography className={classes.libelle}>Montant plafond</Typography>
                  <InputMask
                    mask='99999'
                    maskPlaceholder={null}
                    value={formData.montantPlafondFranchiseProportionnelle}
                    onChange={handleChange('montantPlafondFranchiseProportionnelle')}
                  >
                    <TextFieldSearch
                      id={'montant-plafond-franchise-' + props.data.id}
                      className={classes.textField}
                      endAdornment={'€'}
                    />
                  </InputMask>
                </div>
              </div>

              <Typography className={classes.titre}>Prise en charge</Typography>
              <div className={`${classes.flex} ${classes.flexWrap} ${classes.columnGap60} ${classes.marginTop8}`}>
                <div>
                  <Typography className={classes.libelle}>Taux</Typography>
                  <InputMask
                    mask='999'
                    maskPlaceholder={null}
                    value={formData.tauxPriseEnCharge}
                    onChange={handleChange('tauxPriseEnCharge')}
                  >
                    <TextFieldSearch
                      id={'taux-prise-en-charge-' + props.data.id}
                      className={classes.textField}
                      endAdornment={'%'}
                    />
                  </InputMask>
                </div>
                <div>
                  <Typography className={`${classes.libelle} ${classes.flex}`}>
                    Montant plafond
                    <Tooltip
                      disableInteractive
                      className={classes.marginLeft8}
                      placement="top"
                      title={'Montant compris entre 0 et 9999999,99'}
                      PopperProps={{disablePortal: true}}
                    >
                      <HelpIcon/>
                    </Tooltip>
                  </Typography>
                  <TextFieldCurrency
                    id={'montant-plafond-prise-en-charge-' + props.data.id}
                    className={classes.textField}
                    value={valueCurrency}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValueCurrency(event.target.value)}
                  />
                </div>
              </div>

              <div className={classes.switchContainer}>
                <FormControlLabel
                  className={classes.switch}
                  label="TVA récupérable par l'assuré"
                  labelPlacement="start"
                  control={
                    <BlueSwitch
                      id={'tva-recuperable-' + data.id }
                      name="tvaRecuperableParAssure"
                      checked={checkTvaAssure}
                      onChange={handleChange('tvaRecuperableParAssure')}
                    />
                  }
                />
                <FormControlLabel
                  className={classes.switch}
                  label="Elément compris dans la réparation"
                  labelPlacement="start"
                  control={
                    <BlueSwitch
                      id={'element-compris-reparation-' + data.id}
                      name="elementComprisReparation"
                      checked={checkElementReparation}
                      onChange={handleChange('elementComprisReparation')}
                    />
                  }
                />
                <FormControlLabel
                  className={classes.switch}
                  label="Abattement pour usure à déduire"
                  labelPlacement="start"
                  control={
                    <BlueSwitch
                      id={'abattement-a-deduire-' + data.id}
                      name="abattementADeduire"
                      checked={checkAbattementADeduire}
                      onChange={handleChange('abattementADeduire')}
                    />
                  }
                />
              </div>
            </>
          }
        </AccordionDetails>
      </Accordion>

      <div className={classes.boutonContainer}>
        <div className={classes.marginAuto}>
          <ButtonBlueDelete
            id={'bouton-supprimer-' + formData.id}
            onClick={(event: React.MouseEvent<HTMLElement>) => onDelete(event, data)}
          />
        </div>
      </div>
    </div>
  );
};

export default ElementCalcul;
