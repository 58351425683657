import React from 'react';
import {ItemHistoriqueModel} from '../../../../model/detailsDossier/ItemHistoriqueModel';
import {DetailedBlocHistorique} from './DetailedBlocHistorique';
import {useMissionContext} from '../../../../context/MissionContext';
import {UserContext} from '../../../../context/UserContext';

interface ContentDetailedHistoriqueProps {
  messagesList: ItemHistoriqueModel[] | undefined,
  onClickPj: (tabPanelValue: number, idPJ: string) => void
}

export const ContentDetailedHistorique = (props: ContentDetailedHistoriqueProps) => {
  const {user} = React.useContext(UserContext);
  const {mission} = useMissionContext();
  const {messagesList, onClickPj} = props;
  return (
    <>
      {messagesList?.map((item: ItemHistoriqueModel, index: number) => {
        const keyDetailedBlocHistorique = `${index}`
        return (
          <DetailedBlocHistorique
            key={keyDetailedBlocHistorique}
            id={`message-${index}`}
            click = {onClickPj}
            libelle = {item.libelle}
            emetteur = {item.emetteur}
            destinataire = {item.destinataire}
            destinataireAbo={item.destinataireAbo}
            statut = {item.statut}
            etat = {item.etat}
            envoyeRecu={item.envoyeRecu}
            dateReception = {item.dateReception}
            dateEmission = {item.dateEmission}
            idDocument = {item.idDocument}
            ssType = {item.ssType}
            type = {item.type}
            typeCode = {item.typeCode}
            codeService={user.service}
            isnIdentifier = {item.isnIdentifier}
            archive = {item.archive}
            docVisualisable = {item.docVisualisable}
            nbPieceJointe = {item.nbPieceJointe}
            idPieceJointe = {item.idPieceJointe}
            referenceSinistre = {mission?.infoMissionDTO?.referenceSinistre}
            isDossierSimple = {mission?.dossierSimple}
            userCodeAbonne={user.codeAbo}
            isAssistance={user.isAssistance}
          />
        )
      })}
    </>
  )
};
