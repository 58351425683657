import React from 'react';
import BlocTitle from '../../../rechercherRDV/detailRDV/BlocTitle';
import {Typography} from '@mui/material';
import {customTheme} from '../../../../../common/GlobalTheme';
import {ButtonLink} from '../../../../common/buttons/ButtonLink';
import useEmotionStyles from '../../../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';
import {dateToDateAndHourFullText} from '../../../../../common/utils/DateUtils';
import moment from 'moment/moment';

interface BlocRendezVousProps {
  dateDebut: string,
  dateFin?: string,
  duree?: string,
  hasRoleModificationRDV: boolean,
  rdvActif: boolean,
  onChangeCreneau?: (event: React.MouseEvent<HTMLElement>) => void,
}

type StylesProps = {isRdvModifiable: boolean}
/** Design */
const styles = (theme: Theme, props: StylesProps) => ({
  container: {
    marginTop: '24px',
  },
  card: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: '8px',
    padding: props.isRdvModifiable ? '16px 16px 2px' : '16px',
  },
  date: {
    fontWeight: 'bold',
    color: customTheme.palette.primary.main,
    marginBottom: '6px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonLink: {
    fontSize: '14px',
  },
});

export const BlocRendezVous = (props: BlocRendezVousProps) => {
  const {dateDebut, dateFin, duree, hasRoleModificationRDV, rdvActif, onChangeCreneau} = props;
  const isRdvModifiable = hasRoleModificationRDV && rdvActif;
  const classes = useEmotionStyles(styles, {isRdvModifiable});

  const heure = moment.duration(moment(dateFin).diff(moment(dateDebut))).hours();
  const minute = moment.duration(moment(dateFin).diff(moment(dateDebut))).minutes();
  const dureeCreneau = () => {
    if (duree) {
      return duree;
    } else {
      const h = heure > 0 ? heure + 'h' : '';
      const m = minute > 0 ? minute : '';
      const min = heure <= 0 ? ' min' : '';
      return `${h}${m}${min}`;
    }
  };

  const date = dateToDateAndHourFullText(moment(dateDebut)).substring(0, 1).toUpperCase() +
    dateToDateAndHourFullText(moment(dateDebut)).substring(1);

  return (
    <div className={classes.container}>
      <BlocTitle title={'Rendez-vous'}/>
      <div className={classes.card}>
        <Typography className={classes.date}>{date}</Typography>

        <Typography style={customTheme.styledTypography.libelle}>Durée du créneau</Typography>
        <Typography style={customTheme.styledTypography.donneeGrise14}>{dureeCreneau()}</Typography>

        {hasRoleModificationRDV && onChangeCreneau && rdvActif &&
          <div className={classes.buttonContainer}>
            <ButtonLink
              id={'boutonModifierCreneau'}
              className={classes.buttonLink}
              libelle={'Modifier le créneau'}
              onClick={onChangeCreneau}
            />
          </div>
        }
      </div>
    </div>
  )
};
