import React, {useEffect, useState} from 'react';
import {AccordionDetails, MenuItem, Typography} from '@mui/material';
import {TextFieldSearch} from '../../../common/formsComponents/TextFieldSearch';
import {TextFieldSelect} from '../../../common/formsComponents/TextFieldSelect';
import {TextFieldMultiline} from '../../../common/formsComponents/TextFiledMultiline';
import {ButtonBlue} from '../../../common/buttons/ButtonBlue';
import {IAttachment} from '../initialStateFormFDV';
import {ItemGetValeursCodesModel} from '../../../../model/common/ItemGetValeursCodesModel';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {CommonAttachmentForm} from '../../common/CommonAttachmentForm';

export interface IAttachmentProps {
    expanded: boolean,
    attachment: IAttachment,
    codesValeursNaturePieceJointe: ItemGetValeursCodesModel[],
    onChangePanel: (panel: string) => void,
    onSave: (attachment: IAttachment) => void,
    onChangeAttachment: (attachment: IAttachment) => void,
    onDelete: (attachment: IAttachment) => void,
}

/** Design */
const styles = (theme: Theme) => ({
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  red: {
    color: 'red',
  },
  error: {
    fontSize: '12px',
  },
  textField: {
    width: '100%',
  },
});

const Attachment = (props: IAttachmentProps) => {
  const classes = useEmotionStyles(styles);
  const {
    expanded, attachment, codesValeursNaturePieceJointe,
    onSave, onDelete, onChangePanel, onChangeAttachment,
  } = props;
  const [formData, setFormData] = useState<IAttachment>(attachment);
  const [errorFields, setErrorFields] = useState(
      {natureError: false, referenceError: false, descriptifError: false},
  );

  const codesValeursNaturePieceJointeSort = [...codesValeursNaturePieceJointe].sort((a: ItemGetValeursCodesModel,
      b: ItemGetValeursCodesModel) => {
    if (a.libelleedition > b.libelleedition) {
      return 1
    } else {
      return -1
    }
  });
  const itemsListNaturePieceJointe = codesValeursNaturePieceJointeSort.map((item: ItemGetValeursCodesModel) => {
    return (<MenuItem key={item.valeur} value={item.valeur}>{item.libelleedition}</MenuItem>)
  });

  useEffect(() => {
    setFormData({...attachment})
  }, [attachment]);

  const handleChange = (input: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (input === 'nature') setErrorFields({...errorFields, natureError: false, descriptifError: false});
      else if (input === 'reference') setErrorFields({...errorFields, referenceError: false});
      else setErrorFields({...errorFields, descriptifError: false});

      const attachmentApdated = {...formData, completed: false, [input]: event.target.value};
      setFormData(attachmentApdated);
      onChangeAttachment(attachmentApdated)
    };

  const handleSubmit = async () => {
    setErrorFields({
      natureError: !formData.nature,
      referenceError: !formData.reference,
      descriptifError: formData.nature === '99' && !formData.descriptif,
    });
    if (formData.nature && formData.reference && (formData.nature === '99' ? formData.descriptif : true)) {
      onSave(formData)
    }
  };

  return (
    <CommonAttachmentForm
      attachment={attachment}
      expanded={expanded}
      onChangePanel={onChangePanel}
      onDelete={onDelete}
      contentAccordionDetail={
        <AccordionDetails style={{display: 'block', padding: '16px'}}>
          <div>
            <Typography className={classes.libelle}>
              Nom du justificatif <span className={classes.red}>*</span>
            </Typography>
            <TextFieldSearch id={'reference'} className={classes.textField} value={formData.reference}
              maxLength={50} onChange={handleChange('reference')} onError={errorFields.referenceError}
            />
            {errorFields.referenceError &&
              <Typography className={`${classes.red} ${classes.error}`}>
                Veuillez saisir une valeur
              </Typography>
            }
          </div>
          <div style={{marginTop: '10px'}}>
            <Typography className={classes.libelle}>
              Nature du justificatif <span className={classes.red}>*</span>
            </Typography>
            <TextFieldSelect
              className={classes.textField}
              id={'nature'}
              name={'nature'}
              marginDense={true}
              value={formData.nature}
              itemsList={itemsListNaturePieceJointe}
              onChange={handleChange('nature')}
              onError={errorFields.natureError}
            />
            {errorFields.natureError &&
              <Typography className={`${classes.red} ${classes.error}`}>
                Veuillez sélectionner une valeur
              </Typography>
            }
          </div>
          <div style={{marginTop: '10px'}}>
            <Typography className={classes.libelle}>
              Descriptif du justificatif
              {formData.nature === '99' && <span className={classes.red}> *</span>}
            </Typography>
            <TextFieldMultiline id={'textFieldDescriptif'} maxLength={80} rows={2} marginDense={true}
              value={formData.descriptif} onError={errorFields.descriptifError}
              onChange={handleChange('descriptif')} helperText={formData.descriptif.length + '/80'}
            />
            {errorFields.descriptifError &&
              <Typography className={`${classes.red} ${classes.error}`}>
                Veuillez saisir une valeur
              </Typography>
            }
          </div>
          <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '16px'}}>
            <ButtonBlue id={'boutonValider' + attachment.id} libelle={'Valider'} onClick={handleSubmit}
              disabled={errorFields.natureError || errorFields.referenceError || errorFields.descriptifError}
            />
          </div>
        </AccordionDetails>
      }
    />
  );
};

export default Attachment;
