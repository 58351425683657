import React, {useState} from 'react'
import {InfoSollicitation} from '../../../../model/detailsDossier/SuiviReparationModel';
import {Typography} from '@mui/material';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';

interface MotifsSollicitationProps {
    infoSollicitation: InfoSollicitation[]
}

/** Design */
const styles = (theme: Theme) => ({
  divClick: {
    display: 'flex',
    color: theme.palette.link.main,
    cursor: 'pointer',
    width: 'max-content',
  },
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  motifSollicitation: {
    color: theme.palette.text.primary,
  },
});


const MotifsSollicitation = (props: MotifsSollicitationProps) => {
  const classes = useEmotionStyles(styles);
  const {infoSollicitation} = props;
  const expandable = infoSollicitation.length > 3;
  const [expanded, setExpanded] = useState<boolean>(false);
  const [motifsToDisplay, setMotifsToDisplay] = useState<InfoSollicitation[]>(infoSollicitation.slice(0, 3));

  const onClickVoirPlus = () => {
    setMotifsToDisplay(infoSollicitation);
    setExpanded(true)
  };

  const onClickVoirMoins = () => {
    setMotifsToDisplay(infoSollicitation.slice(0, 3));
    setExpanded(false)
  };

  return (
    <div>
      {infoSollicitation && infoSollicitation.length > 0 &&
      <div>
        <Typography className={classes.libelle}>
          {infoSollicitation.length > 1 ? 'Motifs de la sollicitation' : 'Motif de la sollicitation'}
        </Typography>
        {motifsToDisplay?.map((motif, index) => {
          const keyTypography = `${index}`
          return (<Typography key={keyTypography} className={classes.motifSollicitation}>{motif.motif}</Typography>)
        })}
        {expandable && !expanded &&
          <div className={classes.divClick} onClick={() => onClickVoirPlus()} aria-hidden={true}>
            <Typography
              variant="button"
              display="inline"
              style={{fontSize: '14px'}}>
                  VOIR PLUS
            </Typography>
          </div>
        }
        {expandable && expanded &&
          <div className={classes.divClick} onClick={onClickVoirMoins} aria-hidden={true}>
            <Typography
              variant="button"
              display="inline"
              style={{fontSize: '14px'}}>
                  VOIR MOINS
            </Typography>
          </div>
        }
      </div>
      }
    </div>
  );
};

export default MotifsSollicitation;
