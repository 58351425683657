import React from 'react';
import {ItemHistoriqueModel} from '../../../../model/detailsDossier/ItemHistoriqueModel';
import {SimplifiedBlocHistorique} from './SimplifiedBlocHistorique';

interface ContentSimplifiedHistoriqueProps {
  messagesList: ItemHistoriqueModel[] | undefined,
  onClickPj: (tabPanelValue: number, idPJ: string) => void,
  onClickComment: ((tabPanelValue: number, date: string) => void) | undefined
}

export const ContentSimplifiedHistorique = (props: ContentSimplifiedHistoriqueProps) => {
  const {messagesList, onClickPj, onClickComment} = props;
  return (
    <React.Fragment>
      {messagesList?.map( (item: ItemHistoriqueModel, index: number) => {
        const keySimplifiedBlocHistorique = `${index}`
        return (
          <SimplifiedBlocHistorique
            key={keySimplifiedBlocHistorique}
            indexBubble={index} item={item} click={onClickPj} onClickComment={onClickComment}/>
        )
      })}
    </React.Fragment>
  )
};
