import React, {useState} from 'react';
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AttachmentsForms from './AttachmentsForms';
import {ButtonLink} from '../../../common/buttons/ButtonLink';
import {ButtonBlue} from '../../../common/buttons/ButtonBlue';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import cloneDeep from 'lodash/cloneDeep';
import {CommonUploadFiles} from '../../common/CommonUploadFiles';
import {ID_BOUTON_ENREGISTRER, ID_BOUTON_RETOUR} from '../../../../constantes/ids/Ids';
import {TABLE_TYPES_FILES_OK} from '../../utils/Utils';

/** Taille maximale par fichier (10 Mo) */
const MAX_FILE_SIZE = 10 * 1024;

/** Taille maximale de l'ensemble des fichiers (30 Mo) */
const MAX_SIZE_ALL_FILES = 30 * 1024;

/** Nombre maximum de fichiers (50) */
const MAX_NUMBER_FILES = 50;

export interface FilesDragAndDropProps {
  attachments: Attachments,
  onSave: (attachments: Attachments) => void,
  onCloseAttachmentForms: () => void,
}

export interface Attachments {
  attachments: Attachment[],
  isValid: boolean,
  error?: ErrorFile,
}

export interface Attachment {
  id: number,
  libelle: string,
  type: string,
  nature: string,
  version: string,
  completed: boolean,
  file: File,
  onError: boolean,
  onTypeError : boolean,
}

export interface ErrorFile {
  type: string,
  message: string,
}

/** Design */
const styles = (theme: Theme) => ({
  container: {
    height: '100%',
  },
  body: {
    height: 'calc(100vh - 175px)',
    overflowY: 'auto' as const,
    paddingLeft: '5px',
  },
  footer: {
    height: '10vh',
    display: 'flex',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 'auto 0px', // pour centrer verticalement
    width: '100%',
    paddingRight: '20px',
  },
});

export const FilesDragAndDrop = (props: FilesDragAndDropProps) => {
  const classes = useEmotionStyles(styles);
  const {attachments, onCloseAttachmentForms, onSave} = props;
  const [selectedFiles, setSelectedFiles] = useState<Attachments>(cloneDeep(attachments));
  const [disabled, setDisabled] = useState(attachments.attachments.length <= 0);

  const buildAttachments = (files: File[]) => {
    const attachmentsTemporary: Attachments = {attachments: [], isValid: true};
    // s'il y a des fichiers existants
    const existingFiles: File[] = selectedFiles?.attachments ?
            Array.from(selectedFiles.attachments, (attach) => attach.file) : [];

    const validateExistingFiles: File[] = selectedFiles?.attachments
        .filter((attachment) => !attachment.onError && attachment.file)
        .map((attach) => attach.file);

    // vérifier le nombre total des fichiers
    if (checkNumberFiles([...files, ...existingFiles])) {
      attachmentsTemporary.error = {
        type: 'overflow',
        message: 'Nombre maximal de fichiers atteint',
      };
      attachmentsTemporary.isValid = false
    } else if (checkSizeAllFiles([...files, ...validateExistingFiles])) {
      // vérifier la taille totale des fichiers
      attachmentsTemporary.error = {
        type: 'sizeAllFiles',
        message: 'Taille maximale atteinte pour l\'ensemble des fichiers',
      };
      attachmentsTemporary.isValid = false
    } else {
      attachmentsTemporary.attachments = handleFiles(files);
    }
    return attachmentsTemporary
  };

  const handleAttachmentsTemporary = async (attachmentsTemporary: Attachments) => {
    if (attachmentsTemporary.isValid) {
      setDisabled(true);
      if (selectedFiles) {
        attachmentsTemporary.attachments = [...selectedFiles.attachments, ...attachmentsTemporary.attachments]
      }
      let nbAttachmentsOnError = 0;
      let nbAttachmentsNotCompleted = 0;
      attachmentsTemporary.attachments.forEach((attachment) => {
        if (attachment.onError) nbAttachmentsOnError++;
        if (!attachment.completed) nbAttachmentsNotCompleted++;
      });
      if (nbAttachmentsOnError > 0 && nbAttachmentsNotCompleted === 0) setDisabled(false);
      setSelectedFiles({...attachmentsTemporary})
    } else if (selectedFiles) {
      setSelectedFiles({
        ...selectedFiles,
        isValid: attachmentsTemporary.isValid,
        error: attachmentsTemporary.error,
      })
    } else {
      setSelectedFiles({...attachmentsTemporary})
    }
    // Permet de se positionner sur le 1er élément de la liste une fois les fichiers choisis
    // fonction async pour attendre que la liste selectedFiles soit mise à jour et que les éléments panel-X soient créés
    const panel = document.getElementById('panel-' + attachmentsTemporary.attachments
        .find((attachment) => !attachment.completed)?.id);
    panel?.scrollIntoView({behavior: 'smooth', block: 'center'})
  };

  const handleFiles = (files: File[]) => {
    const attachmentsList: Attachment[] = [];
    files.forEach((file, index) => {
      const attachment: Attachment = {
        id: selectedFiles?.attachments ? selectedFiles.attachments?.length + index : index,
        libelle: file.name,
        type: '',
        nature: '',
        version: '',
        completed: Math.round(file.size / 1024) > MAX_FILE_SIZE,
        file: file,
        onError: Math.round(file.size / 1024) > MAX_FILE_SIZE || !checkTypeFile(file.name),
        onTypeError: !checkTypeFile(file.name),
      };
      attachmentsList.push(attachment)
    });
    return attachmentsList
  };

  const checkNumberFiles = (files: File[]) => {
    return files.length > MAX_NUMBER_FILES
  };

  const checkTypeFile = (name : string) => {
    const type = '.' + name.split('.').pop();
    return TABLE_TYPES_FILES_OK.includes(type ? type.toLowerCase() : '')
  };

  const checkSizeAllFiles = (files: File[]) => {
    const sizeAllFiles = files.map((file) => file.size).reduce((prev, curr) => prev + curr, 0);
    return Math.round(sizeAllFiles / 1024) > MAX_SIZE_ALL_FILES
  };

  const checkAllFilesCompleted = (attachmentsList: Attachment[]) => {
    return attachmentsList.every((attachment) => {
      return attachment.completed
    })
  };

  const onSaveAll = () => {
    onSave({attachments: selectedFiles.attachments.filter((attachment) => !attachment.onError), isValid: true})
  };

  const onSaveAttachments = async (attachmentsList: Attachment[]) => {
    checkAllFilesCompleted(attachmentsList) ? setDisabled(false) : setDisabled(true);
    if (selectedFiles) {
      const attachmentsTemporary = {attachments: attachmentsList, isValid: true};
      setSelectedFiles({...attachmentsTemporary})
    }
  };

  const onDeleteAttachment = (attachmentsList: Attachment[]) => {
    checkAllFilesCompleted(attachmentsList) ? setDisabled(false) : setDisabled(true);
    const attachmentsCopy = {attachments: attachmentsList, isValid: true};
    setSelectedFiles({...attachmentsCopy})
  };

  return (
    <div className={classes.container}>
      <div className={classes.body}>
        <ButtonLink
          id={ID_BOUTON_RETOUR}
          icon={<ArrowBackIcon/>}
          libelle={'Retour'}
          onClick={onCloseAttachmentForms}
        />

        <CommonUploadFiles
          selectedFiles={selectedFiles}
          buildAttachments={buildAttachments}
          handleAttachmentsTemporary={handleAttachmentsTemporary}
        />

        {selectedFiles.attachments.length > 0 &&
          <AttachmentsForms
            attachments={selectedFiles.attachments}
            onSaveAttachments={onSaveAttachments}
            onDeleteAttachment={onDeleteAttachment}
          />
        }
      </div>
      <div className={classes.footer}>
        <div className={classes.buttons}>
          <ButtonBlue
            id={ID_BOUTON_ENREGISTRER}
            icon={<SaveIcon/>}
            libelle={'Enregistrer'}
            onClick={onSaveAll}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};
