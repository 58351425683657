import {Accordion, AccordionSummary, Typography} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {ReactComponent as InProgressIcon} from '../../../images/icones/icn-in-progress.svg';
import {customTheme} from '../../../common/GlobalTheme';
import {ButtonBlueDelete} from '../../common/formsComponents/ButtonBlueDelete';
import React from 'react';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';

/** Design */
const styles = (theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'baseline',
  },
  summary: {
    backgroundColor: theme.palette.secondary.light,
  },
  summaryRoot: {
    '&.Mui-expanded': {
      minHeight: 'inherit',
    },
  },
  summaryContent: {
    'display': 'flex',
    'alignItems': 'center',
    '&.Mui-expanded': {
      margin: '0px',
    },
  },
  libelleAccordion: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  fileOnError: {
    color: '#F0283F',
    fontSize: '14px',
  },
});

export interface CommonAttachmentFormProps {
  attachment: any,
  expanded: boolean,
  onChangePanel: (panel: string) => void,
  onDelete: (attachment: any) => void,
  contentAccordionDetail: React.JSX.Element
}

export const CommonAttachmentForm = (props: CommonAttachmentFormProps) => {
  const classes = useEmotionStyles(styles);
  const {attachment, expanded, onChangePanel, onDelete, contentAccordionDetail} = props

  return (
    <div>
      <div className={classes.root}>
        <Accordion style={{width: '90%', borderRadius: '8px 8px 0px 0px'}}
          expanded={attachment.onError ? false : expanded}
          onChange={() => onChangePanel(`panel-${attachment.id}`)}
          disabled={attachment.onError}
        >
          <AccordionSummary
            expandIcon={<ExpandMore/>}
            aria-controls='panel1a-content'
            id={`panel1a-header-${attachment.id}`}
            className={classes.summary}
            classes={{root: classes.summaryRoot, content: classes.summaryContent}}
          >
            {attachment.onError && <CancelIcon style={{color: '#F0283F'}}/>}
            {attachment.completed && <CheckCircleIcon style={{color: '#1EA851'}}/>}
            {!attachment.onError && !attachment.completed &&
              <InProgressIcon style={{fill: customTheme.palette.primary.main, marginRight: '10px'}}/>}
            <Typography className={classes.libelleAccordion}>{attachment.file.name}</Typography>
          </AccordionSummary>
          {contentAccordionDetail}
        </Accordion>
        <div style={{display: 'flex', width: '10%'}}>
          <div style={{margin: 'auto'}}>
            <ButtonBlueDelete id={'boutonSupprimer' + attachment.id} onClick={() => onDelete(attachment)}/>
          </div>
        </div>
      </div>
      {attachment.onError && attachment.onTypeError &&
        <Typography className={classes.fileOnError}>Erreur : format de fichier non autorisé</Typography>
      }
      {attachment.onError && !attachment.onTypeError &&
        <Typography className={classes.fileOnError}>Erreur : fichier trop volumineux</Typography>
      }
    </div>
  )
}
