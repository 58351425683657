import {Theme} from '@emotion/react';

export const stylesCommon = (theme: Theme) => ({
  cardContent: {
    backgroundColor: theme.palette.secondary.light,
    padding: '24px',
    borderRadius: '0px 0px 8px 8px',
    marginBottom: '16px',
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap' as const,
    rowGap: '8px',
  },
  line: {
    marginTop: '24px',
  },
  textfield: {
    width: '360px',
    marginRight: '24px',
  },
  textfieldSmall: {
    width: '240px',
    marginRight: '24px',
  },
  helpIcon: {
    width: '15px',
    height: 'auto',
  },
  loaderPreremplir: {
    display: 'block',
    marginTop: '32px',
    marginLeft: '40px',
  },
  btnPreremplir: {
    marginTop: '32px',
  },
  remplissageOK: {
    color: theme.palette.green.main,
    marginTop: '8px',
  },
  remplissageKO: {
    color: 'red',
    marginTop: '8px',
  },
  red: {
    color: 'red',
  },
  marginTop: {
    marginTop: '24px',
  },
  error: {
    fontSize: '12px',
    marginTop: '16px',
  },
  loader: {
    display: 'block',
    margin: '0px 60px',
  },
});
