import React, {ChangeEvent, useCallback, useEffect, useState} from 'react';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet/dist/leaflet';
import {DivIcon} from 'leaflet';
import {Card, CardContent, MenuItem, Typography, InputLabel} from '@mui/material';
import {CardReparateur} from './CardReparateur';
import {customTheme} from '../../../../common/GlobalTheme';
import {Circle, MapContainer, Marker, ScaleControl, TileLayer, ZoomControl} from 'react-leaflet';
import RefreshIcon from '@mui/icons-material/Refresh';
import {getListeRDV} from '../../../../api/idigo';
import {getCoordonneesGPS} from '../../../../api/infogeo';
import {Loader} from '../../../common/Loader';
import {TextFieldSelect} from '../../../common/formsComponents/TextFieldSelect';
import AutoCompleteAdresse from '../components/AutoCompleteAdresse';
import {UserContext} from '../../../../context/UserContext';
import {emptyItem, ItemGetValeursCodesModel} from '../../../../model/common/ItemGetValeursCodesModel';
import {DisponibilitesProps} from '../../../../model/priseRDV/DisponibiliteModel';
import {CoordonneesGPS} from '../../../../model/priseRDV/CoordonneeGPSModel';
import {AdresseAutoCompleteProps} from '../../../../model/common/AdresseAutoCompleteModel';
import {PartenaireProps} from '../../../../model/priseRDV/PartenaireModel';
import {CreneauxDisponibiliteProps} from '../../../../model/priseRDV/CreneauDisponibiliteModel';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {customMarker} from './CustomMarkerHtml';
import BlueSwitch from '../../../common/BlueSwitch';
import {ButtonBlue} from '../../../common/buttons/ButtonBlue';
import {ReactComponent as ResetIcon} from '../../../../images/icones/Reset.svg';
import {ReactComponent as SettingsIcon} from '../../../../images/icones/icn-settings.svg';
import {servicesProposes} from './ServicesProposes';
import {ButtonLink} from '../../../common/buttons/ButtonLink';
import AutoCompleteMultipleMarque from '../components/AutoCompleteMultipleMarque';

interface RechercherReparateurProps {
  coordonneesGPSAssure: CoordonneesGPS,
  adresse: AdresseAutoCompleteProps,
  sousActiviteChoisie?: string,
  setAdresseCoordonneesGPS: (adresse: AdresseAutoCompleteProps, coordonneesGPS?: CoordonneesGPS) => void,
  onChangeActiveStep: (activeStep: number) => void,
  onChangePartenaire: (input: string, partenaire: {infosPartenaire: PartenaireProps,
    nextDispo: CreneauxDisponibiliteProps}) => void,
  setErrorApi: (isErrorApi: boolean) => void,
  codesValeursMarque: ItemGetValeursCodesModel[],
}

type StylesProps = {
  isNumberSup9?: boolean,
  isLoadingReparateurs?: boolean,
  isFilterModified?: boolean,
}
const styles = (theme: Theme, props: StylesProps) => ({
  container: {
    position: 'relative' as const,
  },
  map: {
    height: '715px',
  },
  bandeau: {
    zIndex: 1000,
    position: 'absolute' as const,
    top: '16px',
    right: 0,
    padding: '24px',
    width: '340px',
    backgroundColor: theme.palette.white.main,
    backdropFilter: 'blur(20px)',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    height: 'calc(100% - 64px)',
    transitionProperty: 'right',
    transitionDuration: '0.3s',
  },
  bandeauFooter: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    position: 'absolute' as const,
    bottom: '25px',
    width: '85%',
    alignItems: 'center',
  },
  resetIcon: {
    marginRight: '7px',
    fill: props.isLoadingReparateurs ? theme.palette.disabled.main : theme.palette.link.main,
  },
  buttonRight: {
    justifySelf: 'end',
  },
  letfToRight: {
    right: '-400px',
  },
  rightToLeft: {
    right: 0,
  },
  settingsIconContainer: {
    borderRadius: '5px 0 0 5px',
    width: '30px',
    position: 'absolute' as const,
    top: 0,
    left: '-40px',
    height: '30px',
    display: 'flex',
    padding: '5px',
    boxShadow: '0px 3px 6px ' + theme.palette.boxShadow.main,
    alignItems: 'center',
    flexDirection: 'column' as const,
    justifyContent: 'center',
    backgroundColor: theme.palette.white.main,
    cursor: 'pointer',
  },
  settingsIcon: {
    fill: props.isFilterModified ? theme.palette.link.main : theme.palette.primary.main,
  },
  containerFiltres: {
    columnGap: '24px',
  },
  repAgrees: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '24px',
    marginLeft: '-20px',
  },
  label: {
    color: theme.palette.text.primary,
    fontWeight: 500,
  },
  labelRepAgrees: {
    color: theme.palette.text.primary,
    fontWeight: 500,
    marginLeft: '15px',
  },
  select: {
    width: '100%',
  },
  marquePanel: {
    width: '100%',
  },
  adresse: {
    zIndex: 1000,
    position: 'absolute' as const,
    top: '16px',
    left: '30px',
    width: '420px',
  },
  textFieldAdresse: {
    margin: '4px',
  },
  resultats: {
    'zIndex': 1000,
    'position': 'absolute' as const,
    'top': '88px',
    'left': '30px',
    'minHeight': '100px',
    'maxHeight': '580px',
    'width': '420px',
    'overflowY': 'auto' as const,
    /* Firefox */
    'scrollbarWidth': 'thin' as const,
    'scrollbarColor': '#c1c1c1 #f1f1f1',
    /* Chrome */
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
      borderRadius: '40px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#c1c1c1',
      borderRadius: '40px',
      border: '1px solid transparent',
      backgroundClip: 'padding-box',
    },
  },
  separator: {
    margin: '0px',
    borderBottom: '1px solid ' + theme.palette.secondary.main,
  },
  rayon: {
    zIndex: 1000,
    position: 'absolute' as const,
    top: '16px',
    left: '475px',
    minWidth: '200px',
    display: 'flex',
  },
  contentRayon: {
    'display': 'flex',
    'justifyContent': 'center',
    'alignItems': 'center',
    'padding': '0px',
    '&:last-child': {
      padding: '5px 15px',
    },
  },
  textFieldRayon: {
    width: '120px',
    margin: '4px',
  },
  textRayon: {
    cursor: 'pointer',
  },
  separatorRayon: {
    borderRight: '1px solid ' + theme.palette.secondary.dark1,
    borderLeft: 'none',
    borderTop: 'none',
  },
  markerDivIcon: {
    border: 'none',
    backgroundColor: 'transparent',
  },
  numeroReparateur: {
    position: 'absolute' as const,
    color: theme.palette.white.main,
    fontFamily: theme.fonts.main,
    fontWeight: 'bold',
  },
  resetPosition: {
    zIndex: 999,
    position: 'absolute' as const,
    bottom: '100px',
    right: '10px',
    border: '2px solid rgba(0,0,0,0.2)',
    borderRadius: '4px',
    width: '30px',
    height: '30px',
    display: 'flex',
  },
  resetPositionHover: {
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
      cursor: 'pointer',
    },
  },
  contentResetPosition: {
    'margin': 'auto',
    'padding': '0px',
    '&:last-child': {
      padding: '0px',
    },
  },
  refreshIcon: {
    display: 'flex',
    margin: '0px',
    color: 'black',
    fontSize: '22px',
  },
});

export const targetIcon = L.icon({
  iconUrl: require('../../../../images/icones/icones-map/icn-target-map.svg').default,
  iconSize: [30, 30],
});

const getNbrDisposTotal = (rayon?: number) => {
  switch (rayon) {
    case 5:
      return '5';
    case 10:
      return '10';
    case 20:
      return '15';
    case 30:
      return '20';
    case 50:
      return '30';
    default:
      return '10';
  }
};

export const RechercherReparateur = ({coordonneesGPSAssure, adresse, sousActiviteChoisie, setAdresseCoordonneesGPS,
  codesValeursMarque, onChangeActiveStep, onChangePartenaire, setErrorApi}: RechercherReparateurProps) => {
  const {user} = React.useContext(UserContext);
  const [positionGPS, setPositionGPS] = useState<CoordonneesGPS>(coordonneesGPSAssure);
  const [rayonEnKm, setRayonEnKm] = useState(20);
  const rayonEnM = rayonEnKm * 1000;
  const defaultZoom = 10.25;
  const [nbrDisposTotal, setNbrDisposTotal] = useState(getNbrDisposTotal(rayonEnKm));
  const [isRayon, setIsRayon] = useState(false);
  const [classPar, setClassPar] = useState(1);
  const [servicePropose, setServicePropose] = useState('');
  const [repAgrees, setRepAgrees] = useState(false);
  const [isFilterRepAgree, setIsFilterRepAgree] = useState(repAgrees);
  const [map, setMap] = useState<L.Map>();
  const [circleRef, setCircleRef] = useState<L.Circle>();
  const [isLoadingReparateurs, setIsLoadingReparateurs] = useState(true);
  const [disponibilites, setDisponibilites] = useState<DisponibilitesProps[]>([]);
  const [disponibilitesTriees, setDisponibilitesTriees] = useState(disponibilites);
  const nbReparateurs = disponibilitesTriees.length;
  const [selectedAdresse, setSelectedAdresse] = useState<AdresseAutoCompleteProps>(adresse);
  const [errorAdresse, setErrorAdresse] = useState<boolean>(false);
  const [close, setClose] = React.useState(false);
  const [isFilterModified, setIsFilterModified] = React.useState(false);
  const [marquesSelectedList, setMarquesSelectedList] = React.useState<ItemGetValeursCodesModel[]>([]);

  const classes = useEmotionStyles(styles, {isLoadingReparateurs, isFilterModified});

  const toutesMarques: ItemGetValeursCodesModel = {
    valeur: 'TOUTES MARQUES',
    libelle: '',
    libelleedition: 'TOUTES MARQUES',
    etat: '',
    fonction: '',
    lowercase: '',
    uppercase: '',
  };

  const listMarques = [...codesValeursMarque];
  listMarques.unshift(toutesMarques);

  const rayonsList = [5, 10, 20, 30, 50];
  const itemsListRayon = rayonsList.map((rayon, index) => {
    const keyMenuItem = `${index}`
    return (
      <MenuItem key={keyMenuItem} value={rayon}>{rayon} km</MenuItem>
    )
  });

  const classParList = [{key: 1, val: 'Distance'}, {key: 2, val: 'Disponibilité'}];
  const itemsListClassPar = classParList.map((obj, index) => {
    const keyMenuItem = `${index}`
    return (
      <MenuItem key={keyMenuItem} value={obj.key}>{obj.val}</MenuItem>
    )
  });

  const itemsListServicesProposes = servicesProposes.map((obj, index) => {
    const keyMenuItem = `${index}`
    return (
      <MenuItem key={keyMenuItem} value={obj.code}>{obj.libelle}</MenuItem>
    )
  });

  const contains = (array: string[], str: string) => {
    let result = false;
    if (array && array.length > 0) {
      array.forEach((item) => {
        // Utiliser endsWith pour vérifier le code et pas le libelle, ex: VOLKSWAGEN (VOLK)
        if (item.toUpperCase().endsWith('(' + str.trim().toUpperCase() + ')')) {
          result = true;
        }
      })
    }
    return result;
  };

  const processResponseListeRDV = (data: any, rayonValue: number,
      onlyRepAgrees: boolean, serviceValue: string, filterClassPar?: boolean) => {
    let list = data.disponibiliteList;
    let listTtesMarques = data.disponibiliteList;
    let listMarquesUndefined = data.disponibiliteList;
    if (marquesSelectedList !== null && marquesSelectedList.length !== 0 && emptyItem === undefined) {
      listMarquesUndefined = listMarquesUndefined.filter((disp: DisponibilitesProps) =>
        !disp.partenaire.activites[0].marquesReparees ||
          disp.partenaire.activites[0].marquesReparees?.length === 0)

      listTtesMarques = listTtesMarques.filter((disp: DisponibilitesProps) =>
        contains(disp.partenaire.activites[0].marquesReparees, 'TTESMARQUES'))

      list = list.filter((disp: DisponibilitesProps) =>
        contains(disp.partenaire.activites[0].marquesReparees, marquesSelectedList[0].valeur) ||
          (marquesSelectedList.length > 1 &&
              contains(disp.partenaire.activites[0].marquesReparees, marquesSelectedList[1].valeur)))

      if (marquesSelectedList[0]?.valeur !== 'TTESMARQUES' &&
          marquesSelectedList[1]?.valeur !== 'TTESMARQUES') {
        list.push(...listTtesMarques)
      }
      list.push(...listMarquesUndefined)
    }
    setDisponibilites(list);
    setIsLoadingReparateurs(false);
    if (!filterClassPar) {
      setClassPar(rayonValue >= 20 ? 1 : 2);
      checkFilters(rayonValue >= 20 ? 1 : 2, !onlyRepAgrees, rayonValue, serviceValue, []);
    }
  }
  const getPartenaires = (rayon?: number, filterClassPar?: boolean, repAgreesFilter?: boolean, serviceFilter?: '',
      emptyItem?: any) => {
    setIsLoadingReparateurs(true);
    const activiteSousType = sousActiviteChoisie ?? '';
    const {latitude, longitude} = positionGPS;
    const rayonValue = rayon ?? rayonEnKm;
    const {numClient, userCompte} = user;
    const onlyRepAgrees = repAgreesFilter !== undefined ? !repAgreesFilter : !repAgrees;
    const serviceValue = serviceFilter ?? servicePropose;
    getListeRDV({lat: latitude, lng: longitude, rayon: rayonValue, activiteSousType: activiteSousType,
      nbrDisposTotal: rayon ? getNbrDisposTotal(rayon) : nbrDisposTotal,
      nonAgrees: onlyRepAgrees, agreePar: user.siret, tri: rayonValue >= 20 ? 'distance' : 'duree',
      numClient: numClient, userCompte: userCompte, servicesProposes: serviceValue})
        .then((response) => {
          const {data, status} = response;
          if (status === 200) {
            processResponseListeRDV(data, rayonValue, onlyRepAgrees, serviceValue, filterClassPar)
          } else {
            setErrorApi(true)
          }
        })
        .catch(console.error)
  };

  useEffect(() => {
    getPartenaires();
    setCircle(myCircle(isRayon ? 'red' : 'transparent', rayonEnM))
  }, [positionGPS]);

  useEffect(() => {
    setDisponibilitesTriees(disponibilites)
  }, [disponibilites]);

  const markerIcon = (id: string, isSelected: boolean): DivIcon => L.divIcon({
    className: classes.markerDivIcon,
    html: customMarker(id, isSelected),
    iconAnchor: L.point([15, 50]),
  });

  const markersTab = [L.marker([positionGPS.latitude, positionGPS.longitude], {icon: targetIcon, interactive: false})];
  const myMarkers = () => {
    disponibilitesTriees.forEach((disp) => {
      markersTab.push(L.marker(
          [disp.partenaire?.coordonneesAdresse.lat, disp.partenaire?.coordonneesAdresse.lng],
          {icon: markerIcon(disp.numero.toString(), disp.isSelectedOnMap)}).on('click', onClickMarker))
    });
    return (
      <>
        {markersTab.map((marker, index) => {
          const keyMarker = `${index}`
          return (
            <Marker
              key={keyMarker}
              icon={marker.getIcon()}
              interactive={marker.options.interactive}
              position={[marker.getLatLng().lat, marker.getLatLng().lng]}
              eventHandlers={{click: onClickMarker}}
            />
          );
        })}
      </>
    )
  };

  const onResetPosition = useCallback(() => {
    map?.setView([positionGPS.latitude, positionGPS.longitude]);
    if (circleRef?.getBounds()) map?.fitBounds(circleRef?.getBounds())
  }, [map]);

  const onClickMarker = (event: any) => {
    const reparateursCopy = [...disponibilitesTriees];
    reparateursCopy.forEach((reparateur, _) => {
      reparateur.isSelectedOnMap = reparateur.partenaire.coordonneesAdresse.lat === event.target.getLatLng().lat &&
          reparateur.partenaire.coordonneesAdresse.lng === event.target.getLatLng().lng;
    });
    setDisponibilitesTriees(reparateursCopy);
    map?.setView([event.target.getLatLng().lat, event.target.getLatLng().lng], 16)
  };

  const onClickCard = (numeroReparateur: string, infosPartenaire?: PartenaireProps) => {
    markersTab.find((marker) => marker._latlng.lat === infosPartenaire?.coordonneesAdresse.lat &&
      marker._latlng.lng === infosPartenaire?.coordonneesAdresse.lng).fire('click')
  };

  const myCircle = (color: string, rayon: number) => {
    return (
      <Circle
        ref={(ref) => {
          if (ref) setCircleRef(ref)
        }}
        interactive={false}
        center={[positionGPS.latitude, positionGPS.longitude]}
        pathOptions={{color: color, fillColor: 'transparent'}}
        radius={rayon}
      />
    )
  };

  const [circle, setCircle] = useState(myCircle('transparent', rayonEnM));
  const handleClose = () => {
    const bandeau = document.getElementById('bandeau')!;
    const settingsIcon = document.getElementById('settingsIcon')!;
    if (!close) {
      bandeau.classList.add(classes.letfToRight);
      bandeau.classList.remove(classes.rightToLeft);
      settingsIcon.style.left = '-60px';
      settingsIcon.style.borderRadius = '5px 5px 5px 5px'
    } else {
      bandeau.classList.add(classes.rightToLeft);
      bandeau.classList.remove(classes.letfToRight);
      settingsIcon.style.left = '-40px';
      settingsIcon.style.borderRadius = '5px 0 0 5px'
    }
    setClose(!close)
  };

  const updateCircle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRayonEnM = Number(event.target.value) * 1000;
    setCircle(myCircle(isRayon ? 'red' : 'transparent', newRayonEnM))
  };

  const onChangeRayon = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (rayonEnKm !== Number(event.target.value)) {
      setDisponibilites([]);
      setDisponibilitesTriees([]);
      setNbrDisposTotal(getNbrDisposTotal(Number(event.target.value)));
      getPartenaires(Number(event.target.value));
      updateCircle(event);
      setRayonEnKm(Number(event.target.value));
      if (circleRef?.getBounds()) map?.fitBounds(circleRef?.getBounds());
    }
  };

  const onChangeSelectClassPar = (event: React.ChangeEvent<HTMLInputElement>) => {
    const valueAsNumber = Number(event.target.value);
    if (classPar !== valueAsNumber) {
      setClassPar(valueAsNumber);
      checkFilters(valueAsNumber, repAgrees);
    }
  };

  const onChangeSelectServicePropose = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (servicePropose !== value) {
      setServicePropose(value);
      checkFilters(classPar, repAgrees, undefined, value);
    }
  };

  const checkFilters = (classPar: number, repAgrees: boolean, rayon?: number, service?: string,
      marques?: ItemGetValeursCodesModel[]) => {
    setIsFilterModified(true);
    const rayonValue = rayon ?? rayonEnKm;
    const sp = service ?? servicePropose;
    const marquesValue = marques ?? marquesSelectedList;
    if (!(classPar === 1 && rayonValue < 20 || classPar === 2 && rayonValue >= 20) &&
      !repAgrees && sp === '' && marquesValue.length === 0) {
      setIsFilterModified(false);
    }
  };

  const onChangeRepAgrees = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRepAgrees(event.target.checked);
    checkFilters(classPar, event.target.checked);
  };

  const onSelectMarques = (event: React.ChangeEvent<{}>, item: ItemGetValeursCodesModel[]) => {
    setMarquesSelectedList(item);
    checkFilters(classPar, repAgrees, undefined, servicePropose, item);
  };

  const onChangeAdresse = (event: ChangeEvent<{}>, newAdresse: AdresseAutoCompleteProps) => {
    setDisponibilites([]);
    setDisponibilitesTriees([]);
    setSelectedAdresse(newAdresse);
    if (newAdresse) {
      getCoordonneesGPS(newAdresse.nom, user.numClient).then((response) => {
        const {data}= response;
        if (data.nom && data.latitude && data.longitude) {
          setAdresseCoordonneesGPS(newAdresse, data);
          setPositionGPS(data);
          map?.setView([data.latitude, data.longitude]);
        }
      }).catch((error) => {
        console.error(error)
      })
    } else {
      setAdresseCoordonneesGPS(newAdresse)
    }
  };

  const displayMap = () => {
    return (
      <MapContainer
        className={classes.map}
        ref={(map) => {
          if (map) setMap(map)
        }}
        center={[positionGPS.latitude, positionGPS.longitude]}
        zoom={defaultZoom}
        zoomSnap={0.25}
        zoomDelta={0.25}
        zoomControl={false}
      >
        <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        />
        <ScaleControl position={'bottomleft'} imperial={false}/>
        <ZoomControl position={'bottomright'} zoomInTitle={'Zoom avant'} zoomOutTitle={'Zoom arrière'}/>
        {circle}
        {disponibilitesTriees ? myMarkers() : ''}
      </MapContainer>
    )
  };

  const onClickReset = () => {
    setIsFilterModified(false);
    setRepAgrees(false);
    setIsFilterRepAgree(false);
    setMarquesSelectedList([]);
    setServicePropose('');
    if (rayonEnKm < 20) setClassPar(2);
    else setClassPar(1);
    getPartenaires(rayonEnKm, false, false, '', {...emptyItem});
  };

  const onClickAppliquer = () => {
    getPartenaires(rayonEnKm, true);
    setIsFilterRepAgree(repAgrees);
  };

  return (
    <div className={classes.container}>
      {displayMap()}
      <div id='bandeau' className={classes.bandeau}>
        <div id={'settingsIcon'} className={classes.settingsIconContainer} onClick={handleClose} aria-hidden={true}>
          <SettingsIcon className={classes.settingsIcon}/>
        </div>
        <div className={classes.containerFiltres}>
          <Typography variant="h3">Filtres de recherche</Typography>
          <div className={classes.repAgrees}>
            <BlueSwitch
              id={'switchRepAgree'}
              checked={repAgrees}
              name="btn-checked"
              disabled={isLoadingReparateurs}
              onChange={onChangeRepAgrees}
            />
            <InputLabel id="labelRepAgrees" className={classes.labelRepAgrees}>
              Réparateurs agréés uniquement
            </InputLabel>
          </div>
          <br /><br />
          <div>
            <InputLabel id="labelClassPar" className={classes.label}>Classement par</InputLabel>
            <TextFieldSelect
              className={classes.select}
              id={'selectClassPar'}
              name={'selectClassPar'}
              value={classPar.toString()}
              itemsList={itemsListClassPar}
              disabled={isLoadingReparateurs}
              onChange={onChangeSelectClassPar}
              marginDense={true}
            />
          </div>
          <br /><br />
          <div className={classes.marquePanel}>
            <InputLabel id="labelMarques" className={classes.label}>Marques (max 2 marques)</InputLabel>
            <AutoCompleteMultipleMarque
              id={'auto-complete-marque-info-vehicules'}
              fullWidth={true}
              disabled={isLoadingReparateurs}
              codesValeursMarque={listMarques}
              optionsList={marquesSelectedList}
              onChangeMarque={onSelectMarques}
            />
          </div>
          <br /><br />
          <div className={classes.marquePanel}>
            <InputLabel id="labelServices" className={classes.label}>Services proposés</InputLabel>
            <TextFieldSelect
              className={classes.select}
              id={'selectServicesProposes'}
              name={'selectServicesProposes'}
              value={servicePropose}
              itemsList={itemsListServicesProposes}
              onChange={onChangeSelectServicePropose}
              marginDense={true}
              withEmptyItem={true}
              emptyItemLabel={'Tous'}
              disabled={isLoadingReparateurs}
            />
          </div>
          <br /><br />
          <div className={classes.bandeauFooter}>
            <ButtonLink
              id={'reinitialiser'}
              libelle={'RÉINITIALISER'}
              icon={<ResetIcon className={classes.resetIcon}/>}
              disabled={isLoadingReparateurs}
              onClick={onClickReset}
            />
            <div className={classes.buttonRight}>
              <ButtonBlue
                id={'boutonPrendreRDV'}
                libelle={'Appliquer'}
                onClick={onClickAppliquer}
                disabled={isLoadingReparateurs}
              />
            </div>
          </div>
        </div>
      </div>

      <Card className={classes.adresse}>
        <AutoCompleteAdresse
          id='auto-complete-adresse'
          classNameTextField={classes.textFieldAdresse}
          colorLabel={customTheme.palette.text.primary}
          fontWeightLabel={500}
          border={false}
          isMandatory={false}
          selectedAdresse={selectedAdresse}
          getOptionLabel={(adresse: AdresseAutoCompleteProps) => `${adresse.nom}`}
          onError={errorAdresse}
          placeHolder={'Adresse de recherche'}
          onChange={onChangeAdresse}
          onFocus={(_) => setErrorAdresse(false)}
          onBlur={(event) => setErrorAdresse(!event.target.value)}
        />
      </Card>

      <Card className={classes.resultats} style={{display: isLoadingReparateurs || nbReparateurs === 0 ? 'flex' : ''}}>
        <CardContent style={{padding: '0px', margin: isLoadingReparateurs || nbReparateurs === 0 ? 'auto' : '0px'}}>
          {isLoadingReparateurs && <Loader size={25}/>}
          {!isLoadingReparateurs && disponibilitesTriees.map((dispo, index) => {
            return (
              <span key={dispo.numero}>
                <CardReparateur
                  disponibilite={dispo}
                  onClickCard={onClickCard}
                  onChangePartenaire={onChangePartenaire}
                  onChangeActiveStep={onChangeActiveStep}
                  isFilterRepAgree={isFilterRepAgree}
                />
                {nbReparateurs !== index + 1 ? <div className={classes.separator}></div> : ''}
              </span>
            )
          })}
          {!isLoadingReparateurs && nbReparateurs === 0 &&
            <div>
              <Typography>
                {repAgrees ? 'Aucun réparateur trouvé avec les filtres sélectionnés' :
                  `Aucun réparateur trouvé dans un rayon de ${rayonEnKm} km`
                }
              </Typography>
            </div>
          }
        </CardContent>
      </Card>

      <Card className={classes.rayon}>
        <CardContent className={classes.contentRayon}>
          <TextFieldSelect
            className={classes.textFieldRayon}
            id={'rayon'}
            name={'rayon'}
            marginDense={true}
            border={false}
            value={rayonEnKm.toString()}
            itemsList={itemsListRayon}
            onChange={onChangeRayon}
          />
        </CardContent>
        <hr className={classes.separatorRayon}/>
        <CardContent className={classes.contentRayon}>
          <Typography
            className={classes.textRayon}
            style={{fontWeight: isRayon ? 'bold' : ''}}
            onClick={() => {
              setIsRayon(!isRayon);
              setCircle(myCircle(isRayon ? 'transparent' : 'red', rayonEnM))
            }}
          >
            Afficher le rayon
          </Typography>
        </CardContent>
      </Card>

      <Card className={`${classes.resetPosition} ${classes.resetPositionHover}`} onClick={onResetPosition}
        title={'Réinitialiser la position'}>
        <CardContent className={classes.contentResetPosition}>
          <RefreshIcon className={classes.refreshIcon}/>
        </CardContent>
      </Card>
    </div>
  )
};
