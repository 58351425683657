import * as yup from 'yup';
import {
  checkRefSinFormat,
  checkRefSinSpecialChar,
} from '../../../../common/utils/InputValuesUtils';

export const validationSchemaRefSinistre = (isRequired: boolean) => yup.object({
  referenceSinistre: yup.string()
      .min(6, 'La référence sinistre doit faire au moins 6 caractères')
      .test('test-referenceSinistre-format', 'La référence sinistre ne doit pas contenir plus de 9 caractères ' +
      'identiques d\'affilée',
      function(value) {
        return checkRefSinFormat(value ?? '')
      })
      .test('test-referenceSinistre-char', 'Le champ contient des caractères interdits',
          function(value) {
            return checkRefSinSpecialChar(value ?? '')
          })
      .test('test-referenceSinistre-required', 'Veuillez saisir une valeur',
          function(value) {
            if (isRequired) return Boolean(value)
            return true
          }),
});
