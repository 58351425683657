import React, {useContext, useEffect, useState} from 'react';
import {Accordion, AccordionSummary, AccordionDetails, Button, Grid, Typography, Box, Modal} from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ExpandMore from '@mui/icons-material/ExpandMore';
import moment from 'moment';
import {Gallery} from '../photosComponents/Gallery';
import {PhotoModel} from '../../../../model/detailsDossier/PhotoModel';
import {useMissionContext} from '../../../../context/MissionContext';
import {ReactComponent as DownloadIcon} from '../../../../images/icones/btn-download-small.svg';
import {useIconExtensionFile} from '../../../../common/Utils';
import {customTheme} from '../../../../common/GlobalTheme';
import {AttachmentProps, downloadAllAttachments, downloadOneAttachment} from '../DownloadAttachments';
import {Loader} from '../../../common/Loader';
import {AttachmentFile} from './AttachmentFile';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {UserContext} from '../../../../context/UserContext';

export interface Attachment {
  id: string,
  name: string
  type: string,
  format: string,
  date: string,
}

export interface Message {
  id: string,
  typeDoc: string,
  libelleDoc: string,
  author: string,
  dateReceive: string,
  nbPj: number,
  attachments?: Attachment[],
  pictures?: Attachment[],
}

export interface MessageBlocProps {
  itemSelected?: string[],
  message: Message,
}

const styles = (theme: Theme) => ({
  summary: {
    maxHeight: '50px',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '8px 8px 0px 0px',
  },
  divContainerSummary: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    color: '#0095DB',
  },
  div: {
    display: 'flex',
    height: '55px',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  accordionDetailsRoot: {
    padding: '10px 16px 5px',
  },
  iconPj: {
    width: '50px',
  },
  libelle: {
    flexGrow: 4,
    flexBasis: '0px',
  },
  iconButtonRoot: {
    padding: '0',
  },
  icon: {
    width: '30px',
    height: '30px',
    borderRadius: '50px',
    display: 'block',
    margin: 'auto',
  },
  iconImage: {
    display: 'block',
    width: '30px',
    height: '30px',
    borderRadius: '50px',
    margin: 'auto 10px auto auto',
  },
  linkAlbumPhoto: {
    lineHeight: 1,
    marginLeft: '5px',
  },
  dateHeure: {
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  dateHeureSmallScreen: {
    textAlign: 'right' as const,
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
});

const MessageBloc = ({message, itemSelected}: MessageBlocProps) => {
  const classes = useEmotionStyles(styles);
  const {id, typeDoc, libelleDoc, author, dateReceive, nbPj, attachments, pictures} = message;
  const {mission} = useMissionContext();
  const {user} = useContext(UserContext);
  const referenceSinistre = mission?.infoMissionDTO?.referenceSinistre;
  const photos = mission!.photosDTO!.photos!.filter((photo) => {
    return photo.idDoc === id
  });
  const [expande, setExpande] = React.useState(!!itemSelected?.includes(id.trim()));
  const [param, setParam] = React.useState<{
    openGallery: boolean, openAlbum: boolean, id: string, album:
      PhotoModel[], index: number, photo: null | PhotoModel, from: string
  }>({openGallery: false, openAlbum: false, id: '', album: photos, index: -1, photo: null, from: ''});
  const [isLoadingZip, setIsLoadingZip] = useState(false);
  const [isLoadingPJ, setIsLoadingPJ] = useState(false);
  const [isEndDownload, setIsEndDownload] = useState(false);
  const [isFilesInZip, setIsFilesInZip] = useState(true);
  const [nbFilesInZip, setNbFilesInZip] = useState(0);
  const [onErrorDownloadAll, setOnErrorDownloadAll] = useState(false);
  const [onErrorDownloadOne, setOnErrorDownloadOne] = useState(false);

  useEffect(() => {
    setExpande(!!itemSelected?.includes(id.trim()))
  }, [itemSelected]);

  const handleOpenGalery = (id: string, album: PhotoModel[], position : number, photo: PhotoModel, from: string) => {
    setParam({openGallery: true, openAlbum: false, id: id, album: album, index: position, photo: photo, from: from})
  };

  const handleOpenAlbum = (from: string) => {
    setParam({openGallery: false, openAlbum: true, id: '', album: photos, index: -1, photo: photos[0], from: from})
  };

  const handleClose = () => {
    setParam({openGallery: false, openAlbum: false, id: '', album: photos, index: -1, photo: photos[0], from: ''})
  };

  const IconPictures = () => useIconExtensionFile('JPEG');

  const allPJs = () => {
    const tab: AttachmentProps[] = [];
    attachments?.forEach((att) => {
      tab.push({
        id: att.id,
        name: att.name,
        date: att.date?.substring(0, 10).replaceAll('/', ''),
      });
    });
    pictures?.forEach((att) => {
      tab.push({
        id: att.id,
        name: att.name,
        date: att.date?.substring(0, 10).replaceAll('/', ''),
      });
    });
    return tab;
  };

  const downloadAttachments = () => {
    if (allPJs().length === 1) {
      const attachment: AttachmentProps = {
        id: allPJs()[0].id,
        name: allPJs()[0].name,
        date: allPJs()[0].date?.substring(0, 10).replaceAll('/', ''),
      };
      downloadOneAttachment({attachment, author: author || '', typeDoc: typeDoc || '',
        referenceSinistre: referenceSinistre ?? '', immatriculation: '', numClient: user.numClient,
        setOnErrorDownload: setOnErrorDownloadOne, setIsLoading: setIsLoadingPJ});
    } else {
      downloadAllAttachments({attachments: allPJs(), author: author || '', typeDoc: typeDoc || '',
        referenceSinistre: referenceSinistre ?? '', numClient: user.numClient,
        setIsLoading: setIsLoadingZip, setIsEndDownload, setIsFilesInZip,
        setOnErrorDownload: setOnErrorDownloadAll, setNbFilesInZip})
    }
  };

  return (
    <React.Fragment>
      <Accordion
        style={{borderRadius: '8px 8px 8px 8px', boxShadow: '0px 3px 6px 0px #2121211A'}}
        expanded={expande}
        onChange={() => setExpande(!expande)}
      >
        <AccordionSummary
          expandIcon={<ExpandMore/>}
          aria-controls='panel1a-content'
          id='panel1a-header'
          className={classes.summary}
        >
          <div className={classes.divContainerSummary}>
            <div style={{flexGrow: 1, flexBasis: '0px'}}>
              <Box component='div' className={classes.box}>
                <Typography id={'nb-pj-trombone'}>{nbPj}</Typography>
                <AttachFileIcon style={{transform: 'rotate(45deg)'}}/>
              </Box>
            </div>
            <div style={{flexGrow: 4, flexBasis: '10px'}}>
              <Typography id={'author-pj'} style={{fontWeight: 'bold'}}>{author}</Typography>
            </div>
            <div className={classes.dateHeure} style={{flexGrow: 3, flexBasis: '10px', textAlign: 'center'}}>
              <Typography id={'date-receive-pj'} style={{fontSize: '13px', opacity: '1'}}>
                {moment(dateReceive, 'DD/MM/YYYY - hh[H]mm').format('DD/MM/YYYY - HH[H]mm')}
              </Typography>
            </div>
            <div style={{flexGrow: 3, flexBasis: '0px'}}>
              <Typography id={'detail-doc-pj'} style={{fontSize: '13px'}}>{typeDoc} - {libelleDoc}</Typography>
            </div>
          </div>
        </AccordionSummary>

        <AccordionDetails classes={{root: classes.accordionDetailsRoot}}>
          <Grid container>
            <Grid item xs={8}>
              <div style={{marginBottom: '10px'}}>
                <Button classes={{root: classes.iconButtonRoot}} onClick={() => downloadAttachments()}>
                  <div className={classes.iconPj}>
                    {!isLoadingZip && !isLoadingPJ &&
                      <DownloadIcon style={{display: 'block', margin: 'auto', width: '30px'}}
                        fill={customTheme.palette.link.main}/>
                    }
                    {(isLoadingZip || isLoadingPJ) && <Loader size={20}/>}
                  </div>
                  <Typography className={classes.libelle}>Tout télécharger</Typography>
                </Button>
                {onErrorDownloadAll || onErrorDownloadOne &&
                  <Typography style={{color: 'red', fontSize: '14px', marginTop: '10px'}}>
                    Erreur lors du téléchargement
                  </Typography>
                }
                {!isFilesInZip &&
                  <Typography style={{color: 'red', fontSize: '14px', marginTop: '5px'}}>
                    Aucune pièce jointe valide à télécharger
                  </Typography>
                }
                {isFilesInZip && isEndDownload && nbFilesInZip !== allPJs().length &&
                  <Typography style={{color: 'red', fontSize: '14px', marginTop: '5px'}}>
                    {nbFilesInZip}
                    {nbFilesInZip === 1 ? ' pièce jointe téléchargée' :
                      ' pièces jointes téléchargées'} sur {allPJs().length}
                  </Typography>
                }
              </div>

              {attachments && attachments.length > 0 &&
                <React.Fragment>
                  {attachments.map((attachment, index) => {
                    const keyAttachmentFile = `${index}`
                    return <AttachmentFile
                      key={keyAttachmentFile}
                      attachment={attachment}
                      author={author || ''}
                      typeDoc={typeDoc || ''}
                      referenceSinistre={referenceSinistre ?? ''}
                      dateReceive={dateReceive}
                    />
                  })}
                </React.Fragment>
              }
            </Grid>
            <Grid item xs={4}>
              {pictures && pictures.length > 0 &&
                <div
                  className={classes.div}
                  style={{color: customTheme.palette.link.main, cursor: 'pointer'}}
                  aria-hidden={true}
                  onClick={() => handleOpenGalery(photos[0].id, photos, 1, photos[0], 'pj')}
                >
                  <span><IconPictures/></span>
                  <span id={'album-pj'} className={classes.linkAlbumPhoto}>
                    Voir l&apos;album
                    ({pictures.length > 1 ? `${pictures.length} photos` : `${pictures.length} photo`})
                  </span>
                </div>
              }
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <div className={classes.dateHeureSmallScreen}>
        <Typography id={'date-message-pj'} style={{fontSize: '13px', opacity: '1'}}>
          {moment(dateReceive, 'DD/MM/YYYY - hh[H]mm').format('DD/MM/YYYY - HH[H]mm')}
        </Typography>
      </div>
      <Modal
        open={param.openGallery}
        onClose={handleClose}
        aria-labelledby="Album photo"
        aria-describedby="Album photo"
      >
        {param.openGallery ?
          <Gallery id={param.id} onClose={handleClose} onReturn={handleOpenAlbum}
            album={param.album} photo={param.photo!} from={param.from}/> : <div></div>
        }
      </Modal>
    </React.Fragment>
  );
};

export default MessageBloc;
