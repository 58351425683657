import React, {useEffect, useState} from 'react';
import {
  DataGrid,
  GRID_CHECKBOX_SELECTION_FIELD,
  GridRowSelectionModel,
  GridRowsProp,
} from '@mui/x-data-grid';
import {useGridColumns} from './gridColumns';
import {Card, CardContent} from '@mui/material';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {DocumentsParType} from '../../../../model/rechercheEchange/DocumentsParType';
import {Theme} from '@emotion/react';
import {Loader} from '../../../common/Loader';
import {ButtonBlue} from '../../../common/buttons/ButtonBlue';
import NewSlideNotification from '../../../common/newNotification/NewSlideNotification';
import {UserContext} from '../../../../context/UserContext';
import useHasRole from '../../../../hook/useHasRole';
import {permittedRolesRAD} from '../../../../constantes/roles/Roles';
import {postEchangesRAD} from '../../../../api/echange';
import {InfosEchange} from '../../../../model/rechercheEchange/InfosEchange';
import {InfosTypesDocs} from '../../../../model/rechercheEchange/InfosTypesDocs';
import {RadEchangeParams, TypesSousTypesDocs} from '../../../../model/rechercheEchange/RadEchangeParams';

interface IProps {
  documentsParTypeList: DocumentsParType[],
  idEchange: string,
  numeroEchange: string,
  isEchangeRAD: boolean,
  actionEchange: string,
  callbackComponent: (
    component: string,
    infosEchange: InfosEchange,
    infosTypesDocs: InfosTypesDocs,
    lastPaginationCall?: any,
    pageSize?: string) => void,
  lastPaginationCall?: any,
  pageSize?: string
}

interface INotification {
  theme?: 'Error' | 'Success'
  message?: string
}

/** Design */
const styles = (theme: Theme) => ({
  card: {
    marginTop: '16px',
  },
  iconSeparator: {
    fill: theme.palette.secondary.dark2,
  },
  buttonsBlock: {
    display: 'grid',
    gridTemplateColumns: '100px 150px',
    gridColumnGap: '24px',
    justifyContent: 'center',
    marginTop: '32px',
  },
  loaderButton: {
    margin: 0,
  },
  radButton: {
    justifySelf: 'end',
    borderRadius: '20px',
  },
  cancelRadButton: {
    borderRadius: '20px',
  },
});

const customLocaleText = {
  noRowsLabel: 'Aucun document pour cet échange',
};

export const ListeDocsParTypes = (props: IProps) => {
  const classes = useEmotionStyles(styles);
  const {user} = React.useContext(UserContext);
  const hasRoleEchangesRAD = useHasRole(permittedRolesRAD);
  const {documentsParTypeList, callbackComponent, idEchange, numeroEchange, isEchangeRAD,
    actionEchange, lastPaginationCall, pageSize} = props;

  // State
  const [isEnableRAD, setIsEnableRAD] = useState(false);
  const [isEnableCancelRAD, setIsEnableCancelRAD] = useState(false);
  const [selectionModel, setSelectionModel] = useState<DocumentsParType[]>([]);
  const [radLoading, setRadLoading] = useState(false);
  const [cancelRadLoading, setCancelRadLoading] = useState(false);
  const [radNotification, setRadNotification] = useState<INotification>({});

  useEffect(() => {
    setSelectionModel([]);
    setIsEnableRAD(false);
    setIsEnableCancelRAD(false);
  }, [])

  // Récupération des colonnes de la grille
  const gridColumns = useGridColumns({idEchange, numeroEchange, isEchangeRAD, actionEchange,
    callbackComponent, lastPaginationCall, pageSize});

  const handleSelectionChange = (newSelection: GridRowSelectionModel) => {
    setIsEnableCancelRAD(false);
    setIsEnableRAD(false);
    const arrayElement = [];
    for (const element of newSelection) {
      const line = documentsParTypeList.find((line) => element === line.codeType.concat(line.codeSousType));
      if (line) {
        arrayElement.push(line);
        setIsEnableRAD(true)
        setIsEnableCancelRAD(true);
      }
    }
    setSelectionModel(arrayElement);
  };

  const handleClickRAD = () => {
    setRadLoading(true);
    setRadNotification({});
    radProcess(selectionModel, 'O');
  };

  const handleClickCancelRAD = () => {
    setCancelRadLoading(true);
    setRadNotification({});
    radProcess(selectionModel, 'N');
  };

  const radProcess = (typesDocsList: DocumentsParType[], action: 'O' | 'N') => {
    const typesSousTypesList: TypesSousTypesDocs[] = [];

    typesDocsList.forEach((typeDoc) => {
      typesSousTypesList.push({type: typeDoc.codeType, sousType: typeDoc.codeSousType});
    });

    postEchangesRAD([
        {
          idEchange,
          typesSousTypesDocuments: typesSousTypesList,
          action: action,
          codeService: user.service,
          isAssistance: user.isAssistance,
        } as RadEchangeParams,
    ]).then((response) => {
      const result = response.data.filter((item: any) => item.actionOK).length;
      if (result === 0) {
        setRadNotification({
          theme: 'Error',
          message: 'Une erreur s\'est produite lors de ' +
              (action === 'O' ? 'la remise à disposition' : 'l\'annulation de la remise à disposition'),
        })
      } else {
        setRadNotification({
          theme: 'Success',
          message: (action === 'O' ? 'Remise à disposition' :
              'Annulation de la remise à disposition') + ' effectuée',
        })
      }
    }).catch(() => {
      setRadNotification({
        theme: 'Error',
        message: 'Une erreur s\'est produite lors de ' +
            (action === 'O' ? 'la remise à disposition' : 'l\'annulation de la remise à disposition'),
      })
    }).finally(() => {
      if (action === 'O') {
        setRadLoading(false);
      } else {
        setCancelRadLoading(false);
      }
    })
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <DataGrid
          classes={{
            iconSeparator: classes.iconSeparator,
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
                [GRID_CHECKBOX_SELECTION_FIELD]: hasRoleEchangesRAD,
              },
            },
          }}
          rows={documentsParTypeList as GridRowsProp<any>}
          getRowId={(row: DocumentsParType) => row.codeType.concat(row.codeSousType)}
          columns={gridColumns}
          rowHeight={80}
          columnHeaderHeight={80}
          onRowSelectionModelChange={handleSelectionChange}
          rowSelectionModel={selectionModel.map((line) => line.codeType.concat(line.codeSousType))}
          checkboxSelection
          disableColumnMenu
          disableRowSelectionOnClick
          localeText={customLocaleText}
          hideFooter={true}
          density={'compact'}
          isRowSelectable={() => !radLoading && !cancelRadLoading ?
              isEchangeRAD && hasRoleEchangesRAD : false}
          hideFooterPagination
        />
        {hasRoleEchangesRAD &&
            <div className={classes.buttonsBlock}>
              {radLoading ?
                  <Loader className={classes.loaderButton} size={30}/> :
                  <ButtonBlue className={classes.radButton}
                    id={'bouton-rad'}
                    libelle={'RAD'}
                    onClick={handleClickRAD}
                    disabled={!isEnableRAD}
                  />
              }
              {cancelRadLoading ?
                  <Loader className={classes.loaderButton} size={30}/> :
                  <ButtonBlue className={classes.cancelRadButton}
                    id={'bouton-annuler-rad'}
                    libelle={'Annuler RAD'}
                    onClick={handleClickCancelRAD}
                    disabled={!isEnableCancelRAD}
                  />
              }
            </div>
        }
        {radNotification.theme &&
            <NewSlideNotification
              id={'notification-rad-echanges'}
              theme={radNotification.theme}
              storageItem={''}
              autoHideDuration={10000}
              onClose={() => setRadNotification({})}
            >
              {radNotification.message}
            </NewSlideNotification>
        }
      </CardContent>
    </Card>
  )
};
