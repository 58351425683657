import React from 'react';
import {DebutFinProps, HoraireProps} from '../../../../model/priseRDV/HoraireModel';

const HorairesComponent = (horaires: HoraireProps) => {
  return (
    <div>
      {Object.keys(horaires).length === 0 ? <i>Aucun horaire renseigné</i> : ''}
      {Object.keys(horaires).map((jour, index) => {
        const keyDiv = `${index}`
        return (
          <div key={keyDiv} style={{display: 'flex', marginBottom: '5px'}}>
            <span>{jour.substring(0, 1).toUpperCase() + jour.substring(1)} :&nbsp;</span>
            {horaires[jour as keyof HoraireProps].length > 0 ?
              horaires[jour as keyof HoraireProps].map((horaires: DebutFinProps, indexH: number) => {
                const keyDiv = `${indexH}`
                return (
                  <div key={keyDiv}>
                    {(horaires.debut !== '' && horaires.fin !== '') &&
                      <span>
                        {indexH !== 0 && <span>&nbsp;-&nbsp;</span>}
                        {horaires.debut.replace(':', 'h').split(':', 1)}
                          &nbsp;à&nbsp;
                        {horaires.fin.replace(':', 'h').split(':', 1)}
                      </span>
                    }
                  </div>
                )
              }) :
              <span>Fermé</span>
            }
          </div>
        )
      })}
    </div>
  )
};

export default HorairesComponent
