import React, {useEffect} from 'react';
import {IconButton, Menu, MenuItem, Typography} from '@mui/material'
import {ReactComponent as UserIcon} from '../../images/icones/icn-user.svg';
import ConfigEnvironnement from '../../ConfigEnvironnement';
import {customTheme} from '../../common/GlobalTheme';
import {UserContext} from '../../context/UserContext';
import useHasRole from '../../hook/useHasRole';
import {
  permittedRolesCarteExtranet,
  permittedRolesCarteAtwAdministration,
  permittedRolesCarteAtwDecisionnel,
  permittedRolesCarteAtwRecycleurs,
  permittedRolesCarteSinappsDecisions,
  permittedRolesCarteAutoweb,
  permittedRolesCarteAtwDecisionnelMaif,
  permittedRolesCarteIdigo,
  permittedRolesCarteAtwDecisionnelMatmut,
  permittedRolesCarteAtwDecisionnelSferen,
  permittedRolesCarteSinappsOmega,
} from '../../constantes/roles/Roles';
import SvpComponent from './svp/SVPComponent';
import AccessDenied from '../common/errorsComponents/AccessDenied';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../common/useEmotionStyles';
import {ToolbarSVP} from '../app-bar/ToolbarSVP';
import {ICardsTabProps, VignettesComponent} from './VignettesComponent';
import {isAssureur} from '../../common/utils/UserTypeUtils';
import {
  vignetteSinappsAutoExtranet,
  vignetteAutoweb,
  vignetteAdministration,
  vignetteDecisionnel,
  vignetteDecisionnelMaif,
  vignetteDecisionnelMatmut,
  vignetteDecisionnelSferen,
  vignetteIdigo,
  vignetteRecycleurs,
  vignetteSinappsAutoDecisions,
  vignetteSinappsAutoOmega,
} from './VignettesUtils';

const imgPortail = require('../../images/logo/img-portail-sinapps-auto.png');
const logoDarva = require('../../images/logo/logo-darva-white.png');
const logoSinappsAuto = require('../../images/logo/logo-sinapps-auto.png');

interface PortailComponentProps {
  configEnvironnement:ConfigEnvironnement,
  onChangeClient: (clientId: string) => void
}

/** Design */
type StylesProps = {isAssistance?: boolean, isUserCompte?: boolean}
const styles = (theme: Theme, props: StylesProps) => ({
  container: {
    height: '100vh',
  },
  colonneGauche: {
    display: 'contents',
    ['@media (max-width:1500px)']: {
      display: 'none',
    },
  },
  imgPortail: {
    height: props.isAssistance && props.isUserCompte ? 'calc(100vh - 40px)' : '100vh',
  },
  logoDarva: {
    position: 'absolute' as const,
    bottom: '24px',
    left: '24px',
  },
  body: {
    width: '100%',
  },
  top: {
    padding: '30px 120px 0px',
    ['@media (max-width:1750px)']: {
      padding: '20px 80px 0px',
    },
    ['@media (max-width:1550px)']: {
      padding: '20px 60px 0px',
    },
    ['@media (max-width:1280px)']: {
      padding: '20px 100px 0px',
    },
  },
  logoSinapps: {
    height: '92px',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    letterSpacing: '-0.72px',
    marginTop: '80px',
    marginBottom: '16px',
    ['@media (max-width:1750px)']: {
      marginTop: '60px',
    },
    ['@media (max-width:1280px)']: {
      fontSize: '18px',
      marginTop: '40px',
    },
  },
  svp: {
    marginLeft: '25%',
    marginTop: '15%',
    marginRight: '10%',
  },
  userContainer: {
    position: 'absolute' as const,
    top: props.isAssistance && props.isUserCompte ? '60px' : '20px',
    right: '60px',
  },
  iconBtnRoot: {
    borderRadius: '0px',
  },
  divIconUser: {
    backgroundColor: theme.palette.secondary.main,
    padding: '10px',
    borderRadius: '50px',
    display: 'flex',
    margin: 'auto',
  },
  name: {
    textAlign: 'left' as const,
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: '0px',
    color: theme.palette.primary.main,
    opacity: '1',
    marginLeft: '10px',
  },
  logo80: {
    marginTop: '-4px',
    height: '80px',
    ['@media (max-width:1280px)']: {
      height: '60px',
    },
  },
});

export const PortailComponent = (props: PortailComponentProps) => {
  const {configEnvironnement, onChangeClient} = props;
  const {user} = React.useContext(UserContext);
  const isAssistance = user.isAssistance;
  const classes = useEmotionStyles(styles, {isAssistance, isUserCompte: Boolean(user.userCompte)});
  const hasRoleCarteExtranet = useHasRole(permittedRolesCarteExtranet);
  const hasRoleCarteAutoweb = useHasRole(permittedRolesCarteAutoweb);
  const hasRoleCarteAtwDecisionnel = useHasRole(permittedRolesCarteAtwDecisionnel);
  const hasRoleCarteAtwDecisionnelMaif = useHasRole(permittedRolesCarteAtwDecisionnelMaif);
  const hasRoleCarteAtwDecisionnelMatmut = useHasRole(permittedRolesCarteAtwDecisionnelMatmut);
  const hasRoleCarteAtwDecisionnelSferen = useHasRole(permittedRolesCarteAtwDecisionnelSferen);
  const hasRoleCarteAtwAdministration = useHasRole(permittedRolesCarteAtwAdministration);
  const hasRoleCarteAtwRecycleurs = useHasRole(permittedRolesCarteAtwRecycleurs);
  const hasRoleCarteSinappsDecisions = useHasRole(permittedRolesCarteSinappsDecisions);
  const hasRoleCarteSinappsOmega = useHasRole(permittedRolesCarteSinappsOmega);
  const hasRoleCarteIdigo = useHasRole(permittedRolesCarteIdigo);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>();
  const open = Boolean(anchorEl);

  useEffect(() => {
    document.body.style.backgroundColor = customTheme.palette.white.main;
    return () => {
      document.body.style.backgroundColor = customTheme.palette.secondary.main;
    }
  }, []);

  // Tableau contenant les cartes à afficher
  const cardsTab: ICardsTabProps[] = (() => {
    const tabResult: ICardsTabProps[] = [];

    // Carte SinappsAuto Extranet
    if (hasRoleCarteExtranet) {
      tabResult.unshift(vignetteSinappsAutoExtranet(classes.logo80))
    }
    // Carte Autoweb
    if (hasRoleCarteAutoweb) {
      tabResult.push(vignetteAutoweb(classes.logo80))
    }
    // Carte SinappsAuto Omega
    if (hasRoleCarteSinappsOmega && isAssureur(user.type)) {
      tabResult.push(vignetteSinappsAutoOmega(classes.logo80))
    }
    // Carte Décisionnel
    if (hasRoleCarteAtwDecisionnel) {
      tabResult.push(vignetteDecisionnel(classes.logo80))
    }
    // Carte Décisionnel spécifique MAIF
    if (hasRoleCarteAtwDecisionnelMaif) {
      tabResult.push(vignetteDecisionnelMaif(classes.logo80))
    }
    // Carte Décisionnel spécifique MATMUT
    if (hasRoleCarteAtwDecisionnelMatmut) {
      tabResult.push(vignetteDecisionnelMatmut(classes.logo80))
    }
    // Carte Décisionnel spécifique SFEREN
    if (hasRoleCarteAtwDecisionnelSferen) {
      tabResult.push(vignetteDecisionnelSferen(classes.logo80))
    }
    // Carte Administration
    if (hasRoleCarteAtwAdministration) {
      tabResult.push(vignetteAdministration(classes.logo80, user.roles))
    }
    // Carte Recycleurs
    if (hasRoleCarteAtwRecycleurs) {
      tabResult.push(vignetteRecycleurs(classes.logo80, user.roles))
    }
    // Carte SinappsAuto Décisions
    if (hasRoleCarteSinappsDecisions) {
      tabResult.push(vignetteSinappsAutoDecisions(classes.logo80))
    }
    // Carte Idigo
    if (hasRoleCarteIdigo) {
      tabResult.push(vignetteIdigo(classes.logo80))
    }

    return tabResult
  })();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const doLogout = () => {
    const {ssoBaseUrl, realm, redirecturi} = configEnvironnement;
    const redirectUrl = user.isSSO ? `${redirecturi}?kc_idp_hint=groupama-dev` : redirecturi;

    const logoutUrl = `${ssoBaseUrl}/auth/realms/${realm}/protocol/openid-connect/logout?id_token_hint=${user.token}
    &post_logout_redirect_uri=${redirectUrl}`;

    sessionStorage.clear();
    localStorage.removeItem('isTraked');
    const fileLink = document.createElement('a');
    fileLink.href = logoutUrl;
    fileLink.click()
  };

  return (
    <>
      {!isAssistance && cardsTab.length === 0 &&
        <AccessDenied
          message={'Ce compte ne vous permet pas d\'accéder à la plateforme Sinapps AUTO'}
          isReturnButton={true}
          buttonLabel={'Déconnexion'}
          onClickButton={() => doLogout()}
        />
      }

      {(isAssistance || !!cardsTab.length) &&
        <div className={classes.container}>
          {isAssistance && user.userCompte &&
            <ToolbarSVP redirect={() => onChangeClient('')}/>
          }
          <div style={{display: 'flex'}}>
            <div className={classes.colonneGauche}>
              <img className={classes.imgPortail} src={imgPortail} alt='img-portail'/>
              <div className={classes.logoDarva}><img src={logoDarva} alt='logo-darva'/></div>
            </div>
            <div className={classes.body}>
              <div className={classes.top}>
                <img className={classes.logoSinapps} src={logoSinappsAuto} alt='logo-sinapps'/>
                {(!isAssistance || !!user.userCompte) &&
                  <Typography className={classes.title}>
                    Bienvenue sur la plateforme des services DARVA
                  </Typography>
                }
              </div>
              {(!isAssistance || user.userCompte) &&
                <VignettesComponent cardsTab={cardsTab}/>
              }
              {isAssistance && !user.userCompte &&
                <div className={classes.svp}>
                  <SvpComponent onChoiceClient={onChangeClient}/>
                </div>
              }
            </div>
          </div>

          <div className={classes.userContainer}>
            <IconButton
              id={'iconUtilisateur'}
              classes={{root: classes.iconBtnRoot}}
              aria-label="utilisateur"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <div className={classes.divIconUser}><UserIcon width='20px' height='20px'/></div>
              {isAssistance &&
                <Typography className={classes.name}>Mode assistance</Typography>
              }
              {!isAssistance &&
                <Typography className={classes.name}>{user.nom ? user.nom : user.raisonSociale}</Typography>
              }
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem id={'boutonDeconnexion'} onClick={doLogout}>Déconnexion</MenuItem>
            </Menu>
          </div>
        </div>
      }
    </>
  )
};
