import * as yup from 'yup';
import {checkIdentificationPM, checkIdentificationPP} from '../../../../common/utils/InputValuesUtils';

export const validationSchemaIdentification = yup.object({
  identificationPP: yup.string()
      .when('typePersonne', {
        is: (value: string) => value === 'pp',
        then: () => yup.string()
            .required('Veuillez saisir une valeur')
            .test('test-identificationPP', 'Les caractères ! ? * ° : € # ; & ne sont pas autorisés',
                function(value) {
                  return checkIdentificationPP(value || '')
                }),
      }),
  identificationPM: yup.string()
      .when('typePersonne', {
        is: (value: string) => value === 'pm',
        then: () => yup.string()
            .required('Veuillez saisir une valeur')
            .test('test-identificationPM', 'Les caractères ! ? * ° : € # ; ne sont pas autorisés',
                function(value) {
                  return checkIdentificationPM(value || '')
                }),
      }),
});
