import * as yup from 'yup';
import moment from 'moment/moment';
import {validationSchemaImmatriculation} from '../validationSchema/validationSchemaImmatriculation';
import {validationSchemaRefSinistre} from '../validationSchema/validationSchemaRefSinistre';
import {validationSchemaIdentification} from '../validationSchema/validationSchemaIdentification';

export const validationSchemaIR = yup.object({
  dateEvenement: yup.date()
      .required('Veuillez saisir une valeur')
      .min('1900-01-01', 'Veuillez saisir une date postérieure au 01/01/1900')
      .max(moment(), 'Veuillez saisir une date antérieure à celle du jour')
      .test('test-dateEvenement', 'La date de l\'événement ne peut être antérieure à la date du ' +
        'certificat d\'immatriculation',
      function(value, ctx) {
        if (ctx.parent.dateCertificat) {
          return ctx.parent.dateCertificat <= value
        }
        return true
      }),
  numeroFormule: yup.string(),
  dateCertificat: yup.date()
      .transform((current, origin) => origin === '' ? null : current)
      .nullable()
      .min('1900-01-01', 'Veuillez saisir une date postérieure au 01/01/1900')
      .max(moment(), 'Veuillez saisir une date antérieure à celle du jour'),
  dateDeclaration: yup.date()
      .required('Veuillez saisir une valeur')
      .min('1900-01-01', 'Veuillez saisir une date postérieure au 01/01/1900')
      .max(moment(), 'Veuillez saisir une date antérieure à celle du jour')
      .test('test-dateDeclaration', 'La date de déclaration du refus ne peut être antérieure à la date de l\'événement',
          function(value, ctx) {
            if (ctx.parent.dateEvenement) {
              return ctx.parent.dateEvenement <= value
            }
            return true
          }),
  heureDeclaration: yup.string()
      .required('Veuillez saisir une valeur')
      .test('test-heureDeclaration', 'L\'heure doit être antérieure à l\'heure actuelle pour une date ' +
      'égale à celle du jour',
      function(value, ctx) {
        const date = ctx.parent.dateDeclaration;
        if (date && moment(date, 'YYYY-MM-DD').isSame(moment().format('YYYY-MM-DD'))) {
          return value <= moment().format('HH:mm')
        }
        return true
      }),
}).concat(validationSchemaImmatriculation).concat(validationSchemaRefSinistre(true))
    .concat(validationSchemaIdentification);
