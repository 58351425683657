import React, {useEffect, useState} from 'react';
import DocViewer, {DocViewerRenderers, IDocument} from '@cyntler/react-doc-viewer';
import {getPieceJointeById} from '../../../api/dossier';
import {useParams} from 'react-router';
import {AxiosError, AxiosResponse} from 'axios';
import {Loader} from '../../common/Loader';
import NoRenderer from './NoRenderer';
import {getDocumentFromResponse, getFileName} from './utils';
import {IParamsUrl} from '../model/models';
import Cookies from 'js-cookie';
import {UserContextType, userInitialState} from '../../../context/UserContext';
import {jwtDecode} from 'jwt-decode';
import {getMessageErrorByStatusCode} from '../utils/utils';
import useEmotionStyles from '../../../common/useEmotionStyles';
import useUser from '../../../hook/useUser';
const base64 = require('base-64');

const styles = () => ({
  loader: {
    marginTop: '0%',
  },
  red: {
    color: 'red',
  },
});

const PieceJointe = () => {
  const classes = useEmotionStyles(styles);
  const searchParams = useParams<keyof IParamsUrl>();
  const search = JSON.parse(base64.decode(searchParams.urlParams));
  Cookies.set('token', search.accessToken);

  const [isLoadingPj, setIsLoadingPj] = useState<boolean>(false);
  const [isRenderable, setIsRenderable] = useState<boolean>(false);
  const [axiosResponse, setAxiosResponse] = useState<AxiosResponse>();
  const [documents, setDocuments] = useState<IDocument[]>([]);
  const [messageError, setMessageError] = useState<string>('');

  const decodedToken = jwtDecode<any>(search.accessToken);
  const userCompte = decodedToken['preferred_username'];

  const [dataUser, setDataUser] = useState<UserContextType>(
      {
        ...userInitialState,
        isTransparent: true,
        isAssistance: false,
      },
  );

  const {data, error, loading} = useUser({
    userCompte: userCompte,
    codeService: search.codeService,
    isAssistance: false,
    isTransparent: true,
    noClient: decodedToken['nocli'],
    rolesSSO: decodedToken['realm_access'] ? decodedToken['realm_access'].roles : [],
  });

  useEffect(() => {
    if (data.codeAbo) {
      const user: UserContextType = data;
      sessionStorage.setItem('user', JSON.stringify(user));
      setDataUser(user);
    }
  }, [data])

  useEffect(() => {
    if (error) {
      const statusCode = error.response ? error.response.status : 0
      const messageErrorByStatusCode = getMessageErrorByStatusCode(statusCode, 'user')
      setMessageError(messageErrorByStatusCode)
    }
  }, [error])

  useEffect(() => {
    if (dataUser.codeAbo) {
      setIsLoadingPj(true)
      const urlParams = {...search, codeGta: dataUser.codeGTA}
      getPieceJointeById(urlParams)
          .then((response) => {
            setAxiosResponse(response)
          }).catch((error: AxiosError) => {
            if (error.response) {
              setMessageError(getMessageErrorByStatusCode(error.response.status, 'pj'));
              setIsLoadingPj(false)
            }
          })
    }
  }, [dataUser]);

  useEffect(() => {
    if (axiosResponse) {
      const documentToView = getDocumentFromResponse(axiosResponse);
      if (documentToView) {
        setIsRenderable(true)
        setDocuments([documentToView])
      } else {
        setDocuments([{uri: URL.createObjectURL(axiosResponse?.data)}])
      }
      setIsLoadingPj(false)
    }
  }, [axiosResponse]);

  return (
    <div style={{height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      {
        (loading || isLoadingPj) && <Loader className={classes.loader}/>
      }
      {!loading && !isLoadingPj && isRenderable && !!documents.length &&
                <DocViewer
                  documents={documents}
                  pluginRenderers={DocViewerRenderers}
                  style={{width: '75%', backgroundColor: 'red !important', borderRadius: '5px solid red'}}
                  config={{
                    noRenderer: {
                      overrideComponent: NoRenderer,
                    },
                    header: {
                      disableHeader: true,
                    },
                    pdfVerticalScrollByDefault: true,
                  }}
                />
      }
      {!loading && !isLoadingPj && !messageError && !isRenderable && !!documents.length &&
                <NoRenderer
                  fileName={getFileName(axiosResponse!)}
                  document={documents[0]}
                />
      }
      {!!messageError && <div className={classes.red}>
        <p>{messageError}</p>
      </div>
      }
    </div>
  );
};

export default PieceJointe;
