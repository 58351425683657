import React, {useEffect, useRef, useState} from 'react';
import {
  DataGrid, GRID_CHECKBOX_SELECTION_FIELD,
  GridColDef, GridRowSelectionModel,
  GridRowsProp,
} from '@mui/x-data-grid';
import {frFR} from '@mui/x-data-grid/locales';
import {IEchangesResult, initialEchangesResult, IResultLine} from '../../forms/rechercheEchanges/model/model';
import {useGridColumns} from './gridColumns';
import {IconButton, MenuItem, Typography} from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LastPageIcon from '@mui/icons-material/LastPage';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {Loader} from '../../common/Loader';
import {getEchanges, postEchangesRAD} from '../../../api/echange';
import {EchangeSearchModel} from '../../../model/rechercheEchange/EchangeSearchModel';
import {TextFieldSelect} from '../../common/formsComponents/TextFieldSelect';
import NewSlideNotification from '../../common/newNotification/NewSlideNotification';
import {UserContext} from '../../../context/UserContext';
import useHasRole from '../../../hook/useHasRole';
import {permittedRolesRAD} from '../../../constantes/roles/Roles';
import {ButtonBlue} from '../../common/buttons/ButtonBlue';
import {InfosEchange} from '../../../model/rechercheEchange/InfosEchange';
import {InfosTypesDocs} from '../../../model/rechercheEchange/InfosTypesDocs';
import {RadEchangeParams} from '../../../model/rechercheEchange/RadEchangeParams';

export interface SearchResultProps {
  search: EchangeSearchModel
  callbackComponent: (newComponent: string,
                      infosEchange: InfosEchange,
                      infosTypesDocs: InfosTypesDocs,
                      lastPaginationCall?: any,
                      pageSize?: string) => void
  lastPaginationCall: any
  pageSize: string
}
interface INotification {
  theme?: 'Error' | 'Success'
  message?: string
}
const styles = (theme: Theme) => ({
  loader: {
    margin: '56px',
  },
  loaderButton: {
    margin: 0,
  },
  root: {
    marginBottom: '16px',
  },
  iconSeparator: {
    fill: theme.palette.secondary.dark2,
  },
  buttonBar: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    marginTop: '8px',
  },
  itemsPerPageBar: {
    marginRight: '24px',
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
  },
  itemsPerPageSelect: {
    width: '80px',
    textAlign: 'center' as const,
  },
  itemsPerPageInputPadding: {
    padding: '8px',
  },
  iconBtn: {
    color: theme.palette.link.main,
    display: 'inline',
  },
  iconRoot: {
    marginRight: '0px',
    display: 'flex',
  },
  buttonsBlock: {
    display: 'grid',
    gridTemplateColumns: '100px 150px',
    gridColumnGap: '24px',
    justifyContent: 'center',
    marginTop: '32px',
  },
  radButton: {
    justifySelf: 'end',
    borderRadius: '20px',
  },
  cancelRadButton: {
    borderRadius: '20px',
  },
});

const EchangesSearchResult = (props: SearchResultProps) => {
  const classes = useEmotionStyles(styles);
  const {user} = React.useContext(UserContext);
  const hasRoleEchangesRAD = useHasRole(permittedRolesRAD);
  const {search, callbackComponent, pageSize, lastPaginationCall} = props;
  // Référence créée pour savoir si ce composant est chargé pour la 1ère fois ou non
  const mountedRef = useRef(false);

  // State
  const [dataColumns, setDataColumns] = useState<GridColDef[]>([]);
  const [currentLastPaginationCall, setCurrentLastPaginationCall] = useState<any>(lastPaginationCall ?? {});
  const [currentPageSize, setCurrentPageSize] = useState<string>(pageSize ?? '50');
  const [isPending, setIsPending] = useState(false);
  const [result, setResult] = useState<IEchangesResult>(initialEchangesResult);
  const [disablePreviousButton, setDisablePreviousButton] = useState(false);
  const [disableNextButton, setDisableNextButton] = useState(false);
  const [isEnableRAD, setIsEnableRAD] = useState(false);
  const [isEnableCancelRAD, setIsEnableCancelRAD] = useState(false);
  const [selectionModel, setSelectionModel] = useState<IResultLine[]>([]);
  const [radLoading, setRadLoading] = useState(false);
  const [cancelRadLoading, setCancelRadLoading] = useState(false);
  const [radNotification, setRadNotification] = useState<INotification>({});

  // Récupération des colonnes de la grille
  const gridColumns = useGridColumns({callbackComponent, pageSize: currentPageSize,
    lastPaginationCall: currentLastPaginationCall});

  useEffect(() => {
    setDataColumns(gridColumns)
  }, [currentLastPaginationCall, currentPageSize]);

  useEffect(() => {
    if (search) {
      setIsPending(true);
      let searchCriteria:EchangeSearchModel = {...search, nbMaxElement: currentPageSize,
        codePagination: 'D', navigationOrder: 'D'};
      if (Object.keys(lastPaginationCall).length !== 0) {
        searchCriteria = {...search, ...lastPaginationCall, nbMaxElement: currentPageSize};
      }
      findEchanges(searchCriteria);
    }
  }, [search]);

  useEffect(() => {
    setSelectionModel([]);
    setIsEnableRAD(false);
    setIsEnableCancelRAD(false);
  }, [result])

  useEffect(() => {
    // Utilisation de la référence pour ne pas faire le findEchanges() si le composant est chargé pour la 1ère fois
    // Le code du if() sera exécuté seulement quand currentPageSize change
    if (mountedRef.current) {
      setIsPending(true);
      const searchCriteria: EchangeSearchModel = {
        ...search,
        nbMaxElement: currentPageSize,
        codePagination: 'D',
        navigationOrder: 'D',
      };
      findEchanges(searchCriteria);
    } else {
      mountedRef.current = true;
    }
  }, [currentPageSize]);

  const customLocaleText = {
    ...frFR.components.MuiDataGrid.defaultProps.localeText,
    noRowsLabel: 'Aucun échange ne correspond à vos critères',
  };

  const findEchanges = (searchCriteria: EchangeSearchModel) => {
    getEchanges(searchCriteria).then((res) => {
      if (searchCriteria.navigationOrder === 'C' && res?.list.length > 0) {
        const reversedResult = [...res.list].reverse();
        res = {...res, list: reversedResult};
      }
      setResult(res);
      res.indiceDebut ? setDisablePreviousButton(true) : setDisablePreviousButton(false);
      res.indiceFin ? setDisableNextButton(true) : setDisableNextButton(false);
      setIsPending(false);
    });
  }

  const handleSelectionChange = (newSelection: GridRowSelectionModel) => {
    setIsEnableCancelRAD(false);
    setIsEnableRAD(false);
    const arrayElement = [];
    for (const element of newSelection) {
      const line = result?.list.find((line) => element === line.idEchange);
      if (line) {
        arrayElement.push(line);
        setIsEnableRAD(true)
        setIsEnableCancelRAD(true);
      }
    }
    setSelectionModel(arrayElement);
  };

  const handleClickRAD = () => {
    setRadLoading(true);
    setRadNotification({});
    radProcess(selectionModel, 'O');
  };

  const handleClickCancelRAD = () => {
    setCancelRadLoading(true);
    setRadNotification({});
    radProcess(selectionModel, 'N');
  };

  const radProcess = (echangesList: IResultLine[], action: 'O' | 'N') => {
    postEchangesRAD(
        echangesList.map((echange) => {
          return {
            idEchange: echange.idEchange,
            typesSousTypesDocuments: [],
            action: action,
            codeService: user.service,
            isAssistance: user.isAssistance,
          } as RadEchangeParams
        }),
    ).then((response) => {
      const result = response.data.filter((item: any) => item.actionOK).length;
      if (result === 0) {
        setRadNotification({
          theme: 'Error',
          message: 'Une erreur s\'est produite lors de ' +
              (action === 'O' ? 'la remise à disposition' : 'l\'annulation de la remise à disposition'),
        })
      } else if (result === echangesList.length) {
        setRadNotification({
          theme: 'Success',
          message: (action === 'O' ? 'Remise à disposition' :
              'Annulation de la remise à disposition') + ' effectuée',
        })
      }
    }).catch(() => {
      setRadNotification({
        theme: 'Error',
        message: 'Une erreur s\'est produite lors de ' +
            (action === 'O' ? 'la remise à disposition' : 'l\'annulation de la remise à disposition'),
      })
    }).finally(() => {
      if (action === 'O') {
        setRadLoading(false);
      } else {
        setCancelRadLoading(false);
      }
    })
  };

  const resultSize = result?.list ? result.list.length : 0;
  const handleFirstPage = (_: React.MouseEvent<HTMLButtonElement>) => {
    setIsPending(true);
    const paginationCall = {codePagination: 'D', navigationOrder: 'D'};
    const searchFirst = {...search, ...paginationCall, nbMaxElement: currentPageSize};
    getEchanges(searchFirst).then((result) => {
      if (result) {
        setResult(result);
        setIsPending(false);
        result.indiceFin ? setDisableNextButton(true) : setDisableNextButton(false);
        setDisablePreviousButton(true);
        setCurrentLastPaginationCall(paginationCall)
      }
    },
    )
  };
  const handlePreviousPage = (_: React.MouseEvent<HTMLButtonElement>) => {
    const paginationCall = {isnReprise: Number.parseInt(result.list[0].idEchange),
      codePagination: 'P', navigationOrder: 'C'};
    const searchPrevious = {...search, ...paginationCall, nbMaxElement: currentPageSize};
    setIsPending(true);
    getEchanges(searchPrevious).then((result) => {
      if (result?.list.length > 0) {
        const reversedResult = [...result.list].reverse();
        result = {...result, list: reversedResult};
        setResult(result);
        result.indiceFin ? setDisablePreviousButton(true) : setDisablePreviousButton(false);
        setIsPending(false);
        setDisableNextButton(false);
        setCurrentLastPaginationCall(paginationCall);
      }
    },
    )
  };
  const handleNextPage = (_: React.MouseEvent<HTMLButtonElement>) => {
    const paginationCall = {isnReprise: Number.parseInt(result.list[result.list.length - 1].idEchange),
      codePagination: 'S', navigationOrder: 'D'};
    const searchNext = {...search, ...paginationCall, nbMaxElement: currentPageSize};
    setIsPending(true);
    getEchanges(searchNext).then((result) => {
      if (result) {
        setResult(result);
        result.indiceFin ? setDisableNextButton(true) : setDisableNextButton(false);
        setIsPending(false);
        setDisablePreviousButton(false);
        setCurrentLastPaginationCall(paginationCall);
      }
    },
    )
  };
  const handleLastPage = (_: React.MouseEvent<HTMLButtonElement>) => {
    const paginationCall = {codePagination: 'D', navigationOrder: 'C'};
    const searchLast = {...search, ...paginationCall, nbMaxElement: currentPageSize};
    setIsPending(true);
    getEchanges(searchLast).then((result) => {
      if (result?.list.length > 0) {
        const reversedResult = [...result.list].reverse();
        result = {...result, list: reversedResult};
        setResult(result);
        setIsPending(false);
        result.indiceDebut ? setDisablePreviousButton(true) : setDisablePreviousButton(false);
        setDisableNextButton(true);
        setCurrentLastPaginationCall(paginationCall);
      }
    },
    )
  };

  if (isPending) {
    return (<Loader className={classes.loader} size={60}/>)
  } else {
    const pageSizeList = ['10', '20', '30', '40', '50'].map((pageSize: string) => {
      return (
        <MenuItem key={pageSize} value={pageSize}>{pageSize}</MenuItem>
      )
    });

    return (
      <div className={classes.root}>
        <DataGrid
          classes={{
            iconSeparator: classes.iconSeparator,
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                [GRID_CHECKBOX_SELECTION_FIELD]: hasRoleEchangesRAD,
              },
            },
          }}
          rows={result?.list as GridRowsProp<any>}
          getRowId={(row: IResultLine) => row.idEchange}
          columns={dataColumns}
          rowHeight={80}
          columnHeaderHeight={80}
          onRowSelectionModelChange={handleSelectionChange}
          rowSelectionModel={selectionModel.map((line) => line.idEchange)}
          checkboxSelection
          disableColumnMenu
          disableRowSelectionOnClick
          localeText={customLocaleText}
          hideFooter={true}
          density={'compact'}
          isRowSelectable={(params) => !radLoading && !cancelRadLoading ?
              params.row.rad && hasRoleEchangesRAD : false}
          paginationModel={{page: 0, pageSize: Number.parseInt(currentPageSize)}}
        />
        {resultSize > 0 &&
          <div className={classes.buttonBar}>
            <div className={classes.itemsPerPageBar}>
              <Typography>Lignes par page : </Typography>
              <TextFieldSelect id={'nb-items-page'} name={'nb-items-page'}
                className={classes.itemsPerPageSelect}
                classNameInput={classes.itemsPerPageInputPadding}
                itemsList={pageSizeList}
                value={currentPageSize}
                onChange={(event) => setCurrentPageSize(event.target.value)}/>
            </div>
            <IconButton id={'boutonPremierePage'} className={classes.iconBtn} onClick={handleFirstPage}
              disabled={disablePreviousButton}>
              <FirstPageIcon classes={{root: classes.iconRoot}}/>
            </IconButton>
            <IconButton id={'boutonPrecedent'} className={classes.iconBtn} onClick={handlePreviousPage}
              disabled={disablePreviousButton}>
              <ArrowBackIosIcon classes={{root: classes.iconRoot}}/>
            </IconButton>
            <IconButton id={'boutonSuivant'} className={classes.iconBtn} onClick={handleNextPage}
              disabled={disableNextButton}>
              <ArrowForwardIosIcon classes={{root: classes.iconRoot}}/>
            </IconButton>
            <IconButton id={'boutonDernierePage'} className={classes.iconBtn} onClick={handleLastPage}
              disabled={disableNextButton}>
              <LastPageIcon classes={{root: classes.iconRoot}}/>
            </IconButton>
          </div>
        }
        {hasRoleEchangesRAD &&
            <div className={classes.buttonsBlock}>
              {radLoading ?
                  <Loader className={classes.loaderButton} size={30}/> :
                  <ButtonBlue className={classes.radButton}
                    id={'bouton-rad'}
                    libelle={'RAD'}
                    onClick={handleClickRAD}
                    disabled={!isEnableRAD}
                  />
              }
              {cancelRadLoading ?
                  <Loader className={classes.loaderButton} size={30}/> :
                  <ButtonBlue className={classes.cancelRadButton}
                    id={'bouton-annuler-rad'}
                    libelle={'Annuler RAD'}
                    onClick={handleClickCancelRAD}
                    disabled={!isEnableCancelRAD}
                  />
              }
            </div>
        }
        {radNotification.theme &&
            <NewSlideNotification
              id={'notification-rad-echanges'}
              theme={radNotification.theme}
              storageItem={''}
              autoHideDuration={10000}
              onClose={() => setRadNotification({})}
            >
              {radNotification.message}
            </NewSlideNotification>
        }
      </div>
    );
  }
};

export default EchangesSearchResult;
