interface IRechercheDocument {
    dateDebut: string
    dateFin: string
    document: string
    sousDocument: string
    numeroFacture: string
    immatriculation: string
}

export const initialValues: IRechercheDocument = {
  dateDebut: '',
  dateFin: '',
  document: ' ',
  sousDocument: ' ',
  numeroFacture: '',
  immatriculation: '',
};
