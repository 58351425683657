import React from 'react';
import InputTextField from '../../forms/multiStepsForm/fields/InputTextField';
import useEmotionStyles from '../../../common/useEmotionStyles';

interface IProps {
  disabled?: boolean,
}

const styles = () => ({
  flex: {
    display: 'flex',
  },
  marginLeft48: {
    marginLeft: '48px',
  },
  field: {
    'width': '180px',
    'marginRight': '24px',
  },
});

export const Dates = (props: IProps) => {
  const classes = useEmotionStyles(styles);

  return (
    <div className={classes.flex}>
      <InputTextField
        className={classes.field}
        name='dateDebut'
        type='date'
        label='Depuis'
        mandatory={false}
        disabled={props.disabled ?? false}
      />
      <div className={classes.marginLeft48}>
        <InputTextField
          className={classes.field}
          name='dateFin'
          type='date'
          label={'Jusqu\'au'}
          mandatory={false}
          disabled={props.disabled ?? false}
        />
      </div>
    </div>
  )
};
