import React from 'react'
import {Typography} from '@mui/material'
import {useNavigate} from 'react-router-dom'
import {ButtonBlue} from './components/common/buttons/ButtonBlue';
import useEmotionStyles from './common/useEmotionStyles';
import {ID_BOUTON_RETOUR} from './constantes/ids/Ids';

const styles = () => ({
  root: {
    flexGrow: 1,
    maxWidth: '1400px',
    margin: 'auto',
    border: 'none',
    height: '100vh',
    display: 'flex',
  },
  divMessage: {
    flexGrow: 1,
    height: '80%',
    backgroundColor: 'white',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
  },
  message: {
    justifySelf: 'center',
    textAlign: 'center' as const,
    alignSelf: 'center',
  },
  img: {
    maxWidth: '100%',
    height: 'auto',
  },
  button: {
    textTransform: 'none' as const,
    marginTop: '20px',
  },
});

export const Erreur404: React.FC = () => {
  const classes = useEmotionStyles(styles);
  const navigate = useNavigate();

  const handleClick = () => {
    sessionStorage.clear();
    navigate(`/extranet`);
  };

  return (
    <div className={classes.root}>
      <div className={classes.divMessage}>
        <div className={classes.message}>
          <Typography variant="h4">
            Cette page est inexistante.
          </Typography>
          <ButtonBlue id={ID_BOUTON_RETOUR} className={classes.button} libelle={'Retour sur le portail Sinapps Auto'}
            onClick={handleClick}/>
        </div>
      </div>
    </div>
  )
};
