import React, {Dispatch, useState} from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {Button, Typography} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {ContentInfosDossier} from '../infosDossierComponents/InfosDossier';
import {useMissionContext} from '../../../../context/MissionContext';
import {ContentInfosDossierSimple} from '../infosDossierComponents/InfosDossierSimple';
import {ContentInfosDossierArchive} from '../infosDossierComponents/InfosDossierArchive';
import {ContentAssure} from '../Assure';
import {ContentActeurs, useActeursList} from '../acteurs/Acteurs';
import {ContentAlbumPhotos} from '../photosComponents/AlbumPhotos';
import {ContentRendezVous} from '../rendezVousComponents/RendezVous';
import {InfosRendezVousModel} from '../../../../model/detailsDossier/RendezVousModel';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {ButtonLinkMenu} from './ButtonLinkMenu';

interface ModalDossierProps {
  onClose: () => void,
}

/** Design */
const styles = (theme: Theme) => ({
  form: {
    height: 'auto',
    maxHeight: 'calc(100vh - 160px)',
    overflowY: 'auto' as const,
    width: '300px',
    position: 'absolute' as const,
    top: '160px',
    left: '0px',
    backgroundColor: theme.palette.white.main,
    boxShadow: '0px 3px 6px ' + theme.palette.boxShadow.main,
    borderBottomRightRadius: '8px',
  },
  btnClose: {
    'position': 'fixed' as const,
    'top': '160px',
    'left': '300px',
    'padding': '0px',
    'justifyContent': 'flex-start',
    '&:hover': {
      backgroundColor: 'initial',
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  divClose: {
    backgroundColor: theme.palette.white.main,
    color: theme.palette.link.main,
    height: '100px',
    borderRadius: '0px 8px 8px 0px',
    boxShadow: '0px 3px 6px ' + theme.palette.boxShadow.main,
    padding: '5px',
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    margin: '0px 0px 5px 8px',
  },
  typography: {
    fontSize: '12px',
  },
  menu: {
    padding: '55px 25px',
  },
  link: {
    marginTop: '15px',
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    marginTop: '20px',
    marginBottom: '20px',
  },
  buttonReturn: {
    'padding': '0px',
    'fontSize': '16px',
    'letterSpacing': '0px',
    'color': theme.palette.link.main,
    'opacity': '1',
    'border': 'none',
    'textTransform': 'initial' as const,
    '&:hover': {
      border: 'none',
    },
  },
  container: {
    padding: '20px 25px',
  },
  content: {
    'overflowY': 'auto' as const,
    /* Firefox */
    'scrollbarWidth': 'thin' as const,
    'scrollbarColor': '#c1c1c1 #f1f1f1',
    /* Chrome */
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
      borderRadius: '40px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#c1c1c1',
      borderRadius: '40px',
      border: '1px solid transparent',
      backgroundClip: 'padding-box',
    },
  },
});

export const MenuDossier = ({onClose}: ModalDossierProps) => {
  const classes = useEmotionStyles(styles);
  const {mission} = useMissionContext();
  const acteurs = useActeursList();
  const refSinistre = mission?.infoMissionDTO?.referenceSinistre;
  const indicateurArchivage = mission?.indicateurArchivage;
  const isDossierSimple = mission?.dossierSimple;
  const strListRdv = sessionStorage.getItem('listRdv');
  const listRdv : InfosRendezVousModel[] = strListRdv ? JSON.parse(strListRdv) : [];

  // State
  const [isOpenInfosDossier, setIsOpenInfosDossier] = useState(false);
  const [isOpenAssure, setIsOpenAssure] = useState(false);
  const [isOpenActeurs, setIsOpenActeurs] = useState(false);
  const [isOpenAlbum, setIsOpenAlbum] = useState(false);
  const [isOpenRDV, setIsOpenRDV] = useState(false);

  const viewType = (() => {
    if (indicateurArchivage === 'O' || indicateurArchivage === 'A') return 'archive';
    if (isDossierSimple) return 'simple';
    return 'normal';
  })();

  const isOpenMenuDuDossier = isOpenInfosDossier || isOpenAssure || isOpenActeurs || isOpenAlbum || isOpenRDV;

  const retour = (closeMenuFunction: Dispatch<boolean>) =>
    <Button className={classes.buttonReturn} variant="outlined" onClick={() => closeMenuFunction(false)}>
      <ArrowBackIcon/>Retour
    </Button>;

  return (
    <div className={classes.form}>
      <Button className={classes.btnClose} disableRipple onClick={onClose}>
        <div className={classes.divClose}>
          <ArrowBackIosIcon className={classes.icon}/>
          <Typography className={classes.typography}>Replier</Typography>
        </div>
      </Button>

      {!isOpenMenuDuDossier &&
        <div className={classes.menu}>
          <div>
            <ButtonLinkMenu
              id={'bouton-infos-dossier'}
              libelle={'Informations du dossier'}
              onClick={() => setIsOpenInfosDossier(true)}
            />
          </div>
          {viewType === 'normal' &&
            <div className={classes.link}>
              <ButtonLinkMenu
                id={'bouton-assure'}
                libelle={'Assuré'}
                onClick={() => setIsOpenAssure(true)}
              />
            </div>
          }
          <div className={classes.link}>
            <ButtonLinkMenu
              id={'bouton-acteurs'}
              libelle={'Acteurs'}
              onClick={() => setIsOpenActeurs(true)}
            />
          </div>
          {viewType === 'normal' &&
            <div className={classes.link}>
              <ButtonLinkMenu
                id={'bouton-album'}
                libelle={'Album photo'}
                onClick={() => setIsOpenAlbum(true)}
              />
            </div>
          }
          {listRdv && listRdv.length > 0 &&
            <div className={classes.link}>
              <ButtonLinkMenu
                id={'bouton-rdv'}
                libelle={'Rendez-vous'}
                onClick={() => setIsOpenRDV(true)}
              />
            </div>
          }
        </div>
      }

      {isOpenInfosDossier &&
        <div className={classes.container}>
          {retour(setIsOpenInfosDossier)}
          <Typography className={classes.title}>Informations du dossier</Typography>
          <div className={classes.content} style={{maxHeight: 'calc(100vh - 292px)'}}>
            {viewType === 'normal' && <ContentInfosDossier/>}
            {viewType === 'simple' && <ContentInfosDossierSimple/>}
            {viewType === 'archive' && <ContentInfosDossierArchive/>}
          </div>
        </div>
      }

      {isOpenAssure &&
        <div className={classes.container}>
          {retour(setIsOpenAssure)}
          <Typography className={classes.title}>Assuré</Typography>
          <div className={classes.content} style={{maxHeight: 'calc(100vh - 292px)'}}>
            <ContentAssure/>
          </div>
        </div>
      }

      {isOpenActeurs &&
        <div className={classes.container}>
          {retour(setIsOpenActeurs)}
          <Typography className={classes.title}>Acteurs</Typography>
          <div className={classes.content} style={{maxHeight: 'calc(100vh - 297px)', paddingBottom: '5px'}}>
            <ContentActeurs marginDense={true} acteursTab={acteurs}/>
          </div>
        </div>
      }

      {isOpenAlbum &&
        <div className={classes.container}>
          {retour(setIsOpenAlbum)}
          <Typography className={classes.title}>Album photo</Typography>
          <div className={classes.content} style={{maxHeight: 'calc(100vh - 297px)', paddingBottom: '5px'}}>
            <ContentAlbumPhotos marginDense={true}/>
          </div>
        </div>
      }

      {isOpenRDV &&
        <div className={classes.container}>
          {retour(setIsOpenRDV)}
          <Typography className={classes.title}>Rendez-vous</Typography>
          <div className={classes.content} style={{maxHeight: 'calc(100vh - 297px)', paddingBottom: '5px'}}>
            <ContentRendezVous listRdv={listRdv} refSinistre={refSinistre} marginDense={true}/>
          </div>
        </div>
      }
    </div>
  )
};
