import React from 'react';
import {ReactComponent as ArrowIcon} from '../../images/icones/icn-arrow.svg';
import {ReactComponent as EngineeringIcon} from '../../images/icones/icn-engineering.svg';
import {UserContext, userInitialState} from '../../context/UserContext';
import useEmotionStyles from '../../common/useEmotionStyles';
import {Toolbar, Typography} from '@mui/material';
import {Theme} from '@emotion/react';

interface IProps {
  redirect: () => void
}

const styles = (theme: Theme) => ({
  toolbarSVP: {
    backgroundColor: theme.palette.tertiary.main,
    minHeight: '40px',
    display: 'block',
  },
  containerTitreSVP: {
    color: theme.palette.white.main,
    textAlign: 'center' as const,
  },
  titreSVP: {
    lineHeight: '40px',
  },
  menuSVP: {
    display: 'flex',
    columnGap: '20px',
    position: 'absolute' as const,
    top: '4px',
    right: '20px',
  },
  compteSVP: {
    'display': 'flex',
    'alignItems': 'center',
    'columnGap': '5px',
    'color': theme.palette.white.main,
    'fill': theme.palette.white.main,
    'textDecoration': 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  etiquetteSVP: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
    backgroundColor: '#FADEB8',
    color: theme.palette.tertiary.main,
    fill: theme.palette.tertiary.main,
    padding: '4px 12px',
    borderRadius: '8px',
  },
  nomSVP: {
    fontWeight: 'bold',
  },
});

export const ToolbarSVP = (props: IProps) => {
  const {redirect} = props;
  const classes = useEmotionStyles(styles);
  const {user, setDataUser} = React.useContext(UserContext);
  const isAssistance = user.isAssistance;
  const nomCompteAssistance = sessionStorage.getItem('nomCompteAssistance');

  const onClickChangeAccountSVP = () => {
    setDataUser({...userInitialState, isAssistance: isAssistance, token: user.token});
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('loginUser');
    redirect();
  };

  return (
    <Toolbar className={classes.toolbarSVP}>
      <div className={classes.containerTitreSVP}>
        <Typography className={classes.titreSVP}>Mode assistance</Typography>
      </div>
      <div className={classes.menuSVP}>
        <div className={classes.compteSVP} onClick={onClickChangeAccountSVP} aria-hidden={true}>
          <ArrowIcon/>
          <Typography>Changer de compte</Typography>
        </div>
        <div className={classes.etiquetteSVP}>
          <EngineeringIcon/>
          <Typography className={classes.nomSVP}>{nomCompteAssistance}</Typography>
        </div>
      </div>
    </Toolbar>
  )
};
