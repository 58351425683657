import {Dispatch} from 'react'
import {MissionAction, MissionDataState} from '../reducers/MissionReducer'
import {DetailsModel} from '../model/detailsDossier/DetailsModel'

export type MissionActions = {
  setMission: (mission : DetailsModel) => void
}

export const getMissionActions = (state: MissionDataState, dispatch: Dispatch<MissionAction>) => ({
  setMission: (mission : DetailsModel) => {
    dispatch({type: 'SET_MISSION', mission: mission})
  },
});
