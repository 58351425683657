import * as yup from 'yup';
import {checkImmat} from '../../../../common/utils/InputValuesUtils';

export const validationSchemaImmatriculation = yup.object({
  immatriculation: yup.string()
      .required('Veuillez saisir une valeur')
      .test('test-immatriculation', 'Veuillez saisir une valeur au format attendu, voir',
          function(value) {
            return checkImmat(value || '')
          }),
});
