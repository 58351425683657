import React, {useEffect, useState} from 'react';
import {Typography} from '@mui/material';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {IndicateurCard, IndicateurModel} from './IndicateurCard';
import {getDocumentsEnAttente} from '../../../../api/dossier';
import {UserContext} from '../../../../context/UserContext';
import {Loader} from '../../../common/Loader';

const styles = () => ({
  loader: {
    margin: '32px 0',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
    gridColumnGap: '1.8em',
    gridRowGap: '0.6em',
    ['@media (max-width:1600px)']: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
    },
    ['@media (max-width:900px)']: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    ['@media (max-width:600px)']: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
});

const sortIndicatorsByOccurrences = (indicateursList: IndicateurModel[]) => {
  const listCopy = [...indicateursList];
  listCopy.sort((a, b) =>
    (a.nombreOccurences > b.nombreOccurences) ? 1 : -1);
  return listCopy
};

export const IndicateursSuivi = () => {
  const classes = useEmotionStyles(styles);
  const {user} = React.useContext(UserContext);
  const {numClient, userCompte, service} = user;
  const [nbMessage, setNbMessage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [documentsEnAttenteList, setDocumentsEnAttenteList] = useState<IndicateurModel[]>();

  useEffect(() => {
    getDocumentsEnAttente(numClient, userCompte, service)
        .then((data) => {
          if (data.documentsEnAttente && data.documentsEnAttente.length > 0) {
            setDocumentsEnAttenteList(sortIndicatorsByOccurrences(data.documentsEnAttente))
          }
        })
        .catch(() => {
          return false
        })
        .finally(() => setIsLoading(false))
  }, []);

  useEffect(() => {
    setNbMessage(getNombreMessageTotal())
  }, [documentsEnAttenteList]);

  const getNombreMessageTotal = () => {
    let nbMessageTotal = 0;
    documentsEnAttenteList?.map((indicateurModel) => {
      nbMessageTotal += indicateurModel.nombreOccurences
    });
    return nbMessageTotal
  };

  return (
    <div>
      {isLoading && <Loader className={classes.loader} size={60}/>}

      {!isLoading &&
        <>
          <Typography variant={'h3'}>Documents en attente {nbMessage > 0 ? `(${nbMessage})` : '(0)'}</Typography>
          <div className={classes.grid}>
            {documentsEnAttenteList?.map((indicateurModel) => {
              return (
                <IndicateurCard
                  key={indicateurModel.docType}
                  docType={indicateurModel.docType}
                  docTypeLibelle={indicateurModel.docTypeLibelle}
                  nombreOccurences={indicateurModel.nombreOccurences}
                />
              );
            })}
          </div>
        </>
      }
    </div>
  );
};
