import React, {useContext} from 'react';
import SuiviReparationBloc from '../gestionComponents/SuiviReparationsBloc';
import {useMissionContext} from '../../../../context/MissionContext';
import {
  EtapeReparation,
  InformationMoodel,
  SollicitationModel,
} from '../../../../model/detailsDossier/SuiviReparationModel';
import GestionSollicitations from './GestionSollicitations';
import {UserContext} from '../../../../context/UserContext';
import {InfosRendezVousModel} from '../../../../model/detailsDossier/RendezVousModel';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';

 interface SuiviReparationsProps {
   itemSelected: string,
   onClickPj: (tabPanelValue: number, idPJ: string) => void,
   onClickComment: (tabPanelValue: number, date: string) => void,
}

/** Design */
const styles = (theme: Theme) => ({
  root: {
    padding: '0px',
  },
  card25: {
    marginTop: '25px',
    [theme.breakpoints.down('lg')]: {
      marginTop: '15px',
    },
  },
});


const SuiviReparations = (props: SuiviReparationsProps) => {
  const classes = useEmotionStyles(styles);
  const {mission} = useMissionContext();
  const {user} = useContext(UserContext);
  const {itemSelected, onClickPj, onClickComment} = props;
  const repairSteps: EtapeReparation[] = mission?.suiviReparationsDTO?.etapesReparation ?? [];
  const listeMsgSuivi: (SollicitationModel | InformationMoodel)[] = mission?.suiviReparationsDTO?.listeMsgSuivi ?? [];
  const messageASActif = mission?.historiqueDTO?.list.filter((message) =>
    message.typeCode === '100401' && message.etat === 'Actif' && message.dateEmission,
  )[0];

  const strListRdv = sessionStorage.getItem('listRdv');
  const listRdv : InfosRendezVousModel[] = strListRdv ? JSON.parse(strListRdv) : [];

  const isSuiviReparationBloc = ((mission?.gestionDTO?.priseEnCharge?.isOM ||
      mission?.gestionDTO?.priseEnCharge?.isAS) &&
    (mission?.historiqueDTO?.list.some((item) => item.type === 'IFR' && item.etat === 'Actif') ||
      listRdv.length > 0)) || user.codeAbo === messageASActif?.destinataireAbo;

  return (
    <div className={classes.root}>
      {isSuiviReparationBloc &&
        <div className={classes.card25}>
          <SuiviReparationBloc etapesReparation={repairSteps} isInOngletGestion={false}/>
        </div>
      }
      <div className={classes.card25}>
        <GestionSollicitations
          listeMsgSuivi={listeMsgSuivi}
          itemSelected={itemSelected}
          onClickPj={onClickPj}
          onClickComment={onClickComment}
        />
      </div>
    </div>
  );
};

export default SuiviReparations;
