import produce from 'immer'
import {Dispatch} from 'react'
import {SearchModel} from '../model/common/SearchModel'
import {SearchActions} from '../action/search.action'

type SearchActionType =
  | 'SET_SEARCH'

export interface SearchAction {
  type: SearchActionType
  search?: SearchModel
}

export interface SearchDataState {
  search?: SearchModel
  dispatch: Dispatch<SearchAction>
  actions: SearchActions
}

export const searchReducer = (state: SearchDataState, action: SearchAction) => {
  if (action.type === 'SET_SEARCH') {
    return produce(state, (draftState) => {
      draftState.search = action.search;
      return draftState
    })
  } else {
    return {...state}
  }
};
