import React, {useContext, useEffect, useState} from 'react';
import {DocumentsParTypeParPartenaire} from '../../../../model/rechercheEchange/DocumentsParTypeParPartenaire';
import {getEchangeDocumentsParTypeParPartenaire} from '../../../../api/echange';
import {UserContext} from '../../../../context/UserContext';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {Accordion, AccordionDetails, AccordionSummary, Card, CardContent} from '@mui/material';
import {ListeEchangesPartenairesDocs} from '../detailsEchange/ListeEchangesPartenairesDocs';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {ButtonBlue} from '../../../common/buttons/ButtonBlue';
import {ECHANGES_COMPONENTS} from '../../../forms/rechercheEchanges/RechercheEchanges';
import {Theme} from '@emotion/react';
import {DocParTypeParPartenaire} from '../../../../model/rechercheEchange/DocParTypeParPartenaire';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import WarningIcon from '@mui/icons-material/Warning';
import {Loader} from '../../../common/Loader';
import {DetailsEchange} from '../detailsEchange/DetailsEchange';
import {InfosEchange} from '../../../../model/rechercheEchange/InfosEchange';
import {InfosTypesDocs} from '../../../../model/rechercheEchange/InfosTypesDocs';
import {ID_BOUTON_RETOUR} from '../../../../constantes/ids/Ids';

interface IProps {
  idEchange: string,
  numeroEchange: string,
  isEchangeRAD: boolean,
  actionEchange: string,
  typeDoc: string,
  sstypeDoc: string,
  callbackComponent: (component: string,
    infosEchange: InfosEchange,
    infosTypesdDocs: InfosTypesDocs,
    lastPaginationCall?: any,
    pageSize?: string) => void,
  lastPaginationCall?: any,
  pageSize?: string
}

/** Design */
const styles = (theme: Theme) => ({
  container: {
    marginBottom: '16px',
  },
  margin: {
    margin: '48px 0',
  },
  marginTop: {
    marginTop: '16px',
  },
  erreur: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  warningIcon: {
    color: theme.palette.tertiary.main,
  },
  retour: {
    marginTop: '16px',
  },
  accordionSummary: {
    backgroundColor: '#EAE8E9',
  },
  accordion: {
    boxShadow: 'none',
  },
  docsOriginaux: {
    marginBottom: '16px',
    paddingLeft: '16px',
  },
});

const Erreur = () => {
  const classes = useEmotionStyles(styles);

  return (
    <Card className={classes.marginTop}>
      <CardContent className={`${classes.erreur} ${classes.margin}`}>
        <WarningIcon className={classes.warningIcon}/>
        Une erreur s&apos;est produite lors du chargement des messages par type et par partenaire de l&apos;échange.
        Veuillez réessayer plus tard ou contacter le support.
      </CardContent>
    </Card>
  )
};


export const DetailsDocsParType = (props: IProps) => {
  const isEchangeReleve: boolean = props.actionEchange.startsWith('R');
  const user = useContext(UserContext)
  const [detailsDocs, setDetailsDocs] = useState({} as DocumentsParTypeParPartenaire)
  const [nbDocsDup, setNbDocsDup] = useState(0)
  const classes = useEmotionStyles(styles);
  const [isErrorDocumentsParTypeParPart, setIsErrorDocumentsParTypeParPart] = useState(false)
  const [isLoadingDocumentsParTypeParPart, setIsLoadingDocumentsParTypeParPart] = useState(true)

  useEffect(() => {
    getEchangeDocumentsParTypeParPartenaire(user.user.service, props.idEchange, props.typeDoc, props.sstypeDoc)
        .then((response) => {
          setDetailsDocs(response)
          let compteurDocsDup: number = nbDocsDup;
          response.docsDupliques?.forEach((d: DocParTypeParPartenaire) => compteurDocsDup += Number(d.nbDocs))
          setNbDocsDup(compteurDocsDup);
        })
        .catch(() => setIsErrorDocumentsParTypeParPart(true))
        .finally(() => setIsLoadingDocumentsParTypeParPart(false))
  }, [])

  const onClickRetour = () => {
    props.callbackComponent(ECHANGES_COMPONENTS.DETAILS_ECHANGE_ET_DOCS_PAR_TYPE_ET_ANOMALIES,
        {
          idEchange: props.idEchange,
          numeroEchange: props.numeroEchange,
          isEchangeRAD: props.isEchangeRAD,
          actionEchange: props.actionEchange,
        } as InfosEchange,
        {
          typeDoc: '',
          sousTypeDoc: '',
        } as InfosTypesDocs,
        props.lastPaginationCall,
        props.pageSize)
  };

  return (
    <div>
      {isLoadingDocumentsParTypeParPart &&
        <Card>
          <CardContent>
            <Loader className={classes.margin} size={80}/>
          </CardContent>
        </Card>
      }
      {!isLoadingDocumentsParTypeParPart && isErrorDocumentsParTypeParPart &&
          <Erreur/>
      }
      {!isLoadingDocumentsParTypeParPart && !isErrorDocumentsParTypeParPart &&
        <div className={classes.container}>
          <DetailsEchange numeroEchange={props.numeroEchange}/>
          <Card className={classes.marginTop}>
            <CardContent>
              <div className={classes.docsOriginaux}>
                {detailsDocs.docsOriginaux?.map((docType) => {
                  return <ListeEchangesPartenairesDocs docs={docType}
                    txt={`envoyé(s) ${isEchangeReleve ? 'par' : 'vers'}`}
                    key={docType.codeAbonne}/>
                })}
              </div>
              <Accordion className={classes.accordion}>
                <AccordionSummary className={classes.accordionSummary}
                  expandIcon={<ArrowDropDownIcon/>}>
                  <b>Message(s) dupliqué(s) - {nbDocsDup}</b>
                </AccordionSummary>
                <AccordionDetails>
                  {detailsDocs.docsDupliques?.map((docType) => {
                    return <ListeEchangesPartenairesDocs
                      docs={docType}
                      txt={`${isEchangeReleve ? 'envoyé(s) par' : 'dupliqué(s) vers'}`}
                      key={docType.codeAbonne}/>
                  })}
                  {nbDocsDup === 0 &&
                      <div>Aucun document dupliqué pour l&apos;échange.</div>
                  }
                </AccordionDetails>
              </Accordion>
            </CardContent>
          </Card>
        </div>
      }
      <ButtonBlue
        id={ID_BOUTON_RETOUR}
        className={classes.retour}
        icon={<ArrowBackIcon/>}
        libelle={'Retour'}
        onClick={onClickRetour}/>
    </div>
  )
}
