import React from 'react';
import {Card, CardContent, Modal, Typography} from '@mui/material';
import orderBy from 'lodash/orderBy';
import {Gallery} from './Gallery';
import {Albums} from './Albums';
import {useMissionContext} from '../../../../context/MissionContext';
import {PhotoModel} from '../../../../model/detailsDossier/PhotoModel';
import {ButtonBlue} from '../../../common/buttons/ButtonBlue';
import Picture from './Picture';
import useEmotionStyles from '../../../../common/useEmotionStyles';

/** Design */
const styles = () => ({
  date: {
    marginTop: '15px',
    marginBottom: '15px',
    textAlign: 'center' as const,
    fontSize: '14px',
  },
  table: {
    width: '100%',
    display: 'flex',
  },
  containerImg: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  button: {
    display: 'flex',
    margin: '15px auto 0px',
    textAlign: 'center' as const,
  },
});

export const getBy3photo = (tab: PhotoModel[] | undefined) => {
  const result = [];
  if (tab != undefined) {
    const remainder = tab.length % 3;
    const nblignes = remainder === 0 ? tab.length / 3 : ~~(tab.length / 3) + 1;
    let index = 0;
    for (let i = 0; i < nblignes; i++) {
      result.push(tab.slice(index, index + 3));
      index = index + 3
    }
  }
  return result
};

export const ContentAlbumPhotos = ({marginDense = false}: {marginDense?: boolean}) => {
  const classes = useEmotionStyles(styles);
  const {mission} = useMissionContext();

  const stringToDateHour = (_date: string, _format: string, _delimiter: string, _hour: string) => {
    const formatLowerCase=_format.toLowerCase();
    const formatItems=formatLowerCase.split(_delimiter);
    const dateItems=_date.split(_delimiter);
    const monthIndex=formatItems.indexOf('mm');
    const dayIndex=formatItems.indexOf('dd');
    const yearIndex=formatItems.indexOf('yyyy');
    let month=parseInt(dateItems[monthIndex]);
    month-=1;
    const hourItems=_hour.split(':');
    return new Date(Number(dateItems[yearIndex]), month, Number(dateItems[dayIndex]),
        Number(hourItems[0]), Number(hourItems[1]), Number(hourItems[2]));
  };

  const photos = mission?.photosDTO?.photos?.map((p) => {
    const _date = p.date?.split(' - ')[0].trim() ?? '';
    const _hour = p.date?.split(' - ')[1].trim() ?? '';
    return {...p, dateHour: stringToDateHour(_date, 'dd/mm/yyyy', '/', _hour), onError: false}
  });
  const order = orderBy(photos, 'dateHour', 'desc');
  const imagesTrie = getBy3photo(order.slice(0, 9));
  let position = -1;
  const [param, setParam] = React.useState<{openGallery: boolean, openAlbum: boolean, id: string, album: PhotoModel[],
    index: number, photo: null | PhotoModel, from: string}>({openGallery: false, openAlbum: false, id: '',
      album: photos!, index: -1, photo: null, from: ''});

  const handleOpen = (id: string, album: PhotoModel[], position : number, photo: PhotoModel, from: string) => {
    setParam({openGallery: true, openAlbum: false, id: id, album: album, index: position, photo: photo, from: from})
  };
  const handleOpenAlbum = (from: string) => {
    setParam({openGallery: false, openAlbum: true, id: '', album: photos!, index: -1, photo: photos![0], from: from})
  };
  const handleClose = () => {
    setParam({openGallery: false, openAlbum: false, id: '', album: photos!, index: -1, photo: photos![0], from: ''})
  };

  return (
    <>
      {order.length > 0 ?
        <div style={{margin: marginDense ? '0px 5px' : '0px'}}>
          <Typography className={classes.date}>Dernier dépôt le {order[0].date?.replace('-', 'à')}</Typography>
          <div className={classes.table}>
            <div style={{margin: 'auto'}}>
              {imagesTrie.map((imageRow) => {
                return (
                  <div key={imageRow[0].id} style={{display: 'flex', gap: '4px'}}>
                    {imageRow.map((image) => {
                      position++;
                      return (
                        <div id={'imgAlbum-' + image.id} key={image.id} className={classes.containerImg}
                          onClick={() => handleOpen(image.id, photos!, position, image, 'acteur')} aria-hidden={true}>
                          <Picture imgId={image.id}/>
                        </div>
                      )
                    })}
                  </div>
                )
              },
              )}
            </div>
          </div>
          <ButtonBlue className={classes.button} id={'boutonToutVoirPhotos'} libelle={'Tout voir'}
            onClick={() => handleOpenAlbum('acteur')}/>
        </div> :
        <Typography style={{marginTop: '10px'}}>Aucune photo pour ce dossier</Typography>
      }
      <Modal
        open={param.openAlbum || param.openGallery}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <>
          {param.openGallery &&
            <Gallery id={param.id} onClose={handleClose} onReturn={handleOpenAlbum} album={param.album}
              photo={param.photo!} from={param.from}/>
          }
          {param.openAlbum &&
            <Albums album={param.album} click={handleOpen} onClose={handleClose} from={param.from}/>
          }
        </>
      </Modal>
    </>
  )
};

export const AlbumPhotos = () => {
  return (
    <div>
      <Card>
        <CardContent>
          <Typography id={'card-album-photo'} variant={'h3'}>Album photo</Typography>
          <ContentAlbumPhotos/>
        </CardContent>
      </Card>
    </div>
  )
};
