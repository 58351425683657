import React from 'react';
import {InputAdornment, TextField} from '@mui/material';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {NumericFormat, NumericFormatProps} from 'react-number-format';

interface TextFieldCurrencyProps {
  id: string,
  className?: string,
  value?: string,
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
}

/** Design */
const styles = (theme: Theme) => ({
  textfield: {
    backgroundColor: theme.palette.white.main,
  },
});

interface CustomProps {
    onChange: (event: {target: {name: string; value: string}}) => void;
    name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
    function NumericFormatCustom(props: CustomProps, ref) {
      const {onChange, name, ...other} = props;

      return (
        <NumericFormat
          {...other}
          getInputRef={ref}
          onValueChange={(values) => {
            onChange({
              target: {
                name: name,
                value: values.value,
              },
            });
          }}
          valueIsNumericString
          decimalSeparator=","
          decimalScale={2}
          isAllowed={(values) => {
            const {floatValue} = values;
            return (
              floatValue === undefined ||
              floatValue < 10000000
            );
          }}
        />
      );
    },
);

export const TextFieldCurrency = ({id, className, value, onChange}: TextFieldCurrencyProps) => {
  const classes = useEmotionStyles(styles);

  return (
    <TextField
      id={id}
      value={value ? value.replace(/^0+/, ''): value}
      className={`${className}`}
      onChange={onChange}
      name={id}
      InputProps={{
        inputComponent: NumericFormatCustom as any,
        endAdornment: <InputAdornment position="end">€</InputAdornment>,
        startAdornment: '',
        classes: {
          root: classes.textfield,
        },
      }}
      variant='outlined'
      margin="dense"
    />
  )
};
