import React, {useState} from 'react';
import BlocTitle from '../../../rechercherRDV/detailRDV/BlocTitle';
import useEmotionStyles from '../../../../../common/useEmotionStyles';
import {MapContainer, Marker, TileLayer} from 'react-leaflet';
import {LatLngExpression, LatLngTuple} from 'leaflet';
import L from 'leaflet/dist/leaflet';
import {Card, CardContent} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import {renderToStaticMarkup} from 'react-dom/server';
import {ReactComponent as MarkerMapIcon} from '../../../../../images/icones/icones-map/icn-marker-map.svg';
import {customTheme} from '../../../../../common/GlobalTheme';
import {targetIcon} from '../../rechercherReparateur/RechercherReparateur';
import {Theme} from '@emotion/react';

interface BlocLocalisationProps {
  isUpdateRdv: boolean,
  zoom?: number,
  bounds?: LatLngTuple[],
  center: LatLngExpression,
  positionMarkerAssure?: LatLngExpression,
  positionMarkerPartenaire: LatLngExpression,
}

const styles = (theme: Theme) => ({
  mapContainer: {
    position: 'relative' as const,
  },
  map: {
    height: '230px',
    width: '100%',
  },
  resetPosition: {
    zIndex: 1000,
    position: 'absolute' as const,
    bottom: '5px',
    left: '5px',
    border: '2px solid rgba(0,0,0,0.2)',
    borderRadius: '4px',
    width: '25px',
    height: '25px',
    display: 'flex',
  },
  resetPositionHover: {
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
      cursor: 'pointer',
    },
  },
  contentResetPosition: {
    'margin': 'auto',
    'padding': '0px',
    '&:last-child': {
      padding: '0px',
    },
  },
  refreshIcon: {
    display: 'flex',
    margin: '0px',
    color: 'black',
    fontSize: '20px',
  },
});

export const BlocLocalisation = (props: BlocLocalisationProps) => {
  const classes = useEmotionStyles(styles);
  const {isUpdateRdv, zoom, bounds, center, positionMarkerAssure, positionMarkerPartenaire} = props;

  const [map, setMap] = useState<L.Map>();
  const onResetPosition = () => {
    if (isUpdateRdv) {
      map?.setView(positionMarkerPartenaire, 12);
    } else if (bounds) {
      map?.fitBounds(bounds);
    }
  };

  const markerIcon = L.divIcon({
    className: classes.markerDivIcon,
    html: renderToStaticMarkup(<MarkerMapIcon style={{fill: customTheme.palette.primary.main}}/>),
    iconAnchor: L.point([15, 50]),
  });

  return (
    <div>
      <BlocTitle title={'Localisation'}/>
      <div className={classes.mapContainer}>
        <MapContainer
          className={classes.map}
          ref={(map) => {
            if (map) setMap(map)
          }}
          zoomControl={false}
          zoom={zoom}
          bounds={bounds}
          center={center}
        >
          <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'/>
          {!isUpdateRdv && positionMarkerAssure &&
            <Marker icon={targetIcon} interactive={false} position={positionMarkerAssure}/>
          }
          <Marker icon={markerIcon} interactive={false} position={positionMarkerPartenaire}/>
        </MapContainer>

        <Card className={`${classes.resetPosition} ${classes.resetPositionHover}`} onClick={onResetPosition}
          title={'Réinitialiser la position'}>
          <CardContent className={classes.contentResetPosition}>
            <RefreshIcon className={classes.refreshIcon}/>
          </CardContent>
        </Card>
      </div>
    </div>
  )
};
