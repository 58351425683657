import {Dispatch} from 'react'
import {ResultAction, ResultDataState} from '../reducers/ResultReducer'
import {CardsModel} from '../model/rechercheDossier/CardsModel'

export type ResultActions = {
  setResult: (result : CardsModel) => void
}

export const getResultActions = (state: ResultDataState, dispatch: Dispatch<ResultAction>) => ({
  setResult: (result : CardsModel) => {
    dispatch({type: 'SET_RESULT', result: result})
  },
});
