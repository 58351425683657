import {Grid, Typography} from '@mui/material';
import React, {useEffect} from 'react';
import useEmotionStyles from '../../../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';
import InputSelectDtField from '../../../multiStepsForm/fields/InputSelectDTField';
import SwitchField from '../../../multiStepsForm/fields/SwitchField';
import {FormikValues, useFormikContext} from 'formik';
import {InputMaskTextField} from '../../../multiStepsForm/fields/InputMaskTextField';
import {DOMAIN} from '../../../../../constantes/constantesNormeAuto';

const styles = (theme: Theme) => ({
  cardContent: {
    backgroundColor: theme.palette.secondary.light,
    padding: '20px',
    borderRadius: '8px',
    marginBottom: '24px',
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap' as const,
    rowGap: '8px',
  },
  field170: {
    width: '170px',
    marginRight: '24px',
  },
  marginTop24: {
    marginTop: '24px',
  },
  switchContainer: {
    marginTop: '32px',
  },
  switch: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  libelleSwitch: {
    color: theme.palette.secondary.dark2,
    marginBottom: '8px',
  },
});

export const GarantieResponsabilite = () => {
  const classes = useEmotionStyles(styles);
  const {values, setFieldValue}: FormikValues = useFormikContext();
  const {garantieResponsabilite} = values;

  useEffect(() => {
    if (garantieResponsabilite.indetermine) {
      setFieldValue('garantieResponsabilite.responsabilite', '')
    }
  }, [garantieResponsabilite.indetermine]);

  return (
    <div className={classes.cardContent}>
      <Typography variant={'h3'}>Garantie, responsabilité et détermination des dommages</Typography>
      <Grid container>
        <Grid item sm={12} className={classes.flex}>
          <InputSelectDtField
            name='garantieResponsabilite.garantieMiseEnJeu'
            label='Garantie mise en jeu'
            typeDT={'90430101'}
            domain={DOMAIN.AR2}
            mandatory={true}
          />
          <InputSelectDtField
            name='garantieResponsabilite.nature'
            label='Nature du contrat'
            typeDT={'90130301'}
            domain={DOMAIN.AR2}
            mandatory={true}
          />
          <InputMaskTextField
            name={'garantieResponsabilite.pret'}
            label={'Prêt de véhicule'}
            mask={'999'}
            endAdornment={'jour(s)'}
            mandatory={true}
            className={classes.field170}
            titleTooltip={'Nombre compris entre 0 et 999'}
          />
        </Grid>

        <Grid item sm={12} className={`${classes.flex} ${classes.marginTop24}`}>
          <InputSelectDtField
            name='garantieResponsabilite.reglement'
            label='Règlement direct'
            typeDT={'90110301'}
            domain={DOMAIN.AR2}
            mandatory={true}
          />
          <InputMaskTextField
            name={'garantieResponsabilite.responsabilite'}
            label={'% responsabilité'}
            mask={'999'}
            endAdornment={'%'}
            mandatory={!garantieResponsabilite.indetermine}
            disabled={garantieResponsabilite.indetermine}
            className={classes.field170}
            titleTooltip={'Taux compris entre 0 et 100%'}
          />
          <div className={classes.switchContainer}>
            <div className={classes.switch}>
              <SwitchField
                name='garantieResponsabilite.indetermine'
                checked={garantieResponsabilite.indetermine}
              />
              <Typography className={classes.libelleSwitch}>
                Indéterminé
              </Typography>
            </div>
          </div>
        </Grid>

        <Grid item sm={12} className={`${classes.flex} ${classes.marginTop24}`}>
          <InputSelectDtField
            name='garantieResponsabilite.modaliteDommages'
            label='Modalité détermination dommages'
            emptyItemLabel={'Aucune'}
            typeDT={'92830101'}
            domain={DOMAIN.AR2}
            mandatory={garantieResponsabilite.autorisationModalite}
          />
          <InputSelectDtField
            name='garantieResponsabilite.autorisationModalite'
            label='Autorisation modalité'
            emptyItemLabel={'Aucune'}
            typeDT={'92840101'}
            domain={DOMAIN.AR2}
            mandatory={garantieResponsabilite.modaliteDommages}
          />
        </Grid>
      </Grid>
    </div>
  )
};
