import React from 'react'
import {Card, CardContent, Typography} from '@mui/material';
import {FormIT} from './it/FormIT';
import {FormSA} from './sa/FormSA';
import {FormCT} from './ct/FormCT';
import {UserContext} from '../../../context/UserContext';
import {FormDA} from './da/FormDA';
import {FormDC} from './dc/FormDC';
import {FormIR} from './ir/FormIR';
import {AxiosResponse} from 'axios';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {ISIVFormAccesTransparent} from '../../../model/common/SIVFormModel';
import {Theme} from '@emotion/react';
import {mapperCT, mapperDA, mapperDC, mapperIR, mapperIT, mapperSA} from './Utils';

interface ContentFormsSIVProps {
  menu: string,
  onChangeResponse: (resp: AxiosResponse) => void,
  dataAccesTransparent: ISIVFormAccesTransparent,
  isTransparent: boolean,
}

const styles = (theme: Theme) => ({
  card: {
    marginBottom: '16px',
  },
  header: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: '8px 8px 0px 0px',
    padding: '24px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  libelle: {
    fontWeight: 'bold',
  },
});

export const ContentFormsSIV = (props: ContentFormsSIVProps) => {
  const {menu, onChangeResponse, dataAccesTransparent, isTransparent} = props;
  const classes = useEmotionStyles(styles);
  const {user} = React.useContext(UserContext);

  const dataAccesTransparentIT = () => isTransparent ? mapperIT(dataAccesTransparent) : dataAccesTransparent
  const dataAccesTransparentSA = () => isTransparent ? mapperSA(dataAccesTransparent) : dataAccesTransparent
  const dataAccesTransparentCT = () => isTransparent ? mapperCT(dataAccesTransparent) : dataAccesTransparent
  const dataAccesTransparentDA = () => isTransparent ? mapperDA(dataAccesTransparent) : dataAccesTransparent
  const dataAccesTransparentDC = () => isTransparent ? mapperDC(dataAccesTransparent) : dataAccesTransparent
  const dataAccesTransparentIR = () => isTransparent ? mapperIR(dataAccesTransparent) : dataAccesTransparent

  const getFormulaire = () => {
    if (menu === 'IT') {
      return (
        <FormIT
          onChangeResponse={onChangeResponse}
          isAccesTransparent={isTransparent}
          dataAccesTransparent={dataAccesTransparentIT()}/>
      )
    } else if (menu === 'SA') {
      return (
        <FormSA
          onChangeResponse={onChangeResponse}
          isAccesTransparent={isTransparent}
          dataAccesTransparent={dataAccesTransparentSA()}/>
      )
    } else if (menu === 'CT') {
      return (
        <FormCT
          onChangeResponse={onChangeResponse}
          isAccesTransparent={isTransparent}
          dataAccesTransparent={dataAccesTransparentCT()}/>
      )
    } else if (menu === 'DA') {
      return (
        <FormDA
          onChangeResponse={onChangeResponse}
          isAccesTransparent={isTransparent}
          dataAccesTransparent={dataAccesTransparentDA()}
        />
      )
    } else if (menu === 'DC') {
      return (
        <FormDC
          onChangeResponse={onChangeResponse}
          isAccesTransparent={isTransparent}
          dataAccesTransparent={dataAccesTransparentDC()}
        />
      )
    } else if (menu === 'IR') {
      return (
        <FormIR
          onChangeResponse={onChangeResponse}
          isAccesTransparent={isTransparent}
          dataAccesTransparent={dataAccesTransparentIR()}
        />
      )
    } else {
      return <></>
    }
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <div className={classes.header}>
          <Typography className={classes.libelle}>Société : {user.raisonSociale}</Typography>
        </div>
        {getFormulaire()}
      </CardContent>
    </Card>
  )
};
