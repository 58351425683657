import React from 'react';
import {Button} from '@mui/material';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';

interface ButtonLinkProps {
  id: string,
  isBackgroundHover?: boolean,
  isDisableRipple?: boolean,
  className?: string,
  textColor?: string,
  fontSize?: string,
  libelle?: string,
  isLibelleUpperCase?: boolean,
  isLibelleBold?: boolean,
  icon? : React.ReactElement,
  disabled?: boolean,
  onClick: (event: React.MouseEvent<HTMLElement>) => void,
}

/** Design */
type StylesProps = {textColor?: string, fontSize?: string, isBackgroundHover?: boolean}
const styles = (theme: Theme, props: StylesProps) => ({
  button: {
    'backgroundColor': 'inherit',
    'fontSize': props.fontSize ?? '16px',
    'letterSpacing': '0px',
    'padding': '5px',
    'boxShadow': 'none',
    '&:hover': {
      'backgroundColor': props.isBackgroundHover ? 'rgba(0, 0, 0, 0.04)' : 'rgba(0, 0, 0, 0)',
      'boxShadow': 'none',
    },
  },
  textColor: {
    color: props.textColor ?? theme.palette.link.main,
  },
  bold: {
    fontWeight: 'bold',
  },
});

export const ButtonLink = ({id, isBackgroundHover = true, isDisableRipple, className, textColor,
  fontSize, libelle, isLibelleUpperCase, isLibelleBold, icon, disabled, onClick}: ButtonLinkProps) => {
  const classes = useEmotionStyles(styles, {textColor, fontSize, isBackgroundHover});

  return (
    <Button
      id={id}
      className={`${classes.button} ${classes.textColor} ${className} ${isLibelleBold ? classes.bold : ''}`}
      variant="contained"
      disabled={disabled ?? false}
      disableRipple={isDisableRipple ?? false}
      onClick={onClick}
    >
      {icon}{isLibelleUpperCase ? libelle?.toUpperCase() : libelle}
    </Button>
  )
};
