import React, {useEffect, ChangeEvent, useContext, ReactElement, useState} from 'react';
import {AppBar, Box, Card, CardContent, Modal, SvgIcon, Tab, Tabs, Typography} from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import HistoryIcon from '@mui/icons-material/History';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import {ReactComponent as CustomChatBubbleIcon} from '../../../images/icones/btn-message.svg';
import {ReactComponent as SuiviRepIcon} from '../../../images/icones/icn-suivi-rep.svg';
import {Gestion} from './gestionComponents/Gestion'
import {Historique} from './historiqueComponents/Historique'
import {Commentaires} from './commentairesComponents/Commentaires'
import {useMissionContext} from '../../../context/MissionContext'
import {Attachments} from './pjComponents/Attachments';
import SuiviReparations from './suiviReparationsComponents/SuiviReparations';
import {ButtonBlue} from '../../common/buttons/ButtonBlue';
import {getDetailMission} from '../../../api/dossier';
import {useParams} from 'react-router';
import {Loader} from '../../common/Loader';
import ErrorIcon from '@mui/icons-material/Error';
import {customTheme} from '../../../common/GlobalTheme';
import {UserContext} from '../../../context/UserContext';
import {ActeurModel} from '../../../model/detailsDossier/ActeurModel';
import {ItemHistoriqueModel} from '../../../model/detailsDossier/ItemHistoriqueModel';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {IToken} from '../../../model/detailsDossier/TokenModel';

const base64 = require('base-64');

export interface PanelProps {
    index?: number,
    value?: number,
    children: ReactElement
}

interface DetailsProps {
  valueInit: number,
  isPrint: boolean,
  onChangeIsPrint: () => void,
}

/** Design */
const styles = (theme: Theme) => ({
  appbar: {
    flexGrow: 1,
    backgroundColor: theme.palette.white.main,
    color: theme.palette.link.main,
    boxShadow: '0px 3px 6px ' + theme.palette.boxShadow.main,
    border: '1px solid ' + customTheme.palette.secondary.main,
    borderRadius: '8px',
    ['@media print']: {
      display: 'none',
    },
  },
  tabs: {
    padding: '0rem',
    fontSize: '1rem',
    borderRadius: '8px',
  },
  tab: {
    'height': '60px',
    'textTransform': 'none' as const,
    'fontSize': '16px',
    'letterSpacing': '0px',
    'opacity': '1',
    'minHeight': '48px',
    'minWidth': '50px',
    'borderRight': '3px solid '+ theme.palette.secondary.main,
    '&:disabled': {
      color: theme.palette.disabled.main,
    },
    ['@media (max-width:1800px)']: {
      fontSize: '14px',
    },
    ['@media (max-width:1600px)']: {
      fontSize: '13px',
    },
    ['@media (max-width:1400px)']: {
      fontSize: '12px',
    },
    ['@media (max-width:1300px)']: {
      fontSize: '11px',
    },
    ['@media (max-width:1100px)']: {
      fontSize: '10px',
    },
    ['@media (max-width:900px)']: {
      fontSize: '9px',
    },
    ['@media (max-width:800px)']: {
      fontSize: '7.5px',
    },
    '&:first-child': {
      borderRadius: '8px 0px 0px 8px',
    },
    '&:last-child': {
      borderRadius: '0px 8px 8px 0px',
      borderRight: 'none',
    },
  },
  rotate: {
    transform: 'rotate(45deg)',
  },
  notification: {
    minWidth: '1.45em',
    height: '1.45em',
    display: 'flex',
    opacity: '0.3',
    marginLeft: '8px',
    borderRadius: '25px',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1px',
  },
  notificationMoyenne: {
    width: '1.9em',
  },
  notificationGrande: {
    width: '2.4em',
  },
  unselect: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.primary.main,
  },
  select: {
    opacity: '1',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.white.main,
  },
  pastille: {
    backgroundColor: theme.palette.tertiary.main,
    color: theme.palette.secondary.light,
    opacity: '1',
  },
  containerLabel: {
    display: 'inline-flex',
    alignItems: 'center',
    marginBottom: '0.3rem',
    wordBreak: 'initial' as const,
  },
  tabPanel: {
    padding: '0px',
    marginTop: '15px',
  },
  card: {
    marginTop: '25px',
  },
  cardContent: {
    'display': 'flex',
    'justifyContent': 'space-between',
    'alignItems': 'center',
    'columnGap': '20px',
  },
  loader: {
    marginTop: '0px',
    marginRight: '15px',
  },
});

const TabPanel= (props: PanelProps) => {
  const classes = useEmotionStyles(styles);
  const {value, index, children} = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
    >
      {value === index && (
        <Box p={3} className={classes.tabPanel} >
          {children}
        </Box>
      )}
    </div>
  )
};

export const Details = ({valueInit, isPrint, onChangeIsPrint}: DetailsProps) => {
  const classes = useEmotionStyles(styles);
  const {mission, actions} = useMissionContext();
  const {user} = useContext(UserContext);
  const indicateurArchivage = mission?.indicateurArchivage;
  const isDossierSimple = mission?.dossierSimple;
  const messagesHistorique = mission?.historiqueDTO?.list ?? [];
  const [value, setValue] = useState(valueInit);
  const isTabPanelDisabled = indicateurArchivage === 'O' || indicateurArchivage === 'A' || isDossierSimple;
  const [pjSelected, setPjSelected] = useState('-1');
  const [commentSelected, setCommentSelected] = useState('-1');
  const [suiviReparationSelected, setSuiviReparationSelected] = useState('-1');
  const params = useParams<keyof IToken>() as IToken;
  const search = JSON.parse(base64.decode(params.token));
  const [isPending, setIsPending] = useState(false);
  const isIfrOrSrp = mission?.suiviReparationsDTO?.listeMsgSuivi &&
    mission.suiviReparationsDTO.listeMsgSuivi.length > 0;

  useEffect(() => {
    (async function() {
      if (isPrint) {
        setValue(0);
        window.onafterprint = function() {
          onChangeIsPrint()
        };
        window.print()
      }
    })()
  }, [isPrint]);

  const handleChange = (event: ChangeEvent<{}>, value: number) => {
    setValue(value);
    setPjSelected('-1');
    setCommentSelected('-1');
    setSuiviReparationSelected('-1');
    sessionStorage.setItem('tab', value.toString());
  };

  const onClickPj = (tabPanelValue: number, idPJ: string) => {
    setValue(tabPanelValue);
    setPjSelected(idPJ);
    sessionStorage.setItem('tab', tabPanelValue.toString())
  };

  const onClickComment = (tabPanelValue: number, date: string) => {
    setValue(tabPanelValue);
    setCommentSelected(date);
    sessionStorage.setItem('tab', tabPanelValue.toString())
  };

  const onClickEtiquette = (tabPanelValue: number, date: string) => {
    setValue(tabPanelValue);
    setSuiviReparationSelected(date);
  };

  const onClickStepper = (tabPanelValue: number) => {
    setValue(tabPanelValue);
  };

  const a11yProps = (label: string) =>{
    return {
      'id': `full-width-tab-${label}`,
      'aria-controls': `full-width-tab-panel-${label}`,
    };
  };

  const styleGrande=`${classes.notification} ${classes.notificationGrande}`;
  const styleMedium=`${classes.notification} ${classes.notificationMoyenne}`;

  const historiqueList = mission?.historiqueDTO?.list;
  const historique = historiqueList?.length && historiqueList?.filter((message) => message.typeCode !== '900501');
  const counterHistorique = historique ? historique?.length : 0;

  const getClassName = (counter: number, value: number, index : number) => {
    let className
    if (counter < 10) {
      className = `${classes.notification}`
    } else if (counter < 100) {
      className = styleMedium
    } else {
      className = styleGrande
    }
    return `${className} ${value === index ? classes.select : classes.unselect}`
  }

  const getHistorique = (value: number, index: number) => {
    const className = getClassName(counterHistorique ?? 0, value, index)

    return (<div className={classes.containerLabel}>
      Historique
      <div id="pastille-historique" className={className}>
        {counterHistorique}
      </div>
    </div>)
  };

  const commentaires = mission?.commentairesDTO?.commentaires;
  const counterCommentaires = commentaires ? commentaires.length : 0;

  const getCommentaire = (value: number, index: number) => {
    const className = getClassName(counterCommentaires, value, index)

    return (<div className={classes.containerLabel}>
      Commentaires
      <div id="pastille-commentaire" className={className}>
        {counterCommentaires}
      </div>
    </div>)
  };

  const pjs = mission?.pjsDTO?.pj;
  const counterPjs = pjs ? pjs.length : 0;

  const getPj = (value: number, index: number) => {
    const className = getClassName(counterPjs, value, index)

    return (<div className={classes.containerLabel}>
      Pièces jointes
      <div id="pastille-pj" className={className}>
        {counterPjs}
      </div>
    </div>)
  };

  const getSuiviRepLabel = () => {
    const nbSollicitationAttente = mission?.suiviReparationsDTO?.nbSollicitationAttente ?? 0;
    const classNotif = (() => {
      if (nbSollicitationAttente < 10) {
        return `${classes.notification}`
      } else if (nbSollicitationAttente >= 10 && nbSollicitationAttente < 100) {
        return styleMedium
      } else {
        return styleGrande
      }
    })();
    const className = `${classNotif} ${classes.pastille}`;

    return (<div className={classes.containerLabel}>
      <span>Suivi des réparations</span>
      {user.type === 'Carrossier' &&
          <div id='pastille-reparation' className={className}>
            {nbSollicitationAttente}
          </div>
      }
    </div>)
  };

  const onClickLoadAll = () => {
    if (search !== undefined ) {
      setIsPending(true);
      search.isDossierComplet = true;
      getDetailMission(search, user.isTransparent).then((res) => {
        actions.setMission(res);
        sessionStorage.setItem('mission', JSON.stringify(res));
        setIsPending(false)
      })
    }
  };

  // Récupération des émetteurs et des destinataires des derniers messages OM et AS actifs, et de ces messages
  const getActeursEtMessages = () => {
    const acteurs: {emetteurOM: ActeurModel, emetteurAS: ActeurModel, destinataireOM: ActeurModel,
      destinataireAS: ActeurModel} = {emetteurOM: {}, emetteurAS: {}, destinataireOM: {}, destinataireAS: {}};
    let messageOMActif: ItemHistoriqueModel = {destinataire: '', emetteur: '', envoyeRecu: '', etat: '',
      idPieceJointe: '', isnIdentifier: '', libelle: '', nbPieceJointe: 0, statut: '', type: '', typeCode: '',
    };
    let messageASActif: ItemHistoriqueModel = {destinataire: '', emetteur: '', envoyeRecu: '', etat: '',
      idPieceJointe: '', isnIdentifier: '', libelle: '', nbPieceJointe: 0, statut: '', type: '', typeCode: '',
    };

    [...messagesHistorique].reverse().forEach((message) => {
      if (message.typeCode === '050401' && message.etat === 'Actif') {
        acteurs.emetteurOM = mission?.acteursDTO?.acteurs?.filter((acteur) =>
          acteur.codeAbonne === message.emetteurAbo)[0] ?? {};
        acteurs.destinataireOM = mission?.acteursDTO?.acteurs?.filter((acteur) =>
          acteur.codeAbonne === message.destinataireAbo)[0] ?? {};
        if (message.dateEmission) {
          messageOMActif = message;
        }
      }
      if (message.typeCode === '100401' && message.etat === 'Actif') {
        acteurs.emetteurAS = mission?.acteursDTO?.acteurs?.filter((acteur) =>
          acteur.codeAbonne === message.emetteurAbo)[0] ?? {};
        acteurs.destinataireAS = mission?.acteursDTO?.acteurs?.filter((acteur) =>
          acteur.codeAbonne === message.destinataireAbo)[0] ?? {};
        if (message.dateEmission) {
          messageASActif = message;
        }
      }
    });

    return {acteurs, messageOMActif, messageASActif};
  };

  const {acteurs, messageOMActif, messageASActif} = getActeursEtMessages();
  const isOngletSuiviRep = user.codeAbo === acteurs.emetteurOM?.codeAbonne ||
    user.codeAbo === acteurs.emetteurAS?.codeAbonne ||
    (user.codeAbo === acteurs.destinataireOM?.codeAbonne && messageOMActif.dateEmission) ||
    (user.codeAbo === acteurs.destinataireAS?.codeAbonne && messageASActif.dateEmission);

  return (
    <div>
      <AppBar position="static" className={classes.appbar}>
        <Tabs
          indicatorColor="primary" textColor="inherit"
          className={classes.tabs}
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          TabIndicatorProps={{style: {display: 'none'}}}
        >
          <Tab
            className={classes.tab}
            icon={<FormatAlignLeftIcon style={{margin: '0 8px 5px 0'}}/>}
            label={<div className={classes.containerLabel}>Gestion</div>}
            {...a11yProps('gestion')}
            disabled={isTabPanelDisabled}
          />
          <Tab
            className={classes.tab}
            icon={<HistoryIcon style={{margin: '0 8px 5px 0'}}/>}
            label={getHistorique(value, 1)}
            {...a11yProps( 'historique')}
            disabled={historiqueList === null}
          />
          <Tab
            className={classes.tab}
            icon={<SvgIcon style={{margin: '0 8px 0 0'}}><CustomChatBubbleIcon/></SvgIcon>}
            label={getCommentaire(value, 2)}
            {...a11yProps('commentaires')}
            disabled={counterCommentaires === 0 || isTabPanelDisabled}
          />
          <Tab
            className={classes.tab}
            icon={<AttachFileIcon style={{margin: '0 8px 5px 0'}} className={classes.rotate}/>}
            label={getPj(value, 3)}
            {...a11yProps( 'piecesJointes')}
            disabled={counterPjs === 0 || isTabPanelDisabled}
          />
          {isOngletSuiviRep &&
            <Tab
              className={classes.tab}
              icon={<SvgIcon style={{margin: '0 8px 0 0'}}><SuiviRepIcon/></SvgIcon>}
              label={getSuiviRepLabel()}
              {...a11yProps( 'suiviReparations')}
              disabled={!isIfrOrSrp || isTabPanelDisabled}
            />
          }
        </Tabs>
      </AppBar>
      {value === 1 && !mission?.dossierChargerComplet ?
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <div style={{display: 'flex', alignItems: 'center', columnGap: '10px'}}>
              <ErrorIcon style={{color: '#eb5c2e'}}/>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <Typography style={{fontWeight: 'bold'}}>Dossier volumineux</Typography>
                <Typography>
                  <i>Ce dossier contient un grand nombre de messages, il a donc été partiellement chargé.</i>
                </Typography>
              </div>
            </div>
            {isPending ?
              <div style={{display: 'flex', alignItems: 'center'}}>
                <Loader className={classes.loader} size={30}/>
                <Typography style={{fontSize: '14px', width: '100px'}}>Le chargement peut être long</Typography>
              </div> :
              <ButtonBlue id={'boutonChargerDossier'} libelle={'Charger tout le dossier'}
                onClick={() => onClickLoadAll()}/>
            }
          </CardContent>
        </Card> : ''
      }
      {/* Permet de griser la page et d'empêcher l'utilisateur de faire d'autres actions pendant
      le chargement d'un gros dossier */}
      <Modal open={isPending}>
        <div></div>
      </Modal>
      <TabPanel value={value} index={0}>
        <Gestion onClickEtiquette={onClickEtiquette} onClickStepper={onClickStepper}/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Historique onClickPj={onClickPj} onClickComment={onClickComment}/>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Commentaires itemSelected={commentSelected}/>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Attachments itemAccordeon={pjSelected}/>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <SuiviReparations
          itemSelected={suiviReparationSelected}
          onClickPj={onClickPj}
          onClickComment={onClickComment}
        />
      </TabPanel>
    </div>
  )
};
