import React from 'react';
import {Typography} from '@mui/material';
import {InformationsRdv} from '../../../../../model/priseRDV/RDVModel';
import {customTheme} from '../../../../../common/GlobalTheme';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../../common/useEmotionStyles';

interface BlocInfosRdvProps {
  informationsRdv: InformationsRdv,
}

const styles = (theme: Theme) => ({
  title: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginBottom: '5px',
  },
  card: {
    backgroundColor: theme.palette.secondary.light,
    height: 'calc(80% - 30px)',
    borderRadius: '8px',
    padding: '15px',
  },
  group: {
    'display': 'flex',
    'justifyContent': 'space-between',
    'marginBottom': '10px',
    '&:last-child': {
      marginBottom: '0px',
    },
  },
  flex: {
    display: 'flex',
  },
  grow1: {
    flexGrow: 1,
    flexBasis: '0px',
  },
  grow2: {
    flexGrow: 2,
    flexBasis: '0px',
  },
});

export const BlocInfosRdv = (props: BlocInfosRdvProps) => {
  const classes = useEmotionStyles(styles);
  const {informationsRdv} = props;

  return (
    <div style={{height: '100%'}}>
      <Typography className={classes.title}>Informations du rendez-vous</Typography>
      <div className={classes.card}>
        <div className={`${classes.group}`}>
          <div className={`${classes.flex} ${classes.grow2}`}>
            <Typography style={customTheme.styledTypography.libelle}>Référence sinistre :&nbsp;</Typography>
            <Typography style={customTheme.styledTypography.donneeBleue}>
              {informationsRdv.referenceSinistre}
            </Typography>
          </div>
          <div className={`${classes.flex} ${classes.grow1}`}>
            <Typography style={customTheme.styledTypography.libelle}>Type de RDV :&nbsp;</Typography>
            <Typography style={customTheme.styledTypography.donneeBleue}>
              {informationsRdv.typeRdv.libelle}
            </Typography>
          </div>
        </div>
        <div className={`${classes.flex} ${classes.grow2}`}>
          <Typography style={customTheme.styledTypography.libelle}>Prestation du réparateur :&nbsp;</Typography>
          <Typography style={customTheme.styledTypography.donneeBleue}>
            {informationsRdv.sousActivite.libelle}
          </Typography>
        </div>
      </div>
    </div>
  );
};
