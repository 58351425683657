import React, {ReactElement} from 'react';
import {Button} from '@mui/material';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';

interface ButtonBlueProps {
  id: string,
  type?: 'button' | 'submit' | 'reset',
  className?: string,
  icon?: ReactElement,
  libelle?: string,
  disabled?: boolean,
  onClick?: (event: React.MouseEvent<HTMLElement>) => void,
}

/** Design */
const styles = (theme: Theme) => ({
  button: {
    'backgroundColor': theme.palette.link.main,
    'color': theme.palette.white.main,
    'fontSize': '16px',
    'letterSpacing': '0px',
    'padding': '5px 20px',
    'borderRadius': '30px',
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
});

export const ButtonBlue = ({id, type, className, icon, libelle, disabled, onClick}: ButtonBlueProps) => {
  const classes = useEmotionStyles(styles);

  return (
    <Button
      id={id}
      type={type}
      className={`${className} ${classes.button}`}
      variant="contained"
      disabled={Boolean(disabled)}
      {...(onClick && {onClick: onClick})}>
      {icon}{libelle}
    </Button>
  )
};
