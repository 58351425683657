import produce from 'immer'
import {Dispatch} from 'react'
import {DetailsModel} from '../model/detailsDossier/DetailsModel'
import {MissionActions} from '../action/mission.action'

type MissionActionType =
  | 'SET_MISSION'

export interface MissionAction {
  type: MissionActionType
  mission?: DetailsModel
}

export interface MissionDataState {
  mission?: DetailsModel
  dispatch: Dispatch<MissionAction>
  actions: MissionActions
}

export const missionReducer = (state: MissionDataState, action: MissionAction) => {
  if (action.type === 'SET_MISSION') {
    return produce(state, (draftState) => {
      draftState.mission = action.mission;
      return draftState
    })
  } else {
    return {...state}
  }
};
