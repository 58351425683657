import * as Yup from 'yup';
import {checkImmat} from '../../../../common/utils/InputValuesUtils';

export const schemaValidation = Yup.object().shape({
  dateDebut: Yup.date()
      .min('1900-01-01', 'Veuillez saisir une date postérieure au 01/01/1900')
      .test('test-date-debut', 'La date de début ne peut être supérieure à la date de fin',
          function(value, ctx) {
            if (value && ctx.parent.dateFin) {
              return ctx.parent.dateFin >= value
            }
            return true
          }),
  dateFin: Yup.date(),
  document: Yup.string(),
  sousDocument: Yup.string(),
  numeroFacture: Yup.string(),
  immatriculation: Yup.string()
      .test('test-immatriculation', 'Veuillez saisir une valeur au format attendu',
          function(value) {
            return value ? checkImmat(value) : true
          }),
});
