import React, {useEffect, useState} from 'react';
import {Typography} from '@mui/material';
import {TextFieldSearch} from '../../../common/formsComponents/TextFieldSearch';
import {checkImmat} from '../../../../common/utils/InputValuesUtils';
import {ErrorFields} from '../PriseRDV';
import AutoCompleteMarque from '../components/AutoCompleteMarque';
import {ItemGetValeursCodesModel} from '../../../../model/common/ItemGetValeursCodesModel';
import {InformationAssure} from '../../../../model/priseRDV/RDVModel';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {Immatriculation} from '../../../common/vehicule/Immatriculation';

interface InformationsVehiculeProps {
  values: InformationAssure,
  codesValeursMarque: ItemGetValeursCodesModel[],
  selectedMarque: ItemGetValeursCodesModel,
  setMarque: (value: ItemGetValeursCodesModel) => void,
  onChangeValue: (input: string) => (event: React.ChangeEvent<HTMLInputElement>) => void,
  errorFields: ErrorFields,
  setErrorFields: (errorFields: ErrorFields) => void,
}

const styles = (theme: Theme) => ({
  cardContent: {
    backgroundColor: theme.palette.secondary.light,
    padding: '20px',
    borderRadius: '8px',
    marginTop: '20px',
    width: '100%',
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap' as const,
  },
  libelle: {
    marginRight: '24px',
    color: theme.palette.secondary.dark2,
  },
  marginTop12: {
    marginTop: '12px',
  },
  error: {
    color: 'red',
    fontSize: '12px',
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row' as const,
  },
});

export const InfoVehicule = (props: InformationsVehiculeProps) => {
  const classes = useEmotionStyles(styles);
  const {values, codesValeursMarque, selectedMarque, setMarque, onChangeValue, errorFields, setErrorFields} = props;

  const [onErrorImmat, setOnErrorImmat] = useState<boolean>(values.immatriculation ?
    !checkImmat(values.immatriculation) : false);

  useEffect(() => {
    const isHasErrorImmatriculation = errorFields.find((error) => error.filedName === 'immatriculation')
    if (onErrorImmat && !isHasErrorImmatriculation) {
      setErrorFields([...errorFields, {filedName: 'immatriculation', errorMessage: ''}]);
    }
  }, [onErrorImmat, errorFields])

  const onBlurImmat = (event: React.FocusEvent<HTMLInputElement>) => {
    setOnErrorImmat(!checkImmat(event.target.value));
    if (!checkImmat(event.target.value)) {
      setErrorFields([...errorFields, {filedName: 'immatriculation', errorMessage: ''}]);
    }
  };

  const handleFocusImmat = () => {
    setOnErrorImmat(false);
    setErrorFields([...errorFields.filter((error) => error.filedName != 'immatriculation')])
  };

  return (
    <div className={classes.cardContent}>
      <div style={{width: '340px'}}>
        <Typography variant={'h3'}>Informations du véhicule</Typography>
        <Immatriculation
          className={classes.marginTop12}
          name={'immatriculation'}
          formulaire={'RDV'}
          value={values.immatriculation}
          onChangeValue={onChangeValue('immatriculation')}
          onBlur={onBlurImmat}
          onFocus={handleFocusImmat}
          onError={onErrorImmat}
          onErrorVisible={true}
        />
      </div>
      <div style={{width: '340px'}}>
        <Typography className={`${classes.libelle} ${classes.marginTop12}`}>Marque</Typography>
        <AutoCompleteMarque
          id={'auto-complete-marque-info-vehicules'}
          codesValeursMarque={codesValeursMarque}
          selectedMarque={selectedMarque}
          onChangeMarque={(event, value) => setMarque(value)}
        />
      </div>
      <div style={{width: '340px'}}>
        <Typography className={`${classes.libelle} ${classes.marginTop12}`}>Modèle</Typography>
        <TextFieldSearch
          id={'modele'}
          fullWidth={true}
          value={values.modele ?? ''}
          onChange={onChangeValue('modele')}
          maxLength={25}
        />
      </div>
    </div>
  )
};
