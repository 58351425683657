import React from 'react';
import {SIVReqRepModel} from '../../../../../model/common/SIVReqRepModel';
import XMLParser from 'react-xml-parser';
import {CatchErreurSIV} from '../CatchErreurSIV';
import {FooterRequeteSIV} from './FooterRequeteSIV';
import {Typography} from '@mui/material';
import useEmotionStyles from '../../../../../common/useEmotionStyles';
import {stylesCommon} from '../StylesCommon';
import {RequeteCT} from './RequeteCT';
import {RequeteSA} from './RequeteSA';
import {RequeteIR} from './RequeteIR';
import {RequeteIT} from './RequeteIT';
import {RequeteDADC} from './RequeteDADC';

interface RequeteCommonSIVProps {
  sIVReqRepModel: SIVReqRepModel,
  formulaire: string
}

const titre = (formulaire: string) => {
  switch (formulaire) {
    case 'CT': return 'Requête - Caractéristiques techniques du véhicule'
    case 'SA': return 'Requête - Situation administrative du véhicule'
    case 'DC': return 'Requête - Déclaration de cession du véhicule à un professionnel'
    case 'IR': return 'Requête - Inscription de refus de cession du véhicule'
    case 'DA': return `Requête - Déclaration d'achat du véhicule à l'assuré`
    case 'IT': return 'Requête - Identification du titulaire du véhicule'
    default: return ''
  }
}

const titreCatchErreur = (formulaire: string) => {
  switch (formulaire) {
    case 'CT': return 'Consultation des caractéristiques techniques'
    case 'SA': return 'Consultation de la situation administrative'
    case 'DC': return 'Requête - Déclaration de cession du véhicule à un professionnel'
    case 'IR': return 'Consultation de la situation administrative'
    case 'DA': return `Requête - Déclaration d'achat du véhicule`
    case 'IT': return `Consultation de l'identification du titulaire du véhicule`
    default: return ''
  }
}

export const RequeteCommonSIV = (props: RequeteCommonSIVProps) => {
  const classes = useEmotionStyles(stylesCommon);
  const {content} = props.sIVReqRepModel
  const {formulaire} = props

  try {
    const data = new XMLParser().parseFromString(content.contenuMessage, 'text/xml');

    return (
      <div className={classes.container}>
        <Typography className={classes.titre}>{titre(formulaire)}</Typography>
        {formulaire == 'CT' &&
          <RequeteCT refSinistre={content.infos.refSinistre} data={data}/>
        }
        {formulaire == 'SA' &&
          <RequeteSA data={data}/>
        }
        {formulaire == 'IR' &&
          <RequeteIR refSinistre={content.infos.refSinistre} data={data}/>
        }
        {formulaire == 'IT' &&
          <RequeteIT data={data}/>
        }
        {(formulaire == 'DA' || formulaire == 'DC') &&
          <RequeteDADC data={data} refSinistre={content.infos.refSinistre} numMission={content.infos.numMission}
            formulaire={formulaire}/>
        }
        <FooterRequeteSIV/>
      </div>
    )
  } catch (e) {
    return (
      <CatchErreurSIV
        titre={titreCatchErreur(formulaire)}
        footerSiv={<FooterRequeteSIV/>}
      />
    )
  }
};
