import React from 'react';
import {
  AppBar,
  Tab,
  Tabs,
} from '@mui/material';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {Theme as MuiTheme} from '@mui/material/styles';
import {CSSInterpolation} from '@emotion/serialize';

interface EnteteCommentairehistoriqueProps {
  value: number,
  handleChange: (event: React.ChangeEvent<{}>, value: number) => void,
  messagesSentLength: number,
  messagesReceivedLength: number,
}

/** Design */
const styles = (theme: MuiTheme): Record<string, CSSInterpolation> => ({
  appbar: {
    flexGrow: 1,
    backgroundColor: theme.palette.secondary.light,
    boxShadow: 'none',
  },
  tabs: {
    padding: '0rem',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.white.main,
    fontSize: '1rem',
  },
  tab: {
    '&.MuiTab-root': {
      'textTransform': 'none' as const,
      'textAlign': 'left' as const,
      'font': 'normal normal bold 16px/21px Helvetica',
      'letterSpacing': '0px',
      'opacity': '1',
      'minHeight': '48px',
      'color': theme.palette.link.main,
      '&.Mui-selected': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.white.main,
      },
      '&:hover': {
        backgroundColor: theme.palette.white.main,
        color: 'inherit',
      },
      '&:disabled': {
        color: theme.palette.disabled.main,
      },
    },
    '&.Mui-selected': {},
  },
});

const a11yProps = (index : number) =>{
  return {
    'id': `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
};

export const EnteteCommentairehistorique = (props: EnteteCommentairehistoriqueProps) => {
  const classes = useEmotionStyles(styles);
  const {value, handleChange, messagesSentLength, messagesReceivedLength} = props
  return (
    <AppBar position="static" className={classes.appbar}>
      <Tabs
        indicatorColor="primary" textColor="inherit"
        className={classes.tabs}
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        TabIndicatorProps={{style: {backgroundColor: '#241E46', height: '3px'}}}
      >
        <Tab
          label="Tous les messages" {...a11yProps(0)} className={classes.tab}/>
        <Tab
          label="Envoyés" {...a11yProps(1)} disabled={messagesSentLength === 0} className={classes.tab}/>
        <Tab
          label="Reçus" {...a11yProps(2)} disabled={messagesReceivedLength === 0} className={classes.tab}/>
      </Tabs>
    </AppBar>
  )
};
