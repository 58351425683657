import React, {useState} from 'react';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {Grid} from '@mui/material';
import {IFormsList, MenuForms} from './MenuForms';
import {ButtonHome} from '../../common/buttons/ButtonHome';
import FormOM from '../ordre-mission/FormOM';
import ResponseOMComponent from '../components/responses-api/ResponseOMComponent';
import {IResponseOM} from '../ordre-mission/model/ModelOM';
import {Loader} from '../../common/Loader';
import {TokenModel} from '../../../model/common/TokenModel';
import {useNavigate} from 'react-router-dom';
import ResponsePECBG from '../pec-bg/response/ResponsePECBG';
import {IServiceResponse} from '../pec-bg/model/ModelPECBG';
import FormPecBg from '../pec-bg/FormPECBG';
import AccessDenied from '../../common/errorsComponents/AccessDenied';
import {UserContext} from '../../../context/UserContext';
import useHasRole from '../../../hook/useHasRole';
import {permittedRolesFormPECBG, permittedRolesMenuFormOM} from '../../../constantes/roles/Roles';
import {isAssureurOrCourtier, isCarrossier} from '../../../common/utils/UserTypeUtils';

const base64 = require('base-64');

/** Design */
const styles = () => ({
  root: {
    flexGrow: 1,
    margin: 'auto',
    border: 'none',
    marginTop: '24px',
    opacity: '1',
  },
  back: {
    textAlign: 'left' as const,
    height: '114px',
  },
});

export const Forms = () => {
  const classes = useEmotionStyles(styles);
  const navigate = useNavigate();
  const {user} = React.useContext(UserContext);
  const [responseOM, setResponseOM] = useState<IResponseOM>();
  const [responsePecBg, setResponsePecBg] = useState<IServiceResponse>();
  const [tokenMission, setTokenMission] = useState<TokenModel>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Gestion des roles
  const hasRoleFormOM = useHasRole(permittedRolesMenuFormOM);
  const hasRolePECBG = useHasRole(permittedRolesFormPECBG);

  // Gestion de la présence des formulaires
  const isFormOM = isAssureurOrCourtier(user.type) && hasRoleFormOM;
  const isFormPECBG = isCarrossier(user.type) && user.agrementsBG?.length > 0 && hasRolePECBG;

  const isFormsItem = isFormOM || isFormPECBG;
  const formsList: IFormsList = {
    OM: isFormOM,
    PECBG: isFormPECBG,
  };

  // Premier formulaire présent dans la liste pour l'utilisateur connecté
  const firstForm = Object.keys(formsList).find((item: string) => formsList[item as keyof IFormsList]);
  const [selectedForm, setSelectedForm] = useState<string>(firstForm ?? '');

  const onChangeSelectedForm = (menu: string) => {
    setSelectedForm(menu)
  };

  const onSubmitOM = (response: IResponseOM, tokenMission: TokenModel) => {
    setResponseOM(response);
    setTokenMission(tokenMission);
  }

  const onSubmitPecBg = (response: IServiceResponse) => {
    setResponsePecBg(response);
  };

  const onLoading = (isLoading: boolean) => {
    setIsLoading(isLoading)
  }

  const onClickAccesDossier = () => {
    const token = base64.encode(JSON.stringify(tokenMission))
    navigate(`/extranet/dossiers/mission/`+ token)
  };

  const handleNewPecBgRequest = () => {
    setResponsePecBg({} as IServiceResponse);
    setSelectedForm('PECBG')
  }

  const handleGoBack = () => {
    window.history.back();
  }

  const getFormulaire = (formName: string) => {
    switch (formName) {
      case 'OM':
        return (
          <FormOM
            onSubmit={onSubmitOM}
            onLoading={onLoading}
          />
        );
      case 'PECBG':
        return (
          <FormPecBg
            onSubmit={onSubmitPecBg}
            onLoading={onLoading}
          />
        );
      case 'AP':
        return (
          <div>AP Form</div>
        );
      case 'DE':
        return (
          <div>DE Form</div>
        );
      default:
        return (
          <></>
        );
    }
  };

  return (
    <>
      {isLoading &&
        <Loader/>
      }

      {!isLoading && !isFormsItem &&
        <AccessDenied message={'Ce compte ne vous permet pas d\'accéder aux formulaires'}/>
      }

      {isFormsItem && !isLoading && !responseOM && !responsePecBg?.response &&
        <div className={classes.root}>
          <Grid container justifyContent="center" spacing={6}>
            <Grid item sm={3}>
              <div>
                <div className={classes.back}>
                  <ButtonHome/>
                </div>
                <MenuForms
                  onChangeMenu={onChangeSelectedForm}
                  activeMenu={selectedForm}
                  formsList={formsList}
                />
              </div>
            </Grid>
            <Grid item sm={8}>
              {getFormulaire(selectedForm)}
            </Grid>
          </Grid>
        </div>
      }

      {isFormsItem && !isLoading && responseOM &&
        <ResponseOMComponent
          responseOM={responseOM}
          onClickAccesDossier={onClickAccesDossier}
        />
      }

      {isFormsItem && !isLoading && responsePecBg &&
        <ResponsePECBG
          servicePecBgResponse={responsePecBg}
          onClickNewRequest={handleNewPecBgRequest}
          onClickGoBack={handleGoBack}
        />
      }
    </>
  )
};
