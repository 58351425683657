import {CardContainer} from '../CardContainer';
import React, {ReactElement} from 'react';
import {getService} from '../../../../../common/Utils';
import {UserContext} from '../../../../../context/UserContext';
import {getValueDateHeureElement, getValueElement} from '../Utils';

interface EnteteProps {
  data: any,
  prefix: string,
  formulaire: string,
  withoutTitreContainer?: boolean
  specificiteDADC?: ReactElement
}

export const Entete = (props: EnteteProps) => {
  const {data, prefix, formulaire, withoutTitreContainer, specificiteDADC} = props
  const {user} = React.useContext(UserContext);

  const libellesValeursSpecifiques = () => {
    switch (formulaire) {
      case 'CT':
      case 'IR':
        return [
          {'libelle': `N° immatriculation :`,
            'valeur': getValueElement(data, prefix, 'numero_immatriculation')},
        ];
      case 'IT':
      case 'SA':
        return [
          {'libelle': `N° immatriculation :`,
            'valeur': getValueElement(data, prefix, 'numero_immatriculation')},
          {'libelle': `Date et heure du sinistre :`,
            'valeur': getValueDateHeureElement(data, prefix, 'horodate_sinistre')},
        ];
      case 'DA':
      case 'DC':
        return [
          {'libelle': `N° immatriculation :`,
            'valeur': getValueElement(data, prefix, 'numero_immatriculation')},
          {'libelle': `N° VIN (E) :`,
            'valeur': getValueElement(data, prefix, 'numero_vin')},
        ];
      default: return []
    }
  }

  const libellesValeurs = () => {
    return (
      [
        {
          libellesValeurs: [
            [
              {'libelle': 'Service :',
                'valeur': getService(user.service) ?? ''},
              {'libelle': `Société :`,
                'valeur': user.raisonSociale},
            ],
            libellesValeursSpecifiques(),
          ],
        },
      ]
    )
  };

  return (
    <CardContainer titreContainer={
      withoutTitreContainer ? undefined : 'Informations sur la demande'
    }
    content={libellesValeurs()}
    isEntete={true}
    specificiteDADC={specificiteDADC}/>
  )
};
