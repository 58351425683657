import React from 'react';
import {Theme} from '@emotion/react';
import BlocTitle from '../../../rechercherRDV/detailRDV/BlocTitle';
import useEmotionStyles from '../../../../../common/useEmotionStyles';
import {BlocAdresse} from '../../../../common/adresse/BlocAdresse';
import {Grid, IconButton, Tooltip, Typography} from '@mui/material';
import {ReactComponent as CustomChatBubbleIcon} from '../../../../../images/icones/btn-message.svg';
import {customTheme} from '../../../../../common/GlobalTheme';
import {Adresse} from '../../../../../model/common/Adresse';
import moment from 'moment/moment';
import {dateToDateText} from '../../../../../common/utils/DateUtils';
import {DebutFinProps, HoraireProps} from '../../../../../model/priseRDV/HoraireModel';
import HorairesComponent from '../../choisirCreneau/HorairesComponent';

interface BlocInfosPartenaireProps {
  isUpdateRdv: boolean,
  setOpenMsgForm?: (isOpenMsgForm: boolean) => void,
  data: {
    raisonSociale: string,
    distance?: number,
    adresse: Adresse,
    coordonnees: string,
    isToutesMarques: boolean,
    marquesReparees: string[],
    conges: DebutFinProps[],
    horaires: HoraireProps,
  }
}

/** Design */
const styles = (theme: Theme) => ({
  container: {
    height: 'calc(100% - 16px)',
  },
  card: {
    backgroundColor: theme.palette.secondary.light,
    height: 'calc(100% - 60px)',
    marginBottom: '0px',
    borderRadius: '8px',
    padding: '16px',
  },
  title: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  iconButton: {
    margin: '-12px 0px 0px 8px',
  },
  marginTop12: {
    marginTop: '12px',
  },
  marginTop16: {
    marginTop: '16px',
  },
  fontSize14: {
    fontSize: '14px',
  },
  conges: {
    'width': 'max-content',
    'maxHeight': '140px',
    'overflowY': 'auto' as const,
    'paddingRight': '20px',
    /* Firefox */
    'scrollbarWidth': 'thin' as const,
    'scrollbarColor': '#c1c1c1 #f1f1f1',
    /* Chrome */
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
      borderRadius: '40px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#c1c1c1',
      borderRadius: '40px',
      border: '1px solid transparent',
      backgroundClip: 'padding-box',
    },
  },
});

export const BlocInfosPartenaire = (props: BlocInfosPartenaireProps) => {
  const classes = useEmotionStyles(styles);
  const {isUpdateRdv, setOpenMsgForm} = props;
  const {
    raisonSociale, distance, adresse, coordonnees, isToutesMarques,
    marquesReparees, conges, horaires,
  } = props.data;

  const congesTries = conges.length ?
    [...conges].sort((conge1, conge2) => {
      if (conge1.debut < conge2.debut) {
        return -1
      } else {
        return 1
      }
    }) : [];

  const marquesTriees = marquesReparees?.length ?
    [...marquesReparees].sort((marque1, marque2) => {
      if (marque1 < marque2) {
        return -1
      } else {
        return 1
      }
    }) : [];

  return (
    <div className={classes.container}>
      <BlocTitle title={'Réparateur sélectionné'}/>
      <div className={classes.card}>
        <div className={`${!isUpdateRdv ? classes.title : ''}`}>
          <BlocTitle title={raisonSociale}/>
          {!isUpdateRdv &&
            <Tooltip disableInteractive title="Joindre un message au rendez-vous">
              <IconButton
                className={classes.iconButton}
                component='span'
                onClick={() => {
                  if (setOpenMsgForm) setOpenMsgForm(true)
                }}
              >
                <CustomChatBubbleIcon fill={customTheme.palette.link.main}/>
              </IconButton>
            </Tooltip>
          }
        </div>
        {!isUpdateRdv &&
          <Typography className={classes.fontSize14}>{distance} km</Typography>
        }

        <Grid container justifyContent='space-between' spacing={2}>
          <Grid item xs={6}>
            <BlocAdresse
              title={'Coordonnées'}
              adresse={adresse}
              isSmallTypo={true}
            />
            <Typography className={classes.fontSize14}>{coordonnees}</Typography>

            <div className={classes.marginTop16}>
              <Typography style={customTheme.styledTypography.libelle}>Marques réparées</Typography>
              <Typography style={customTheme.styledTypography.donneeGrise14}>
                {isToutesMarques &&
                  <>Toutes les marques</>
                }
                {!isToutesMarques && !!marquesTriees.length &&
                  marquesTriees.map((marque, index) => {
                    return (
                      <React.Fragment key={marque}>
                        {marque.substring(0, marque.lastIndexOf('(') - 1)}
                        {marquesTriees.length !== index+1 && ', '}
                      </React.Fragment>
                    )
                  })}
                {(!marquesReparees || marquesReparees.length === 0) &&
                  <i>Aucune marque renseignée</i>
                }
              </Typography>
            </div>

            <div className={classes.marginTop16}>
              <Typography style={customTheme.styledTypography.libelle}>Congés</Typography>
              <div className={classes.conges}>
                {!!congesTries?.length && congesTries.map((conge, index) => {
                  const keyTypography = `${index}`
                  return (
                    <Typography style={customTheme.styledTypography.donneeGrise14} key={keyTypography}>
                      <span>
                        {moment(conge.debut).isSame(moment(conge.fin)) || !conge.fin ?
                          `Le ${dateToDateText(moment(conge.debut))}` :
                          `Du ${dateToDateText(moment(conge.debut))} au ${dateToDateText(moment(conge.fin))}`
                        }
                      </span>
                    </Typography>
                  )
                })}
              </div>
              {!congesTries?.length && <i>Aucun congé renseigné</i>}
            </div>
          </Grid>

          <Grid item xs={6}>
            <div className={classes.marginTop12}>
              <Typography style={customTheme.styledTypography.libelle}>Horaires d&apos;ouverture</Typography>
              <div style={customTheme.styledTypography.donneeGrise14}>
                <HorairesComponent {...horaires}/>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
};
