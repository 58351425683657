import React, {useState} from 'react';
import {IconButton, Tooltip, Typography} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import {ButtonBlue} from '../../common/buttons/ButtonBlue';
import UploadJustificatif from './justificatifs/UploadJustificatif';
import {ButtonLink} from '../../common/buttons/ButtonLink';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {IAttachment, IAttachments} from './initialStateFormFDV';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {ID_BOUTON_ENREGISTRER, ID_BOUTON_RETOUR} from '../../../constantes/ids/Ids';

interface ModalJustificatifProps {
  alerteId: number,
  attachmentsList: IAttachment[],
  onClose: () => void,
  onSave: (attachments: IAttachments) => void
}

/** Design */
const styles = (theme: Theme) => ({
  form: {
    height: '100vh',
    width: '40%',
    position: 'absolute' as const,
    top: '0%',
    right: '0%',
    backgroundColor: theme.palette.white.main,
    boxShadow: '0px 10px 20px ' + theme.palette.boxShadow.light1,
    [theme.breakpoints.down('lg')]: {
      width: '50%',
    },
    [theme.breakpoints.down('md')]: {
      width: '60%',
    },
  },
  body: {
    height: '90vh',
    overflowY: 'auto' as const,
  },
  header: {
    height: '70px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 20px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white.main,
  },
  titre: {
    fontSize: '20px',
    fontWeight: 'bold',
    letterSpacing: '0px',
    opacity: '1',
  },
  justificatifs: {
    padding: '10px 24px',
  },
  footer: {
    height: '10vh',
    display: 'flex',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0px 20px',
    margin: 'auto 0px', // pour centrer verticalement
    width: '100%',
  },
});

export const ModalJustificatif = ({alerteId, attachmentsList, onClose, onSave}: ModalJustificatifProps) => {
  const classes = useEmotionStyles(styles);
  const initialStateAttachments : IAttachments = {
    alerteId: alerteId,
    attachments: [...attachmentsList],
    isValid: true,
  };
  const [attachments, setAttachments] = useState<IAttachments>(initialStateAttachments);
  const [disabled, setDisabled] = useState(initialStateAttachments.attachments.length <= 0);

  const handleClickSaveJustificatifs = () => {
    onSave({...attachments, attachments: attachments.attachments.filter((attachment) => !attachment.onError)})
  };

  const checkAllFilesCompleted = (justificatifs: IAttachment[]) => {
    return justificatifs.every((attachment) => {
      return attachment.completed
    })
  };

  const handleChangeAttachments = (attachedFiles: IAttachments) => {
    setAttachments({...attachedFiles});
    setDisabled(!checkAllFilesCompleted(attachedFiles.attachments))
  };

  const onSaveAttachments = (attachedFiles: IAttachments) => {
    setDisabled(!checkAllFilesCompleted(attachedFiles.attachments));
    setAttachments({...attachedFiles})
  };

  const handleDeleteAttachments= (attachedFiles: IAttachments)=>{
    setAttachments({...attachedFiles})
  };

  return (
    <div className={classes.form}>
      <div className={classes.body}>
        <div className={classes.header}>
          <Typography className={classes.titre}>Justificatifs de l&apos;alerte {alerteId}</Typography>
          <Tooltip disableInteractive title='Quitter' PopperProps={{disablePortal: true}}>
            <IconButton id={'fermer'} component='span' style={{padding: '0px'}} onClick={onClose}>
              <CancelIcon style={{marginRight: '0px', color: 'white'}}/>
            </IconButton>
          </Tooltip>
        </div>
        <div className={classes.justificatifs}>
          <div>
            <ButtonLink
              id={ID_BOUTON_RETOUR}
              icon={<ArrowBackIcon/>}
              libelle={'Retour'}
              onClick={onClose}
            />
            <UploadJustificatif
              alerteId={alerteId}
              attachments={attachments}
              onChangeAttachments={handleChangeAttachments}
              onSave={onSaveAttachments}
              onDeleteAttachments={handleDeleteAttachments}
              setDisabled={(isDisabled) => setDisabled(isDisabled)}
            />
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <div className={classes.buttons}>
          <ButtonBlue id={ID_BOUTON_ENREGISTRER} icon={<SaveIcon/>} libelle={'Enregistrer'}
            disabled={disabled} onClick={handleClickSaveJustificatifs}/>
        </div>
      </div>
    </div>
  )
};
