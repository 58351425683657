import React, {useState} from 'react';
import {IconButton, Tooltip, Typography} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import {ButtonBlue} from '../../../common/buttons/ButtonBlue';
import {TextFieldMultiline} from '../../../common/formsComponents/TextFiledMultiline';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {ID_BOUTON_ENREGISTRER} from '../../../../constantes/ids/Ids';

interface ModalMsgProps {
  destinataire: string,
  message: string,
  onClose: () => void,
  onClickSave: (msg: string) => void,
}

/** Design */
const styles = (theme: Theme) => ({
  form: {
    position: 'absolute' as const,
    top: '0%',
    right: '0%',
    height: '100vh',
    width: '40%',
    backgroundColor: theme.palette.white.main,
    boxShadow: '0px 10px 20px ' + theme.palette.boxShadow.light1,
    [theme.breakpoints.down('lg')]: {
      width: '50%',
    },
    [theme.breakpoints.down('md')]: {
      width: '70%',
    },
  },
  content: {
    height: '90vh',
    overflowY: 'auto' as const,
  },
  header: {
    height: '70px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: '10px',
    padding: '0px 20px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white.main,
  },
  titre: {
    fontSize: '20px',
    fontWeight: 'bold',
    letterSpacing: '0px',
  },
  body: {
    padding: '20px',
  },
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  bold: {
    fontWeight: 'bold',
  },
  red: {
    color: 'red',
  },
  error: {
    fontSize: '12px',
  },
  divResponse: {
    display: 'flex',
    height: '75vh',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column' as const,
  },
  buttonOK: {
    marginTop: '15px',
  },
  bottom: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    height: '10vh',
  },
  button: {
    padding: '0px 20px 25px 0px',
  },
});

export const ModalMsg = (props: ModalMsgProps) => {
  const classes = useEmotionStyles(styles);
  const {destinataire, message, onClose, onClickSave} = props;
  const [msg, setMsg] = useState(message);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMsg(event.target.value);
  };

  return (
    <div className={classes.form}>
      <div className={classes.content}>
        <div className={classes.header}>
          <Typography className={classes.titre}>Envoyer un message à {destinataire}</Typography>
          <Tooltip disableInteractive title='Quitter' PopperProps={{disablePortal: true}}>
            <IconButton component='span' style={{padding: '0px'}}
              onClick={onClose}>
              <CancelIcon style={{marginRight: '0px', color: 'white'}}/>
            </IconButton>
          </Tooltip>
        </div>
        <div className={classes.body}>
          <Typography className={classes.libelle}>
            Votre message joint au rendez-vous
          </Typography>
          <TextFieldMultiline id={'message'} style={{marginTop: '15px'}} marginDense={true} rows={14}
            onChange={handleChange} value={msg} maxLength={500}/>
          <Typography className={classes.error}>
            <span className={classes.bold}>{msg.length}</span> / 500 caractères maximum
          </Typography>
          {msg.length === 500 &&
              <Typography className={classes.error}>
                <span className={classes.red}>Votre message a atteint le seuil</span>
              </Typography>
          }
        </div>
      </div>
      <div className={classes.bottom}>
        <div className={classes.button}>
          <ButtonBlue id={ID_BOUTON_ENREGISTRER} icon={<SaveIcon/>} libelle={'Enregistrer'}
            onClick={() => onClickSave(msg)}/>
        </div>
      </div>
    </div>
  );
};
