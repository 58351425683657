import React from 'react';
import MultiStepForm, {FormStep} from '../multiStepsForm/MultiStepForm';
import {identificationInitialState} from './model/ConstantsOM';
import {
  IdentificationSchemaValidation,
  MissionnementExpertSchemaValidation,
  CirconstancesSchemaValidation,
  InfosVehiculeSchemaValidation,
  CoordonneesSchemaValidation,
  GarantieSchemaValidation,
} from './schema/Schemas';
import Identification from './steps/identification/Identification';
import {UserContext} from '../../../context/UserContext';
import {FormikValues} from 'formik';
import {Typography} from '@mui/material';
import MissionnementExpert from './steps/missionnementExpert/MissionnementExpert';
import Circonstances from './steps/circonstances/Circonstances';
import {Coordonnees} from './steps/coordonnees/Coordonnees';
import InfosVehicule from './steps/infosVehicule/InfosVehicule';
import {Garantie} from './steps/garantie/Garantie';
import PiecesJointesCommentaire from './steps/pieces-jointes-commentaires/PiecesJointesCommentaire';
import {Attachment, IElementCalcul, IResponseOM, IZone} from './model/ModelOM';
import {postOMForm} from '../../../api/message';
import {TokenModel} from '../../../model/common/TokenModel';

interface FormOMProps {
  onSubmit: (response: IResponseOM, tokenMission: TokenModel) => void
  onLoading: (isLoading: boolean) => void
}

const FormOM = (props: FormOMProps) => {
  const {user} = React.useContext(UserContext);
  const {onSubmit, onLoading} = props
  const isCourtier = user.type === 'Courtier'
  const isAssureur = user.type === 'Assureur'
  const {numeroVoie, typeVoie, libelleVoie, complementAdresse, codePostal, libelleCommune} = user.adresse

  const getAssuranceForCourtier = () => {
    const filteredAgrements = user.agrements.filter((agrement) => {
      return ['_1', '_3'].includes(agrement.codeAgrement.substring(agrement.codeAgrement.lastIndexOf('_')))
    })
    return !filteredAgrements.length ? user.raisonSociale : ''
  }

  const fillerObject: FormikValues = {
    assurance: isAssureur ? user.raisonSociale : getAssuranceForCourtier(),
    isCourtier,
    isIntermediaire: isCourtier,
    intermediaire: {
      nomIntermediaire: isCourtier ? user.raisonSociale ?? '' : '',
      telephonePerso: isCourtier ? user.telephone ?? '' : '',
      telephonePro: '',
      telephonePortable: '',
      adresse: {
        adresse1: isCourtier ? [numeroVoie, typeVoie, libelleVoie].join(' ').trim() : '',
        adresse2: isCourtier ? complementAdresse ?? '' : '',
        adresse3: '',
        localite: {
          codePostal: isCourtier ? codePostal ?? '' : '',
          commune: isCourtier ? libelleCommune ?? '' : '',
        },
      },
    },
  };

  const getCodeGta = (codeAgrement: string) => {
    if (isCourtier) {
      const agrement = user.agrements.find((agrement) => agrement.codeAgrement === codeAgrement)
      return agrement?.codeGTA ?? ''
    } else return user.codeGTA
  }

  const buildPayLoad = (values: FormikValues): FormData => {
    const {
      dossier, gestionnaire, missionnement, isIntermediaire, intermediaire, circonstances, infosVehicule, zonesVehicule,
      coordonneesLese, coordonneesReparateur, garantieResponsabilite, elementsCalcul, commentaire, piecesJointes,
    } = values

    const formData = new FormData()

    const elementsCalculList = elementsCalcul.map((element: IElementCalcul) => {
      return {
        priseEnCharge: element.elementPriseEnCharge,
        descriptif: element.descriptif,
        montantFranchiseForaitaire: element.montantFranchiseForfaitaire,
        tauxFranchiseProportionnelle: element.tauxFranchiseProportionnelle,
        montantPlancherFranchiseProportionnelle: element.montantPlancherFranchiseProportionnelle,
        montantPlafondFranchiseProportionnelle: element.montantPlafondFranchiseProportionnelle,
        tauxPriseEnCharge: element.tauxPriseEnCharge,
        plafondPriseEnCharge: element.montantPlafondPriseEnCharge.replace(',', '.'),
        tvaRecuperableParAssure: element.tvaRecuperable ? 'O' : 'N',
        elementComprisReparation: element.elementComprisReparation ? 'O' : 'N',
        abattementADeduire: element.abattementADeduire ? 'O' : 'N',
      }
    })

    const piecesJointesList = piecesJointes.attachments.map((attachment: Attachment, index: number) => {
      formData.append(`file-${index}`, attachment.file);
      return {
        id: `file-${index}`,
        reference: attachment.libelle,
        type: attachment.type,
        nature: attachment.nature,
        version: attachment.version,
      }
    })

    const positionCondition = circonstances.position && circonstances.position !== 'Aucun'
    const metadata = {
      referenceSinistre: dossier.referenceSinistre,
      numMission: dossier.numeroMission,
      codeGTA: getCodeGta(dossier['assurance']),
      codeGestionnaireSinistre: gestionnaire.codeGestionnaire,
      nomGestionnaireSinistre: gestionnaire.nomGestionnaire,
      numClient: user.numClient,
      userCompte: user.userCompte,
      codeService: user.service,
      codeAbonneEmeteur: user.codeAbo,
      codeAbonneDestinataire: missionnement.codeAbonne,
      numeroAssure: dossier.numeroPolice,
      commentaire: commentaire,
      scenario: dossier.scenario,
      ...(isIntermediaire) && {
        intermediaire: {
          nom: intermediaire.nomIntermediaire,
          adresse1: intermediaire.adresse.adresse1,
          adresse2: intermediaire.adresse.adresse2,
          adresse3: intermediaire.adresse.adresse3,
          codePostal: intermediaire.adresse.localite.codePostal,
          localite: intermediaire.adresse.localite.commune,
          codePays: 'FR',
          pays: 'France',
          coordonneesCommunicationIntermediaire: {
            telephonePersonnel: [],
            telephoneProfessionnel: [intermediaire.telephone],
            telephoneMobile: [],
          },
        },
      },
      missionnement: {
        natureMission: missionnement.natureMission,
        dateMissionnement: missionnement.dateMissionnement,
        lieuExpertise: missionnement.lieuExpertise,
        dateDepot: missionnement.dateDepot,
        codeExpert: missionnement.codeExpert,
        adresseExpertise: {
          nomAdresse: missionnement.nom,
          adresse1: missionnement.adresse,
          adresse2: '',
          adresse3: '',
          codePostal: missionnement.localite.codePostal,
          localite: missionnement.localite.commune,
          pays: 'FR',
        },
      },
      evenementsCirconstances: {
        natureEvenement: circonstances.nature,
        dateEvenement: circonstances.date,
        heureEvenement: circonstances.heure,
        lieuEvenement: circonstances.lieu,
        departement: circonstances.departement,
        pays: circonstances.pays,
        typeCirconstances: circonstances.typeCirconstances,
        ...(positionCondition) && {position: circonstances.position},
        projection: circonstances.projection,
        natureVehiculeAdverseObstacle: circonstances.vehiculeAdverse,
        nombreVehiculesImpliques: circonstances.autresVehicules,
        autresObjets: circonstances.autresObjets,
      },
      garantieResponsabilite: {
        garantieMiseEnJeu: garantieResponsabilite.garantieMiseEnJeu,
        natureContrat: garantieResponsabilite.nature,
        reglementDirect: garantieResponsabilite.reglement,
        tauxResponsabilite: garantieResponsabilite.indetermine ? '999' : garantieResponsabilite.responsabilite,
        pretVehicule: garantieResponsabilite.pret,
      },
      modalite: {
        modaliteDeterminationDommages: garantieResponsabilite.modaliteDommages,
        autorisationModalite: garantieResponsabilite.autorisationModalite,
      },
      infosVehicule: {
        genre: infosVehicule.genre,
        marque: infosVehicule.marque.valeur,
        modele: infosVehicule.modele,
        immatriculation: infosVehicule.immatriculation,
        ...(infosVehicule.serie) && {serie: infosVehicule.serie},
      },
      degatsVehicule: zonesVehicule.map((zone: IZone)=> zone.code),
      lese: {
        nom: coordonneesLese.nom,
        telPerso: coordonneesLese.telPerso,
        telPro: coordonneesLese.telPro,
        telPortable: coordonneesLese.telPortable,
        adressePartie: {
          nomAdresse: '',
          adresse1: coordonneesLese.adresse,
          adresse2: coordonneesLese.adresseComplementaire1,
          adresse3: coordonneesLese.adresseComplementaire2,
          codePostal: coordonneesLese.localite.codePostal,
          localite: coordonneesLese.localite.commune,
          pays: 'FR',
        },
      },
      reparateur: {
        nom: coordonneesReparateur.nom,
        telPerso: coordonneesReparateur.telPerso,
        telPro: coordonneesReparateur.telPro,
        telPortable: coordonneesReparateur.telPortable,
        adressePartie: {
          nomAdresse: '',
          adresse1: coordonneesReparateur.adresse,
          adresse2: coordonneesReparateur.adresseComplementaire1,
          adresse3: coordonneesReparateur.adresseComplementaire2,
          codePostal: coordonneesReparateur.localite.codePostal,
          localite: coordonneesReparateur.localite.commune,
          pays: 'FR',
        },
        codificationAssureur: coordonneesReparateur.codificationAssureur,
        natureAccord: coordonneesReparateur.natureAccord,
      },
      elementsCalcul: elementsCalculList,
      piecesJointes: piecesJointesList,
    }

    formData.append(
        'metadata',
        new Blob([JSON.stringify({...metadata})], {type: 'application/json'}),
    );
    return formData
  }

  const onsubmitForm = (values: FormikValues) => {
    onLoading(true)
    const payLoad = buildPayLoad(values)
    postOMForm(payLoad).then((response) => {
      const tokenMission: TokenModel = {
        referenceSinistre: values.dossier.referenceSinistre,
        numeroMission: values.dossier.numeroMission,
        codeGTA: getCodeGta(values['dossier']['assurance']),
        numClient: user.numClient,
        userCompte: user.userCompte,
        codeAbonne: user.codeAbo,
        codeService: user.service,
        immatriculation: values.infosVehicule.immatriculation,
        isDossierComplet: false,
      }
      const responseOM = {codeStatus: response.status, codeRetour: response.data}
      onSubmit(responseOM, tokenMission)
    }).catch((error) => {
      console.error(error);
      onSubmit({codeStatus: error.status, codeRetour: ''} as IResponseOM, {} as TokenModel);
    }).finally(() => {
      onLoading(false)
    })
  }

  return (
    <div>
      <Typography variant={'h4'}>Création d&apos;un ordre de mission</Typography>
      <MultiStepForm
        initialValues={identificationInitialState(fillerObject)}
        onSubmit={onsubmitForm}
        showStepper={true}
      >
        <FormStep
          validationSchema={IdentificationSchemaValidation}
        >
          <Identification/>
        </FormStep>

        <FormStep
          validationSchema={MissionnementExpertSchemaValidation}
        >
          <MissionnementExpert/>
        </FormStep>

        <FormStep
          validationSchema={CirconstancesSchemaValidation}
        >
          <Circonstances/>
        </FormStep>

        <FormStep
          validationSchema={InfosVehiculeSchemaValidation}
        >
          <InfosVehicule/>
        </FormStep>

        <FormStep
          validationSchema={CoordonneesSchemaValidation}
        >
          <Coordonnees/>
        </FormStep>

        <FormStep
          validationSchema={GarantieSchemaValidation}
        >
          <Garantie/>
        </FormStep>

        <FormStep>
          <PiecesJointesCommentaire/>
        </FormStep>
      </MultiStepForm>
    </div>
  );
};

export default FormOM;
