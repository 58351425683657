import React, {useContext} from 'react';
import {Card, CardContent, Grid, Typography} from '@mui/material';
import {StyledTypography} from '../../../common/StyledTypography';
import {UserContext} from '../../../../context/UserContext';
import {getService} from '../../../../common/Utils';

interface IProps {
  numeroEchange: string
}

export const DetailsEchange = (props: IProps) => {
  const {user} = useContext(UserContext);
  const {numeroEchange} = props;

  return (
    <Card>
      <CardContent>
        <Typography variant={'h3'}>Détails de l&apos;échange</Typography>

        <Grid container justifyContent={'space-between'}>
          <Grid item>
            <StyledTypography
              id={'service'}
              title={'Service'}
              text={getService(user.service)}
              isTextBold={true}
            />
          </Grid>

          <Grid item>
            <StyledTypography
              id={'societe'}
              title={'Société'}
              text={user.raisonSociale}
              isTextBold={true}
            />
          </Grid>

          <Grid item>
            <StyledTypography
              id={'numero-echange'}
              title={'Numéro d\'échange'}
              text={numeroEchange}
              isTextBold={true}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
};
