import React, {ChangeEvent} from 'react';
import {ItemGetValeursCodesModel} from '../../../../model/common/ItemGetValeursCodesModel';
import {FieldConfig, FieldHookConfig, useField} from 'formik';
import {AutocompleteField} from './AutocompleteField';

export interface InputSelectAutocompleteProps extends FieldConfig {
  id: string,
  itemsList: ItemGetValeursCodesModel[],
  label: string,
  isMandatory: boolean,
  disabled?: boolean,
}

const InputSelectAutocompleteField = (props: InputSelectAutocompleteProps) => {
  const {id, itemsList, isMandatory, label, disabled} = props;
  const [field, meta, helper] = useField<ItemGetValeursCodesModel>(props as FieldHookConfig<ItemGetValeursCodesModel>);

  const onSelectItem = (event: ChangeEvent<{}>, value: any) => {
    helper.setValue(value)
  };

  return (
    <AutocompleteField
      id={id}
      label={label}
      isMandatory={isMandatory}
      options={itemsList}
      getOptionLabel={(item: ItemGetValeursCodesModel) => item.libelleedition?.trim() ?? ''}
      onChange={onSelectItem}
      disabled={Boolean(disabled)}
      field={field}
      meta={meta}
    />
  );
};

export default InputSelectAutocompleteField;
