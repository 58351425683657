import React, {ReactNode, useEffect, useState} from 'react';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {Slide} from '@mui/material';
import Notification from '../notifications/Notification';

interface SlideNotificationProps {
  id: string
  theme: 'Success' | 'Error' | 'Warning'
  storageItem: string
  autoHideDuration?: number
  children: ReactNode
  onClose?: () => void
}

const styles = () => ({
  notification: {
    position: 'fixed' as const,
    width: '100%',
    bottom: '0',
    right: '0',
  },
})

const NewSlideNotification = (props: SlideNotificationProps) => {
  const classes = useEmotionStyles(styles);
  const {children, id, theme, storageItem, autoHideDuration, onClose} = props;
  const [showNotification, setShowNotification] = useState(true);

  useEffect(() => {
    if (showNotification) {
      if (autoHideDuration) {
        setTimeout(() => {
          setShowNotification(false);
        }, autoHideDuration);
      }
    } else {
      sessionStorage.removeItem(storageItem)
    }
  }, [showNotification])

  return (
    <Slide
      style={{zIndex: 1}}
      id={id}
      in={showNotification}
      direction={'up'}
      timeout={1000}
      mountOnEnter unmountOnExit
    >
      <div className={classes.notification}>
        <Notification
          theme={theme}
          onClose={() => {
            setShowNotification(false)
            if (onClose) {
              onClose()
            }
          }}
        >
          {children}
        </Notification>
      </div>
    </Slide>
  );
};

export default NewSlideNotification;
