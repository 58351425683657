import React from 'react';
import {Autocomplete, FormControl, TextField, Typography} from '@mui/material';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {FieldInputProps, FieldMetaProps} from 'formik';

const styles = (theme: Theme) => ({
  container: {
    width: '360px',
    marginRight: '24px',
  },
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  textfield: {
    backgroundColor: theme.palette.white.main,
  },
});

interface AutocompleteFieldProps {
  id: string,
  label: string,
  isMandatory: boolean,
  isLoading?: boolean,
  options: any[],
  getOptionLabel: any,
  onChange: any,
  onInputChange?: any,
  disabled?: boolean,
  field: FieldInputProps<any>,
  meta: FieldMetaProps<any>
}

export const AutocompleteField = ( props: AutocompleteFieldProps) => {
  const classes = useEmotionStyles(styles);
  const {id, label, isMandatory, isLoading, options, getOptionLabel,
    onChange, onInputChange, disabled, field, meta} = props

  const renderInputAutoCompletion = (params: any) => {
    return <FormControl fullWidth>
      <TextField
        {...params}
        name={field.name}
        margin='dense'
        size='small'
        fullWidth
        variant="outlined"
        disabled={Boolean(disabled)}
        InputProps={{
          ...params.InputProps,
          classes: {
            root: classes.textfield,
          },
        }}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
      />
    </FormControl>
  };

  return (
    <div className={classes.container}>
      <Typography className={classes.libelle}>
        {label} {isMandatory && <span style={{color: 'red'}}>*</span>}
      </Typography>
      <Autocomplete
        id={id}
        autoHighlight
        loading={isLoading ?? false}
        loadingText='Chargement...'
        popupIcon={''}
        value={field.value}
        options={options}
        getOptionLabel={getOptionLabel}
        onChange={onChange}
        disabled={Boolean(disabled)}
        onBlur={field.onBlur}
        onInputChange={onInputChange}
        renderInput={renderInputAutoCompletion}
        filterOptions={(options) => options}
        noOptionsText={'Aucun résultat trouvé'}
      />
    </div>
  );
};
