import React from 'react';
import {UserContext} from '../../../context/UserContext';

interface PersonneSivProps {
  isPersonnePhysique?: boolean,
  isSocieteCommerciale?: boolean,
  identificationPersonne?: string,
  nom?: string,
  nomUsage?: string,
  prenom?: string,
  sexe?: string,
  siret?: string,
  siren?: string,
  etage?: string,
  immeuble?: string,
  numeroVoie?: string,
  extension?: string,
  type?: string,
  nomVoie?: string,
  lieuxDit?: string,
  boitePostale?: string,
  codePostal?: string,
  localite?: string,
  pays?: string,
}

interface VehiculeProps {
  numeroImmatriculation: string,
  numeroVin?: string,
  numeroFormule?: string,
  dateImmaticulation?: string,
  isPresenceCertificat?: boolean,
}

interface InfoAchatProps {
  dateAchat: string,
  heureAchat: string,
  dateDeclarationAchat: string,
  heureDeclarationAchat: string,
  isVehiculeSuspensionVE: boolean,
  isVehiculeEcoIrreparable: boolean,
}

interface InfoCessionProps {
  dateCession: string,
  heureCession: string,
  dateDeclarationCession: string,
  heureDeclarationCession: string,
}

interface InfoRefusProps {
  dateDeclarationRefus: string,
  heureDeclarationRefus: string,
}

export interface DataProps {
  user: string,
  numClient: string,
  userCompte: string,
  referenceSinistre?: string,
  numMissionsList?: string[],
  typeOperation: string,
  codeAbonneEmeteur: string,
  codeService: string,
  dateSinistre: string,
  heureSinistre: string,
  numeroPoliceAssurance: string,
  titulaireActuel: PersonneSivProps,
  nouveauTitulaire: PersonneSivProps,
  vehicule: VehiculeProps,
  infoAchat: InfoAchatProps,
  infoCession: InfoCessionProps,
  infoRefus: InfoRefusProps,
}

export const useInitialStateFormSIV = () => {
  const {user} = React.useContext(UserContext);

  const data: DataProps = {
    user: user.userCompte,
    numClient: user.numClient,
    userCompte: user.userCompte,
    referenceSinistre: '',
    numMissionsList: [],
    typeOperation: '',
    codeAbonneEmeteur: user.codeAbo,
    codeService: user.service,
    dateSinistre: '',
    heureSinistre: '',
    numeroPoliceAssurance: '',
    titulaireActuel: {
      isPersonnePhysique: false,
      isSocieteCommerciale: false,
      identificationPersonne: '',
      nom: '',
      nomUsage: '',
      prenom: '',
      sexe: '',
      siret: '',
      siren: '',
      etage: '',
      immeuble: '',
      numeroVoie: '',
      extension: '',
      type: '',
      nomVoie: '',
      lieuxDit: '',
      boitePostale: '',
      codePostal: '',
      localite: '',
      pays: '',
    },
    nouveauTitulaire: {
      isPersonnePhysique: false,
      isSocieteCommerciale: false,
      identificationPersonne: '',
      nom: '',
      nomUsage: '',
      prenom: '',
      sexe: '',
      siret: '',
      siren: '',
      etage: '',
      immeuble: '',
      numeroVoie: '',
      extension: '',
      type: '',
      nomVoie: '',
      lieuxDit: '',
      boitePostale: '',
      codePostal: '',
      localite: '',
      pays: '',
    },
    vehicule: {
      numeroImmatriculation: '',
      numeroVin: '',
      numeroFormule: '',
      dateImmaticulation: '',
      isPresenceCertificat: false,
    },
    infoAchat: {
      dateAchat: '',
      heureAchat: '',
      dateDeclarationAchat: '',
      heureDeclarationAchat: '',
      isVehiculeSuspensionVE: false,
      isVehiculeEcoIrreparable: false,
    },
    infoCession: {
      dateCession: '',
      heureCession: '',
      dateDeclarationCession: '',
      heureDeclarationCession: '',
    },
    infoRefus: {
      dateDeclarationRefus: '',
      heureDeclarationRefus: '',
    },
  }

  return data
}
