import {useEffect, useState} from 'react';
import {getUserbyIdClient} from '../api/user';
import {UserContextType, userInitialState} from '../context/UserContext';
import {AxiosError} from 'axios';
import Keycloak from 'keycloak-js';
import {listServices} from '../common/Utils';

interface UserProps {
  userCompte: string,
  codeService: string
  isAssistance: boolean
  isTransparent: boolean
  keycloak?: Keycloak
  noClient?: string,
  rolesSSO?: string[],
  userFromStorage?: UserContextType
}

const useUser = (props: UserProps) => {
  const {userCompte, isAssistance, isTransparent, codeService, keycloak, userFromStorage} = props;
  const initialStateUser = userFromStorage ?? {
    ...userInitialState,
    isAssistance: isAssistance,
    isTransparent: isTransparent,
  };

  const [data, setData] = useState<UserContextType>(initialStateUser);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<AxiosError>();

  interface UserDetailsProps {
    response: any,
    userCompte: string
    roles: string[]
    codeAbo: string
    isSSO: boolean
    raisonSocialeInfogeo: string,
    siren: string,
    adresse: any
  }

  const userDetails = (props: UserDetailsProps): UserContextType => {
    return {
      userCompte: userCompte,
      nom: props.response.compte.partenaire.nom,
      givenName: data.givenName,
      familyName: data.familyName,
      type: props.response.compte.partenaire.libelleType,
      roles: props.roles,
      codeAbo: props.codeAbo,
      service: codeService,
      listeServices: listServices(props.response),
      codeGTA: props.response.compte.partenaire.codeGta,
      numClient: props.response.compte.partenaire.numeroClient,
      isAssistance: isAssistance,
      isTransparent: isTransparent,
      isSSO: props.isSSO,
      raisonSociale: props.response.compte.partenaire.raisonSociale,
      raisonSocialeInfogeo: props.raisonSocialeInfogeo,
      siret: props.response.compte.partenaire.siret || '',
      siren: props.siren,
      telephone: props.response.compte.partenaire.telephone.trim() || '',
      token: keycloak?.idToken ?? '',
      adresse: props.adresse,
      filiales: props.response.filiales,
      agrements: props.response.agrements,
      agrementsBG: props.response.agrementsBG,
    }
  }

  useEffect(() => {
    if (!userFromStorage && !!userCompte) {
      getUserbyIdClient()
          .then((response) => {
            const userCompte = response.compte._ref_compte
            const roles = response.compte.roles.map((role: any) => role.code_role)
            const codeAbo = response.compte.partenaire.abonnements
                .find((e: any) => e.codeService === codeService)?.codeAbonne
            const raisonSocialeInfogeo = response.infogeoEtablissement?.siege.raisonSociale
            const siren = response.infogeoEtablissement?.siren || ''
            const adresse = {
              complementAdresse: response.infogeoEtablissement?.adresse.complementAdresse,
              numeroVoie: response.infogeoEtablissement?.adresse.numeroVoie,
              indiceRepetition: response.infogeoEtablissement?.adresse.indiceRepetition,
              typeVoie: response.infogeoEtablissement?.adresse.typeVoie,
              libelleVoie: response.infogeoEtablissement?.adresse.libelleVoie,
              codePostal: response.infogeoEtablissement?.adresse.codePostal,
              codeCedex: response.infogeoEtablissement?.adresse.codeCedex,
              distributionSpeciale: response.infogeoEtablissement?.adresse.distributionSpeciale,
              libelleCommune: response.infogeoEtablissement?.adresse.libelleCommune,
              libelleCedex: response.infogeoEtablissement?.adresse.libelleCedex,
            }
            setData(userDetails({
              response: response, userCompte: userCompte,
              roles: roles, codeAbo: codeAbo, isSSO: false,
              raisonSocialeInfogeo: raisonSocialeInfogeo, siren: siren, adresse}));
          })
          .catch((error: AxiosError) => {
            setError(error);
          })
          .finally(() => setLoading(false))
    } else {
      setLoading(false)
    }
  }, [userCompte]);

  return {data, error, loading}
};

export default useUser
