import * as yup from 'yup';
import {
  checkRaisonSoc,
} from '../../../../common/utils/InputValuesUtils';
import {validationSchemaImmatriculation} from '../validationSchema/validationSchemaImmatriculation';
import {validationSchemaRefSinistre} from '../validationSchema/validationSchemaRefSinistre';
import {validationSchemaNomUsagePrenom} from '../validationSchema/validationSchemaNomUsagePrenom';

export const validationSchemaCT = yup.object({
  policeAssurance: yup.string()
      .required('Veuillez saisir une valeur'),
  raisonSociale: yup.string()
      .when('typePersonne', {
        is: (value: string) => value === 'pm',
        then: () => yup.string()
            .required('Veuillez saisir une valeur')
            .test('test-raisonSociale', 'Les caractères ! ? * ° : € # ; ne sont pas autorisés',
                function(value) {
                  return checkRaisonSoc(value || '')
                }),
      }),
  societeCommerciale: yup.boolean(),
  siren: yup.string()
      .when('typePersonne', {
        is: (value: string) => value === 'pm',
        then: () => yup.string()
            .min(9, 'Veuillez saisir une valeur au format attendu, voir')
            .test('test-siren', 'Veuillez saisir une valeur',
                function(value, ctx) {
                  if (ctx.parent.societeCommerciale) {
                    return value !== undefined && value !== ''
                  }
                  return true
                }),
      }),
  siret: yup.string()
      .when('typePersonne', {
        is: (value: string) => value === 'pm',
        then: () => yup.string()
            .min(14, 'Veuillez saisir une valeur au format attendu, voir')
            .test('test-siret', 'Veuillez saisir une valeur',
                function(value, ctx) {
                  if (ctx.parent.societeCommerciale) {
                    return value !== undefined && value !== ''
                  }
                  return true
                }),
      }),
}).concat(validationSchemaImmatriculation).concat(validationSchemaRefSinistre(false))
    .concat(validationSchemaNomUsagePrenom);
