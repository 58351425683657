import React, {useEffect, useState} from 'react';
import {FormControl, IconButton, Tooltip, Typography} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import {TextFieldSearch} from '../../../common/formsComponents/TextFieldSearch';
import SaveIcon from '@mui/icons-material/Save';
import {ButtonBlue} from '../../../common/buttons/ButtonBlue';
import {checkNom, checkPrenom, checkTelMobile, ErrorFields} from '../PriseRDV';
import AutoCompleteMarque from '../components/AutoCompleteMarque';
import {emptyItem, ItemGetValeursCodesModel} from '../../../../model/common/ItemGetValeursCodesModel';
import {IMarque, InformationAssure} from '../../../../model/priseRDV/RDVModel';
import {updateRendezvous} from '../../../../api/idigo';
import {Loader} from '../../../common/Loader';
import {Telephone} from '../../../common/personne/Telephone';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {UserContext} from '../../../../context/UserContext';
import {PrenomRdv} from '../../../common/personne/PrenomRdv';
import {NomRdv} from '../../../common/personne/NomRdv';
import {ID_BOUTON_ENREGISTRER} from '../../../../constantes/ids/Ids';

interface ModalModificationRecapProps {
  from: string,
  onClose: () => void,
  infosAssure: InformationAssure,
  idRdv?: string,
  idPartenaire?: string,
  idActivite?: string,
  codesValeursMarque: ItemGetValeursCodesModel[],
  errorFields: ErrorFields,
  setErrorFields: (errorFields: ErrorFields) => void,
  onClickSaveInfosAssure?: (prenom: string, nom: string, tel: string, email: string, marque?: IMarque,
                            modele?: string) => void,
  setOpenModalModif: (openModal: boolean) => void,
}

/** Design */
const styles = (theme: Theme) => ({
  form: {
    position: 'absolute' as const,
    top: '0%',
    right: '0%',
    height: '100vh',
    width: '35%',
    backgroundColor: theme.palette.white.main,
    boxShadow: '0px 10px 20px ' + theme.palette.boxShadow.light1,
    [theme.breakpoints.down('lg')]: {
      width: '45%',
    },
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
  },
  header: {
    display: 'flex',
    height: '70px',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 20px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white.main,
  },
  titre: {
    fontSize: '20px',
    fontWeight: 'bold',
    letterSpacing: '0px',
    opacity: '1',
  },
  body: {
    height: '92%',
    overflowY: 'auto' as const,
    marginBottom: '10px',
  },
  content: {
    padding: '24px',
  },
  error: {
    fontSize: '12px',
  },
  red: {
    color: 'red',
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap' as const,
    rowGap: '10px',
    columnGap: '24px',
    justifyContent: 'space-between',
  },
  componentContainer: {
    width: 'calc(50% - 12px)',
  },
  marginTop16: {
    marginTop: '16px',
  },
  marginTop36: {
    marginTop: '36px',
  },
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  bottom: {
    height: '5%',
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0px 20px',
  },
  loader: {
    display: 'block',
    margin: '0px 20px 0px 0px',
  },
  divResponse: {
    display: 'flex',
    height: '75vh',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column' as const,
  },
  buttonOK: {
    marginTop: '15px',
  },
});

const EMAIL_PATTERN = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

export const ModalModificationRecap = (props: ModalModificationRecapProps) => {
  const classes = useEmotionStyles(styles);
  const {user} = React.useContext(UserContext);
  const {from, infosAssure, idRdv, idPartenaire, idActivite, codesValeursMarque, errorFields,
    setErrorFields, onClose, onClickSaveInfosAssure, setOpenModalModif} = props;
  const [newInfosRdv, setNewInfosRdv] = useState<InformationAssure>(infosAssure);
  const [selectedMarque, setSelectedMarque] = useState<ItemGetValeursCodesModel>(emptyItem);
  const [isLoading, setIsLoading] = useState(false);
  const [responseReturn, setResponseReturn] = useState(0);

  useEffect(() => {
    const defaultMarque = codesValeursMarque.find((item) =>
      item.libelleedition.toUpperCase().trim() === newInfosRdv.marque?.libelleedition.toUpperCase());
    defaultMarque && setSelectedMarque(defaultMarque);
  }, [codesValeursMarque]);

  const isErrorField = (fieldName: string) => {
    return errorFields && errorFields.filter((error) => error.filedName === fieldName).length > 0 || false
  };

  const getMessageErrorFromList = (fieldName: string) => {
    const fieldOnError = errorFields?.filter((error) => error.filedName === fieldName);
    return (fieldOnError?.length) ? fieldOnError[0].errorMessage : ''
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    let errorTab = [...errorFields];
    errorTab = errorTab.filter((error) => error.filedName != event.target.name);
    if (event.target.name === 'telephone' || event.target.name === 'email') {
      errorTab = errorTab.filter((error) => error.filedName != 'canauxCommunication');
    }
    setErrorFields([...errorTab])
  };

  const onBlurPrenom = (event: React.FocusEvent<HTMLInputElement>) => {
    if (checkPrenom(event.target.value).onError) {
      setErrorFields([...errorFields,
        {filedName: 'prenom', errorMessage: checkPrenom(event.target.value).typeError},
      ])
    }
  };

  const onBlurNomUsage = (event: React.FocusEvent<HTMLInputElement>) => {
    if (checkNom(event.target.value, false).onError) {
      setErrorFields([...errorFields,
        {filedName: 'nom', errorMessage: checkNom(event.target.value, true).typeError},
      ])
    }
  };

  const onBlurTelephone = (event: React.FocusEvent<HTMLInputElement>) => {
    const telFormated = event.target.value.replaceAll(' ', '');
    const isErrorTel = event.target.value.length &&
      (!event.target.value.startsWith('06') && !event.target.value.startsWith('07') || telFormated.length != 10);
    const isErrorCanauxComm = event.target.value.length === 0 && !newInfosRdv.email;
    if (isErrorCanauxComm) {
      errorFields.push({filedName: 'canauxCommunication',
        errorMessage: 'Veuillez saisir une valeur'})
    }
    if (isErrorTel) {
      errorFields.push({filedName: 'telephone',
        errorMessage: checkTelMobile(event.target.value, false).typeError ?? ''})
    }
    setErrorFields([...errorFields]);
  };

  const onChangeMarque = (value: ItemGetValeursCodesModel) => {
    setSelectedMarque(value);
    setNewInfosRdv({
      ...newInfosRdv,
      marque: {valeur: value?.valeur.toUpperCase().trim(), libelleedition: value?.libelleedition.trim()},
    })
  };

  const onBlurEmail = (event: React.FocusEvent<HTMLInputElement>) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const isErrorEmail = event.target.value.length && !emailPattern.test(event.target.value);
    const isErrorCanauxComm = event.target.value.length === 0 && !newInfosRdv.telephone;
    if (isErrorCanauxComm) {
      errorFields.push({filedName: 'canauxCommunication',
        errorMessage: 'Veuillez saisir une valeur'})
    }
    if (isErrorEmail) {
      errorFields.push({filedName: 'email',
        errorMessage: 'Veuillez saisir une adresse email au bon format'})
    }
    setErrorFields([...errorFields]);
  };

  const initPayload = () => {
    const {prenom, nom, telephone, email, marque, modele} = newInfosRdv;
    const nomDiff = nom !== infosAssure.nom;
    const prenomDiff = prenom !== infosAssure.prenom;
    const telDiff = telephone && telephone.replace('+33', '0') !== infosAssure.telephone?.replaceAll(' ', '');
    const emailDiff = email && email !== infosAssure.email;
    const canalTelDiff = telephone?.length !== infosAssure.telephone?.length;
    const canalEmailDiff = email?.length !== infosAssure.email?.length;
    const marqueVehiculeDiff = marque?.valeur !== infosAssure.marque?.valeur;
    const modeleVehiculeDiff = modele !== infosAssure.modele;
    const hasCoordonnees = telDiff ?? emailDiff ?? canalTelDiff ?? canalEmailDiff;
    return {
      personne: {
        ...(nomDiff && {nom: newInfosRdv.nom}),
        ...(prenomDiff && {prenom: newInfosRdv.prenom}),
        ...(hasCoordonnees && {coordonnees: {
          portable: newInfosRdv.telephone && newInfosRdv.telephone !== '' ?
              '+33'.concat(newInfosRdv.telephone.substring(1)).replaceAll(' ', '') : '',
          email: newInfosRdv.email,
          telephoneAutorisation: !!telephone?.length,
          emailAutorisation: !!email?.length,
        }}),
      },
      dommages: {
        ...(marqueVehiculeDiff && {marqueVehicule: newInfosRdv.marque?.valeur ?? ''}),
        ...(modeleVehiculeDiff && {modeleVehicule: newInfosRdv.modele}),
      },
      creneau: {},
    };
  }
  const errors = () => {
    const result: ErrorFields = [];
    const checkTel = !newInfosRdv.telephone?.startsWith('06') && !newInfosRdv.telephone?.startsWith('07') ||
        newInfosRdv.telephone?.replaceAll(' ', '').length != 10;

    const checkEmail = !EMAIL_PATTERN.test(newInfosRdv.email ?? '');

    if (!!newInfosRdv.telephone?.length && checkTel) {
      result.push({filedName: 'telephone',
        errorMessage: 'Veuillez saisir un n° de téléphone mobile au bon format'});
    }

    if (!!newInfosRdv.email?.length && checkEmail) {
      result.push({filedName: 'email',
        errorMessage: 'Veuillez saisir une adresse email au bon format'});
    }
    return result
  }

  const onValidateForm = () => {
    setErrorFields(errors());
    if (errors.length === 0) {
      const {prenom, nom, telephone, email, marque, modele} = newInfosRdv;
      if (from === 'detailRdv') {
        setIsLoading(true);
        const payload = initPayload()
        const {numClient, userCompte} = user;
        if (idPartenaire && idRdv && idActivite) {
          updateRendezvous({idPartenaire: idPartenaire, idRdv: idRdv, idActivite: idActivite, numClient: numClient,
            userCompte: userCompte, codeGTA: '',
            serviceCode: user.service, data: payload, sendNotif: false})
              .then((response) => {
                setResponseReturn(response.status);
                setIsLoading(false)
              }).catch((error) => {
                setResponseReturn(error.response.status);
                setIsLoading(false)
              })
        } else {
          setResponseReturn(500);
          setIsLoading(false)
        }
      } else if (onClickSaveInfosAssure) {
        onClickSaveInfosAssure(prenom, nom, telephone ?? '', email ?? '', marque, modele);
        setOpenModalModif(false);
      }
    }
  };

  return (
    <div className={classes.form}>
      <div className={classes.body}>
        <div className={classes.header}>
          <Typography className={classes.titre}>Modification des informations de l&apos;assuré</Typography>
          <Tooltip disableInteractive title='Quitter' PopperProps={{disablePortal: true}}>
            <IconButton component='span' style={{padding: '0px'}} onClick={onClose}>
              <CancelIcon style={{marginRight: '0px', color: 'white'}}/>
            </IconButton>
          </Tooltip>
        </div>
        {responseReturn === 0 &&
          <div className={classes.content}>
            <Typography variant={'h3'}>Informations de l&apos;assuré</Typography>
            <div className={`${classes.flex} ${classes.marginTop16}`}>
              <PrenomRdv
                className={classes.componentContainer}
                fullWidth={true}
                formulaire={'RDVRecap'}
                value={newInfosRdv?.prenom}
                onChangeValue={(event) => setNewInfosRdv({...newInfosRdv, prenom: event.target.value})}
                onBlur={onBlurPrenom}
                onFocus={handleFocus}
                onError={isErrorField('prenom')}
                typeError={checkPrenom(newInfosRdv?.prenom).typeError}
                maxLength={32}
              />
              <NomRdv
                className={classes.componentContainer}
                fullWidth={true}
                formulaire={'RDVRecap'}
                nomUsage={false}
                value={newInfosRdv?.nom}
                onChangeValue={(event) => setNewInfosRdv({...newInfosRdv, nom: event.target.value})}
                onBlur={onBlurNomUsage}
                onFocus={handleFocus}
                onError={isErrorField('nom')}
                typeError={checkNom(newInfosRdv?.nom, false).typeError}
                maxLength={32}
              />
            </div>

            <div className={`${classes.flex} ${classes.marginTop16}`}>
              <div className={classes.componentContainer}>
                <Telephone
                  fullWidth={true}
                  title={'Téléphone'}
                  isMandatory={false}
                  formulaire={'RDV'}
                  defaultTelephone={newInfosRdv.telephone}
                  onChangeValue={(event) => setNewInfosRdv({...newInfosRdv, telephone: event.target.value})}
                  onBlur={onBlurTelephone}
                  onFocus={handleFocus}
                  onError={isErrorField('telephone') || isErrorField('canauxCommunication')}
                  typeError={checkTelMobile(newInfosRdv.telephone ?? '', false).typeError}
                />
                <Typography className={`${classes.error} ${isErrorField('canauxCommunication') && classes.red}`}>
                  Veuillez remplir au moins un des deux champs
                </Typography>
              </div>
              <div className={classes.componentContainer}>
                <Typography className={classes.libelle}>E-mail</Typography>
                <FormControl error fullWidth variant="outlined">
                  <TextFieldSearch
                    id='email'
                    name='email'
                    value={newInfosRdv.email ?? ''}
                    maxLength={70}
                    onChange={(event) => setNewInfosRdv({...newInfosRdv, email: event.target.value})}
                    onError={isErrorField('email') || isErrorField('canauxCommunication')}
                    onBlur={onBlurEmail}
                    onFocus={handleFocus}
                  />
                  {errorFields && isErrorField('email') &&
                    <Typography id="component-error-text" className={`${classes.error} ${classes.red}`}>
                      {getMessageErrorFromList('email')}
                    </Typography>
                  }
                </FormControl>
              </div>
            </div>

            <Typography className={classes.marginTop36} variant={'h3'}>Informations du véhicule</Typography>
            <div className={`${classes.flex} ${classes.marginTop16}`}>
              <div className={classes.componentContainer}>
                <Typography className={`${classes.libelle}`}>Marque</Typography>
                <AutoCompleteMarque
                  id={'auto-complete-marque-modal-recap'}
                  codesValeursMarque={codesValeursMarque}
                  selectedMarque={selectedMarque}
                  onChangeMarque={(event, value: ItemGetValeursCodesModel) => onChangeMarque(value)}
                />
              </div>
              <div className={classes.componentContainer}>
                <Typography className={`${classes.libelle}`}>Modèle</Typography>
                <TextFieldSearch
                  id={'modele'}
                  fullWidth={true}
                  value={newInfosRdv.modele ?? ''}
                  maxLength={25}
                  onChange={(event) => setNewInfosRdv({...newInfosRdv, modele: event.target.value})}
                />
              </div>
            </div>
          </div>
        }

        {(responseReturn === 200) &&
          <div className={classes.divResponse}>
            <Typography variant='body1' component='div' className={classes.titre}>
              Vos modifications ont bien été enregistrées.
            </Typography>
            <ButtonBlue
              id={'bouton-ok'}
              className={classes.buttonOK}
              libelle={'OK'}
              onClick={() => window.location.reload()}
            />
          </div>
        }

        {responseReturn > 0 && responseReturn !== 200 &&
          <div className={classes.divResponse}>
            <Typography variant='body1' component='div' className={classes.titre}>
              Une erreur s&apos;est produite. Merci de réessayer ultérieurement.
            </Typography>
            <ButtonBlue
              id={'bouton-ok'}
              className={classes.buttonOK}
              libelle={'OK'}
              onClick={() => window.location.reload()}
            />
          </div>
        }
      </div>

      {responseReturn === 0 &&
        <div className={classes.bottom}>
          <div className={classes.button}>
            {isLoading ? <Loader className={classes.loader} size={30}/> :
              <ButtonBlue
                id={ID_BOUTON_ENREGISTRER}
                icon={<SaveIcon/>}
                libelle={'Enregistrer'}
                disabled={errorFields.length > 0}
                onClick={onValidateForm}
              />
            }
          </div>
        </div>
      }
    </div>
  )
};
