import React, {useState} from 'react';
import {Immatriculation} from '../../../common/vehicule/Immatriculation';
import {RefSinistre} from '../../../common/RefSinistre';
import {InputDate} from '../../../common/InputDate';
import {Hour} from '../../../common/Hour';
import {useInitialStateFormSIV} from '../initialStateFormSIV';
import {postSIVForm} from '../../../../api/siv';
import {ISIVFormSAAccesTransparent, SIVFormModel} from '../../../../model/common/SIVFormModel';
import {dateToDateText} from '../../../../common/utils/DateUtils';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {getInitialValueSIV, helperTextWithIcon} from '../../../../common/Utils';
import {useFormik} from 'formik';
import {validationSchemaSA} from './validationSchemaSA';
import {FooterFormSIV} from '../FooterFormSIV';
import {stylesCommon} from '../StylesCommon';

export const FormSA = ({onChangeResponse, dataAccesTransparent, isAccesTransparent}: SIVFormModel) => {
  const classes = useEmotionStyles(stylesCommon);
  const getItemMission = sessionStorage.getItem('mission');
  const mission = getItemMission ? JSON.parse(getItemMission) : '';
  const data = dataAccesTransparent as ISIVFormSAAccesTransparent;
  const [isLoading, setIsLoading] = useState(false);

  const initialValuesSA = {
    immatriculation: getInitialValueSIV([data?.immatriculation, mission?.infoMissionDTO?.immatriculation]),
    referenceSinistre: getInitialValueSIV([data?.referenceSinistre,
      mission?.infoMissionDTO?.referenceSinistre?.trim()]),
    date: getInitialValueSIV([data?.date, mission?.gestionDTO?.informationSinistreDTO?.dateSinistre]),
    heure: getInitialValueSIV([data?.heure, mission?.gestionDTO?.informationSinistreDTO?.heureSinistre]),
  };

  let formData = useInitialStateFormSIV();
  const onSubmit = () => {
    setIsLoading(true);
    formData = {...formData, typeOperation: 'SA', vehicule: {numeroImmatriculation: formikSA.values.immatriculation},
      referenceSinistre: formikSA.values.referenceSinistre,
      dateSinistre: dateToDateText(new Date(formikSA.values.date)),
      heureSinistre: formikSA.values.heure};
    postSIVForm(formData, isAccesTransparent)
        .then((response) => {
          onChangeResponse(response);
        }).catch((error) => {
          onChangeResponse(error.response)
        }).finally(() =>
          setIsLoading(false),
        )
  };

  const onFocus = (field: string) => () => {
    formikSA.setFieldTouched(field, false, false)
  };

  const formikSA = useFormik({
    initialValues: initialValuesSA,
    validationSchema: validationSchemaSA,
    onSubmit: onSubmit,
  });

  const disabled = () => !!(formikSA.touched.immatriculation && formikSA.errors.immatriculation) ||
    !!(formikSA.touched.referenceSinistre && formikSA.errors.referenceSinistre) ||
    !!(formikSA.touched.date && formikSA.errors.date) ||
    !!(formikSA.touched.heure && formikSA.errors.heure);

  return (
    <div>
      <div className={classes.cardContent}>
        <div className={classes.flex}>
          <Immatriculation
            className={classes.textfield}
            name={'immatriculation'}
            formulaire={'SA'}
            value={formikSA.values.immatriculation}
            onChangeValue={formikSA.handleChange}
            onBlur={formikSA.handleBlur}
            onFocus={onFocus('immatriculation')}
            onError={(formikSA.touched.immatriculation && !!formikSA.errors.immatriculation) || false}
            helperText={helperTextWithIcon(formikSA, 'immatriculation', '', classes.helpIcon)}
          />
          <RefSinistre
            className={classes.textfield}
            name={'referenceSinistre'}
            formulaire={'SA'}
            onChangeValue={formikSA.handleChange}
            onBlur={formikSA.handleBlur}
            onFocus={onFocus('referenceSinistre')}
            isMandatory={true}
            value={formikSA.values.referenceSinistre}
            onError={(formikSA.touched.referenceSinistre && !!formikSA.errors.referenceSinistre) || false}
            helperText={formikSA.touched.referenceSinistre && formikSA.errors.referenceSinistre ?
              formikSA.errors.referenceSinistre : ''
            }
          />
        </div>
        <div className={`${classes.flex} ${classes.line}`}>
          <InputDate
            className={classes.textfieldSmall}
            name={'date'}
            id={'date'}
            formulaire={'SA'}
            isMandatory={true}
            title={'Date du sinistre'}
            onBlur={formikSA.handleBlur}
            onFocus={onFocus('date')}
            onChangeValue={formikSA.handleChange}
            value={formikSA.values.date}
            onError={(formikSA.touched.date && !!formikSA.errors.date) || false}
            helperText={formikSA.touched.date && formikSA.errors.date ? formikSA.errors.date : ''}
          />
          <Hour
            className={classes.textfieldSmall}
            name={'heure'}
            id={'heure'}
            formulaire={'SA'}
            isMandatory={true}
            title={'Heure du sinistre'}
            value={formikSA.values.heure}
            onChangeValue={formikSA.handleChange}
            onBlur={formikSA.handleBlur}
            onFocus={onFocus('heure')}
            onError={(formikSA.touched.heure && !!formikSA.errors.heure) || false}
            helperText={formikSA.touched.heure && formikSA.errors.heure ? formikSA.errors.heure : ''}
          />
        </div>
      </div>

      <FooterFormSIV
        isLoading={isLoading}
        disabled={disabled()}
        onClick={formikSA.submitForm}/>
    </div>
  )
};
