import React from 'react';
import {InputDate} from '../InputDate';
import {Immatriculation} from './Immatriculation';
import {getTypeImmat} from '../../../common/utils/InputValuesUtils';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {helperText, helperTextWithIcon, onError, onFocus} from '../../../common/Utils';
import {NumeroVIN} from './NumeroVIN';
import {NumeroFormule} from './NumeroFormule';
import {PresenceCertificat} from './PresenceCertificat';

interface VehiculeProps {
  className?: string,
  formulaire: string,
  formik: any,
  entity: string,
  formikValues: {
    immatriculation: string,
    numeroVin?: string,
    presenceCertificat?: boolean,
    numeroFormule: string,
    dateCertificat: string,
  },
  formikTouched: any,
  formikErrors: any,
}

const styles = () => ({
  flex: {
    display: 'flex',
    flexWrap: 'wrap' as const,
    rowGap: '8px',
  },
  textfield: {
    width: '360px',
    marginRight: '24px',
  },
  textfieldSmall: {
    width: '240px',
    marginRight: '24px',
  },
  marginTop: {
    marginTop: '24px',
  },
  helpIcon: {
    width: '15px',
    height: 'auto',
  },
});

export const Vehicule = (props: VehiculeProps) => {
  const {className, formulaire, formik, entity, formikValues, formikTouched, formikErrors} = props;
  const classes = useEmotionStyles(styles);

  return (
    <div className={className}>
      <div className={classes.flex}>
        <Immatriculation
          className={classes.textfield}
          name={entity !== '' ? entity + '.immatriculation' : 'immatriculation'}
          formulaire={formulaire}
          value={formikValues.immatriculation}
          onChangeValue={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={onFocus('immatriculation', entity, formik)}
          onError={onError(formikTouched?.immatriculation, formikErrors?.immatriculation)}
          helperText={helperTextWithIcon(formik, 'immatriculation', '', classes.helpIcon)}
        />

        {formulaire !== 'IR' ?
          <span>
            <NumeroVIN
              className={classes.textfield}
              name={entity !== '' ? entity + '.numeroVin' : 'numeroVin'}
              formulaire={formulaire}
              value={formikValues.numeroVin ?? ''}
              onChangeValue={formik.handleChange}
              onBlur={formik.handleBlur}
              onFocus={onFocus('numeroVin', entity, formik)}
              onError={onError(formikTouched?.numeroVin, formikErrors?.numeroVin)}
              helperText={helperText(formikTouched?.numeroVin, formikErrors?.numeroVin)}
            />
          </span> :
          <span>
            <NumeroFormule
              className={classes.textfield}
              name={entity !== '' ? entity + '.numeroFormule' : 'numeroFormule'}
              formulaire={formulaire}
              isMandatory={false}
              value={formikValues.numeroFormule}
              onChangeValue={formik.handleChange}
              onBlur={formik.handleBlur}
              onFocus={onFocus('numeroFormule', entity, formik)}
              onError={onError(formikTouched?.numeroFormule, formikErrors?.numeroFormule)}
              helperText={helperText(formikTouched?.numeroFormule, formikErrors?.numeroFormule)}
            />
          </span>
        }

        {formulaire === 'IR' &&
          <InputDate
            classNameTextField={classes.textfieldSmall}
            id={'dateCertificatImmat'}
            name={entity !== '' ? entity + '.dateCertificat' : 'dateCertificat'}
            formulaire={formulaire}
            isMandatory={false}
            title={'Date du certificat d\'immatriculation'}
            value={formikValues.dateCertificat}
            onChangeValue={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={onFocus('dateCertificat', entity, formik)}
            onError={onError(formikTouched?.dateCertificat, formikErrors?.dateCertificat)}
            helperText={helperText(formikTouched?.dateCertificat, formikErrors?.dateCertificat)}
          />
        }
      </div>

      {formulaire !== 'IR' &&
        <React.Fragment>
          <PresenceCertificat
            className={classes.marginTop}
            name={entity !== '' ? entity + '.presenceCertificat' : 'presenceCertificat'}
            value={formikValues.presenceCertificat ?? false}
            onChangeValue={formik.handleChange}
          />

          <div className={`${classes.flex} ${classes.marginTop}`}>
            <NumeroFormule
              className={classes.textfield}
              name={entity !== '' ? entity + '.numeroFormule' : 'numeroFormule'}
              formulaire={formulaire}
              isMandatory={(formikValues.presenceCertificat || false) && (formikValues.immatriculation !== '' &&
                (getTypeImmat(formikValues.immatriculation) === 'SIV' ||
                  getTypeImmat(formikValues.immatriculation) === 'CYCLO'))
              }
              value={formikValues.numeroFormule}
              onChangeValue={formik.handleChange}
              onBlur={formik.handleBlur}
              onFocus={onFocus('numeroFormule', entity, formik)}
              onError={onError(formikTouched?.numeroFormule, formikErrors?.numeroFormule)}
              helperText={helperText(formikTouched?.numeroFormule, formikErrors?.numeroFormule)}
            />
            <InputDate
              classNameTextField={classes.textfieldSmall}
              id={'dateCertificatImmat'}
              name={entity !== '' ? entity + '.dateCertificat' : 'dateCertificat'}
              formulaire={formulaire}
              isMandatory={(formikValues.presenceCertificat || false) && formikValues.immatriculation !== '' &&
                getTypeImmat(formikValues.immatriculation) === 'FNI'
              }
              title={'Date du certificat d\'immatriculation'}
              value={formikValues.dateCertificat}
              onChangeValue={formik.handleChange}
              onBlur={formik.handleBlur}
              onFocus={onFocus('dateCertificat', entity, formik)}
              onError={onError(formikTouched?.dateCertificat, formikErrors?.dateCertificat)}
              helperText={helperText(formikTouched?.dateCertificat, formikErrors?.dateCertificat)}
            />
          </div>
        </React.Fragment>
      }
    </div>
  )
};
