import React, {ReactElement} from 'react';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';

interface EtiquetteProps {
    id: string,
    icon?: ReactElement,
    libelle?: string,
    style?: object
}

/** Design */
const styles = (theme: Theme) => ({
  etiquette: {
    'backgroundColor': theme.palette.secondary.main,
    'color': theme.palette.link.main,
    'fontSize': '14px',
    'letterSpacing': '0px',
    'padding': '5px 20px',
    'border': '1px solid ' + theme.palette.link.main,
    'borderRadius': '6px',
  },
});

export const Etiquette = (props: EtiquetteProps) => {
  const classes = useEmotionStyles(styles);
  const {id, icon, libelle, style} = props;

  return (
    <div id={id} className={classes.etiquette} style={style}>
      {icon}{libelle}
    </div>
  )
};
