import React from 'react';
import {Typography} from '@mui/material';
import {useNavigate} from 'react-router';
import {ButtonBlue} from '../../../common/buttons/ButtonBlue';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';

interface RdvNonConfirmeProps {
 choisirNouveauCreneau: () => void,
}

/** Design */
const styles = (theme: Theme) => ({
  container: {
    width: '50%',
    margin: '50px auto',
    textAlign: 'center' as const,
    padding: '24px',
  },
  img: {
    width: '300px',
    height: '100px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column' as const,
  },
  bold: {
    fontWeight: 'bold',
  },
  button: {
    margin: '24px 0px',
  },
});

const RdvNonConfirme = (props: RdvNonConfirmeProps) => {
  const classes = useEmotionStyles(styles);
  const navigate = useNavigate();
  return (
    <div className={classes.container}>
      <EventBusyIcon style={{height: '100px', width: '100px', color: 'red', marginRight: '0px'}}/>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <div className={classes.content}>
          <Typography style={{marginTop: '30px'}}>
            Le créneau que vous avez sélectionné a déjà été réservé. Veuillez en choisir un autre.
          </Typography>
          <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '20px'}}>
            <ButtonBlue
              id={'boutonRetourRdvNonConfirme'}
              className={classes.button}
              libelle={'Retour sur le portail Sinapps Auto'}
              onClick={() => {
                navigate('/extranet/portail-sinapps-auto');
                sessionStorage.clear();
              }}
            />
            <ButtonBlue
              id={'choisirNouveauCreneau'}
              className={classes.button}
              libelle={'Choisir un nouveau créneau'}
              onClick={() => props.choisirNouveauCreneau()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RdvNonConfirme;
