import React, {useState} from 'react';
import {Modal, Slide, Typography} from '@mui/material';
import {customTheme} from '../../../../../common/GlobalTheme';
import {ItemGetValeursCodesModel} from '../../../../../model/common/ItemGetValeursCodesModel';
import {ErrorFields} from '../../PriseRDV';
import BlocTitle from '../../../rechercherRDV/detailRDV/BlocTitle';
import {ButtonLink} from '../../../../common/buttons/ButtonLink';
import {ModalModificationRecap} from '../ModalModificationRecap';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../../common/useEmotionStyles';
import {IMarque, InformationAssure} from '../../../../../model/priseRDV/RDVModel';

interface BlocInfosAssureProps {
  from: string,
  infosAssure: InformationAssure,
  idRdv?: string,
  idPartenaire?: string,
  idActivite?: string,
  rdvActif: boolean,
  codesValeursMarque: ItemGetValeursCodesModel[],
  hasRoleModificationRDV: boolean,
  errorFields: ErrorFields,
  setErrorFields: (errorFields: ErrorFields) => void,
  onClickSaveInfosAssure?: (prenom: string, nom: string, tel: string, email: string, marque?: IMarque,
                            modele?: string) => void,
}

type StylesProps = {isRdvModifiable: boolean}
const styles = (theme: Theme, props: StylesProps) => ({
  container: {
    marginTop: '16px',
  },
  card: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: '8px',
    padding: props.isRdvModifiable ? '16px 16px 2px' : '16px',
  },
  group: {
    'display': 'flex',
    'justifyContent': 'space-between',
    'marginBottom': '10px',
    '&:last-child': {
      marginBottom: '0px',
    },
  },
  flex: {
    display: 'flex',
  },
  grow1: {
    flexGrow: 1,
    flexBasis: '0px',
  },
  grow2: {
    flexGrow: 2,
    flexBasis: '0px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonLink: {
    fontSize: '14px',
    marginTop: '16px',
  },
});

export const BlocInfosAssure = (props: BlocInfosAssureProps) => {
  const {from, infosAssure, idRdv, idPartenaire, idActivite, rdvActif, codesValeursMarque,
    hasRoleModificationRDV, errorFields, setErrorFields, onClickSaveInfosAssure} = props;
  const isRdvModifiable = hasRoleModificationRDV && rdvActif;
  const classes = useEmotionStyles(styles, {isRdvModifiable});
  const [openModalModif, setOpenModalModif] = useState(false);
  const confirm = 'Si vous n\'avez pas enregistré vos modifications, elles seront perdues.\n' + '\n' +
    'Souhaitez-vous continuer ?';

  const onCloseModifRecap = () => {
    const result = window.confirm(confirm);
    if (result) {
      setOpenModalModif(false);
      setErrorFields([])
    }
  };

  return (
    <div className={classes.container}>
      <BlocTitle title={'Informations assuré'}/>
      <div className={classes.card}>
        <div>
          <div className={classes.group}>
            <div className={`${classes.flex} ${classes.grow1}`}>
              <Typography style={customTheme.styledTypography.libelle}>Prénom :&nbsp;</Typography>
              <Typography style={customTheme.styledTypography.donneeBleue}>
                {infosAssure.prenom}
              </Typography>
            </div>
            <div className={`${classes.flex} ${classes.grow2}`}>
              <Typography style={customTheme.styledTypography.libelle}>Nom :&nbsp;</Typography>
              <Typography style={customTheme.styledTypography.donneeBleue}>
                {infosAssure.nom}
              </Typography>
            </div>
          </div>
          <div className={classes.group}>
            <div className={`${classes.flex} ${classes.grow1}`}>
              <Typography style={customTheme.styledTypography.libelle}>N° de téléphone :&nbsp;</Typography>
              <Typography style={customTheme.styledTypography.donneeBleue}>
                {infosAssure.telephone}
              </Typography>
            </div>
            <div className={`${classes.flex} ${classes.grow2}`}>
              <Typography style={customTheme.styledTypography.libelle}>Email :&nbsp;</Typography>
              <Typography style={customTheme.styledTypography.donneeBleue}>
                {infosAssure.email}
              </Typography>
            </div>
          </div>
          {(infosAssure.adresse.voie || infosAssure.adresse.codePostal) &&
            <div className={classes.group}>
              <div className={`${classes.flex} ${classes.grow2}`}>
                <Typography style={customTheme.styledTypography.libelle}>Adresse de recherche :&nbsp;</Typography>
                <Typography style={customTheme.styledTypography.donneeBleue}>
                  {[infosAssure.adresse.numero, infosAssure.adresse.voie].filter(Boolean).join(' ')}
                </Typography>
              </div>
              <div className={`${classes.flex} ${classes.grow1}`}>
                <Typography style={customTheme.styledTypography.libelle}>Localité :&nbsp;</Typography>
                <Typography style={customTheme.styledTypography.donneeBleue}>
                  {infosAssure.adresse.codePostal} - {infosAssure.adresse.commune}
                </Typography>
              </div>
            </div>
          }
          <div className={classes.group}>
            <div className={`${classes.flex} ${classes.grow1}`}>
              <Typography style={customTheme.styledTypography.libelle}>
                N° d&apos;immatriculation :&nbsp;
              </Typography>
              <Typography style={customTheme.styledTypography.donneeBleue}>
                {infosAssure.immatriculation}
              </Typography>
            </div>
            <div className={`${classes.flex} ${classes.grow1}`}>
              <Typography style={customTheme.styledTypography.libelle}>Marque :&nbsp;</Typography>
              <Typography style={customTheme.styledTypography.donneeBleue}>
                {infosAssure.marque?.libelleedition}
              </Typography>
            </div>
            <div className={`${classes.flex} ${classes.grow1}`}>
              <Typography style={customTheme.styledTypography.libelle}>Modèle :&nbsp;</Typography>
              <Typography style={customTheme.styledTypography.donneeBleue}>
                {infosAssure.modele}
              </Typography>
            </div>
          </div>
        </div>

        {isRdvModifiable &&
          <div className={classes.buttonContainer}>
            <ButtonLink
              id={'boutonModifierInfoAssure'}
              className={classes.buttonLink}
              libelle={'Modifier les informations'}
              onClick={() => setOpenModalModif(true)}
            />
          </div>
        }
      </div>

      <Modal
        open={openModalModif}
        aria-labelledby="modal-form-update-rdv"
        aria-describedby="modal-formulaire-modification-info-rdv"
      >
        <Slide
          in={openModalModif}
          direction={'left'}
          timeout={800}
          mountOnEnter unmountOnExit
        >
          <div>
            <ModalModificationRecap
              from={from}
              infosAssure={infosAssure}
              idRdv={idRdv}
              idPartenaire={idPartenaire}
              idActivite={idActivite}
              codesValeursMarque={codesValeursMarque}
              errorFields={errorFields}
              setErrorFields={setErrorFields}
              onClose={onCloseModifRecap}
              onClickSaveInfosAssure={onClickSaveInfosAssure}
              setOpenModalModif={setOpenModalModif}
            />
          </div>
        </Slide>
      </Modal>
    </div>
  );
};
