const chars: { [index: string]: string } = {
  '.': '-',
  '[': '-',
  ']': '',
};

export const getIdFromName = (name: string) => {
  return name.replace(/[.[\]]/g, (char) => chars[char])
}

export const TABLE_TYPES_FILES_OK = ['.bmp', '.doc', '.docx', '.gif', '.html', '.jpeg', '.pdf', '.png',
  '.pps', '.ppsx', '.ppt', '.pptx', '.tif', '.txt', '.xls', '.xlsx', '.xml', '.jpg'];

export const STRING_TYPES_FILES_OK = TABLE_TYPES_FILES_OK.join(', ');
