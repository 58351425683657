import React from 'react';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {Numero} from './Numero';
import {Indice} from './Indice';
import {TypeVoie} from './TypeVoie';
import {NomVoie} from './NomVoie';
import {LieuDit} from './LieuDit';
import {Etage} from './Etage';
import {Immeuble} from './Immeuble';
import {CodePostal} from './CodePostal';
import {BoitePostale} from './BoitePostale';
import {Ville} from './Ville';
import {Pays} from './Pays';
import {helperText, helperTextWithIcon, onError, onFocus} from '../../../common/Utils';

interface AdresseProps {
  formulaire: string,
  formik: any,
  entity: string,
  formikValues: {
    numero: string,
    indice: string,
    type: string,
    nom: string,
    lieuDit: string,
    etage: string,
    immeuble: string,
    codePostal: string,
    boitePostale: string,
    ville: string,
    pays: string,
  },
  formikTouched: any,
  formikErrors: any,
}

const styles = () => ({
  flex: {
    'display': 'flex',
    'flexWrap': 'wrap' as const,
    'marginBottom': '24px',
    '&:last-child': {
      marginBottom: '0px',
    },
  },
  textfield: {
    width: '360px',
    marginRight: '24px',
  },
  textfieldMedium: {
    width: '200px',
    marginRight: '24px',
  },
  textfieldSmall: {
    width: '168px',
    marginRight: '24px',
  },
  textfieldExtraSmall: {
    width: '134px',
    marginRight: '24px',
  },
  helpIcon: {
    width: '15px',
    height: 'auto',
  },
});

export const Adresse = (props: AdresseProps) => {
  const {formulaire, formik, entity, formikValues, formikTouched, formikErrors} = props;
  const classes = useEmotionStyles(styles);

  return (
    <div>
      <div className={classes.flex}>
        <Numero
          className={classes.textfieldExtraSmall}
          name={entity + '.numero'}
          formulaire={formulaire}
          value={formikValues.numero}
          onChangeValue={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={onFocus('numero', entity, formik)}
          onError={onError(formikTouched?.numero, formikErrors?.numero)}
          helperText={helperText(formikTouched?.numero, formikErrors?.numero)}
        />

        <Indice
          className={classes.textfieldMedium}
          name={entity + '.indice'}
          formulaire={formulaire}
          value={formikValues.indice}
          onChangeValue={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={onFocus('indice', entity, formik)}
          onError={onError(formikTouched?.indice, formikErrors?.indice)}
          helperText={helperText(formikTouched?.indice, formikErrors?.indice)}
        />

        <TypeVoie
          className={classes.textfield}
          name={entity + '.type'}
          formulaire={formulaire}
          value={formikValues.type}
          onChangeValue={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={onFocus('type', entity, formik)}
          onError={onError(formikTouched?.type, formikErrors?.type)}
          helperText={helperText(formikTouched?.type, formikErrors?.type)}
        />

        <NomVoie
          className={classes.textfield}
          name={entity + '.nom'}
          formulaire={formulaire}
          value={formikValues.nom}
          onChangeValue={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={onFocus('nom', entity, formik)}
          onError={onError(formikTouched?.nom, formikErrors?.nom)}
          helperText={helperText(formikTouched?.nom, formikErrors?.nom)}
        />
      </div>

      <div className={classes.flex}>
        <LieuDit
          className={classes.textfield}
          name={entity + '.lieuDit'}
          formulaire={formulaire}
          value={formikValues.lieuDit}
          onChangeValue={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={onFocus('lieuDit', entity, formik)}
          onError={onError(formikTouched?.lieuDit, formikErrors?.lieuDit)}
          helperText={helperText(formikTouched?.lieuDit, formikErrors?.lieuDit)}
        />

        <Etage
          className={classes.textfield}
          name={entity + '.etage'}
          formulaire={formulaire}
          value={formikValues.etage}
          onChangeValue={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={onFocus('etage', entity, formik)}
          onError={onError(formikTouched?.etage, formikErrors?.etage)}
          helperText={helperText(formikTouched?.etage, formikErrors?.etage)}
        />

        <Immeuble
          className={classes.textfield}
          name={entity + '.immeuble'}
          formulaire={formulaire}
          value={formikValues.immeuble}
          onChangeValue={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={onFocus('immeuble', entity, formik)}
          onError={onError(formikTouched?.immeuble, formikErrors?.immeuble)}
          helperText={helperText(formikTouched?.immeuble, formikErrors?.immeuble)}
        />
      </div>

      <div className={classes.flex}>
        <CodePostal
          className={classes.textfieldSmall}
          name={entity + '.codePostal'}
          formulaire={formulaire}
          value={formikValues.codePostal}
          onChangeValue={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={onFocus('codePostal', entity, formik)}
          onError={onError(formikTouched?.codePostal, formikErrors?.codePostal)}
          helperText={helperTextWithIcon(formik, 'codePostal', entity, classes.helpIcon)}
        />

        <BoitePostale
          className={classes.textfieldSmall}
          name={entity + '.boitePostale'}
          formulaire={formulaire}
          value={formikValues.boitePostale}
          onChangeValue={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={onFocus('boitePostale', entity, formik)}
          onError={onError(formikTouched?.boitePostale, formikErrors?.boitePostale)}
          helperText={helperText(formikTouched?.boitePostale, formikErrors?.boitePostale)}
        />

        <Ville
          className={classes.textfield}
          name={entity + '.ville'}
          formulaire={formulaire}
          value={formikValues.ville}
          onChangeValue={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={onFocus('ville', entity, formik)}
          onError={onError(formikTouched?.ville, formikErrors?.ville)}
          helperText={helperText(formikTouched?.ville, formikErrors?.ville)}
        />

        <Pays
          className={classes.textfield}
          name={entity + '.pays'}
          formulaire={formulaire}
          value={formikValues.pays}
          onChangeValue={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={onFocus('pays', entity, formik)}
          onError={onError(formikTouched?.pays, formikErrors?.pays)}
          helperText={helperText(formikTouched?.pays, formikErrors?.pays)}
        />
      </div>
    </div>
  )
};
