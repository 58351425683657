import {IElementRecherchable} from '../../model/common/ElementRecherchable';
import useEmotionStyles from '../useEmotionStyles';
import {MenuItem} from '@mui/material';
import {Loader} from '../../components/common/Loader';
import React from 'react';

const styles = () => ({
  loader: {
    marginTop: 0,
  },
});

export const useGetItemsList = (list: IElementRecherchable[]) => {
  const classes = useEmotionStyles(styles);

  if (list.length) {
    return list.map((document) => {
      return (
        <MenuItem
          key={document.code}
          value={document.code}
        >
          {document.libelle}
        </MenuItem>
      )
    })
  } else {
    return [
      <MenuItem
        key={'chargement'}
        value={'chargement'}
        disabled={true}
      >
        <Loader className={classes.loader} size={15}/>
      </MenuItem>,
    ]
  }
};
