import {TokenEdiModel} from '../../../../model/common/TokenEdiModel';
import {UserContextType} from '../../../../context/UserContext';

export const ARCHIVE = 'O';
export const EN_COURS_DESARCHIVAGE = 'A';

export const mapperEDI = (search: any, user: UserContextType, data: any): TokenEdiModel => {
  return {
    idDocument: search.idDocument,
    search: {
      referenceSinistre: search.referenceSinistre,
      numeroMission: search.numeroMission,
      codeGTA: user.codeGTA,
      numClient: search.numClient,
      userCompte: user.userCompte,
      codeAbonne: user.codeAbo,
      codeService: search.codeService,
      isDossierComplet: search.isDossierComplet,
      immatriculation: search.immatriculation,
    },
    type: data.docType,
    ssType: data.docSousType,
    isnIdentifier: data.identifier,
  } as TokenEdiModel;
};

export const canOpenPdf = (typeCode: string | undefined, ssType: string | undefined,
    archive: string | undefined, docVisualisable: boolean | undefined) => {
  return !('DI0000' === typeCode || 'RP0000' === typeCode || 'CSIT00' === typeCode || 'CSSA00' === typeCode ||
    'CSCT00' === typeCode || 'ODDA00' === typeCode || 'ODDC00' === typeCode || 'ODIR00' === typeCode ||
    'AQIR00' === typeCode || 'ISIT00' === typeCode || 'ISSA00' === typeCode || 'ISCT00' === typeCode ||
    'AQDA00' === typeCode || 'AQDC00' === typeCode || '900501' === typeCode || ('410301' === typeCode &&
      '31' === ssType) || archive === ARCHIVE || archive === EN_COURS_DESARCHIVAGE) && docVisualisable;
};
