import React from 'react';
import useEmotionStyles from '../../../common/useEmotionStyles';
import DocumentsSearchResult from '../../rechercheDocuments/components/DocumentsSearchResult';
import {Card, CardContent, FormLabel, Typography} from '@mui/material';
import {ButtonBlue} from '../../common/buttons/ButtonBlue';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {ECHANGES_COMPONENTS} from '../../forms/rechercheEchanges/RechercheEchanges';
import {UserContext} from '../../../context/UserContext';
import {Theme} from '@emotion/react';
import {DocumentSearchModel} from '../../../model/rechercheDocument/DocumentSearchModel';
import {InfosEchange} from '../../../model/rechercheEchange/InfosEchange';
import {InfosTypesDocs} from '../../../model/rechercheEchange/InfosTypesDocs';
import {ID_BOUTON_RETOUR} from '../../../constantes/ids/Ids';

export interface ListeDocsEchangeProps {
  numeroEchangeSelected: string
  idEchangeSelected: string
  callbackComponent: (component: string, infosEchange: InfosEchange, infosTypesDocs: InfosTypesDocs,
                      lastPaginationCall?: any, pageSize?: string) => void
  lastPaginationCall: any
  pageSize: string
}

const styles = (theme: Theme) => ({
  cardResult: {
    marginBottom: '16px',
  },
  noEchangeLabel: {
    justifySelf: 'end',
  },
  noEchangeGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: '16px',
    margin: '8px 0 16px 0',
  },
  retour: {
    marginTop: '16px',
  },
});

export const ListeDocsEchange = (props: ListeDocsEchangeProps) => {
  const classes = useEmotionStyles(styles);
  const {callbackComponent, numeroEchangeSelected, idEchangeSelected, lastPaginationCall, pageSize} = props;
  const {user} = React.useContext(UserContext);
  const search: DocumentSearchModel = {
    codeAbo: user.codeAbo,
    numClient: user.numClient,
    codeService: user.service,
    idEchange: idEchangeSelected,
  };

  const onClickRetour = () => {
    callbackComponent(ECHANGES_COMPONENTS.RESULTATS,
        {
          idEchange: '',
          numeroEchange: '',
          isEchangeRAD: false,
        } as InfosEchange,
        {
          typeDoc: '',
          sousTypeDoc: '',
        } as InfosTypesDocs,
        lastPaginationCall,
        pageSize)
  };

  return (
    <Card className={classes.cardResult}>
      <CardContent>
        <div>
          <div className={classes.noEchangeGrid}>
            <FormLabel className={classes.noEchangeLabel}>N&deg; d&apos;échange:</FormLabel>
            <Typography>{numeroEchangeSelected}</Typography>
          </div>
          <DocumentsSearchResult search={search} isEchange={true}/>
        </div>
        <ButtonBlue
          id={ID_BOUTON_RETOUR}
          className={classes.retour}
          icon={<ArrowBackIcon/>}
          libelle={'Retour'}
          onClick={onClickRetour}
        />
      </CardContent>
    </Card>
  );
}
