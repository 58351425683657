import * as Yup from 'yup';

export const schemaValidation = Yup.object().shape({
  dateDebut: Yup.date()
      .min('1900-01-01', 'Veuillez saisir une date postérieure au 01/01/1900')
      .test('test-date-debut', 'La date de début ne peut être supérieure à la date de fin',
          function(value, ctx) {
            if (value && ctx.parent.dateFin) {
              return ctx.parent.dateFin >= value
            }
            return true
          }),
  dateFin: Yup.date(),
  isEchangeDepuisHier: Yup.boolean(),
  etat: Yup.string(),
  numeroEchange: Yup.string(),
});
