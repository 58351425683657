import AxiosService from '../services/AxiosService';
import {dateToApiFormat} from '../common/utils/DateUtils';
import Cookies from 'js-cookie';
import {DocumentSearchModel} from '../model/rechercheDocument/DocumentSearchModel';
import {RadDocumentParams} from '../model/rechercheDocument/RadDocumentParams';

const baseUrl = window.extranet.globalConfig.apiUrl + '/api';
const axiosInstance = AxiosService.getAxiosClient();

/**
 * Recherche de documents
 *
 * @param {DocumentSearchModel} search - Objet contenant les infos sur le client (numClient, codeAbonne, codeService),
 * sur les paramètres choisis (état, action, dateDebut, dateFin)
 * et des infos supplémentaires (nbMaxElement, navigationOrder, codePagination, isnReprise)
 *
 * @return {Promise} response data
 *
 *
 */
export async function getDocuments(search: DocumentSearchModel) {
  const response = await axiosInstance(`${baseUrl}/documents`,
      {
        params: {
          numClient: search?.numClient,
          codeAbonne: search?.codeAbo,
          codeService: search?.codeService,
          docType: search?.docType,
          docSousType: search?.docSousType,
          numFacture: search?.numFacture,
          numImmatriculation: search?.numImmatriculation,
          dateDebut: dateToApiFormat(search?.dateDebut ?? ''),
          dateFin: dateToApiFormat(search?.dateFin ?? ''),
          idEchange: search?.idEchange,
          nbMaxElement: search?.nbMaxElement,
          navigationOrder: search?.navigationOrder,
          codePagination: search?.codePagination,
          isnReprise: search?.isnReprise,
        },
      });
  return response.data
}

/**
 * Remise à disposition de plusieurs documents
 *
 * @param {Object} data[] - Objet contenant les données nécessaires à la RAD des documents
 * @return {Promise}
 */
export function postDocumentsRAD(data: RadDocumentParams[]) {
  return axiosInstance.post(`${baseUrl}/remisesAdispositionDocuments`, data,
      {
        headers: {
          'Authorization': `Bearer ${Cookies.get('token')}`,
        },
      },
  )
}
