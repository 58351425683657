import React from 'react';
import {createRoot} from 'react-dom/client';
import * as serviceWorker from './serviceWorker';
import './index.css';
import App from './App';
import KeyCloakService from './services/KeyCloakService';
import ConfigEnvironnement from './ConfigEnvironnement';
import {getConfigurations} from './api/config';
import LiensTransparents from './components/liensTransparents/container/LiensTransparents';

type KcConfig = {
  url: string,
  realm: string,
  clientId: string,
}

type Configurations = {
  kcConfig: KcConfig,
  configEnvironnement: ConfigEnvironnement,
}

const container = document.getElementById('root');
const isLienTransparent = window.location.pathname.includes('/liens-transparents/')
const urlParams = new URLSearchParams(window.location.search);
const idpHint = urlParams.get('idp')

if (isLienTransparent) {
  const root = createRoot(container!);
  root.render(<LiensTransparents/>);
} else {
  getConfigurations()
      .then((configurations: Configurations) => {
        if (configurations) {
          const {configEnvironnement, kcConfig} = configurations;
          // eslint-disable-next-line new-cap
          const authService = KeyCloakService(kcConfig)
          if (idpHint) {
            authService.initKeycloakSSO(idpHint, (kc) => {
              const root = createRoot(container!);
              const render = <App keycloak={kc} configEnvironnement={configEnvironnement}/>;
              root.render(render);
            });
          } else {
            authService.initKeycloak((kc) => {
              const root = createRoot(container!);
              const render = <App keycloak={kc} configEnvironnement={configEnvironnement}/>
              root.render(render);
            });
          }
          authService.updateToken()
        }
      })
      .catch(console.error)
  serviceWorker.register();
}
