import * as yup from 'yup';
import {checkNom, checkPrenom} from '../../../../common/utils/InputValuesUtils';

export const validationSchemaNomUsagePrenom = yup.object({
  nom: yup.string()
      .when('typePersonne', {
        is: (value: string) => value === 'pp',
        then: () => yup.string()
            .required('Veuillez saisir une valeur')
            .test('test-nom', 'Les caractères ! ? * ° : € # ; & ne sont pas autorisés',
                function(value) {
                  return checkNom(value || '')
                }),
      }),
  nomUsage: yup.string()
      .when('typePersonne', {
        is: (value: string) => value === 'pp',
        then: () => yup.string()
            .test('test-nomUsage', 'Les caractères ! ? * ° : € # ; & ne sont pas autorisés',
                function(value) {
                  return checkNom(value ?? '')
                }),
      }),
  prenom: yup.string()
      .when('typePersonne', {
        is: (value: string) => value === 'pp',
        then: () => yup.string()
            .required('Veuillez saisir une valeur')
            .test('test-prenom', 'Les caractères ! ? * ° : € # ; & ne sont pas autorisés',
                function(value) {
                  return checkPrenom(value || '')
                }),
      }),
});
