import React from 'react';
import {Etiquette} from '../../../../model/detailsDossier/SuiviReparationModel';
import {ReactComponent as SollicitationIcon} from '../../../../images/icones/icn-attente.svg';
import {ReactComponent as WarningIcon} from '../../../../images/icones/icn-warning.svg';
import {Typography} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';

interface EtiquetteProps {
    etiquette: Etiquette
    onClickEtiquette?: (tabPanelValue: number, date: string) => void
}

const styles = (theme: Theme) => ({
  etiquette: {
    height: '30px',
    cursor: 'pointer',
  },
  label: {
    fontSize: '14px',
    fontFamily: 'Roboto-Medium, Roboto',
    fontWeight: 500,
    marginRight: '6px',
    overflow: 'hidden',
  },
  alerte: {
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#fcf0ef',
    color: '#e95e2f',
    borderRadius: '4px',
  },
  information: {
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.primary.main,
    borderRadius: '4px',
  },
});

const EtiquetteBloc = (props: EtiquetteProps) => {
  const {typeEtiquette, date, libelle} = props.etiquette;
  const {onClickEtiquette} = props;
  const classes = useEmotionStyles(styles);

  return (
    <div
      className={classes.etiquette}
      onClick={()=> {
        if (onClickEtiquette) onClickEtiquette(4, date)
      }}
      aria-hidden={true}
    >
      {
        typeEtiquette === 'SOLLICITATION' &&
        <div className={classes.alerte}>
          <SollicitationIcon style={{marginRight: '5px'}}/>
          <Typography className={classes.label} >
            Sollicitation en attente
          </Typography>
        </div>
      }

      {
        typeEtiquette === 'INFORMATION' &&
        <div className={classes.information}>
          <InfoIcon style={{margin: '0px 5px'}}/>
          <Typography className={classes.label} >
            {libelle}
          </Typography>
        </div>
      }

      {
        typeEtiquette === 'ALERTE' &&
        <div className={classes.alerte}>
          <WarningIcon style={{margin: '0px 6px'}}/>
          <Typography className={classes.label} >
            {libelle}
          </Typography>
        </div>
      }

    </div>
  );
};

export default EtiquetteBloc;
