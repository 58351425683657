import * as yup from 'yup';
import moment from 'moment';

export const validationSchemaHeure = yup.object({
  heure: yup.string()
      .required('Veuillez saisir une valeur')
      .test('test-heure', 'L\'heure doit être antérieure à l\'heure actuelle pour une date égale à celle du jour',
          function(value, ctx) {
            const date = ctx.parent.date;
            if (date && moment(date, 'YYYY-MM-DD').isSame(moment().format('YYYY-MM-DD'))) {
              return value <= moment().format('HH:mm')
            }
            return true
          }),
});
