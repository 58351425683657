import {useMemo} from 'react'
import {css} from '@emotion/css'
import {CSSInterpolation} from '@emotion/serialize'
import {useTheme} from '@emotion/react'
import {Theme as MuiTheme} from '@mui/material/styles'

function useEmotionStyles(
    styles: () => Record<string, CSSInterpolation>
): Record<string, ReturnType<typeof css>>

function useEmotionStyles(
    styles: (theme: MuiTheme) => Record<string, CSSInterpolation>
): Record<string, ReturnType<typeof css>>

function useEmotionStyles<T>(
    styles: (theme: MuiTheme, props: T) => Record<string, CSSInterpolation>,
    props: T
): Record<string, ReturnType<typeof css>>

/**
 *
 */
function useEmotionStyles<T>(
    styles: (theme: MuiTheme, props?: T) => Record<string, CSSInterpolation>,
    props?: T,
): Record<string, ReturnType<typeof css>> {
  const theme = useTheme()

  return useMemo(() => {
    const classes = styles(theme, props)
    const classNameMap = {}

    Object.entries(classes).forEach(([key, value]) => {
      // @ts-ignore
      classNameMap[key] = css(value)
    })

    return classNameMap
  }, [props, styles, theme])
}

export default useEmotionStyles
