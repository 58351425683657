import React, {useEffect, useState} from 'react';
import {Button, FormLabel, IconButton, TextField, Tooltip, Typography} from '@mui/material';
import {useSearchContext} from '../../../context/SearchContext'
import {isStringEmpty} from '../../../common/Utils'
import RefreshIcon from '@mui/icons-material/Refresh';
import {ButtonBlue} from '../../common/buttons/ButtonBlue';
import {TextFieldSearch} from '../../common/formsComponents/TextFieldSearch';
import {UserContext, UserContextType} from '../../../context/UserContext';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {permittedRolesSupport} from '../../../constantes/roles/Roles';
import useHasRole from '../../../hook/useHasRole';

const styles = (theme: Theme) => ({
  container: {
    lineHeight: '55px',
  },
  containerPrincipal: {
    backgroundColor: theme.palette.white.main,
    border: '1px solid ' + theme.palette.secondary.main,
    borderRadius: '8px',
    opacity: '1',
    padding: '15px',
  },
  containerSecondaire: {
    backgroundColor: theme.palette.secondary.light,
    border: '1px solid ' + theme.palette.secondary.main,
    borderRadius: '8px',
    opacity: '1',
    padding: '15px',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 210,
  },
  dateField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    borderRadius: '4px',
  },
  labelDate: {
    border: 'none',
    verticalAlign: 'middle',
  },
  inputDate: {
    'backgroundColor': theme.palette.white.main,
    '&:before': {
      border: 'none',
    },
  },
  divider: {
    height: '40px',
    borderLeft: '2px solid lightgrey',
    display: 'inline',
    marginLeft: '10px',
    marginRight: '10px',
    marginTop: '8px',
    marginBottom: '8px',
  },
  buttonAvancee: {
    color: theme.palette.link.main,
    textDecoration: 'underline',
    margin: '8px',
  },
  cache: {
    display: 'none',
  },
  visible: {
    display: 'block',
  },
  inputText: {
    backgroundColor: theme.palette.white.main,
  },
  iconRoot: {
    margin: '0px',
  },
  btnReset: {
    margin: '0px 10px',
  },
});

export const SearchComponent = () => {
  const {search, actions} = useSearchContext();
  const {user} = React.useContext(UserContext);
  const hasRoleSupport = useHasRole(permittedRolesSupport);
  const classes = useEmotionStyles(styles);
  const localSearch = sessionStorage.getItem('searchDossier');
  const searchINIT = localSearch != null ? JSON.parse(localSearch) : search;
  const [referenceSinistre, setReferenceSinistre] = useState<string>(searchINIT?.referenceSinistre || '');
  const [disabledReferenceSinistre, setDisabledReferenceSinistre] = useState(false);
  const [immatriculation, setImmatriculation] = useState<string>(searchINIT?.immatriculation || '');
  const [disabledImmatriculation, setDisabledImmatriculation] = useState(false);
  const [dateDebut, setDateDebut] = useState<string>(searchINIT?.dateDebut || '');
  const [dateFin, setDateFin] = useState<string>(searchINIT?.dateFin || '');
  const [referenceCourtier, setReferenceCourtier] = useState<string>(searchINIT?.referenceCourtier || '');
  const [disabledRefCourtier, setDisabledRefCourtier] = useState(false);
  const [referenceExpert, setReferenceExpert] = useState<string>(searchINIT?.referenceExpert || '');
  const [disabledRefExpert, setDisabledRefExpert] = useState(false);
  const [numeroPolice, setNumeroPolice] = useState<string>(searchINIT?.numeroPolice || '');
  const [disabledNumPolice, setDisabledNumPolice] = useState(false);
  const [dateEvenement, setDateEvenement] = useState<string>(searchINIT?.dateEvenement || '');
  const [disabledDateEvenement, setDisabledDateEvenement] = useState(false);
  const [isRechercheAvancee, setIsRechercheAvancee] = useState(searchINIT?.isRechercheAvancee || false);
  const [canSearch, setCanSearch] = useState(false);

  const checkFields = () => {
    if (referenceSinistre && referenceSinistre !== '') {
      setCanSearch(true);
      setDisabledImmatriculation(true);
      setDisabledRefCourtier(true);
      setDisabledRefExpert(true);
      setDisabledNumPolice(true);
      setDisabledDateEvenement(true)
    }
    if (immatriculation && immatriculation.trim() !== '') {
      setCanSearch(true);
      setDisabledReferenceSinistre(true);
      setDisabledRefCourtier(true);
      setDisabledRefExpert(true);
      setDisabledNumPolice(true);
      setDisabledDateEvenement(true)
    }
    if (referenceCourtier && referenceCourtier.trim() !== '') {
      setCanSearch(true);
      setDisabledImmatriculation(true);
      setDisabledReferenceSinistre(true);
      setDisabledRefExpert(true);
      setDisabledNumPolice(true);
      setDisabledDateEvenement(true)
    }
    if (referenceExpert && referenceExpert.trim() != '') {
      setCanSearch(true);
      setDisabledImmatriculation(true);
      setDisabledRefCourtier(true);
      setDisabledReferenceSinistre(true);
      setDisabledNumPolice(true);
      setDisabledDateEvenement(true)
    }
    if (numeroPolice && numeroPolice.trim() != '') {
      setCanSearch(true);
      setDisabledImmatriculation(true);
      setDisabledRefCourtier(true);
      setDisabledRefExpert(true);
      setDisabledReferenceSinistre(true);
      setDisabledDateEvenement(true)
    }
    if (dateEvenement && dateEvenement.trim() != '') {
      setCanSearch(true);
      setDisabledImmatriculation(true);
      setDisabledRefCourtier(true);
      setDisabledRefExpert(true);
      setDisabledReferenceSinistre(true);
      setDisabledNumPolice(true)
    }
  };

  useEffect(() => {
    checkFields()
  }, []);

  const changeReferenceSinistre = (event: {target: { value: string }}) => {
    setReferenceSinistre(event.target.value);
    if (event.target.value && event.target.value.trim() != '') {
      setCanSearch(true);
      setDisabledImmatriculation(true);
      setDisabledRefCourtier(true);
      setDisabledRefExpert(true);
      setDisabledNumPolice(true);
      setDisabledDateEvenement(true)
    } else {
      setCanSearch(false);
      setDisabledImmatriculation(false);
      setDisabledRefCourtier(false);
      setDisabledRefExpert(false);
      setDisabledNumPolice(false);
      setDisabledDateEvenement(false)
    }
  };
  const changeImmatriculation = (event: {target: { value: string }}) => {
    if (event.target.value && event.target.value.trim() != '') {
      setCanSearch(true);
      setDisabledReferenceSinistre(true);
      setDisabledRefCourtier(true);
      setDisabledRefExpert(true);
      setDisabledNumPolice(true);
      setDisabledDateEvenement(true)
    } else {
      setCanSearch(false);
      setDisabledReferenceSinistre(false);
      setDisabledRefCourtier(false);
      setDisabledRefExpert(false);
      setDisabledNumPolice(false);
      setDisabledDateEvenement(false)
    }
    setImmatriculation(event.target.value)
  };
  const changeDateDebut = (event: {target: { value: string }}) => {
    if (event.target.value && event.target.value.trim() != '') {
      setCanSearch(true)
    } else {
      setCanSearch(false)
    }
    setDateDebut(event.target.value)
  };
  const changeDateFin = (event: {target: { value: string }}) => {
    if (event.target.value && event.target.value.trim() != '') {
      setCanSearch(true)
    } else {
      setCanSearch(false)
    }
    setDateFin(event.target.value)
  };
  const changeReferenceCourtier = (event: {target: { value: string }}) => {
    if (event.target.value && event.target.value.trim() != '') {
      setCanSearch(true);
      setDisabledImmatriculation(true);
      setDisabledReferenceSinistre(true);
      setDisabledRefExpert(true);
      setDisabledNumPolice(true);
      setDisabledDateEvenement(true)
    } else {
      setCanSearch(false);
      setDisabledImmatriculation(false);
      setDisabledReferenceSinistre(false);
      setDisabledRefExpert(false);
      setDisabledNumPolice(false);
      setDisabledDateEvenement(false)
    }
    setReferenceCourtier(event.target.value)
  };
  const changeReferenceExpert = (event: {target: { value: string }}) => {
    if (event.target.value && event.target.value.trim() != '') {
      setCanSearch(true);
      setDisabledImmatriculation(true);
      setDisabledRefCourtier(true);
      setDisabledReferenceSinistre(true);
      setDisabledNumPolice(true);
      setDisabledDateEvenement(true)
    } else {
      setCanSearch(false);
      setDisabledImmatriculation(false);
      setDisabledRefCourtier(false);
      setDisabledReferenceSinistre(false);
      setDisabledNumPolice(false);
      setDisabledDateEvenement(false)
    }
    setReferenceExpert(event.target.value)
  };
  const changeNumeroPolice = (event: {target: { value: string }}) => {
    if (event.target.value && event.target.value.trim() != '') {
      setCanSearch(true);
      setDisabledImmatriculation(true);
      setDisabledRefCourtier(true);
      setDisabledRefExpert(true);
      setDisabledReferenceSinistre(true);
      setDisabledDateEvenement(true)
    } else {
      setCanSearch(false);
      setDisabledImmatriculation(false);
      setDisabledRefCourtier(false);
      setDisabledRefExpert(false);
      setDisabledReferenceSinistre(false);
      setDisabledDateEvenement(false)
    }
    setNumeroPolice(event.target.value)
  };
  const changeDateEvenement = (event: {target: { value: string }}) => {
    if (event.target.value && event.target.value.trim() != '') {
      setCanSearch(true);
      setDisabledImmatriculation(true);
      setDisabledRefCourtier(true);
      setDisabledRefExpert(true);
      setDisabledReferenceSinistre(true);
      setDisabledNumPolice(true)
    } else {
      setCanSearch(false);
      setDisabledImmatriculation(false);
      setDisabledRefCourtier(false);
      setDisabledRefExpert(false);
      setDisabledReferenceSinistre(false);
      setDisabledNumPolice(false)
    }
    setDateEvenement(event.target.value)
  };

  const go = (newUser?: UserContextType) => {
    const usr = newUser ?? user;
    const search = {
      numClient: usr.numClient,
      userCompte: usr.userCompte,
      codeAbo: usr.codeAbo,
      codeService: usr.service,
      nbMaxElement: '12',
      isRechercheAvancee: isRechercheAvancee,
      referenceSinistre: referenceSinistre,
      immatriculation: immatriculation,
      dateDebut: dateDebut,
      dateFin: dateFin,
      referenceCourtier: referenceCourtier,
      referenceExpert: referenceExpert,
      numeroPolice: numeroPolice,
      dateEvenement: dateEvenement,
    };
    sessionStorage.setItem('searchDossier', JSON.stringify(search));
    actions.setSearch(search)
  };

  useEffect(() => {
    const searchLocal = localSearch != null ? JSON.parse(localSearch) : search;
    const initSearch = {
      numClient: user.numClient,
      userCompte: user.userCompte,
      codeAbo: user.codeAbo,
      codeService: user.service,
      nbMaxElement: '12',
      isRechercheAvancee: searchLocal?.isRechercheAvancee,
      referenceSinistre: searchLocal?.referenceSinistre,
      immatriculation: searchLocal?.immatriculation,
      dateDebut: searchLocal?.dateDebut,
      dateFin: searchLocal?.dateFin,
      referenceCourtier: searchLocal?.referenceCourtier,
      referenceExpert: searchLocal?.referenceExpert,
      numeroPolice: searchLocal?.numeroPolice,
      dateEvenement: searchLocal?.dateEvenement,
    };
    actions.setSearch(initSearch);
    sessionStorage.setItem('searchDossier', JSON.stringify(initSearch))
  }, [user]);

  useEffect(() => {
    if (!isRechercheAvancee) {
      if (referenceCourtier != '' && referenceCourtier != null) changeReferenceCourtier({target: {value: ''}});
      if (referenceExpert != '' && referenceExpert != null) changeReferenceExpert({target: {value: ''}});
      if (numeroPolice != '' && numeroPolice != null) changeNumeroPolice({target: {value: ''}});
      if (dateEvenement != '' && dateEvenement != null) changeDateEvenement({target: {value: ''}})
    }
  }, [isRechercheAvancee]);

  useEffect( () => {
    if (!canSearch) {
      // Pour gérer les cas particuliers :
      // Effacer tous les champs sauf la date ou saisir la date et un autre champ et effacer seulement la date
      setCanSearch(!(isStringEmpty(referenceSinistre) && isStringEmpty(immatriculation) &&
        isStringEmpty(referenceExpert) && isStringEmpty(referenceCourtier) && isStringEmpty(dateDebut) &&
        isStringEmpty(dateFin) && isStringEmpty(numeroPolice)))
    }
  }, [canSearch]);

  const resetFilters = () => {
    const initSearch = {
      numClient: user.numClient,
      userCompte: user.userCompte,
      codeAbo: user.codeAbo,
      codeService: user.service,
      nbMaxElement: '12',
      isRechercheAvancee: isRechercheAvancee,
    };
    actions.setSearch(initSearch);
    sessionStorage.setItem('searchDossier', JSON.stringify(initSearch));
    setReferenceSinistre('');
    setImmatriculation('');
    setDateDebut('');
    setDateFin('');
    setReferenceCourtier('');
    setReferenceExpert('');
    setNumeroPolice('');
    setDateEvenement('');
    setDisabledReferenceSinistre(false);
    setDisabledImmatriculation(false);
    setDisabledRefCourtier(false);
    setDisabledRefExpert(false);
    setDisabledNumPolice(false);
    setDisabledDateEvenement(false);
    setCanSearch(false)
  };

  const onPressedEnterKey = (event: React.KeyboardEvent) => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      go()
    }
  };

  return (
    <div className={classes.container}>
      <Typography id={'title-search-page'} variant={'h3'}>Rechercher un dossier</Typography>
      <form noValidate autoComplete="off">
        <div className={classes.containerPrincipal}>
          <TextFieldSearch id={'referenceSinistre'} className={classes.textField} value={referenceSinistre}
            label={'Référence sinistre'} maxLength={17} disabled={disabledReferenceSinistre}
            onChange={changeReferenceSinistre} onKeyDown={onPressedEnterKey}/>
          <TextFieldSearch id={'immatriculation'} className={classes.textField} value={immatriculation}
            label={'Immatriculation'} maxLength={12} disabled={disabledImmatriculation || hasRoleSupport}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => e.target.value = ('' + e.target.value).toUpperCase()}
            onChange={changeImmatriculation} onKeyDown={onPressedEnterKey}/>
          <div className={classes.divider}></div>
          <span style={{display: 'inline-block'}}>
            <FormLabel className={classes.labelDate}>Depuis le </FormLabel>
            <TextField
              id="dateDebut"
              label=""
              type="date"
              variant="outlined"
              margin="dense"
              size="small"
              value={dateDebut}
              placeholder="Saisir une date"
              className={classes.dateField}
              InputProps={{
                classes: {
                  root: classes.inputDate,
                },
              }}
              inputProps={{
                min: '1980-01-01',
                max: '2099-05-31',
              }}
              onChange={changeDateDebut}
              onKeyDown={onPressedEnterKey}
            />
          </span>
          <span style={{display: 'inline-block'}}>
            <FormLabel className={classes.labelDate}>Jusqu&apos;au </FormLabel>
            <TextField
              id="dateFin"
              label=""
              type="date"
              variant="outlined"
              margin="dense"
              size="small"
              value={dateFin}
              placeholder="Saisir une date"
              className={classes.dateField}
              InputProps={{
                classes: {
                  root: classes.inputDate,
                },
              }}
              onChange={changeDateFin}
              onKeyDown={onPressedEnterKey}
            />
          </span>
          {!isRechercheAvancee ?
              <span>
                <span>
                  <ButtonBlue id={'boutonRechercherDossier'} className={classes.btnReset} libelle={'Rechercher'}
                    disabled={!canSearch} onClick={() => go()}/>
                  <Tooltip title="Réinitialiser la recherche">
                    <IconButton onClick={() => resetFilters()} id={'boutonReinitialiserRechercheDossier'}>
                      <RefreshIcon classes={{root: classes.iconRoot}}/>
                    </IconButton>
                  </Tooltip>
                </span>
                <Button id="rechercheAvance" className={classes.buttonAvancee}
                  onClick={() => setIsRechercheAvancee(!isRechercheAvancee)}>
                  Recherche avancée
                </Button>
              </span> :
              <span>
                <span style={{visibility: 'hidden'}}>
                  <ButtonBlue id={'boutonReinitialiser'} className={classes.btnReset} libelle={'Rechercher'}
                    onClick={() => go()}/>
                  <Tooltip disableInteractive title="Réinitialiser la recherche">
                    <IconButton>
                      <RefreshIcon classes={{root: classes.iconRoot}}/>
                    </IconButton>
                  </Tooltip>
                </span>
                <Button id="rechercheSimple" className={classes.buttonAvancee}
                  onClick={() => setIsRechercheAvancee(!isRechercheAvancee)}>
                Recherche simplifiée
                </Button>
              </span>
          }
        </div>
        {isRechercheAvancee ?
          <div className={classes.containerSecondaire}>
            <TextFieldSearch id={'referenceCourtier'} className={classes.textField} label={'Référence courtier'}
              maxLength={12} value={referenceCourtier} disabled={disabledRefCourtier}
              onChange={changeReferenceCourtier} onKeyDown={onPressedEnterKey}/>
            <TextFieldSearch id={'referenceExpert'} className={classes.textField} label={'Référence expert'}
              maxLength={12} value={referenceExpert} disabled={disabledRefExpert}
              onChange={changeReferenceExpert} onKeyDown={onPressedEnterKey}/>
            <TextFieldSearch id={'numeroPolice'} className={classes.textField} label={'Numéro de police'}
              maxLength={17} value={numeroPolice} disabled={disabledNumPolice}
              onChange={changeNumeroPolice} onKeyDown={onPressedEnterKey}/>

            <span style={{display: 'inline-block'}}>
              <FormLabel className={classes.labelDate}>Date de l&apos;événement </FormLabel>
              <TextField
                id="dateEvt"
                label=""
                type="date"
                variant="outlined"
                margin="dense"
                size="small"
                value={dateEvenement}
                disabled={disabledDateEvenement}
                placeholder="Saisir une date"
                className={classes.dateField}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  classes: {
                    root: classes.inputDate,
                  },
                }}
                onChange={changeDateEvenement}
                onKeyDown={onPressedEnterKey}
              />
            </span>
            <ButtonBlue id={'boutonRechercherDossier'} className={classes.btnReset} libelle={'Rechercher'}
              disabled={!canSearch} onClick={() => go()}/>
            <span style={{display: 'inline-block'}}>
              <Tooltip title="Réinitialiser la recherche">
                <IconButton id={'boutonReinitialiserRechercheDossier'} onClick={() => resetFilters()}>
                  <RefreshIcon classes={{root: classes.iconRoot}} />
                </IconButton>
              </Tooltip>
            </span>
          </div> :
          null
        }
      </form>
    </div>
  )
};
