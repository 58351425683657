import React from 'react';
import MultiStepForm, {FormStep} from '../multiStepsForm/MultiStepForm';
import {Typography} from '@mui/material';
import {pecBgFormInitialState} from './model/ConstantsPECBG';
import {InfosPecPgSchemaValidation} from './schema/Schemas';
import ConsultationPecBg from './steps/consultationPecBg/ConsultationPecBg';
import {IPecBgForm, IPecBgResponse, IServiceResponse} from './model/ModelPECBG';
import {FormikValues} from 'formik';
import {UserContext} from '../../../context/UserContext';
import moment from 'moment';
import {postPECBGForm} from '../../../api/pecbg';
import {pecBgResponseMapper} from './Utils';

interface FormPecBgProps {
  onSubmit: (response: IServiceResponse) => void
  onLoading: (isLoading: boolean) => void
}

const FormPecBg = (props: FormPecBgProps) => {
  const {user} = React.useContext(UserContext);
  const {onSubmit, onLoading} = props;
  const {numClient, userCompte, codeAbo, service} = user;

  const onsubmitForm = (values: FormikValues) => {
    onLoading(true);
    const pecBgForm = values as IPecBgForm;
    // Récupérer les informations sur la demande
    const date = moment(pecBgForm.infosVehicule.dateSurvenance, 'YYYY-MM-DD');
    const demandeInformation = {
      assureur: pecBgForm.infosVehicule.assureur,
      numeroContrat: pecBgForm.infosVehicule.numContrat,
      numeroImmatriculation: pecBgForm.infosVehicule.immatriculation,
      kilometrage: pecBgForm.infosVehicule.kilometrage,
      dateSurvenance: date.format('DD/MM/YYYY'),
      heureSurvenance: pecBgForm.infosVehicule.heureSurvenance,
    }
    pecBgForm.codeAbonne = codeAbo;
    pecBgForm.numClient = numClient;
    pecBgForm.userCompte = userCompte;
    pecBgForm.codeService = service;

    postPECBGForm(pecBgForm)
        .then((response) => {
          const responsePecBg = {
            codeStatus: response.status,
            response: pecBgResponseMapper(response.data, demandeInformation, user.service),
          }
          onSubmit(responsePecBg)
        }).catch((error) => {
          const {codeErreur, libelleErreur, descriptionErreur} = error.response.data.errorInfos
          const resultKO = {
            codeStatus: error.response?.status ?? '500',
            response: {
              demandeInformation: demandeInformation,
              reponseInformation: {
                erreur: {
                  codeErreur: codeErreur,
                  libelleErreur: libelleErreur,
                  descriptionErreur: descriptionErreur,
                },
              },
            } as IPecBgResponse,
          }
          onSubmit(resultKO)
        }).finally(() => {
          onLoading(false)
        });
  }

  return (
    <div>
      <Typography variant={'h4'}>Consultation de la prise en charge bris de glace</Typography>
      <MultiStepForm
        initialValues={pecBgFormInitialState}
        onSubmit={onsubmitForm}
        showStepper={false}
      >
        <FormStep
          validationSchema={InfosPecPgSchemaValidation}
        >
          <ConsultationPecBg/>
        </FormStep>
      </MultiStepForm>
    </div>
  );
};

export default FormPecBg;
