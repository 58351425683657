import React from 'react';
import {Typography} from '@mui/material';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';
import {TextFieldSearch} from '../formsComponents/TextFieldSearch';
import {CommonProps} from './CommonProps';

const styles = (theme: Theme) => ({
  libelle: {
    color: theme.palette.secondary.dark2,
  },
});

export const LieuDit = (props: CommonProps) => {
  const {className, name, formulaire, value, onChangeValue, onBlur, onFocus, onError, helperText} = props;
  const classes = useEmotionStyles(styles);

  return (
    <div className={className}>
      <Typography className={classes.libelle}>Lieu-dit</Typography>
      <TextFieldSearch
        id={'lieuDit' + formulaire}
        name={name}
        fullWidth={true}
        maxLength={80}
        value={value}
        onChange={onChangeValue}
        onError={onError}
        helperText={helperText}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    </div>
  )
};
