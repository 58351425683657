import React, {useEffect, useState} from 'react';
import {Card, CardContent, Switch, Typography} from '@mui/material';
import {useMissionContext} from '../../../../context/MissionContext';
import {UserContext} from '../../../../context/UserContext';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import {ArchivedHistorique} from './ArchivedHistorique';
import {postUnarchive} from '../../../../api/dossier';
import {useParams} from 'react-router';
import {Loader} from '../../../common/Loader';
import {ScrollTop} from '../../../common/ScrollTop';
import {ButtonBlue} from '../../../common/buttons/ButtonBlue';
import {permittedRolesHistoriqueDetaille} from '../../../../constantes/roles/Roles';
import useHasRole from '../../../../hook/useHasRole';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {CommonHistorique} from './CommonHistorique';
import {IToken} from '../../../../model/detailsDossier/TokenModel';

const base64 = require('base-64');

export interface HistoriqueProps {
  onClickPj: (tabPanelValue: number, idPJ: string) => void,
  onClickComment?: ((tabPanelValue: number, date: string) => void),
}

/** Design */
const styles = (theme: Theme) => ({
  card: {
    marginTop: '25px',
    [theme.breakpoints.down('lg')]: {
      marginTop: '15px',
    },
  },
  subtitle: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  switchBase: {
    'color': theme.palette.link.main,
    '&$checked': {
      color: theme.palette.link.main,
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.secondary.dark2,
      opacity: 0.5,
    },
  },
  track: {
    backgroundColor: theme.palette.secondary.dark2,
  },
  loader: {
    margin: '0px 50px 0px 0px',
  },
});

export const Historique = ({onClickPj, onClickComment}: HistoriqueProps) => {
  const classes = useEmotionStyles(styles);
  const {user} = React.useContext(UserContext);
  const {mission} = useMissionContext();
  const nomCompteAssistance = sessionStorage.getItem('nomCompteAssistance');
  const isActiveViewDetailedFromStrorage = sessionStorage.getItem('isActiveViewDetailed');
  const params = useParams<keyof IToken>() as IToken;
  const search = JSON.parse(base64.decode(params.token));
  const referenceSinistre = search?.referenceSinistre.trim();
  const numeroMission = search?.numeroMission;
  const indicateurArchivage = mission?.indicateurArchivage;
  const isDossierSimple = mission?.dossierSimple;
  const isViewDetailedVisible = useHasRole(permittedRolesHistoriqueDetaille);
  const [isActiveViewDetailed, setIsActiveViewDetailed] = useState<boolean>(!!isActiveViewDetailedFromStrorage);
  const [responseStatusUnarchive, setResponseStatusUnarchive] = useState(0);
  const [onErrorUnarchive, setOnErrorUnarchive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [messagesLength, setMessagesLength] = useState<number>();

  const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsActiveViewDetailed(event.target.checked);
    sessionStorage.setItem('isActiveViewDetailed', JSON.stringify(event.target.checked))
  };

  const unarchive = () => {
    const data = {
      codeGTA: mission?.codeGTA,
      referenceSinistre: referenceSinistre,
      numeroMission: numeroMission,
      codeService: user.service,
      numClient: user.numClient,
      userCompte: user.userCompte,
      nomCompteAssistance: nomCompteAssistance ?? '',
    };
    setIsLoading(true);
    postUnarchive(data).then((response) => {
      setResponseStatusUnarchive(response.status);
      setIsLoading(false)
    }).catch((error) => {
      setResponseStatusUnarchive(error.response.status);
      setIsLoading(false)
    })
  };

  useEffect(() => {
    if (responseStatusUnarchive > 0) {
      if (responseStatusUnarchive !== 201) {
        setOnErrorUnarchive(true)
      } else {
        setOnErrorUnarchive(false);
        window.location.reload()
      }
    }
  }, [responseStatusUnarchive]);

  useEffect(() => {
    if ('99999' == user.numClient) {
      setIsActiveViewDetailed(true)
    }
  }, []);

  const renderBoutonDesarchiver = () => {
    if (indicateurArchivage === 'O' || indicateurArchivage === 'A') {
      return (isLoading ? <Loader className={classes.loader} size={30}/> :
        <ButtonBlue id={'boutonDesarchiver'} icon={<UnarchiveIcon/>} libelle={'Désarchiver le dossier'}
          disabled={indicateurArchivage === 'A'} onClick={() => unarchive()}/>)
    }
    return (<></>)
  }

  const typeHistorique = () => {
    if (!isDossierSimple) {
      return (isViewDetailedVisible && isActiveViewDetailed) ? 'detailed' : 'simplified'
    } else return 'detailed'
  }

  const renderHistorique = () => {
    if (indicateurArchivage) {
      return (
        <CommonHistorique
          onClickPj={onClickPj}
          setMessagesLength={setMessagesLength}
          onClickComment={!isDossierSimple ? onClickComment : undefined}
          typeHistorique={typeHistorique()}/>
      )
    } else {
      if (indicateurArchivage === 'A' || indicateurArchivage === 'O') {
        return (<ArchivedHistorique setMessagesLength={setMessagesLength}/>)
      }
      return (<></>)
    }
  }

  return (
    <Card className={classes.card}>
      <CardContent>
        <div style={{display: 'flex', justifyContent: 'space-between',
          alignItems: indicateurArchivage === 'O' || indicateurArchivage === 'A' ?'center' : 'flex-start'}}
        >
          <Typography variant={'h3'}>Historique des messages ({messagesLength})</Typography>
          {indicateurArchivage !== 'O' && indicateurArchivage !== 'A' && !isDossierSimple && isViewDetailedVisible ?
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Typography className={classes.subtitle}>Historique métier</Typography>
              <Switch
                id={'historiqueswitcher'}
                classes={{switchBase: classes.switchBase, track: classes.track}}
                color="default"
                checked={isActiveViewDetailed}
                onChange={onChangeValue}
              />
              <Typography className={classes.subtitle}>Historique détaillé</Typography>
            </div> : ''
          }
          {renderBoutonDesarchiver()}
        </div>
        {onErrorUnarchive ?
          <div style={{textAlign: 'right', color: 'red'}}>
            Une erreur s&apos;est produite. Veuillez réessayer plus tard ou contacter le support.
          </div> : ''
        }
        {renderHistorique()}
        <ScrollTop/>
      </CardContent>
    </Card>
  )
};
