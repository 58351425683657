import {Tooltip, Typography} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import React from 'react';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';

const styles = (theme: Theme) => ({
  title: {
    display: 'flex',
  },
  tooltip: {
    marginLeft: '8px',
    color: theme.palette.secondary.dark2,
  },
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  red: {
    color: 'red',
  },
});

interface LabelProps {
  label: string,
  mandatory: boolean | undefined
}

export const Label = (props: LabelProps) => {
  const classes = useEmotionStyles(styles);

  return (<Typography className={classes.libelle}>
    {props.label} {props.mandatory && <span className={classes.red}>*</span>}
  </Typography>)
}

interface CommonTooltipProps {
  label: string,
  mandatory: boolean | undefined,
  titleTooltip: React.JSX.Element | string,
}

export const CommonTooltip = (props: CommonTooltipProps) => {
  const classes = useEmotionStyles(styles);

  return (<div className={classes.title}>
    <Label label={props.label} mandatory={props.mandatory}/>
    <Tooltip
      className={classes.tooltip}
      disableInteractive
      placement="top"
      PopperProps={{disablePortal: true}}
      title={props.titleTooltip}
    >
      <HelpIcon/>
    </Tooltip>
  </div>)
}
