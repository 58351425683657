import React, {useEffect, useState} from 'react';
import {AccordionDetails, MenuItem, Typography} from '@mui/material';
import {TextFieldSearch} from '../../../common/formsComponents/TextFieldSearch';
import {TextFieldSelect} from '../../../common/formsComponents/TextFieldSelect';
import {Attachment} from './FilesDragAndDrop';
import {ButtonBlue} from '../../../common/buttons/ButtonBlue';
import {ItemGetValeursCodesModel} from '../../../../model/common/ItemGetValeursCodesModel';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {CommonAttachmentForm} from '../../common/CommonAttachmentForm';

export interface AttachmentFormProps {
  expanded: boolean,
  attachment: Attachment
  codesValeursNaturePieceJointe: ItemGetValeursCodesModel[],
  codesValeursTypePieceJointe: ItemGetValeursCodesModel[],
  onChangePanel: (panel: string) => void,
  onSave: (attachment: Attachment) => void,
  onDelete: (attachment: Attachment) => void,
}

/** Design */
const styles = (theme: Theme) => ({
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  red: {
    color: 'red',
  },
  error: {
    fontSize: '12px',
  },
  textField: {
    width: '100%',
  },
});

const AttachmentForm = (props: AttachmentFormProps) => {
  const classes = useEmotionStyles(styles);
  const {
    expanded, attachment, codesValeursNaturePieceJointe, codesValeursTypePieceJointe,
    onChangePanel, onSave, onDelete,
  } = props;
  const [formData, setFormData] = useState<Attachment>(attachment);
  const [errorFields, setErrorFields] = useState({libelleError: false, typeError: false});

  const codesValeursNaturePieceJointeSort = [...codesValeursNaturePieceJointe].sort((a: ItemGetValeursCodesModel,
      b: ItemGetValeursCodesModel) => {
    if (a.libelleedition > b.libelleedition) {
      return 1
    } else {
      return -1
    }
  });

  const codesValeursTypePieceJointeSort = [...codesValeursTypePieceJointe].sort((a: ItemGetValeursCodesModel,
      b: ItemGetValeursCodesModel) => {
    if (a.libelleedition > b.libelleedition) {
      return 1
    } else {
      return -1
    }
  });

  const itemsListNaturePieceJointe = codesValeursNaturePieceJointeSort.map((item: ItemGetValeursCodesModel) => {
    return (<MenuItem key={item.valeur} value={item.valeur}>{item.libelleedition}</MenuItem>)
  });

  const itemsListTypePieceJointe = codesValeursTypePieceJointeSort.map((item: ItemGetValeursCodesModel) => {
    return (<MenuItem key={item.valeur} value={item.valeur}>{item.lowercase}</MenuItem>)
  });

  useEffect(() => {
    setFormData({...attachment})
  }, [attachment]);

  const handleChange = (input: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (input === 'type') setErrorFields({...errorFields, typeError: false});
      if (input === 'libelle') setErrorFields({...errorFields, libelleError: false});
      setFormData({...formData, [input]: event.target.value})
    };

  const handleSubmit = () => {
    setErrorFields({libelleError: !formData.libelle, typeError: !formData.type});
    if (formData.libelle && formData.type) {
      onSave(formData)
    }
  };

  return (
    <CommonAttachmentForm
      attachment={attachment}
      expanded={expanded}
      onChangePanel={onChangePanel}
      onDelete={onDelete}
      contentAccordionDetail={
        !attachment.onError ?
          <AccordionDetails style={{display: 'block', padding: '16px'}}>
            <div>
              <Typography className={classes.libelle}>
                Nom de la pièce jointe<span style={{color: 'red'}}> *</span>
              </Typography>
              <TextFieldSearch id={'nomPJ'} className={classes.textField} value={formData.libelle}
                maxLength={50} onChange={handleChange('libelle')} onError={errorFields.libelleError}
              />
              {errorFields.libelleError &&
                <Typography id={'nomPJRequired'} className={`${classes.red} ${classes.error}`}>
                  Veuillez saisir le nom de la pièce jointe
                </Typography>
              }
            </div>
            <div style={{marginTop: '10px'}}>
              <Typography className={classes.libelle}>Type<span style={{color: 'red'}}> *</span>
              </Typography>
              <TextFieldSelect
                className={classes.textField}
                id={'type'}
                name={'type'}
                marginDense={true}
                value={formData.type}
                itemsList={itemsListTypePieceJointe}
                onChange={handleChange('type')}
                onError={errorFields.typeError}
              />
              {errorFields.typeError &&
                <Typography id={'typePJRequired'} className={`${classes.red} ${classes.error}`}>
                  Veuillez sélectionner le type de la pièce jointe
                </Typography>
              }
            </div>
            <div style={{marginTop: '10px'}}>
              <Typography className={classes.libelle}>Nature</Typography>
              <TextFieldSelect
                className={classes.textField}
                id={'nature'}
                name={'nature'}
                marginDense={true}
                value={formData.nature}
                withEmptyItem={true}
                emptyItemLabel={'Aucune'}
                itemsList={itemsListNaturePieceJointe}
                onChange={handleChange('nature')}
              />
            </div>
            <div style={{marginTop: '10px'}}>
              <Typography className={classes.libelle}>Version</Typography>
              <TextFieldSearch
                id={'version'}
                className={classes.textField}
                value={formData.version}
                maxLength={10}
                onChange={handleChange('version')}
              />
            </div>
            <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '16px'}}>
              <ButtonBlue
                id={'boutonValider' + attachment.id}
                libelle={'Valider'}
                onClick={handleSubmit}
                disabled={errorFields.libelleError || errorFields.typeError}
              />
            </div>
          </AccordionDetails> : <></>
      }
    />
  );
};

export default AttachmentForm;
