import {Tooltip, Typography} from '@mui/material';
import InputMask from 'react-input-mask-3.0';
import {TextFieldSearch} from '../formsComponents/TextFieldSearch';
import React from 'react';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';
import HelpIcon from '@mui/icons-material/Help';
import {CommonProps} from './CommonProps';

const styles = (theme: Theme) => ({
  title: {
    display: 'flex',
  },
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  red: {
    color: 'red',
  },
  tooltip: {
    marginLeft: '10px',
    color: theme.palette.secondary.dark2,
  },
});

export const CodePostal = (props: CommonProps) => {
  const {className, name, formulaire, value, onChangeValue, onBlur, onFocus, onError, helperText} = props;
  const classes = useEmotionStyles(styles);

  return (
    <div className={className}>
      <div className={classes.title}>
        <Typography className={classes.libelle}>
          Code postal&nbsp;<span className={classes.red}>*</span>
        </Typography>
        <Tooltip
          className={classes.tooltip}
          disableInteractive
          placement="top"
          title={<span>Saisissez le code postal à 5 chiffres</span>}
        >
          <HelpIcon/>
        </Tooltip>
      </div>

      <InputMask
        mask="99999"
        maskPlaceholder={null}
        value={value}
        onChange={onChangeValue}
        onBlur={onBlur}
        onFocus={onFocus}
      >
        <TextFieldSearch
          id={'codePostal' + formulaire}
          name={name}
          fullWidth={true}
          onError={onError}
          helperText={helperText}
        />
      </InputMask>
    </div>
  )
};
