import React, {useEffect, useState} from 'react';
import {IDommage} from './Models';
import DommageForm from './DommageForm';
import {ButtonLink} from '../../../../common/buttons/ButtonLink';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {detailOptiqueList, typeInterventionList} from './Constants';
import {getValeursCodes} from '../../../../../api/norme';
import {ItemGetValeursCodesModel} from '../../../../../model/common/ItemGetValeursCodesModel';
import {FormikValues, useFormikContext} from 'formik';
import {lustrageCases} from '../../model/ConstantsPECBG';
import {elementVitreClignotant, elementVitreFeuxArriere} from '../../../../../constantes/constantesNormeAuto';
import {Typography} from '@mui/material';
import useEmotionStyles from '../../../../../common/useEmotionStyles';
import {ID_BOUTON_RETOUR} from '../../../../../constantes/ids/Ids';

interface IProps {
  idExpanded: number
  setIdExpanded: (idExpanded: number) => void
  onCloseModal: () => void,
}

/** Design */
const styles = () => ({
  error: {
    color: 'red',
    marginBottom: '16px',
    textAlign: 'center' as const,
  },
});

const DommagesForms = (props: IProps) => {
  const classes = useEmotionStyles(styles);
  const {idExpanded, setIdExpanded, onCloseModal} = props;
  const [codesValeursElementVitreList, setCodesValeursElementVitreList] = useState<ItemGetValeursCodesModel[]>([]);
  const {values, errors}: FormikValues = useFormikContext();

  useEffect(() => {
    getValeursCodes('AR2', '92420101').then((result: ItemGetValeursCodesModel[]) => {
      setCodesValeursElementVitreList(result.filter((item) =>
        item.valeur !== elementVitreClignotant && item.valeur !== elementVitreFeuxArriere),
      )
    });
  }, []);

  return (
    <div>
      <ButtonLink
        id={ID_BOUTON_RETOUR}
        icon={<ArrowBackIcon/>}
        libelle={'Retour'}
        onClick={onCloseModal}
      />

      {values.dommages.length === 0 &&
        <Typography className={classes.error}>{errors?.dommages}</Typography>
      }

      {values.dommages.map((dommage: IDommage) => {
        return (
          <DommageForm
            key={dommage.id}
            idExpanded={idExpanded}
            setIdExpanded={setIdExpanded}
            dommage={dommage}
            elementVitreList={codesValeursElementVitreList}
            detailOptiqueList={detailOptiqueList}
            typeInterventionList={typeInterventionList.filter((item) => {
              return lustrageCases.includes(values.infosVehicule.assureurGTA) ? true : item.valeur !== 'L';
            })}
          />
        )
      })}
    </div>
  );
};

export default DommagesForms;
