import React from 'react';
import {FieldConfig, FieldHookConfig, useField} from 'formik';
import {Checkbox} from '@mui/material';
import {getIdFromName} from '../../utils/Utils';
import useEmotionStyles from '../../../../common/useEmotionStyles';
interface CheckBoxFieldProps extends FieldConfig{
    checked?: boolean
    mandatory?: boolean
    disabled?: boolean
    onChange?: () => void
}

const styles = () => ({
  reducedPadding: {
    paddingRight: 4,
  },
})

const CheckBoxField = ({mandatory, checked, disabled, onChange, ...props} : CheckBoxFieldProps) => {
  const classes = useEmotionStyles(styles);
  const [field] = useField<string>(props as FieldHookConfig<string>);

  return (
    <Checkbox
      id={getIdFromName(field.name)}
      className={classes.reducedPadding}
      onChange={(value) => {
        field.onChange(value);
        if (onChange) onChange();
      }}
      name={field.name}
      onBlur={field.onBlur}
      value={field.value}
      color={'info'}
      disableRipple
      checked={Boolean(checked)}
      disabled={Boolean(disabled)}
    />
  );
};

export default CheckBoxField;
