import React from 'react';
import {Typography} from '@mui/material';
import {useMissionContext} from '../../../../context/MissionContext';
import {ReactComponent as IconVP} from '../../../../images/icones/icones-vehicules-eclates/icn-vp.svg';
import {ReactComponent as IconUtilitaire} from '../../../../images/icones/icones-vehicules-eclates/icn-utilitaire.svg';
import {ReactComponent as IconMoto} from '../../../../images/icones/icones-vehicules-eclates/icn-moto.svg';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';

interface VehiculeProps {
  onmouseover: (event: any) => void,
  allInChocs: boolean,
  allInDommages: boolean,
  ninePointsInChocs: boolean,
  ninePointsInDommages: boolean,
  isMoto: boolean,
  isUtilitaire: boolean,
}

export const findElementInArray = (array: any, text: string) => {
  return array?.find((element: any) => {
    return element.libelle === text
  })
}

export const Dommages = ({onmouseover, allInChocs, allInDommages, ninePointsInChocs, ninePointsInDommages,
  isMoto, isUtilitaire}: VehiculeProps) => {
  const {mission} = useMissionContext()
  const chocs = mission?.gestionDTO?.dommages?.chocs ?? []
  const dommages = mission?.gestionDTO?.dommages?.dommages ?? []
  const elementsVitres = mission?.gestionDTO?.dommages?.elementsVitres ?? []

  const chocsStyleList = () => {
    const style: any = {}
    chocs.forEach((choc, _) => {
      style['& .' + choc.libelle.toLowerCase().replaceAll('/', '').replaceAll(' ', '')] = {
        fill: '#DB653D',
        stroke: '#DB653D',
      }
    })
    return style
  }
  const chocsStyle = chocsStyleList()

  const dommagesStyleList = () => {
    const style: any = {}
    dommages.forEach((dommage, _) => {
      if (!chocs.find((choc) => choc.abreviation === dommage.abreviation)) {
        style['& .' + dommage.libelle.toLowerCase().replaceAll('/', '').replaceAll(' ', '')] = {
          fill: '#F4B35F',
          stroke: '#F4B35F',
        };
      }
    })
    return style
  }
  const dommagesStyle = dommagesStyleList()

  const elementsVitresStyleList = () => {
    const style: any = {};
    elementsVitres.filter((element) => {
      if (isMoto) {
        return element.code !== '1' && element.code !== '2' && element.code !== '3' && element.code !== '4' &&
          element.code !== '9'
      } else {
        return element.code !== '2' && element.code !== '6' && element.code !== '9'
      }
    }).forEach((elementVitre) => {
      style['& .' + elementVitre.libelle.toLowerCase().replaceAll('-', '')
          .replaceAll(/[èéêë]/g, 'e')
          .replaceAll(' ', '')] = {
        fill: '#227398',
        stroke: '#227398',
      };
    });
    return style
  };
  const elementsVitresStyle = elementsVitresStyleList();

  const styles = (theme: Theme, {chocsStyle, dommagesStyle, elementsVitresStyle}: any) => {
    return {...{
      vehicule: {
        margin: 'auto',
        marginTop: '-20px',
      },
      ensemble: {
        border: '2px solid #DB653D',
        borderRadius: '20px',
        padding: '10px',
      },
      libelleEnsemble: {
        color: '#DB653D',
        marginBottom: '5px',
      },
      iconVP: {
        width: '250px',
      },
      iconUtilitaire: {
        width: '250px',
      },
      iconMoto: {
        width: '320px',
      },
    },
    iconVehicule: {...chocsStyle, ...dommagesStyle, ...elementsVitresStyle}}
  }

  const classes = useEmotionStyles(styles, {
    chocsStyle: chocsStyle, dommagesStyle: dommagesStyle, elementsVitresStyle: elementsVitresStyle})

  const classNameVehicule = () => {
    const className = `${classes.vehicule}`;
    if (isUtilitaire) {
      return className.concat(` ${classes.iconUtilitaire}`);
    }
    if (isMoto) {
      return className.concat(` ${classes.iconMoto}`);
    }
    return className.concat(` ${classes.iconVP}`);
  };

  const iconVehicule = () => {
    if (isUtilitaire) {
      return <IconUtilitaire/>;
    }
    if (isMoto) {
      return <IconMoto/>;
    }
    return <IconVP/>;
  }

  if (findElementInArray(chocs, 'ENSEMBLE') || findElementInArray(dommages, 'ENSEMBLE') ||
    allInChocs || allInDommages) {
    return (
      <div className={classNameVehicule()}>
        <Typography className={classes.libelleEnsemble}>Ensemble du véhicule</Typography>
        <div className={classes.ensemble}>
          {iconVehicule()}
        </div>
      </div>
    )
  } else if (ninePointsInChocs || ninePointsInDommages) {
    return (
      <div className={classNameVehicule()}>
        <Typography className={classes.libelleEnsemble}>Ensemble des parties extérieures</Typography>
        <div className={classes.ensemble}>
          {iconVehicule()}
        </div>
      </div>
    )
  } else if (isUtilitaire) {
    return <IconUtilitaire className={`${classes.iconVehicule} ${classes.iconUtilitaire}`}
      onMouseOver={(event) => onmouseover(event)}/>
  } else if (isMoto) {
    return <IconMoto className={`${classes.iconVehicule} ${classes.iconMoto}`}
      onMouseOver={(event) => onmouseover(event)}/>
  } else {
    return <IconVP className={`${classes.iconVehicule} ${classes.iconVP}`}
      onMouseOver={(event) => onmouseover(event)}/>
  }
}
