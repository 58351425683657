import {IconButton, Tooltip, Typography} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import React from 'react';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {Theme as MuiTheme} from '@mui/material/styles';
import {CSSInterpolation} from '@emotion/serialize';
import {ButtonBlue} from '../../common/buttons/ButtonBlue';
import {CommonAttachmentsForm, CommonAttachmentsFormProps} from './CommonAttachmentsForm';
import {Loader} from '../../common/Loader';
import {ButtonLink} from '../../common/buttons/ButtonLink';
import {ButtonBlueSend} from '../../common/formsComponents/ButtonBlueSend';
import ElementsCalcul, {ElementsCalculProps} from '../sd05/ElementsCalcul';
import {ID_BOUTON_ENVOYER} from '../../../constantes/ids/Ids';

type StylesProps = {
  showElementCalcul: boolean | undefined,
  showPjForm: boolean | undefined,
  isLoading: boolean,
  isButtonLink: boolean
}
/** Design */
const styles = (theme: MuiTheme,
    {showElementCalcul, showPjForm, isLoading, isButtonLink}: StylesProps): Record<string, CSSInterpolation> => ({
  form: {
    height: '100vh',
    width: '40%',
    position: 'absolute' as const,
    top: '0%',
    right: '0%',
    backgroundColor: theme.palette.white.main,
    boxShadow: '0px 10px 20px ' + theme.palette.boxShadow.light1,
    [theme.breakpoints.down('lg')]: {
      width: '50%',
    },
    [theme.breakpoints.down('md')]: {
      width: '70%',
    },
  },
  content: {
    height: showElementCalcul || showPjForm ? '100vh' : '90vh',
    overflowY: 'auto' as const,
  },
  header: {
    height: '70px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: '10px',
    padding: '0px 20px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white.main,
  },
  titre: {
    fontSize: '20px',
    fontWeight: 'bold',
    letterSpacing: '0px',
    opacity: '1',
  },
  iconButton: {
    padding: '0px',
  },
  cancelIcon: {
    marginRight: '0px',
    color: theme.palette.white.main,
  },
  divResponse: {
    display: 'flex',
    height: '75vh',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column' as const,
  },
  buttonOK: {
    marginTop: '15px',
  },
  elementsCalcul: {
    padding: '10px 20px 0px',
    height: 'calc(100vh - 80px)', // 100vh - (hauteur du header + paddingTop)
  },
  footer: {
    height: '10vh',
    display: 'flex',
  },
  buttons: {
    display: 'flex',
    justifyContent: isLoading || !isButtonLink ? 'flex-end': 'space-between',
    padding: '0px 20px',
    margin: 'auto 0px', // pour centrer verticalement
    width: '100%',
  },
  loader: {
    margin: '0px 20px 0px 0px',
  },
})

interface CommonFormProps {
  idForm: string,
  idTitre: string,
  titre: string,
  libelleEnvoye?: string,
  onClose: () => void,
  onCloseAndRefresh: () => void,
  responseReturn: number,
  content: React.JSX.Element
  showPjForm?: boolean,
  showElementCalcul?: boolean
  elementsCalculProps?: ElementsCalculProps
  commonAttachmentsFormProps?: CommonAttachmentsFormProps
  withoutPj: boolean
  isLoading: boolean
  libelleLink?: string
  idLink?: string
  setShowPjForm?: any
  errorMessageField: boolean
  handleClick: () => void
  showFooter?: boolean
}

export const CommonForm = (props: CommonFormProps) => {
  const {titre, libelleEnvoye, responseReturn, content, showPjForm, showElementCalcul, elementsCalculProps,
    commonAttachmentsFormProps, withoutPj, isLoading, libelleLink, idLink, idForm, idTitre,
    setShowPjForm, errorMessageField, handleClick, showFooter} = props

  const isButtonLink = !withoutPj && Boolean(libelleLink);
  const classes = useEmotionStyles(styles, {showElementCalcul, showPjForm, isLoading, isButtonLink});

  return (
    <div id={`form-${idForm}`} className={classes.form}>
      <div className={classes.content}>
        <div className={classes.header}>
          <Typography id={`title-form-${idTitre}`} className={classes.titre}>{titre}</Typography>
          <Tooltip disableInteractive title='Quitter' PopperProps={{disablePortal: true}}>
            <IconButton
              component='span'
              className={classes.iconButton}
              onClick={responseReturn <= 0 ? props.onClose : props.onCloseAndRefresh}
            >
              <CancelIcon className={classes.cancelIcon}/>
            </IconButton>
          </Tooltip>
        </div>

        {content}

        {responseReturn <= 0 && showPjForm && commonAttachmentsFormProps &&
          <CommonAttachmentsForm
            piecesJointesList={commonAttachmentsFormProps.piecesJointesList}
            onUpload={commonAttachmentsFormProps.onUpload}
            onCloseAttachmentForms={commonAttachmentsFormProps.onCloseAttachmentForms}
          />
        }
        {responseReturn <= 0 && showElementCalcul && elementsCalculProps &&
          <div className={classes.elementsCalcul}>
            <ElementsCalcul
              listElements={elementsCalculProps.listElements}
              onSave={elementsCalculProps.onSave}
              onCloseElementsCalcul={elementsCalculProps.onCloseElementsCalcul}
            />
          </div>
        }

        {(responseReturn === 201) &&
          <div className={classes.divResponse}>
            <Typography variant='body1' component='div' className={classes.titre}>
              {libelleEnvoye}
            </Typography>
            <ButtonBlue id={'bouton-ok'} className={classes.buttonOK} libelle={'OK'} onClick={props.onCloseAndRefresh}/>
          </div>
        }
        {responseReturn > 0 && responseReturn !== 201 &&
          <div className={classes.divResponse}>
            <Typography variant='body1' component='div' className={classes.titre}>
              Une erreur s&apos;est produite. Merci de réessayer ultérieurement.
            </Typography>
            <ButtonBlue id={'bouton-ok'} className={classes.buttonOK} libelle={'OK'} onClick={props.onClose}/>
          </div>
        }
      </div>

      {responseReturn <= 0 && (withoutPj || !showFooter) &&
        <div className={classes.footer}>
          <div className={classes.buttons}>
            {isLoading ? <Loader className={classes.loader} size={30}/> :
              <>
                {isButtonLink &&
                  <ButtonLink
                    id={idLink ?? 'bouton-ajout-PJ'}
                    libelle={libelleLink}
                    isLibelleUpperCase={true}
                    isLibelleBold={true}
                    onClick={() => setShowPjForm(!showPjForm)}
                  />
                }
                <ButtonBlueSend
                  id={ID_BOUTON_ENVOYER}
                  disabled={errorMessageField}
                  onClick={handleClick}
                />
              </>
            }
          </div>
        </div>
      }
    </div>
  )
}
