import React, {useEffect} from 'react';
import groupBy from 'lodash/groupBy'
import {useMissionContext} from '../../../../context/MissionContext'
import {PJModel} from '../../../../model/detailsDossier/PJModel';
import MessageBloc, {Attachment, Message} from './MessageBloc';
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import moment from 'moment';
import {ScrollTop} from '../../../common/ScrollTop';
import {ActeurModel} from '../../../../model/detailsDossier/ActeurModel';
import {TextFieldSelect} from '../../../common/formsComponents/TextFieldSelect';
import {getMaxDate, getMinDate} from '../../../../common/utils/DateUtils';
import {extensionsList} from '../../../../constantes/extensionsPJ/extensionsPJ';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';

/** Design */
const styles = (theme: Theme) => ({
  card: {
    marginTop: '25px',
    [theme.breakpoints.down('lg')]: {
      marginTop: '15px',
    },
  },
  containerPrincipal: {
    backgroundColor: theme.palette.white.main,
    border: '1px solid #DBDBDB',
    borderRadius: '8px',
    opacity: '1',
    padding: '15px',
    margin: '30px 0px',
  },
  main: {
    lineHeight: '55px',
  },
  search: {
    paddingBottom: '10px',
    color: theme.palette.secondary.dark2,
  },
  labelDate: {
    marginLeft: '10px',
    border: 'none',
    verticalAlign: 'middle',
  },
  inputDate: {
    'backgroundColor': theme.palette.white.main,
    '&:before': {
      border: 'none',
    },
  },
  textField: {
    minWidth: '300px',
  },
  dateField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    color: 'lightgrey',
    borderRadius: '4px',
  },
  radioGroupe: {
    marginTop: '20px',
    width: '100%',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '200px',
  },
  gridItemLeft: {
    paddingLeft: '20px',
  },
  checkboxRoot: {
    paddingRight: '0px',
  },
  checkboxChecked: {
    color: theme.palette.link.main,
  },
  formLabel: {
    maxWidth: 'max-content',
  },
  iconRoot: {
    margin: '0px',
  },
});

interface AttachmentsProps {
  itemAccordeon: string
}

const getActeurName = (acteurList: ActeurModel[], code: string, baseName: string) => {
  const acteur = acteurList.find((acteur)=> acteur.codeAbonne === code);
  return acteur?.libelle ?? baseName
};

export const useMessagesList = (acteurList: ActeurModel[]) => {
  const {mission} = useMissionContext();
  const groupByDoc = groupBy(mission!.pjsDTO!.pj, function(doc: PJModel) {
    return doc.idDoc
  });
  const buildMessage = (idDoc: string) => {
    const doc = groupByDoc[idDoc];
    const attachmentList: Attachment[] = doc.map((attachment: PJModel) => {
      return {
        id: attachment.id,
        name: attachment.name,
        type: attachment.type,
        format: attachment.format,
        date: attachment.date,
      }
    });

    const picturesList: Attachment[] = attachmentList.filter((atta) => extensionsList.includes(atta.format));
    const othersList: Attachment[] = attachmentList.filter((atta)=> !extensionsList.includes(atta.format));

    const act = getActeurName(acteurList, doc[0].authorAbo.trim(), doc[0].author.trim());
    return {
      id: doc[0].idDoc,
      nbPj: doc.length,
      author: act.trim(),
      dateReceive: doc[0].date,
      typeDoc: doc[0].typeDoc,
      libelleDoc: doc[0].libelleDoc,
      attachments: [...othersList],
      pictures: [...picturesList],
    }
  };
  return Object.keys(groupByDoc).map((key) => buildMessage(key))
};

export const Attachments = ({itemAccordeon}: AttachmentsProps) => {
  const classes = useEmotionStyles(styles);
  moment.locale('fr');
  const {mission} = useMissionContext();
  const acteurList = mission!.acteursDTO!.acteurs!;
  const messages = useMessagesList(acteurList);
  const listPJ = mission?.pjsDTO?.pj ?? [];
  const counter = listPJ.length;
  const [messageSelected, setMessageSelected] = React.useState('-1');
  const [messageListCourrante, setMessageListCourrante] = React.useState<Message[]>(messages);
  const [typeDoc, setTypeDoc] = React.useState('');
  const [format, setFormat] = React.useState<string[]>([]);
  const [acteur, setActeur] = React.useState<string[]>([]);
  const groupeActeur = groupBy(messages, 'author');
  const groupeFormatSansImage = Object.keys(groupBy(listPJ, 'format'))
      .filter((form)=> !extensionsList.includes(form));
  const presenceImage = Object.keys(groupBy(listPJ, 'format'))
      .filter((form)=> extensionsList.includes(form)).length>0;
  const groupeFormat = presenceImage ? groupeFormatSansImage.concat('Image') : groupeFormatSansImage;
  const groupeTypeDoc = groupBy(listPJ, 'libelleDoc');

  const minDate = () => {
    const dates = listPJ.map((item) => {
      return moment(item.date?.substring(0, 10), 'DD/MM/YYYY').toDate()
    });
    const min = getMinDate(dates);
    return moment(min).format( 'YYYY-MM-DD')
  };

  const maxDate = () => {
    const dates = listPJ.map((item) => {
      return moment(item.date?.substring(0, 10), 'DD/MM/YYYY').toDate()
    });
    const max = getMaxDate(dates);
    return moment(max).format( 'YYYY-MM-DD')
  };

  const [dateDebut, setDateDebut] = React.useState(minDate());
  const [dateFin, setDateFin] = React.useState(maxDate());

  const valueTypeDoc = () => {
    if (Object.keys(groupeTypeDoc).length !== 1) {
      return typeDoc;
    } else return Object.keys(groupeTypeDoc)[0];
  };

  const itemsListTypeDoc = Object.keys(groupeTypeDoc).map((key) => {
    return <MenuItem key={key} value={key}>{key}</MenuItem>
  });

  useEffect(() => {
    const selectedElement = document.getElementById(itemAccordeon.trim());
    if (selectedElement) {
      selectedElement.scrollIntoView({behavior: 'smooth', block: 'center'});
      setMessageSelected(itemAccordeon.trim())
    }
  }, [itemAccordeon]);

  const getTitle = () => {
    if (counter > 1) {
      return 'Pièces jointes'
    } else {
      return 'Pièce jointe'
    }
  };

  const initFiltre = () => {
    setFormat([]);
    setActeur([]);
    setTypeDoc('');
    setDateDebut(minDate());
    setDateFin(maxDate());
  };

  const choixDateDebut = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value;
    setMessageSelected('-1');
    setDateDebut(keyword)
  };

  const choixDateFin = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value;
    setMessageSelected('-1');
    setDateFin(keyword)
  };

  const choixFormat = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value;
    setMessageSelected('-1');
    setFormat(format.includes(keyword) ? format.filter((c) => c !== keyword) : [...format, keyword])
  };

  const choixActeur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value;
    setMessageSelected('-1');
    setActeur(acteur.includes(keyword) ? acteur.filter((c) => c !== keyword) : [...acteur, keyword])
  };

  const choixTypeDoc = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value;
    setMessageSelected('-1');
    setTypeDoc(keyword)
  };

  useEffect(() => {
    changementFiltre()
  }, [typeDoc, format, acteur, dateDebut, dateFin]);

  const changementFiltre = () => {
    let results = messages;

    if (typeDoc !== '') {
      results = results?.filter((msg) => {
        return msg?.libelleDoc.toLowerCase().endsWith(typeDoc.toLowerCase());
      });
      setMessageListCourrante(results);
    } else {
      setMessageListCourrante(messages);
    }

    if (format.length > 0) {
      results = results?.filter((msg) => {
        msg.attachments = msg?.attachments?.filter((att) =>{
          return format.includes(att.format)
        });
        if (!format.includes('Image')) {
          return msg?.attachments?.find((att) => {
            return format.includes(att.format)
          })
        } else {
          return msg?.attachments?.find((att) => {
            return format.includes(att.format)
          }) || msg.pictures && msg?.pictures?.length > 0
        }
      });
      setMessageListCourrante(results);
    }

    if (acteur.length > 0) {
      results = results?.filter((msg) => {
        return acteur.includes(msg?.author)
      });
      setMessageListCourrante(results);
    }

    if (dateDebut != '') {
      results = results?.filter((msg) => {
        const dateCourante = moment(msg.dateReceive.substring(0, 10), 'DD/MM/YYYY').toDate();
        return dateCourante >= moment(dateDebut, 'YYYY-MM-DD').toDate()
      });
      setMessageListCourrante(results);
    }
    if (dateFin != '') {
      results = results?.filter((msg) => {
        const dateCourante = moment(msg.dateReceive.substring(0, 10), 'DD/MM/YYYY').toDate();
        return dateCourante <= moment(dateFin, 'YYYY-MM-DD').toDate()
      });
      setMessageListCourrante(results);
    }
  };

  return (
    <div>
      <Card className={classes.card}>
        <CardContent>
          {counter == 0 ?
              <Box className={classes.main} flexWrap="wrap" justifyContent="center">
                <Typography variant={'h3'}>Aucune pièce jointe</Typography>
              </Box> :
              <Box className={classes.main} flexWrap="wrap" justifyContent="center">
                <Typography variant={'h3'}>{getTitle()} ({counter})</Typography>
                {/* bloc filtre haut */}
                <div className={classes.containerPrincipal}>
                  <Typography className={classes.search}>Rechercher une pièce jointe</Typography>
                  <form noValidate autoComplete="off">
                    <div>
                      <TextFieldSelect
                        className={classes.textField}
                        id={'typeDoc'}
                        name={'typeDoc'}
                        label="Type de message"
                        marginDense={true}
                        value={valueTypeDoc()}
                        disabled={Object.keys(groupeTypeDoc).length === 1}
                        withEmptyItem={true}
                        emptyItemLabel={'Tous'}
                        itemsList={itemsListTypeDoc}
                        onChange={choixTypeDoc}
                      />

                      <span style={{display: 'inline-block'}}>
                        <FormLabel className={classes.labelDate}>Depuis le </FormLabel>
                        <TextField
                          id="dateDebut"
                          label=""
                          type="date"
                          variant="outlined"
                          margin="dense"
                          size="small"
                          placeholder="Saisir une date"
                          value={dateDebut}
                          className={classes.dateField}
                          InputProps={{
                            classes: {
                              root: classes.inputDate,
                            },
                          }}
                          inputProps={minDate() !== maxDate() ? {
                            min: minDate(),
                            max: maxDate(),
                          } : {}}
                          disabled={minDate() === maxDate()}
                          onChange={choixDateDebut}
                        />
                      </span>
                      <span style={{display: 'inline-block'}}>
                        <FormLabel className={classes.labelDate}>Jusqu&apos;au </FormLabel>
                        <TextField
                          id="dateFin"
                          label=""
                          type="date"
                          variant="outlined"
                          margin="dense"
                          size="small"
                          placeholder="Saisir une date"
                          value={dateFin}
                          className={classes.dateField}
                          InputProps={{
                            classes: {
                              root: classes.inputDate,
                            },
                          }}
                          inputProps={minDate() !== maxDate() ? {
                            min: minDate(),
                            max: maxDate(),
                          } : {}}
                          disabled={minDate() === maxDate()}
                          onChange={choixDateFin}
                        />
                      </span>
                      <span style={{display: 'inline-block'}}>
                        <Tooltip disableInteractive title="Réinitialiser la recherche">
                          <IconButton onClick={initFiltre}>
                            <RefreshIcon classes={{root: classes.iconRoot}} />
                          </IconButton>
                        </Tooltip>
                      </span>
                    </div>
                  </form>
                </div>
                {/* bloc filtre gauche */}
                <Grid container>
                  <Grid item xs={3} md={4} className={classes.gridItemLeft}>
                    <FormControl component="fieldset" className={classes.radioGroupe}>
                      <Typography style={{color: '#7c7c7c'}}>Emetteur</Typography>
                      <RadioGroup name="acteur" value={acteur}>
                        {Object.keys(groupeActeur).length === 1 ?
                          Object.keys(groupeActeur).map((key) => {
                            return (
                              <FormControlLabel key={key} className={classes.formLabel}
                                control={
                                  <Checkbox checked={true} disabled color="secondary"
                                    classes={{root: classes.checkboxRoot, checked: classes.checkboxChecked}}
                                    value={key.trim()} onChange={choixActeur}
                                  />
                                }
                                label={key.trim()}
                              />
                            )
                          }) :
                          Object.keys(groupeActeur).map((key) => {
                            return (
                              <FormControlLabel key={key} className={classes.formLabel}
                                control={
                                  <Checkbox color='default' checked={acteur.includes(key)}
                                    classes={{root: classes.checkboxRoot, checked: classes.checkboxChecked}}
                                    value={key.trim()} onChange={choixActeur}
                                  />
                                }
                                label={key.trim()}
                              />
                            )
                          })
                        }
                      </RadioGroup>
                    </FormControl>
                    <FormControl component="fieldset" className={classes.radioGroupe}>
                      <Typography style={{color: '#7c7c7c'}}>Format</Typography>
                      <FormGroup>
                        {groupeFormat.length === 1 ?
                          groupeFormat.map((key) => {
                            return (
                              <FormControlLabel key={key} className={classes.formLabel}
                                control={
                                  <Checkbox checked={true} disabled color="secondary"
                                    classes={{root: classes.checkboxRoot, checked: classes.checkboxChecked}}
                                    value={key.trim()} onChange={choixFormat}
                                  />
                                }
                                label={key.trim()}
                              />
                            )
                          }) :
                          groupeFormat.map((key) => {
                            return (
                              <FormControlLabel key={key} className={classes.formLabel}
                                control={
                                  <Checkbox color='default' checked={format.includes(key)}
                                    classes={{root: classes.checkboxRoot, checked: classes.checkboxChecked}}
                                    value={key.trim()} onChange={choixFormat}
                                  />
                                }
                                label={key.trim()}
                              />
                            )
                          })
                        }
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  {/* bloc resultat droite */}
                  <Grid item xs={9} md={8} style={{marginTop: '20px', paddingRight: '10px'}}>
                    {messageListCourrante && messageListCourrante.length > 0 &&
                      messageListCourrante.map((message) => {
                        const getItem = () => {
                          if (messageSelected != '-1') {
                            return [messageSelected]
                          } else {
                            return [messageListCourrante.at(0)!.id]
                          }
                        };
                        return (
                          <div key={message.id} id={message.id} style={{marginBottom: '30px'}}>
                            <MessageBloc message={message} itemSelected={getItem()}/>
                          </div>
                        )
                      })
                    }
                    {!messageListCourrante || messageListCourrante.length === 0 &&
                      <Typography style={{textAlign: 'center'}}>Aucun résultat</Typography>
                    }
                  </Grid>
                </Grid>
              </Box>
          }
          <ScrollTop/>
        </CardContent>
      </Card>
    </div>
  );
};
