import React, {useContext, useEffect, useState} from 'react';
import {Typography} from '@mui/material';
import {ButtonBlue} from '../../../common/buttons/ButtonBlue';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {TextFieldMultiline} from '../../../common/formsComponents/TextFiledMultiline';
import {customTheme} from '../../../../common/GlobalTheme';
import {Motif} from './Motif';
import {Attachments} from '../../components/Attachment/FilesDragAndDrop';
import {postSRPForm} from '../../../../api/message';
import {UserContext} from '../../../../context/UserContext';
import {useMissionContext} from '../../../../context/MissionContext';
import {FormModel} from '../../../../model/forms/FormModel';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {CommonForm} from '../../common/CommonForm';

/** Design */
type StylesProps = { showPjForm: boolean, isLoading: boolean }
const styles = (theme: Theme, props: StylesProps) => ({
  body: {
    height: 'calc(100% - 90px)',
    padding: '20px 20px 0px',
  },
  addMotif: {
    width: '100%',
    textAlign: 'right' as const,
    marginBottom: '20px',
  },
  motifsContainer: {
    'height': 'calc(100% - 253px)', // 175 + 15 + 58 + 5 = 253
    'overflowY': 'auto' as const,
    'padding': '0px 10px 5px 5px',
    /* Firefox */
    'scrollbarWidth': 'thin' as const,
    'scrollbarColor': '#c1c1c1 #f1f1f1',
    /* Chrome */
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
      borderRadius: '40px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#c1c1c1',
      borderRadius: '40px',
      border: '1px solid transparent',
      backgroundClip: 'padding-box',
    },
  },
  message: {
    marginTop: '25px',
  },
  error: {
    fontSize: '12px',
    color: 'red',
  },
});

const MOTIFS_NB_MAX = 9;

export const SRPForm = (props: FormModel) => {
  const {destinataire} = props;
  const {user} = useContext(UserContext);
  const {mission} = useMissionContext();
  const nomCompteAssistance = sessionStorage.getItem('nomCompteAssistance');
  const initialStatePj = {
    attachments: [],
    isValid: true,
  };
  const [expanded, setExpanded] = useState('panel-1');
  const [isDisabledButton, setIsDisabledButton] = useState(false);
  const [motifsTab, setMotifsTab] = useState([{id: 1, codeMotif: '', onErrorCodeMotif: false, detail: ''}]);
  const [message, setMessage] = useState('');
  const [piecesJointesList, setPiecesJointesList] = useState<Attachments>(initialStatePj);
  const [showPjForm, setShowPjForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseReturn, setResponseReturn] = useState(0);
  const classes = useEmotionStyles(styles, {showPjForm, isLoading});

  const listRefAccordion = React.useRef<Array<HTMLDivElement | null>>([]);

  useEffect(() => {
    listRefAccordion.current = listRefAccordion.current.slice(0, motifsTab.length);
    listRefAccordion.current[motifsTab.length - 1]?.scrollIntoView()
  }, [motifsTab]);

  const libelleLink = (!piecesJointesList || piecesJointesList.attachments.length === 0) ?
    'Ajouter des pièces jointes' :
    'Gérer les pièces jointes (' + piecesJointesList.attachments.length + ')';

  const onUpload = (attachments: Attachments) => {
    setShowPjForm(!showPjForm);
    setPiecesJointesList(attachments)
  };

  const onCloseAttachmentForms = () => {
    const result = window.confirm('Si vous n\'avez pas enregistré vos modifications, elles seront perdues.\n' + '\n' +
      'Souhaitez-vous continuer ?');
    if (result) {
      setShowPjForm(!showPjForm)
    }
  };

  const handleError = (tab = motifsTab) => {
    const motifsTabCopy = [...tab];
    motifsTabCopy.forEach((motif) =>{
      motif.onErrorCodeMotif = !motif.codeMotif
    });
    setMotifsTab(motifsTabCopy);

    return motifsTabCopy.filter((motif) =>
      motif.onErrorCodeMotif,
    ).length > 0
  };

  const handleClickAddMotif = () => {
    const isError = handleError();
    if (motifsTab.length >= MOTIFS_NB_MAX) {
      const result = window.confirm('Vous avez atteint le nombre maximum de motifs');
      if (result) {
        setExpanded(``)
      }
    } else if (isError) {
      const motifError = motifsTab.find((motif ) => motif.onErrorCodeMotif);
      setExpanded(`panel-${motifError?.id}`);
      setIsDisabledButton(true);
    } else {
      const newMotif = {id: motifsTab.length ? motifsTab[motifsTab.length-1].id + 1 : 1, codeMotif: '',
        onErrorCodeMotif: false, detail: ''};
      setMotifsTab([...motifsTab, newMotif]);
      setExpanded(`panel-${newMotif.id}`)
    }
  };

  const handleChangeMotif = (event: React.ChangeEvent<HTMLInputElement>, motifId: number) => {
    const motifsTabCopy = [...motifsTab];
    motifsTabCopy.forEach((motif) => {
      if (motif.id === motifId) {
        motif.codeMotif = event.target.value;
        motif.onErrorCodeMotif = false
      }
    });
    setMotifsTab(motifsTabCopy);
    setIsDisabledButton(false);
  };

  const handleChangeDetail = (event: React.ChangeEvent<HTMLInputElement>, motifId: number) => {
    const motifsTabCopy = [...motifsTab];
    motifsTabCopy.forEach((motif) => {
      if (motif.id === motifId) {
        motif.detail = event.target.value
      }
    });
    setMotifsTab(motifsTabCopy)
  };

  const handleDeleteMotif = (e: React.MouseEvent<HTMLElement>, motifId: number) => {
    const motifsTabCopy = [...motifsTab].filter((element) =>
      motifId != element.id,
    );
    motifsTabCopy.forEach((motif, index) => {
      motif.id = index + 1
    });
    const isError = handleError(motifsTabCopy);
    setIsDisabledButton(isError);
    if (isError) {
      const motifError = motifsTabCopy.find((motif ) => motif.onErrorCodeMotif);
      setExpanded(`panel-${motifError?.id}`);
    } else {
      setExpanded('');
    }
    setMotifsTab([...motifsTabCopy]);
  };

  const handleChangePanel = (panel: string) => {
    setExpanded(expanded !== panel ? panel : '')
  };

  const buildPayLoad = () => {
    const payLoad = new FormData();
    const piecesJointes = piecesJointesList.attachments.map((attachment, index) => {
      payLoad.append(`file-${index}`, attachment.file);
      return {
        id: `file-${index}`,
        reference: attachment.libelle,
        type: attachment.type,
        nature: attachment.nature,
        version: attachment.version,
      };
    });

    const formData = {
      scenario: mission?.scenario,
      codeAbonneEmeteur: user.codeAbo,
      codeAbonneDestinataire: destinataire?.codeAbonne,
      codeGTA: mission?.codeGTA,
      codeService: user.service,
      numClient: user.numClient,
      numeroAssure: mission?.societaireDTO?.numero,
      referenceSinistre: mission?.infoMissionDTO?.referenceSinistre,
      numMission: mission?.infoMissionDTO?.numeroMission,
      dateEffetPolice: mission?.infoMissionDTO?.dateEffetPolice,
      codeGestionnaireSinistre: mission?.infoMissionDTO?.codeGestionnaireSinistre,
      nomGestionnaireSinistre: mission?.infoMissionDTO?.nomGestionnaireSinistre,
      coordonneesCommunicationGestionnaire: mission?.infoMissionDTO?.coordonneesCommunicationGestionnaire,
      listSollicitationsRep: motifsTab.map((motif) => {
        return {motif: motif.codeMotif, detail: motif.detail};
      }),
      commentaire: message,
      userCompte: user.userCompte,
      nomCompteAssistance: nomCompteAssistance ?? '',
    };

    payLoad.append('metadata', new Blob([JSON.stringify({...formData, piecesJointes: piecesJointes})],
        {type: 'application/json'}));
    return payLoad;
  };

  const handleSubmit = () => {
    const isError = handleError();
    setIsDisabledButton(isError);
    if (!isError) {
      setIsLoading(true);
      const payload = buildPayLoad();
      postSRPForm(payload)
          .then((response) => {
            setResponseReturn(response.status);
          })
          .catch((error) => {
            if (error.response) {
              setResponseReturn(error.response.status);
            } else {
              setResponseReturn(-1);
            }
          })
          .finally(() => {
            setIsLoading(false)
          })
    }
  };

  const formName = 'srp';

  return (
    <CommonForm
      idForm={formName}
      idTitre={formName}
      titre={'Ajouter une sollicitation'}
      libelleEnvoye={'Votre message a bien été envoyé'}
      onClose={props.onClose}
      onCloseAndRefresh={props.onCloseAndRefresh}
      responseReturn={responseReturn}
      content={(responseReturn <= 0 && !showPjForm) ?
        <div className={classes.body}>
          <div className={classes.addMotif}>
            <ButtonBlue id={'boutonAjoutMotif'} icon={<AddCircleIcon/>} libelle={'Ajouter un motif'}
              onClick={handleClickAddMotif} disabled={isDisabledButton}/>
          </div>
          <div className={classes.motifsContainer}>
            {motifsTab.map((motif, index) => {
              const keyDiv = `${index}`
              return (
                <div key={keyDiv} ref={(element) => listRefAccordion.current[index] = element}>
                  <Motif nbMotifs={motifsTab.length} id={motif.id} codeMotif={motif.codeMotif}
                    onErrorCodeMotif={motif.onErrorCodeMotif} detail={motif.detail}
                    expanded={expanded === `panel-${motif.id}`}
                    onChangePanel={(panel) => handleChangePanel(panel)}
                    handleChangeMotif={(e) => handleChangeMotif(e, motif.id)}
                    handleChangeDetail={(e) => handleChangeDetail(e, motif.id)}
                    onDelete={(e) => handleDeleteMotif(e, motif.id)}/>
                </div>
              )
            })}
          </div>
          <div className={classes.message}>
            <Typography style={customTheme.styledTypography.libelle}>Votre message</Typography>
            <TextFieldMultiline
              id={'message'}
              marginDense={true}
              value={message}
              rows={4}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          {responseReturn === -1 &&
            <Typography className={`${classes.error}`}>
              Échec de l&apos;envoi du message. Le contenu de votre formulaire n&apos;est pas valide.
            </Typography>
          }
        </div> : <></>
      }
      showPjForm={showPjForm}
      commonAttachmentsFormProps={{
        piecesJointesList: piecesJointesList,
        onUpload: onUpload,
        onCloseAttachmentForms: onCloseAttachmentForms,
      }}
      withoutPj={false}
      isLoading={isLoading}
      handleClick={handleSubmit}
      showFooter={showPjForm}
      libelleLink={libelleLink}
      errorMessageField={isDisabledButton}
      setShowPjForm={() => setShowPjForm(!showPjForm)}
    />
  )
};
