import React, {useContext, useEffect, useState} from 'react';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {AppBar, Card, CardContent, Grid, Tab} from '@mui/material';
import {Theme} from '@emotion/react';
import {IndicateursSuivi} from '../../rechercheDossier/components/indicateurs/IndicateursSuivi';
import DocumentsSearchResult from '../../rechercheDocuments/components/DocumentsSearchResult';
import useHasRole from '../../../hook/useHasRole';
import {permittedRolesIndicateurs} from '../../../constantes/roles/Roles';
import {RechercheDocuments} from './RechercheDocuments';
import {DocumentSearchModel} from '../../../model/rechercheDocument/DocumentSearchModel';
import {UserContext} from '../../../context/UserContext';

const styles = (theme: Theme) => ({
  appBar: {
    boxShadow: 'none',
    borderRadius: '8px 8px 0 0',
    backgroundColor: theme.palette.white.main,
  },
  tabs: {
    color: theme.palette.primary.main,
    borderRadius: '8px 8px 0 0',
  },
  tab: {
    'borderRadius': 0,
    '&.MuiTab-root': {
      'textTransform': 'none' as const,
      'textAlign': 'left' as const,
      'fontSize': '16px',
      'letterSpacing': '0px',
      'opacity': '1',
      'minHeight': '48px',
      'color': theme.palette.link.main,
      '&.Mui-selected': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.white.main,
      },
      '&:hover': {
        backgroundColor: theme.palette.white.main,
        color: 'inherit',
      },
      '&:disabled': {
        color: theme.palette.disabled.main,
      },
    },
    '&.Mui-selected': {},
  },
  tabPanelSearch: {
    padding: 0,
  },
  card: {
    borderRadius: '0 0 8px 8px',
    boxShadow: 'none',
    border: 'none',
    marginBottom: '16px',
  },
  tabPanel: {
    backgroundColor: theme.palette.white.main,
    borderRadius: '0 0 8px 8px',
  },
  cardResult: {
    marginBottom: '16px',
  },
});

const DocumentsSubTabs = () => {
  const classes = useEmotionStyles(styles);
  const [value, setValue] = React.useState('0');
  const hasRoleIndicateurs = useHasRole(permittedRolesIndicateurs);
  const [search, setSearch] = useState<DocumentSearchModel>({});
  const [showResults, setShowResults] = useState(false);
  const {user} = useContext(UserContext)

  useEffect(() => {
    setShowResults(false);
  }, [user.codeAbo]);

  const handleChange = (event: React.ChangeEvent<{}>, value: number) => {
    setShowResults(false);
    setValue(value.toString())
  };

  return (
    <TabContext value={value}>
      <AppBar position="static" className={classes.appBar}>
        <TabList
          onChange={handleChange}
          className={classes.tabs}
        >
          <Tab label="Recherche de documents" value="0" className={classes.tab}/>
          {hasRoleIndicateurs &&
            <Tab label="Documents en attente" value="1" className={classes.tab}/>
          }
        </TabList>
      </AppBar>

      <TabPanel value="0" className={classes.tabPanelSearch}>
        <Card className={classes.card}>
          <CardContent>
            <RechercheDocuments
              setShowResults={setShowResults}
              setSearch={setSearch}
            />
          </CardContent>
        </Card>

        {showResults &&
          <Card className={classes.cardResult}>
            <CardContent>
              <DocumentsSearchResult search={search} />
            </CardContent>
          </Card>
        }
      </TabPanel>

      <TabPanel value="1" className={classes.tabPanel}>
        <Grid item md={12}>
          <IndicateursSuivi/>
        </Grid>
      </TabPanel>
    </TabContext>
  );
};

export default DocumentsSubTabs;
