import React from 'react'
import {Link, Typography} from '@mui/material'
import {ButtonBlue} from '../buttons/ButtonBlue';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {customTheme} from '../../../common/GlobalTheme';
import {IAction} from '../../../constantes/Action';
import {useNavigate} from 'react-router';
import Keycloak from 'keycloak-js';

const ErrorIdigoImg = require('../../../images/icones/error-idigo.png');

interface GenericErrorProps {
  action?: IAction
  keycloak?: Keycloak
  errorMessage?: string | null
  detailMessage?: string
  isClientContact?: boolean
}

/** Design */
const styles = () => ({
  container: {
    width: '75%',
    margin: '35px auto',
    textAlign: 'center' as const,
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column' as const,
  },
  text: {
    color: customTheme.palette.primary.main,
  },
  email: {
    color: customTheme.palette.link.main,
    textDecoration: 'none' as const,
    margin: '12px 0px',
  },
  button: {
    margin: '24px 0px',
  },
});

export const GenericError = (props: GenericErrorProps) => {
  const classes = useEmotionStyles(styles);
  const {action, keycloak, errorMessage, detailMessage, isClientContact} = props;
  const navigate = useNavigate();

  const getAction = () => {
    switch (action?.id) {
      case 'boutonDeconnexion':
        sessionStorage.clear();
        localStorage.removeItem('isTraked');
        keycloak?.logout();
        break;
      case 'boutonRetourPortail':
        navigate('/extranet/portail-sinapps-auto');
        break;
      default:
        return false
    }
  };

  return (
    <div className={classes.container}>
      <img src={ErrorIdigoImg} alt={'logo-warning'}/>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <div className={classes.content}>
          <Typography className={classes.text}>
            {errorMessage ?? 'Une erreur est survenue, veuillez contacter notre service d\'assistance.'}
          </Typography>
          {detailMessage &&
            <Typography className={classes.text}>
              {detailMessage}
            </Typography>}
          {!isClientContact &&
            <div className={classes.content}>
              <Link
                className={classes.email}
                href={`mailto:assistance@darva.com?subject=Erreur sur l'application Sinapps Auto Extranet`}
              >
                assistance@darva.com
              </Link>
              <Typography className={classes.text}>
                0 820 030 031
              </Typography>
              <Typography variant={'caption'} className={classes.text}>
                L&apos;assistance DARVA est disponible du lundi au vendredi de 8h à 18h.
              </Typography>
            </div>
          }
          <div>
            {action &&
              <ButtonBlue
                id={action.id}
                className={classes.button}
                libelle={action.libelle}
                onClick={getAction}
              />
            }
          </div>
        </div>
      </div>
    </div>
  )
};
