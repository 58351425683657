import React from 'react';
import {UserContext} from '../../../context/UserContext';

export interface IAttachment {
  id: number,
  nature: string,
  reference: string,
  descriptif: string,
  file?: File,
  completed?: boolean,
  onError?: boolean,
  onTypeError?: boolean,
}

export interface IAttachments {
  alerteId: number,
  attachments: IAttachment[]
  isValid: boolean
  error?: ErrorAttachments
}
export interface ErrorAttachments {
  type: string,
  message: string,
}

export interface AlerteProps {
  id: number,
  famille: string,
  onErrorFamille?: boolean,
  type: string,
  onErrorType?: boolean,
  justificatifs: IAttachment[],
  commentaire: string,
}

interface DataProps {
  scenario: string,
  codeAbonneEmeteur: string,
  codeAbonneDestinataire: string,
  codeGTA: string,
  codeService: string,
  numClient: string,
  numeroAssure?: string,
  referenceSinistre?: string,
  numMission?: string,
  dateEffetPolice?: string,
  codeGestionnaireSinistre?: string,
  nomGestionnaireSinistre?: string,
  niveauCriticite: string,
  qualificationAlerte: string,
  scoring: string,
  montantDu: string,
  montantReclamation: string,
  reclamationIntegrale: string,
  commentaire: string,
  alertesTab: AlerteProps[],
  intermediaire: {
    nom: string,
    adresse1: string,
    adresse2: string,
    adresse3: string,
    codePostal: string,
    localite: string,
    pays: string,
    numeroDossier: string,
  },
  userCompte: string,
  nomCompteAssistance: string,
}

export const useInitialStateFormFDV = () => {
  const {user} = React.useContext(UserContext);
  const nomCompteAssistance = sessionStorage.getItem('nomCompteAssistance');

  const data: DataProps = {
    scenario: '',
    codeAbonneEmeteur: user.codeAbo,
    codeAbonneDestinataire: '',
    codeGTA: user.codeGTA,
    codeService: user.service,
    numClient: user.numClient,
    numeroAssure: '',
    referenceSinistre: '',
    numMission: '',
    dateEffetPolice: '',
    codeGestionnaireSinistre: '',
    nomGestionnaireSinistre: '',
    niveauCriticite: '',
    qualificationAlerte: '',
    scoring: '',
    montantDu: '',
    montantReclamation: '',
    reclamationIntegrale: '',
    commentaire: '',
    alertesTab: [],
    intermediaire: {
      nom: '',
      adresse1: '',
      adresse2: '',
      adresse3: '',
      codePostal: '',
      localite: '',
      pays: '',
      numeroDossier: '',
    },
    userCompte: user.userCompte,
    nomCompteAssistance: nomCompteAssistance ?? '',
  };

  return data
};
