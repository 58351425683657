import React, {useEffect, useState} from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../common/useEmotionStyles';

/** Design */
const styles = (theme: Theme) => ({
  goTopHidden: {
    display: 'none',
  },
  goTopVisible: {
    display: 'block',
    position: 'fixed' as const,
    height: '40px',
    width: '40px',
    bottom: '50px',
    right: '50px',
    cursor: 'pointer',
    backgroundColor: theme.palette.white.main,
    borderRadius: '20px',
    border: 'none',
    [theme.breakpoints.down('md')]: {
      left: '50px',
    },
  },
  iconRoot: {
    margin: '0px',
  },
  iconColor: {
    'fill': theme.palette.link.main,
    '&:hover': {
      fill: theme.palette.primary.main,
    },
  },
})

export const ScrollTop = () => {
  const classes = useEmotionStyles(styles);
  const [showGoTop, setShowGoTop] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', handleVisibleButton)
    return () => window.removeEventListener('scroll', handleVisibleButton)
  })

  const handleVisibleButton = () => {
    const position = window.scrollY
    position > 400 ? setShowGoTop(true) : setShowGoTop(false)
  }

  const handleScrollTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  return (
    <div>
      <button
        id={'boutonScrollTop'}
        className={`${!showGoTop ? classes.goTopHidden : classes.goTopVisible}`}
        onClick={handleScrollTop}
      >
        <ExpandLessIcon className={classes.iconColor} classes={{root: classes.iconRoot}}/>
      </button>
    </div>
  )
}
