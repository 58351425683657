import React from 'react';
import {Typography} from '@mui/material';
import {ButtonBlue} from '../../../common/buttons/ButtonBlue';
import {useNavigate, useParams} from 'react-router';
import {RetourDossier} from '../../../common/buttons/RetourDossier';
import {InformationAssure} from '../../../../model/priseRDV/RDVModel';
import {PartenaireProps} from '../../../../model/priseRDV/PartenaireModel';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {IToken} from '../../../../model/detailsDossier/TokenModel';
import {ID_BOUTON_RETOUR} from '../../../../constantes/ids/Ids';

const SendIcon = require('../../../../images/icones/icn-email-send.png');

interface RdvConfirmeProps {
  assure: InformationAssure,
  reparateur: PartenaireProps,
  isUpdateRdv?: boolean,
}

/** Design */
const styles = (theme: Theme) => ({
  container: {
    width: '75%',
    margin: '35px auto',
    textAlign: 'center' as const,
  },
  bold: {
    fontWeight: 'bold',
  },
  button: {
    marginTop: '50px',
  },
});

export const RdvConfirme = ({assure, reparateur, isUpdateRdv}: RdvConfirmeProps) => {
  const classes = useEmotionStyles(styles);
  const navigate = useNavigate();
  const fromPath = sessionStorage.getItem('fromPath');
  const params = useParams<keyof IToken>() as IToken;
  const token = params.token;

  const autoAccept = true;

  const isSMS = assure.telephone?.length;
  const isEmail = assure.email?.length;

  const canauxComm = () => {
    if (isSMS && isEmail) {
      return 'un SMS et un EMAIL';
    } else if (isSMS) {
      return 'un SMS';
    } else {
      return 'un EMAIL';
    }
  };

  const libelleRecap = () => {
    if (autoAccept) {
      return isSMS && isEmail ? 'récapitulatifs ' : 'récapitulatif '
    }
    return 'de confirmation '
  }

  return (
    <div className={classes.container}>
      <img src={SendIcon} alt={'logo-envoye'}/>
      {isUpdateRdv &&
        <Typography variant={'h3'}>Votre rendez-vous a bien été modifié.</Typography>
      }
      {!isUpdateRdv &&
        <Typography variant={'h3'}>
          {autoAccept ? 'Rendez-vous posé chez le réparateur !' : 'Demande de rendez-vous envoyée au réparateur !'}
        </Typography>
      }

      <div style={{display: 'flex', justifyContent: 'center'}}>
        <Typography>L&apos;assuré&nbsp;</Typography>
        <Typography className={classes.bold}>{assure.prenom} {assure.nom}&nbsp;</Typography>
        <Typography>recevra&nbsp;</Typography>
        <Typography className={classes.bold}>{canauxComm()}&nbsp;</Typography>
        <Typography>
          {libelleRecap()} prochainement de la part du réparateur&nbsp;
        </Typography>
        <Typography className={classes.bold}>{reparateur.raisonSociale.nom}.</Typography>
      </div>

      {!isUpdateRdv && fromPath?.includes('extranet/dossiers/mission/') &&
        <RetourDossier classNameButton={classes.button} isButton={true} token={token}/>
      }
      {!isUpdateRdv && !fromPath?.includes('extranet/dossiers/mission/') &&
        <ButtonBlue
          id={ID_BOUTON_RETOUR}
          className={classes.button}
          libelle={'Retour sur le portail Sinapps Auto'}
          onClick={() => {
            sessionStorage.removeItem('searchRdv');
            navigate('/extranet/portail-sinapps-auto');
          }}
        />
      }
      {isUpdateRdv &&
        <ButtonBlue
          id={ID_BOUTON_RETOUR}
          className={classes.button}
          libelle={'Retour au rendez-vous'}
          onClick={() => {
            sessionStorage.removeItem('detailRdv');
            navigate('/extranet/dossiers/prise-rdv/rechercher-rdv');
          }}
        />
      }
    </div>
  )
};
