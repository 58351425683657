import {ButtonLink} from '../../../common/buttons/ButtonLink';
import React from 'react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';

interface IProps {
  id: string
  libelle: string
  onClick: () => void
}

/** Design */
const styles = (theme: Theme) => ({
  linkColor: {
    color: theme.palette.primary.main,
  },
});

export const ButtonLinkMenu = (props: IProps) => {
  const classes = useEmotionStyles(styles);
  const {id, libelle, onClick} = props;

  return (
    <ButtonLink
      id={id}
      className={classes.linkColor}
      isLibelleBold={true}
      libelle={libelle}
      onClick={onClick}
    />
  )
};
