import {IResultLine} from '../model/model';
import {TokenEdiModel} from '../../../../model/common/TokenEdiModel';
import {UserContextType} from '../../../../context/UserContext';

export const mapperEDI = (user: UserContextType, doc: IResultLine): TokenEdiModel => {
  return {
    idDocument: doc.idDoc,
    search: {
      referenceSinistre: doc.claim,
      numeroMission: doc.mission,
      codeGTA: doc.gtaCode,
      numClient: user.numClient,
      userCompte: user.userCompte,
      codeAbonne: user.codeAbo,
      codeService: user.service,
      immatriculation: doc.immatriculation,
    },
    type: doc.docType,
    ssType: doc.docSousType,
    isnIdentifier: doc.identifier,
  } as TokenEdiModel;
};
