import React, {useEffect, useState} from 'react';
import {FieldConfig, FieldHookConfig, useField} from 'formik';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {MenuItem, TextField, Typography} from '@mui/material';
import {Theme} from '@emotion/react';
import {getIdFromName} from '../../utils/Utils';

interface InputSelectFieldProps extends FieldConfig{
    label: string
    itemsList: React.ReactElement[]
    mandatory?: boolean
    disabled?: boolean
    className?: string
    emptyItemLabel?: string
    tooltip?: React.ReactElement
}

const styles = (theme: Theme) => ({
  container: {
    width: '360px',
    marginRight: '24px',
  },
  fieldWithTooltip: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
  textfield: {
    backgroundColor: theme.palette.white.main,
  },
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  red: {
    color: 'red',
  },
})

const InputSelectField = ({label, ...props}: InputSelectFieldProps) => {
  const classes = useEmotionStyles(styles);
  const [field, meta] = useField<string>(props as FieldHookConfig<string>);
  const {tooltip, itemsList, mandatory, disabled, className, emptyItemLabel} = props;
  const [list, setList] = useState<React.ReactElement[]>([]);

  useEffect(() => {
    if (emptyItemLabel) {
      setList([
        <MenuItem key={'empty'} value={''}>
          <em>- {emptyItemLabel} -</em>
        </MenuItem>, ...itemsList]);
    } else {
      setList(itemsList);
    }
  }, [itemsList]);

  return (
    <div className={className ?? classes.container}>
      <div className={classes.fieldWithTooltip}>
        <Typography className={classes.libelle}>
          {label} {mandatory && <span className={classes.red}>*</span>}
        </Typography>
        {tooltip}
      </div>
      <TextField
        id={getIdFromName(field.name)}
        fullWidth
        select
        margin='dense'
        size='small'
        disabled={Boolean(disabled)}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
        {...field}
        InputProps={{
          classes: {
            root: classes.textfield,
          },
        }}
        SelectProps={{
          MenuProps: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          },
        }}
      >
        {list}
      </TextField>
    </div>
  );
};

export default InputSelectField;
