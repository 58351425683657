import React, {useEffect, useState} from 'react';
import {ButtonBlue} from '../../common/buttons/ButtonBlue';
import {ReactComponent as CheckIcon} from '../../../images/icones/icn-check.svg';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';
import {Form, Formik, FormikValues} from 'formik';
import {initialValues} from './initialValues';
import {schemaValidation} from './schema/schema';
import {Dates} from '../../common/recherche/Dates';
import {Card, CardContent, Typography} from '@mui/material';
import {SearchFields} from './components/SearchFields';
import moment from 'moment';
import {EchangeSearchModel} from '../../../model/rechercheEchange/EchangeSearchModel';
import {UserContext} from '../../../context/UserContext';
import EchangesSearchResult from '../../rechercheEchange/components/EchangesSearchResult';
import {ButtonLink} from '../../common/buttons/ButtonLink';
import {ReactComponent as ResetIcon} from '../../../images/icones/Reset.svg';
import CheckBoxField from '../multiStepsForm/fields/CheckBoxField';
import {FormikErrors} from 'formik/dist/types';
import {
  DetailsEchangesDocsParTypeAnomalies,
} from '../../rechercheEchange/components/DetailsEchangesDocsParTypeAnomalies';
import {ListeDocsEchange} from '../../rechercheEchange/components/ListeDocsEchange';
import {DetailsDocsParType} from '../../rechercheEchange/components/docsParType/DetailsDocParType';
import {InfosEchange} from '../../../model/rechercheEchange/InfosEchange';
import {InfosTypesDocs} from '../../../model/rechercheEchange/InfosTypesDocs';

const styles = (theme: Theme) => ({
  card: {
    marginBottom: '16px',
  },
  form: {
    position: 'relative' as const,
  },
  libelle: {
    marginBottom: '16px',
  },
  flexBottom: {
    display: 'flex',
    alignItems: 'end',
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  containerIconReset: {
    position: 'absolute' as const,
    top: 0,
    right: 0,
  },
  iconReset: {
    fill: theme.palette.link.main,
    marginRight: '8px',
  },
  button: {
    textAlign: 'right' as const,
  },
  marginTop24: {
    marginTop: '24px',
  },
  iconValidate: {
    fill: theme.palette.white.main,
    marginRight: '8px',
  },
  cardResult: {
    marginBottom: '16px',
  },
});

// Liste des composants pour la recherche d'échanges
export const ECHANGES_COMPONENTS = {
  RESULTATS: 'RESULTATS',
  LISTE_DOCS_ECHANGE: 'LISTE_DOCS_ECHANGE',
  DETAILS_ECHANGE_ET_DOCS_PAR_TYPE_ET_ANOMALIES: 'DETAILS_ECHANGE_ET_DOCS_PAR_TYPE_ET_ANOMALIES',
  DETAILS_ECHANGE_ET_DETAILS_PAR_TYPE_DOC: 'DETAILS_ECHANGE_ET_DETAILS_PAR_TYPE_DOC',
};

export const RechercheEchanges = () => {
  const classes = useEmotionStyles(styles);
  const {user} = React.useContext(UserContext);

  // State
  const [search, setSearch] = useState<EchangeSearchModel>({});
  const [showResults, setShowResults] = useState(false);
  const [showRefreshButton, setShowRefreshButton] = useState(false);
  const [component, setComponent] = useState<string>(ECHANGES_COMPONENTS.RESULTATS);
  const [idEchangeSelected, setIdEchangeSelected] = useState<string>('');
  const [numeroEchangeSelected, setNumeroEchangeSelected] = useState<string>('');
  const [actionEchangeSelected, setActionEchangeSelected] = useState<string>('');
  const [isEchangeRADSelected, setIsEchangeRADSelected] = useState<boolean>(false);
  const [typeDocSelected, setTypeDocSelected] = useState<string>('');
  const [sousTypeDocSelected, setSousTypeDocSelected] = useState<string>('');
  const [lastPaginationCall, setLastPaginationCall] = useState<any>({});
  const [pageSize, setPageSize] = useState<string>('50');

  useEffect(() => {
    setShowResults(false);
  }, [user.codeAbo]);

  const onSubmit = (values: FormikValues) => {
    callbackComponent(ECHANGES_COMPONENTS.RESULTATS,
        {
          idEchange: '',
          numeroEchange: '',
          isEchangeRAD: false,
        } as InfosEchange,
        {
          typeDoc: '',
          sousTypeDoc: '',
        } as InfosTypesDocs,
        {},
        pageSize);
    setShowResults(true);
    setShowRefreshButton(true);
    setSearch({
      numClient: user.numClient,
      codeAbo: user.codeAbo,
      codeService: user.service,
      etat: values.etat,
      numEchange: values.numeroEchange,
      dateDebut: values.dateDebut,
      dateFin: values.dateFin,
      action: values.action,
    });
  };

  const handleEchangesDepuisHier = (values: FormikValues,
      setFieldValue: (field: string, value: any) => Promise<void | FormikErrors<FormikValues>>) => {
    if (!values.isEchangeDepuisHier) {
      setFieldValue('dateFin', moment().format('YYYY-MM-DD'));
      setFieldValue('dateDebut', moment().subtract(1, 'days').format('YYYY-MM-DD'));
    } else {
      setFieldValue('dateDebut', '');
      setFieldValue('dateFin', '');
    }
  };

  const callbackComponent = (newComponent: string,
      infosEchange: InfosEchange,
      infosTypesDocs: InfosTypesDocs,
      lastPaginationCall?: any,
      lastPageSize?: string) => {
    setIdEchangeSelected(infosEchange.idEchange);
    setNumeroEchangeSelected(infosEchange.numeroEchange);
    setActionEchangeSelected(infosEchange.actionEchange ?? '');
    setIsEchangeRADSelected(infosEchange.isEchangeRAD ?? false);
    setTypeDocSelected(infosTypesDocs.typeDoc);
    setSousTypeDocSelected(infosTypesDocs.sousTypeDoc);
    setComponent(newComponent);
    setLastPaginationCall(lastPaginationCall ?? {});
    setPageSize(lastPageSize ?? pageSize);
  };

  return (
    <>
      <Card className={classes.card}>
        <CardContent>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={schemaValidation}
          >
            {({values, resetForm, setFieldValue}) =>
              <Form className={classes.form}>
                <Typography className={classes.libelle}>{'Lister les échanges'}</Typography>
                <div className={classes.flexBottom}>
                  <Dates disabled={values.isEchangeDepuisHier || values.numeroEchange != ''} />

                  <div className={classes.flexCenter}>
                    <CheckBoxField
                      name={'isEchangeDepuisHier'}
                      checked={values.isEchangeDepuisHier}
                      disabled={values.numeroEchange != ''}
                      onChange={() => handleEchangesDepuisHier(values, setFieldValue)}
                    />
                    <Typography>Lister les échanges depuis hier</Typography>
                  </div>
                </div>

                <SearchFields />

                {showRefreshButton &&
                  <div className={classes.containerIconReset}>
                    <ButtonLink
                      id={'bouton-reinitialiser'}
                      libelle={'réinitialiser'}
                      isLibelleUpperCase={true}
                      icon={<ResetIcon className={classes.iconReset}/>}
                      onClick={() => {
                        setShowResults(false)
                        setShowRefreshButton(false)
                        resetForm()
                      }}
                    />
                  </div>
                }

                <div className={`${classes.button} ${classes.marginTop24}`}>
                  <ButtonBlue
                    id={'bouton-valider'}
                    type={'submit'}
                    icon={<CheckIcon className={classes.iconValidate}/>}
                    libelle={'Valider la recherche'}
                  />
                </div>
              </Form>
            }
          </Formik>
        </CardContent>
      </Card>

      {showResults &&
        <>
          {component === ECHANGES_COMPONENTS.RESULTATS &&
            <Card className={classes.cardResult}>
              <CardContent>
                <EchangesSearchResult search={search} callbackComponent={callbackComponent}
                  pageSize={pageSize} lastPaginationCall={lastPaginationCall}/>
              </CardContent>
            </Card>
          }
          {component === ECHANGES_COMPONENTS.LISTE_DOCS_ECHANGE &&
            <ListeDocsEchange
              numeroEchangeSelected={numeroEchangeSelected}
              idEchangeSelected={idEchangeSelected}
              callbackComponent={callbackComponent}
              pageSize={pageSize}
              lastPaginationCall={lastPaginationCall}/>
          }
          {component === ECHANGES_COMPONENTS.DETAILS_ECHANGE_ET_DOCS_PAR_TYPE_ET_ANOMALIES &&
            <DetailsEchangesDocsParTypeAnomalies
              idEchange={idEchangeSelected}
              numeroEchange={numeroEchangeSelected}
              actionEchange={actionEchangeSelected}
              isEchangeRAD={isEchangeRADSelected}
              callbackComponent={callbackComponent}
              pageSize={pageSize}
              lastPaginationCall={lastPaginationCall}/>
          }
          {component === ECHANGES_COMPONENTS.DETAILS_ECHANGE_ET_DETAILS_PAR_TYPE_DOC &&
            <DetailsDocsParType
              idEchange={idEchangeSelected}
              numeroEchange={numeroEchangeSelected}
              actionEchange={actionEchangeSelected}
              isEchangeRAD={isEchangeRADSelected}
              typeDoc={typeDocSelected}
              sstypeDoc={sousTypeDocSelected}
              callbackComponent={callbackComponent}
              pageSize={pageSize}
              lastPaginationCall={lastPaginationCall}/>
          }
        </>
      }
    </>
  )
};
