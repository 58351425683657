import React, {ChangeEvent, useContext, useState} from 'react';
import {Autocomplete, TextField, Typography} from '@mui/material';
import {getAdresses} from '../../../../api/infogeo';
import {AdresseAutoCompleteProps} from '../../../../model/common/AdresseAutoCompleteModel';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {UserContext} from '../../../../context/UserContext';

export interface AutoCompleteAdresseProps {
  id: string,
  className?: string,
  classNameTextField?: string,
  label?: string,
  colorLabel?: string,
  fontWeightLabel?: number,
  border?: boolean,
  isMandatory: boolean,
  placeHolder?: string
  selectedAdresse: AdresseAutoCompleteProps,
  getOptionLabel: (adresse: AdresseAutoCompleteProps) => string,
  onError: boolean,
  errorMessage?: string,
  onChange: (event: ChangeEvent<{}>, value: any) => void,
  onFocus: (event: React.FocusEvent<HTMLInputElement>) => void,
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void,
}

type StylesProps = {
  colorLabel?: string,
  fontWeightLabel?: number
}

const styles = (theme: Theme, props: StylesProps) => ({
  textfield: {
    backgroundColor: theme.palette.white.main,
  },
  label: {
    color: props.colorLabel ?? theme.palette.secondary.dark2,
    fontWeight: props.fontWeightLabel ?? 0,
  },
  error: {
    color: 'red',
    fontSize: '12px',
  },
  notchedOutline: {
    border: 'none',
  },
});

const AutoCompleteAdresse = (props: AutoCompleteAdresseProps) => {
  const {id, className, classNameTextField, label, colorLabel, fontWeightLabel, border, isMandatory, placeHolder,
    selectedAdresse, getOptionLabel, onError, errorMessage, onChange, onFocus, onBlur} = props;
  const classes = useEmotionStyles(styles, {colorLabel, fontWeightLabel});
  const {user} = useContext(UserContext);
  const [adresses, setAdresses] = useState<AdresseAutoCompleteProps[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const notchedOutline = border !== undefined && !border ? {notchedOutline: classes.notchedOutline} : '';
  const inputPropsClasses = {
    root: classes.textfield,
    ...{...notchedOutline},
  };

  const renderInputAutoCompletion = (params: any) => {
    return <><TextField
      {...params}
      id='adresse'
      name='adresse'
      className={`${classNameTextField}`}
      margin='dense'
      size='small'
      variant='outlined'
      InputProps={{
        ...params.InputProps,
        classes: inputPropsClasses,
      }}
      error={onError}
      onFocus={onFocus}
      onBlur={onBlur}
      placeholder={placeHolder}
    />
    {onError && errorMessage &&
      <Typography id="component-error-text" className={classes.error}>
        {errorMessage}
      </Typography>
    }
    </>
  };

  const handleSearchAdresse = (event: ChangeEvent<{}>, value: string) => {
    if (value.length > 2 && !isLoading) {
      setIsLoading(true);
      getAdresses(value, user.numClient)
          .then((response) => {
            setAdresses(response);
            setIsLoading(false)
          }).catch((error) => {
            console.error(error)
          })
    } else {
      setAdresses([])
    }
  };

  return (
    <div className={className}>
      <Typography className={classes.label}>
        {label} {isMandatory && <span style={{color: 'red'}}>*</span>}
      </Typography>
      <Autocomplete
        id={id}
        autoHighlight
        loading={isLoading}
        loadingText='Chargement...'
        popupIcon={''}
        value={selectedAdresse}
        options={adresses}
        getOptionLabel={getOptionLabel}
        onChange={onChange}
        renderOption={(props, adresse: AdresseAutoCompleteProps) =>
          (<li {...props} key={props.id}>{adresse.nom}</li>)}
        onInputChange={handleSearchAdresse}
        renderInput={renderInputAutoCompletion}
        filterOptions={(options) => options}
        noOptionsText={'Aucun résultat trouvé'}
      />
    </div>
  );
};

export default AutoCompleteAdresse;
