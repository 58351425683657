import React, {ChangeEvent} from 'react';
import {AppBar, Box, Grid, Tab, Tabs} from '@mui/material'
import {SearchComponent} from './components/SearchComponent'
import {ResultComponent} from './components/ResultComponent'
import {PanelProps} from '../detailsDossier/components/Details';
import {customTheme} from '../../common/GlobalTheme';
import {
  permittedRolesDocuments,
  permittedRolesDossiers,
  permittedRolesEchanges,
} from '../../constantes/roles/Roles';
import useHasRole from '../../hook/useHasRole';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../common/useEmotionStyles';
import DocumentsSubTabs from '../forms/rechercheDocuments/DocumentsSubTabs';
import {RechercheEchanges} from '../forms/rechercheEchanges/RechercheEchanges';

const styles = (theme: Theme) => ({
  grid: {
    marginTop: '2%',
    marginLeft: '6%',
    marginRight: '6%',
  },
  appbar: {
    flexGrow: 1,
    backgroundColor: theme.palette.white.main,
    color: theme.palette.link.main,
    boxShadow: '0px 3px 6px ' + theme.palette.boxShadow.main,
    border: '1px solid ' + customTheme.palette.secondary.main,
    borderRadius: '8px',
  },
  tabs: {
    padding: '0rem',
    fontSize: '1rem',
    borderRadius: '8px',
  },
  tab: {
    'textTransform': 'none' as const,
    'fontSize': '16px',
    'letterSpacing': '0px',
    'opacity': '1',
    'minHeight': '48px',
    '&:disabled': {
      color: theme.palette.disabled.main,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
  tabPanel: {
    padding: '0px',
    marginTop: '30px',
  },
  message: {
    color: customTheme.palette.tertiary.main,
    fontSize: '20px',
    fontWeight: 'bold',
    letterSpacing: '0px',
    opacity: '1',
  },
  button: {
    marginTop: '32px',
  },
});

const TabPanel = (props: PanelProps) => {
  const classes = useEmotionStyles(styles);
  const {value, index, children} = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      style={{width: '100%'}}
    >
      {value === index && (
        <Box p={3} className={classes.tabPanel}>
          {children}
        </Box>
      )}
    </div>
  )
};

export const Recherche: React.FC = () => {
  const classes = useEmotionStyles(styles);
  const hasRoleDossiers = useHasRole(permittedRolesDossiers);
  const hasRoleDocuments = useHasRole(permittedRolesDocuments);
  const hasRoleEchanges = useHasRole(permittedRolesEchanges);

  const [tabValue, setTabValue] = React.useState(0);

  const handleChangeTabValue = (event: ChangeEvent<{}>, value: number) => {
    setTabValue(value)
  };

  const getIndexDocuments = () => {
    return hasRoleDossiers ? 1 : 0;
  };

  const getIndexEchanges = () => {
    if (hasRoleDossiers && hasRoleDocuments) {
      return 2;
    } else if (hasRoleDossiers || hasRoleDocuments) {
      return 1;
    } else {
      return 0;
    }
  };

  const a11yProps = (index: number) => {
    return {
      'id': `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  };

  return (
    <div className={classes.grid}>
      {(hasRoleDossiers || hasRoleDocuments || hasRoleEchanges) &&
        <Grid container>
          <>
            <AppBar position="static" className={classes.appbar}>
              <Tabs
                indicatorColor="primary" textColor="inherit"
                className={classes.tabs}
                value={tabValue}
                onChange={handleChangeTabValue}
                variant="fullWidth"
                TabIndicatorProps={{style: {display: 'none'}}}
              >
                {hasRoleDossiers &&
                  <Tab
                    className={classes.tab}
                    label="Dossiers"
                    {...a11yProps(0)}
                  />
                }
                {hasRoleDocuments &&
                  <Tab
                    className={classes.tab}
                    label="Documents"
                    {...a11yProps(getIndexDocuments())}
                  />
                }
                {hasRoleEchanges &&
                  <Tab
                    className={classes.tab}
                    label="Échanges"
                    {...a11yProps(getIndexEchanges())}
                  />
                }
              </Tabs>
            </AppBar>

            <TabPanel value={tabValue} index={0}>
              <>
                {hasRoleDossiers &&
                  <>
                    <Grid item md={12}>
                      <SearchComponent/>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <ResultComponent/>
                    </Grid>
                  </>
                }
              </>
            </TabPanel>
            <TabPanel value={tabValue} index={getIndexDocuments()}>
              <>
                {hasRoleDocuments &&
                  <DocumentsSubTabs/>
                }
              </>
            </TabPanel>
            <TabPanel value={tabValue} index={getIndexEchanges()}>
              <>
                {hasRoleEchanges &&
                  <RechercheEchanges />
                }
              </>
            </TabPanel>
          </>
        </Grid>
      }
    </div>
  )
};
