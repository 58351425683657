import React from 'react';
import {ReactComponent as IconClose} from '../../../../../../images/icones/icn-close.svg';
import {ReactComponent as IconAdd} from '../../../../../../images/icones/icn-add.svg';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../../../common/useEmotionStyles';

interface BlueTagProps {
  label: string
  selected: boolean
  handleClickTag: Function
}

const styles = (theme: Theme) => ({
  tag: {
    padding: '5px',
    borderRadius: '6px',
    height: 'min-content',
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    paddingRight: '10px',
    marginRight: '10px',
  },
  blueTag: {
    backgroundColor: '#B2E3F9',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  selectedBlueTag: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white.main,
    cursor: 'pointer',
  },
  iconTag: {
    marginRight: '5px',
    cursor: 'pointer',
  },
  icnClose: {
    fill: theme.palette.white.main,
  },
  icnAdd: {
    fill: theme.palette.primary.main,
  },
  pointerCursor: {
    cursor: 'pointer',
  },
});

const BlueTag = (props: BlueTagProps) => {
  const classes = useEmotionStyles(styles);
  const {label, selected, handleClickTag} = props;

  const handleClick = () => {
    handleClickTag();
  }

  return (
    <div
      className={`${classes.tag} ${selected ? classes.selectedBlueTag : classes.blueTag}`}
      aria-hidden={true}
      onClick={handleClick}
    >
      {selected ?
        <IconClose className={`${classes.iconTag} ${classes.icnClose}`}/> :
        <IconAdd className={`${classes.iconTag} ${classes.icnAdd}`}/>
      }
      <label className={classes.pointerCursor}>{label}</label>
    </div>
  );
};

export default BlueTag;
