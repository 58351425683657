import React, {useEffect, useState} from 'react';
import {Button, Modal, Slide, Typography} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {MenuDossier} from './MenuDossier';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';
import {customTheme} from '../../../../common/GlobalTheme';

interface IProps {
  className?: string
}

const styles = (theme: Theme) => ({
  btnMenuSmallScreen: {
    'position': 'fixed' as const,
    'top': '160px',
    'padding': '0px',
    'justifyContent': 'flex-start',
    '&:hover': {
      backgroundColor: 'initial',
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  menuSmallScreen: {
    backgroundColor: theme.palette.white.main,
    color: theme.palette.link.main,
    height: '100px',
    borderRadius: '0px 8px 8px 0px',
    boxShadow: '0px 3px 6px ' + theme.palette.boxShadow.main,
    padding: '5px',
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    marginRight: '0px',
    marginBottom: '5px',
  },
  typography: {
    fontSize: '12px',
  },
});

export const ButtonMenuDossier = (props: IProps) => {
  const classes = useEmotionStyles(styles);
  const {className} = props;

  // State
  const [isOpenMenuDossier, setIsOpenMenuDossier] = useState(false);

  // Permet de fermer la modale du menu Dossier si l'utilisateur dézoome jusqu'à atteindre la vue "normale"
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  const handleWindowSizeChange = () => {
    if (window.innerWidth >= customTheme.breakpoints.values.md) {
      setIsOpenMenuDossier(false);
    }
  };

  const slideContentMenuDossier = (
    <div>
      <MenuDossier onClose={() => setIsOpenMenuDossier(!isOpenMenuDossier)}/>
    </div>
  );

  return (
    <span className={className}>
      <Button
        className={classes.btnMenuSmallScreen}
        disableRipple
        onClick={() => setIsOpenMenuDossier(!isOpenMenuDossier)}
      >
        <div className={classes.menuSmallScreen}>
          <ArrowForwardIosIcon className={classes.icon}/>
          <Typography className={classes.typography}>Dossier</Typography>
        </div>
      </Button>

      <Modal open={isOpenMenuDossier}>
        <Slide in={isOpenMenuDossier} direction={'right'} timeout={800} mountOnEnter unmountOnExit>
          {slideContentMenuDossier}
        </Slide>
      </Modal>
    </span>
  )
};
