import React, {ChangeEvent, useEffect, useState} from 'react';
import {Autocomplete, FormControl, TextField, Typography} from '@mui/material';
import {getLocalites} from '../../../../api/infogeo';
import {ErrorFields} from '../PriseRDV';
import {LocaliteProps} from '../../../../model/priseRDV/LocaliteModel';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {UserContext} from '../../../../context/UserContext';

export interface AutoCompleteLocaliteProps {
  id: string,
  className?: string,
  label: string,
  isMandatory: boolean,
  selectedLocalite: LocaliteProps,
  errorMessage?: string,
  onError: boolean,
  onErrorAdresse: boolean,
  errorFields: ErrorFields,
  setErrorFields: (errorFields: ErrorFields) => void,
  onChange: (event: ChangeEvent<{}>, value: any) => void,
  onFocus: (event: React.FocusEvent<HTMLInputElement>) => void,
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void,
}

const styles = (theme: Theme) => ({
  textfield: {
    backgroundColor: theme.palette.white.main,
  },
  label: {
    color: theme.palette.secondary.dark2,
  },
  error: {
    color: 'red',
    fontSize: '12px',
  },
});

const AutoCompleteLocalite = (props: AutoCompleteLocaliteProps) => {
  const classes = useEmotionStyles(styles);
  const {user} = React.useContext(UserContext);
  const {id, className, label, isMandatory, selectedLocalite, errorMessage, onError, onErrorAdresse, errorFields,
    setErrorFields, onChange, onFocus, onBlur} = props;
  const [localites, setLocalites] = useState<LocaliteProps[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [valueSelected, setValueSelected] = useState<LocaliteProps | null>(null);

  useEffect(() => {
    setValueSelected(selectedLocalite)
  }, [selectedLocalite]);

  useEffect(()=> {
    const {codePostal, commune} = selectedLocalite;
    const termes = [codePostal, commune].filter(Boolean).join(' ');

    if (termes.length > 0 && termes.length <= 2 ) {
      setErrorFields([...errorFields,
        {filedName: 'localite', errorMessage: 'Saisissez un nom de localité ou un code postal valide'},
      ]);
    } else if (termes.length > 2) {
      setValueSelected(selectedLocalite);
      getLocalites(termes, user.numClient).then((response) => {
        const localiteFound = response.find((localite: LocaliteProps) =>
          localite.codePostal === selectedLocalite.codePostal ||
            localite.commune.toUpperCase() === selectedLocalite.commune.toUpperCase());

        if (localiteFound) {
          setErrorFields(errorFields.filter((error) => error.filedName !== 'localite'));
        } else {
          setErrorFields([...errorFields,
            {filedName: 'localite', errorMessage: 'Saisissez un nom de localité ou un code postal valide'},
          ]);
        }
      }).catch((error) => {
        console.error(error)
      })
    }
  }, []);

  const renderInputAutoCompletion = (params: any) => {
    return <FormControl fullWidth>
      <TextField
        {...params}
        id='localite'
        name='localite'
        margin="dense"
        size="small"
        variant="outlined"
        InputProps={{
          ...params.InputProps,
          classes: {
            root: classes.textfield,
          },
        }}
        error={onError || onErrorAdresse}
        onBlur={onBlur}
        onFocus={onFocus}
      />
      {isMandatory && onError && errorMessage &&
        <Typography id="component-error-text" className={classes.error}>
          {errorMessage}
        </Typography>
      }
    </FormControl>
  };

  const handleSearchLocality = (event: ChangeEvent<{}>, value: string) => {
    if (value.trim().length > 2) {
      setIsLoading(true);
      getLocalites(value, user.numClient).then((response) => {
        setLocalites(response);
        setIsLoading(false)
      }).catch((error) => {
        console.error(error)
      })
    } else {
      setLocalites([])
    }
  };

  const onSelectLocalite = (event: ChangeEvent<{}>, value: any) => {
    setValueSelected(value);
    onChange(event, value)
  };

  return <div className={className}>
    <Typography className={classes.label}>
      {label} {isMandatory && <span style={{color: 'red'}}>*</span>}
    </Typography>
    <Autocomplete
      id={id}
      autoHighlight
      loading={isLoading}
      loadingText='Chargement...'
      popupIcon={''}
      value={valueSelected}
      options={localites}
      getOptionLabel={(loc: LocaliteProps) => [loc.codePostal, loc.commune].filter(Boolean).join(' - ')}
      onChange={onSelectLocalite}
      onInputChange={handleSearchLocality}
      renderInput={renderInputAutoCompletion}
      filterOptions={(options) => options}
      noOptionsText={'Aucun résultat trouvé'}
    />
  </div>
};

export default AutoCompleteLocalite;
