import React, {useEffect, useState} from 'react';
import {Modal, Slide, Typography} from '@mui/material';
import {BlocAdresse} from '../../common/adresse/BlocAdresse';
import {ButtonLink} from '../../common/buttons/ButtonLink';
import {useNavigate} from 'react-router-dom';
import {RendezVousModel} from '../../../model/rechercherRendezVous/SearchRdvModel';
import moment from 'moment';
import {customTheme} from '../../../common/GlobalTheme';
import {RefusForm} from '../priseRDV/RefusForm';
import {Etiquette} from './Etiquette';
import {UserContext} from '../../../context/UserContext';
import useHasRole from '../../../hook/useHasRole';
import {permittedRolesRDV} from '../../../constantes/roles/Roles';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';

const base64 = require('base-64');

interface BlocRdvProps {
  rdv: RendezVousModel,
  referenceSinistre: string,
}

const styles = (theme: Theme) => ({
  cardContent: {
    height: '200px',
    backgroundColor: theme.palette.secondary.light,
    padding: '20px',
    borderRadius: '8px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  red: {
    color: 'red',
  },
});

const BlocRdv = (props: BlocRdvProps) => {
  const classes = useEmotionStyles(styles);
  const {user} = React.useContext(UserContext);
  const hasRoleModificationRDV = useHasRole(permittedRolesRDV);
  const {id, partenaire, dateDebut, dateFin, status, auteurRefus} = props.rdv;
  const {referenceSinistre} = props;
  const navigate = useNavigate();
  const [openRefusForm, setOpenRefusForm] = useState(false);
  const [statusExtranet, setStatusExtranet] = useState('');
  const [rdvActif, setRdvActif] = useState(true);
  const [bgColor, setBgColor] = useState('');
  const [color, setColor] = useState('');

  const setEtiquetteLibStyle = () => {
    if ((status === 'EnAttente' || status === 'Accepte' || status === 'NonRealise') &&
        moment(dateDebut) >= moment()) {
      setStatusExtranet('à venir');
      setBgColor('#0095DB19');
      setColor('#0095DB')
    } else if ((status === 'EnAttente' || status === 'Accepte') && moment(dateDebut) < moment()) {
      setStatusExtranet('passé');
      setRdvActif(false)
      setBgColor('#FEF9F2');
      setColor('#F4B35F')
    } else if (status === 'Refuse') {
      if (auteurRefus === 'Assureur') {
        setStatusExtranet('annulé')
        setRdvActif(false)
      } else {
        setStatusExtranet('refusé')
        setRdvActif(false)
      }
      setBgColor('#FCF0EF');
      setColor('#F0283F')
    } else if (status === 'NonRealise' && moment(dateDebut) < moment()) {
      setStatusExtranet('non réalisé');
      setBgColor('#DEDEE2');
      setColor('#241E46')
    } else if (status === 'Realise' && moment(dateDebut) < moment()) {
      setStatusExtranet('réalisé');
      setBgColor('#50A55B19');
      setColor('#1EA851')
    }
  };

  useEffect(() => {
    setEtiquetteLibStyle()
  }, []);

  const onClickConsulter = () => {
    const paramsRdv = {
      referenceSinistre: referenceSinistre,
      idPartenaire: partenaire.id,
      idRdv: id,
      rdvActif: rdvActif,
    };
    const params = base64.encode(JSON.stringify(paramsRdv));
    navigate(`/extranet/dossiers/detail-rdv/` + params)
  };

  const handleClickCloseModal = (reload: boolean) => {
    if (reload) {
      setOpenRefusForm(false);
      window.location.reload()
    } else {
      const result = window.confirm('Si vous n\'avez pas enregistré vos modifications, elles seront perdues.\n' + '\n' +
        'Souhaitez-vous continuer ?');
      if (result) {
        setOpenRefusForm(false)
      }
    }
  };

  const slideContentRefus = (
    <div>
      <RefusForm
        rdv={props.rdv}
        referenceSinistre={referenceSinistre}
        onClose={() => handleClickCloseModal(false)}
        onCloseAndRefresh={() => handleClickCloseModal(true)}
      />
    </div>
  );

  return (
    <>
      <div className={classes.cardContent}>
        <div style={{display: 'flex', justifyContent: 'space-between', height: '90%'}}>
          <div style={{width: '70%'}}>
            <Typography style={customTheme.styledTypography.libelle}>
              Rendez-vous avec le réparateur
            </Typography>
            <Typography style={{fontWeight: 'bold'}}>
              {partenaire.raisonSociale}
            </Typography>
            <div style={{marginTop: '-12px', marginBottom: '10px'}}>
              <BlocAdresse adresse={partenaire.adresse}/>
            </div>
            <div>
              <Typography style={customTheme.styledTypography.libelle}>
                Date du rendez-vous
              </Typography>
              <Typography style={{fontWeight: 'bold'}}>
                {moment(dateDebut).format('DD/MM/YYYY')}
              </Typography>
              <Typography>
                {`De ${moment(dateDebut).format('HH:mm')} à ${moment(dateFin).format('HH:mm')}`}
              </Typography>
            </div>
          </div>
          <div>
            <Etiquette
              id={'etiquette-status'}
              libelle={statusExtranet}
              style={{fontWeight: 'bold', backgroundColor: bgColor, color: color, borderColor: color}}
            />
          </div>
        </div>
        <div className={classes.footer}>
          {hasRoleModificationRDV && (user.type === 'Assureur' || user.type === 'Courtier') && status !== 'Refuse' &&
              status !== 'Realise' && ((status === 'Accepte' || status === 'EnAttente' || status === 'NonRealise') &&
                  moment(dateDebut) >= moment()) &&
              <ButtonLink
                id={'boutonAnnulerRDV' + id}
                textColor={'red'}
                fontSize={'14px'}
                libelle={'Annuler'}
                isLibelleUpperCase={true}
                isLibelleBold={true}
                onClick={() => setOpenRefusForm(true)}
              />
          }
          <ButtonLink
            id={'boutonConsulterRDV' + id}
            fontSize={'14px'}
            libelle={'Consulter'}
            isLibelleUpperCase={true}
            isLibelleBold={true}
            onClick={onClickConsulter}
          />
        </div>
      </div>

      <Modal
        open={openRefusForm}
        aria-labelledby="modal-form-annulation"
        aria-describedby="modal-formulaire-envoie-annulation"
      >
        <Slide in={openRefusForm} direction={'left'} timeout={800} mountOnEnter unmountOnExit>
          {slideContentRefus}
        </Slide>
      </Modal>
    </>
  );
};

export default BlocRdv;
