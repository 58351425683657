import {Dispatch} from 'react'
import {SearchAction, SearchDataState} from '../reducers/SearchReducer'
import {SearchModel} from '../model/common/SearchModel'

export type SearchActions = {
  setSearch: (search : SearchModel) => void
}

export const getSearchActions = (state: SearchDataState, dispatch: Dispatch<SearchAction>) => ({
  setSearch: (search : SearchModel) => {
    dispatch({type: 'SET_SEARCH', search: search})
  },
});
