import * as yup from 'yup';
import {checkAdresse} from '../../../../common/utils/InputValuesUtils';

export const validationSchemaAdresse = yup.object({
  adresse: yup.object({
    numero: yup.string(),
    indice: yup.string(),
    type: yup.string(),
    nom: yup.string()
        .required('Veuillez saisir une valeur')
        .test('test-nom', 'Les caractères ! ? * ° : € # ; & + @ % ne sont pas autorisés',
            function(value) {
              return checkAdresse(value || '')
            }),
    lieuDit: yup.string()
        .test('test-lieuDit', 'Les caractères ! ? * ° : € # ; & + @ % ne sont pas autorisés',
            function(value) {
              return checkAdresse(value ?? '')
            }),
    etage: yup.string()
        .test('test-etage', 'Les caractères ! ? * ° : € # ; & + @ % ne sont pas autorisés',
            function(value) {
              return checkAdresse(value ?? '')
            }),
    immeuble: yup.string()
        .test('test-immeuble', 'Les caractères ! ? * ° : € # ; & + @ % ne sont pas autorisés',
            function(value) {
              return checkAdresse(value ?? '')
            }),
    codePostal: yup.string()
        .required('Veuillez saisir une valeur')
        .min(5, 'Veuillez saisir une valeur au format attendu, voir'),
    boitePostale: yup.string()
        .test('test-boitePostale', 'Les caractères ! ? * ° : € # ; & + @ % ne sont pas autorisés',
            function(value) {
              return checkAdresse(value ?? '')
            }),
    ville: yup.string()
        .required('Veuillez saisir une valeur')
        .test('test-ville', 'Les caractères ! ? * ° : € # ; & + @ % ne sont pas autorisés',
            function(value) {
              return checkAdresse(value || '')
            }),
    pays: yup.string().required('Veuillez saisir une valeur'),
  }),
});
